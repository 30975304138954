<template>
  <div>
    <v-card-title class="ma-2 pa-2 display-1">Lot: {{ lotId }}</v-card-title>
    <v-divider></v-divider>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="6" md="5">
        <v-row align="center" justify="center">
          <v-card class="mx-auto pa-4 text-center" width="400">
            <v-img max-height="250" contain :src="currentPhoto"></v-img>
          </v-card>
        </v-row>
      </v-col>

      <v-col>
        <!-- <v-row justify="center" class="">Time left: {{timeLeft}}</v-row> -->
        <v-row align="center" justify="center">
          <v-card class="mx-auto pa-4 text-center" width="400">
            <v-row
              v-if="countdownStatus != 'end'"
              justify="center"
              class="headline red--text"
              >Time left: {{ timeLeft }}</v-row
            >
            <v-row
              v-if="countdownStatus == 'end'"
              justify="center"
              class="headline red--text"
              >Auction has ended</v-row
            >
            <!-- <div v-if="higestBidPrice >= lotDetails.price"> -->
            <div>
              <v-row align="center" justify="center" class="ma-0">
                <v-btn
                  :disabled="currentBidder == user.id || offerBidPrice == 0"
                  @click="addMinusBiding('-')"
                  :dark="currentBidder != user.id && offerBidPrice != 0"
                  color="red"
                  class="ml-0 headline"
                  >-</v-btn
                >
                <v-col cols="6" sm="6" md="6">
                  <v-card class="pa-2 headline">{{
                    ringgitFormat(offerBidPrice)
                  }}</v-card>
                </v-col>
                <v-btn
                  :disabled="currentBidder == user.id"
                  @click="addMinusBiding('+')"
                  :dark="currentBidder != user.id"
                  color="green"
                  class="ml-0 headline"
                  >+</v-btn
                >
              </v-row>
              <!-- <v-btn :disabled="currentBidder == user.email" @click="addMinusBiding('reset')" color="grey--1" class="subtitle-1">Reset Amount</v-btn> -->
              <v-col cols="12">
                <!-- <div class="mb-2">*Increase bid to <span class="font-weight-medium pl-1">{{ringgitFormat(this.higestBidPrice + parseInt(this.offerBidPrice))}}*</span></div> -->
                <div v-if="higestBidPrice >= lotDetails.price" class="mb-2">
                  *Increase bid to
                  <span class="font-weight-medium pl-1"
                    >RM{{
                      this.higestBidPrice + parseInt(this.offerBidPrice)
                    }}*</span
                  >
                </div>
                <div v-if="higestBidPrice < lotDetails.price" class="mb-2">
                  *Increase bid to
                  <span class="font-weight-medium pl-1"
                    >RM{{
                      lotDetails.price + parseInt(this.offerBidPrice)
                    }}*</span
                  >
                </div>
                <!-- <v-card class="pa-2">This Lot can be bid with a minimum amount of <span class="font-weight-medium">{{ringgitFormat(priceAddPerBid)}}</span></v-card> -->
              </v-col>
            </div>
            <v-divider></v-divider>
            <v-btn
              v-if="higestBidPrice < lotDetails.price"
              :disabled="currentBidder == user.id"
              @click="
                promptBidRaise();
                startOrRaise = 'start';
              "
              :dark="currentBidder != user.id"
              color="orange"
              class="ma-5 headline"
              >Start Bid</v-btn
            >
            <v-btn
              v-if="higestBidPrice >= lotDetails.price"
              :disabled="currentBidder == user.id"
              @click="
                promptBidRaise();
                startOrRaise = 'raise';
              "
              :dark="currentBidder != user.id"
              color="orange"
              class="ma-5 headline"
              >Raise Bid</v-btn
            >
            <div v-if="currentBidder == user.id">
              <!-- <div class="display-1 orange--text">Congratulation!! <v-icon large color="red">mdi-party-popper</v-icon></div> -->
              <div class="title orange--text">You are the highest bidder!!</div>
            </div>
            <v-row
              v-if="higestBidPrice >= lotDetails.price"
              align="center"
              justify="center"
            >
              <v-card-title class="grey--text pa-0 mr-1"
                >Current bidding price:
              </v-card-title>
              <div class="headline d-flex">
                {{ ringgitFormat(higestBidPrice) }}
              </div>
            </v-row>
            <v-row
              v-if="higestBidPrice < lotDetails.price"
              align="center"
              justify="center"
            >
              <v-card-title class="grey--text">Starting price: </v-card-title>
              <div class="headline d-flex">
                {{ ringgitFormat(lotDetails.price) }}
              </div>
            </v-row>
            <v-card class="ma-1 pa-2 mb-3">
              <v-row justify="center" class="ma-0 pa-0">
                <v-btn
                  class="text-center"
                  @click="getCurrentBidder(lotId)"
                  color="green"
                  dark=""
                  ><v-icon large="">mdi-refresh</v-icon></v-btn
                >
              </v-row>
              <div class="text-center">
                Click the refresh button to see the latest ongoing bidding.
              </div>
            </v-card>
          </v-card>
        </v-row>
        <v-row class="pa-2 ma-0" justify="center">
          <v-btn @click="previousPage()" color="grey lighten-1">Back</v-btn>
        </v-row>
      </v-col>

      <!-- <v-col cols="12" sm="6" md="6">                
                <v-card flat class="mx-auto mt-2 pa-5">
                    <v-row dense="" v-for="(detail,i) in lotDetails" v-bind:key="i">
                        <div class="ma-1 grey--text d-flex align-center">{{i}}: </div>
                        <v-col class="ma-1">{{detail}}</v-col>
                    </v-row>                    
                </v-card>                
            </v-col> -->
    </v-row>

    <v-dialog v-model="show.bidAlert" persistent max-width="600px">
      <v-card class="pa-2 mb-1">
        <v-row justify="center" class="pa-0 ma-0">
          <v-col class="text-center pa-0 ma-0">
            <div v-if="higestBidPrice >= lotDetails.price" class="pa-5 title">
              Are you sure you want to Raise your bid to RM{{ tempBidPrice }}?
            </div>
            <div v-if="higestBidPrice < lotDetails.price" class="pa-5 title">
              Do you want to start bidding at RM{{ tempBidPrice }}?
            </div>
            <v-row
              v-if="higestBidPrice >= lotDetails.price"
              class="pa-0 ma-0 text-center"
              justify="center"
            >
              <v-col>
                <div class="d-flex">Current price:</div>
                <div class="headline d-flex">
                  {{ ringgitFormat(higestBidPrice) }}
                </div>
              </v-col>
              <v-col class="headline" align-self="center">>></v-col>
              <v-col>
                <div class="d-flex orange--text">Raised price:</div>
                <div class="headline d-flex orange--text">
                  {{ ringgitFormat(tempBidPrice) }}
                </div>
              </v-col>
            </v-row>
            <v-divider class="info" style="opacity: 0.22"></v-divider>
            <v-row class="pa-0 ma-0" align="center" justify="center">
              <v-btn
                @click="ConfirmBidRaise('yes')"
                dark
                color="green"
                class="ma-5 headline"
                >Yes</v-btn
              >
              <v-btn
                @click="ConfirmBidRaise('no')"
                dark
                color="red"
                class="ma-5 headline"
                >No</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="show.outbid" persistent max-width="600px">
      <v-card class="pa-2 mb-1">
        <v-row justify="center" class="pa-0 ma-0">
          <v-col class="text-center pa-0 ma-0">
            <div class="pa-1 title red--text">Sorry</div>
            <div class="pa-1 title red--text">You have been outbid</div>
            <v-row class="pa-0 ma-0" align="center" justify="center">
              <v-btn
                @click="ConfirmBidRaise('ok')"
                dark
                color="grey"
                class="ma-3 headline"
                >OK</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="permission.exceedwinning" persistent max-width="600px">
      <v-card class="pa-2 mb-1">
        <v-row justify="center" class="pa-0 ma-0">
          <v-col class="text-center pa-0 ma-0">
            <div class="pa-1 title red--text">
              Exceeded number of winning auction lot
            </div>
            <v-divider></v-divider>
            <div class="pa-1 text-center">
              You can only bid in an auction lot with
              <span class="font-weight-bold">{{ winnings }}</span> winning/s at
              a time.
            </div>
            <v-btn
              class="mx-5 mt-1"
              @click="
                () => {
                  permission.exceedwinning = false;
                }
              "
              color="grey"
              dark
              >Close</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="permission.exceedwinningPrivate"
      persistent
      max-width="600px"
    >
      <v-card class="pa-2 text-center">
        <v-row justify="center" class="pa-1 ma-0 font-weight-bold"
          >You already have the highest bid for a lot.
          {{ session.publicOrPrivate }} auction only allows one winning lot per
          person in one session.</v-row
        >
        <v-row justify="center" class="pa-1 ma-0">
          <v-btn
            color="orange"
            dark
            @click="permission.exceedwinningPrivate = false"
            >OK</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import io from "socket.io-client";
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
import storage from "../jscontroller/storage";
export default {
  data() {
    return {
      // socket: {},
      display: "",
      serverUrl: serverConfig.hostname,
      lotId: this.$route.params.lotId,
      higestBidPrice: 0,
      priceAddPerBid: 0,
      offerBidPrice: 200,
      tempBidPrice: 0,
      timeLeft: "",
      show: {
        bidAlert: false,
        outbid: false,
      },
      lotDetails: {},
      user: {},
      session: 0,
      countdownStatus: "",
      currentBidder: null,
      currentPhoto: "",
      winnings: 0,
      permission: {
        exceedwinning: false,
        exceedwinningPrivate: false,
      },
      startOrRaise: "raise",
      snackbar: {
        showProgress: false,
        showStatus: false,
        text: "",
        timeout: 4000,
        icon: "mdi-check-circle-outline",
        color: "green",
      },
    };
  },
  created() {
    this.user = storage.GetUser();
    this.socket = io.connect(serverConfig.hostnameSocket, {
      path: "/socket.io",
    });
  },
  mounted() {
    this.checkUserinLot(this.user.id, this.lotId);
    this.scrollToTop();
    this.checkRole();
    this.socket.emit("joinAuction", { user: this.user.id, lotNo: this.lotId });
    // this.socket.on("updateBidPrice", data =>{
    //     // data:{id,latestEmail, latestBid}
    //     if(data.latestBid > this.higestBidPrice){
    //         this.higestBidPrice = parseInt(data.latestBid);
    //         this.currentBidder = data.latestEmail;
    //     }
    // });
    this.getAuctionLotsById(this.lotId);
    this.getCurrentBidder(this.lotId);
  },
  methods: {
    navigate: function(pagePath) {
      if (this.$route.path != pagePath) {
        this.$router.push(pagePath);
      }
    },
    previousPage() {
      window.history.back();
    },
    ringgitFormat(amount) {
      if (amount == null) {
        return "RM " + (0).toFixed(2);
      } else {
        var num = amount.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        });
        var deci = num.split(".")[1];
        if (deci != null) {
          if (deci.length == 1) {
            num = num + "0";
          }
        } else {
          num = num + ".00";
        }
        return "RM " + num;
      }
    },
    checkRole() {
      var self = this;
      if (
        self.user.role != "admin" &&
        self.user.role != "user" &&
        self.user.role != "vendor"
      ) {
        self.navigate("/");
      }
    },
    addMinusBiding(symbol) {
      if (symbol == "+")
        this.offerBidPrice =
          parseInt(this.offerBidPrice) + parseInt(this.priceAddPerBid);
      else if (
        symbol == "-" &&
        this.offerBidPrice != this.priceAddPerBid &&
        this.offerBidPrice != 0
      )
        this.offerBidPrice =
          parseInt(this.offerBidPrice) - parseInt(this.priceAddPerBid);
      else if (
        symbol == "-" &&
        this.higestBidPrice < this.lotDetails.price &&
        this.offerBidPrice != 0
      )
        this.offerBidPrice =
          parseInt(this.offerBidPrice) - parseInt(this.priceAddPerBid);
      else if (symbol == "-" && this.higestBidPrice > this.lotDetails.price)
        this.offerBidPrice = parseInt(this.priceAddPerBid);
    },
    promptBidRaise() {
      var self = this;
      self.getDepositBalace(self.user.id, (exceedwinning) => {
        if (exceedwinning == true) {
          self.permission.exceedwinning = true;
        } else {
          self.show.bidAlert = true;
          var tempBP = 0;
          if (self.higestBidPrice == 0) {
            tempBP = self.offerBidPrice + self.lotDetails.price;
          } else {
            tempBP = self.offerBidPrice + self.higestBidPrice;
          }
          self.tempBidPrice = tempBP;
        }
      });
    },
    ConfirmBidRaise(confirm) {
      if (confirm == "yes") {
        var self = this;

        var body = {
          id: this.lotId,
          latestEmail: this.user.email,
          bidderNo: this.user.id,
          bidderName: this.user.name,
          latestBid: parseInt(this.tempBidPrice),
          session: this.lotDetails.session,
          deposit: this.lotDetails.deposit,
          offerBidPrice: this.offerBidPrice,
          startOrRaise: this.startOrRaise,
          vendor: this.lotDetails.vendor,
        };

        self.uploadAuctionLotSocket(body);
      }

      if (confirm == "ok" || confirm == "no") {
        this.show.outbid = false;
      }

      this.offerBidPrice = parseInt(this.priceAddPerBid);
      this.show.bidAlert = false;
      if (this.higestBidPrice < this.lotDetails.price) this.offerBidPrice = 0;
    },
    uploadAuctionLotSocket(body) {
      var apiUrl = serverConfig.hostname + "/api/socket/auctionlot/post";
      var self = this;
      axios
        .post(apiUrl, body)
        .then(function(response) {
          self.higestBidPrice = response.data.auctionLotSocket.latestBid;
          self.currentBidder = response.data.auctionLotSocket.bidderNo;
          self.socket.emit("sendDisplayBidding", body);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(err) {
          const message = err.response.data.message;
          self.higestBidPrice = err.response.data.auctionLotSocket.latestBid;
          self.currentBidder = err.response.data.auctionLotSocket.bidderNo;

          // eslint-disable-next-line no-console
          // console.log(err);
          if (message == "failed") {
            self.show.outbid = true;
          } else {
            self.hideSnackbar(message, "red");

            if (message == "Auction has ended") {
              if (self.serverUrl != "http://localhost:8081")
                window.location.href = self.serverUrl + "/myaccount/mybid";
              else
                window.location.href = "http://localhost:8080/myaccount/mybid";
            }
          }
        });
    },
    getAuctionLotsById(id) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/auctionlot/get/" + id;
      axios
        .get(apiUrl)
        .then(function(response) {
          self.lotDetails = response.data;
          self.getSession(self.lotDetails.session);
          self.getAuctionLotFileNamesQuery(self.lotDetails.regnNo);
          self.priceAddPerBid = self.lotDetails.addPrice;
          self.offerBidPrice = self.lotDetails.addPrice;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {});
    },
    getCurrentBidder(id) {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/socket/auctionlot/" + id;
      axios
        .get(apiUrl)
        .then(function(response) {
          var data = response.data;
          if (data != null && data != "") {
            self.higestBidPrice = data.latestBid;
            self.currentBidder = data.bidderNo;
          } else self.offerBidPrice = 0;
          self.hideSnackbar("Finish fetching latest bid..", "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {
          self.hideSnackbar("Failed to refresh", "red");
        });
    },
    getSession(sessionNo) {
      var self = this;
      var apiUrl =
        serverConfig.hostname + "/api/auctionmanager/session/" + sessionNo;
      axios
        .get(apiUrl)
        .then(function(response) {
          self.session = response.data;
          self.getServerTime((serverTime) => {
            var currentTime = new Date();
            setInterval(() => {
              if (self.session == "") {
                self.countdownStatus = "end";
                if (self.serverUrl != "http://localhost:8081")
                  window.location.href = self.serverUrl + "/myaccount/mybid";
                else
                  window.location.href =
                    "http://localhost:8080/myaccount/mybid";
              } else {
                self.countdown(self.session, serverTime, currentTime);
              }
            }, 1000);
          });
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {});
    },
    checkUserinLot(id, lotNo) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/socket/userinlot/find";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      bodyFormData.set("lotNo", lotNo);
      axios
        .post(apiUrl, bodyFormData)
        .then(function(response) {
          if (response.data == "user not in lot") {
            self.navigate("/onlineAuction/onAuction/1");
          }
        })
        .catch(function(err) {
          self.navigate("/onlineAuction/onAuction/1");
          self.showSnackbar();
          self.hideSnackbar(err.response.data, "red");
        });
    },
    getServerTime(callback) {
      var apiUrl = serverConfig.hostname + "/api/time/get/monthdayformat";
      axios
        .get(apiUrl)
        .then(function(response) {
          callback(response.data);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {});
    },
    countdown(session, serverTime, currentTime) {
      var self = this;
      var countDownEnd = new Date(
        `${session.endDate} ${session.endTime} +8:00`
      ).getTime();
      var countDownStart = new Date(
        `${self.session.startDate} ${self.session.startTime} +8:00`
      ).getTime();
      var timeLeft = "";
      var now =
        new Date(serverTime).getTime() +
        new Date().getTime() -
        new Date(currentTime).getTime();
      if (now < countDownStart && now < countDownEnd) {
        self.countdownStatus = "not started";
      } else if (now > countDownStart && now < countDownEnd) {
        self.countdownStatus = "started";
      } else if (now > countDownStart && now > countDownEnd) {
        self.countdownStatus = "end";
        if (self.serverUrl != "http://localhost:8081")
          window.location.href = self.serverUrl + "/myaccount/mybid";
        else window.location.href = "http://localhost:8080/myaccount/mybid";
        // self.navigate('/itempage/' + self.lotId);
      }
      // Find the distance between now and the count down date
      var distance = countDownEnd - now;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      timeLeft = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
      this.timeLeft = timeLeft;
      // eslint-disable-next-line no-console
    },
    getAuctionLotFileNamesQuery(regnNo) {
      var self = this;
      var apiUrl = self.serverUrl + "/api/file/filenames";
      var bodyFormData = new FormData();
      bodyFormData.set("folderName", regnNo);
      bodyFormData.set("folderName2", "auctionlot");
      bodyFormData.set("folderType", "image");
      axios
        .post(apiUrl, bodyFormData)
        .then(function(response) {
          var photos = response.data;
          if (response.data != "") {
            self.currentPhoto =
              self.serverUrl +
              "/api/file/image/auctionlot/" +
              regnNo +
              "/" +
              photos[0];
            for (var i = 0; i < photos.length; i++) {
              if (photos[i] == "0" || photos[i] == "0.jpg")
                self.currentPhoto =
                  self.serverUrl +
                  "/api/file/image/auctionlot/" +
                  regnNo +
                  "/" +
                  photos[i];
            }
          } else self.currentPhoto = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1200px-No_image_available.svg.png";
          // for(var i=0; i< photos.length; i++){
          //     self.photos.push(self.serverUrl + '/api/file/image/auctionlot/'+ lotNo +'/'+photos[i]);
          // }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function() {
          // self.photos = [];
          self.currentPhoto =
            "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1200px-No_image_available.svg.png";
        });
    },
    getDepositBalace(id, callback) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/user/asset";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      axios
        .post(apiUrl, bodyFormData)
        .then(function(response) {
          var depositBalance = response.data.depositBalance;
          self.countWinningAuctions(id, depositBalance, (exceedwinning) => {
            callback(exceedwinning);
          });
        })
        .catch(function() {});
    },
    countWinningAuctions(id, depositBalance, callback) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/socket/winning/id";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      axios
        .post(apiUrl, bodyFormData)
        .then(function(response) {
          var winningLot = response.data;
          var totalDepositToBeUsed = 0;
          self.winnings = response.data.length;
          for (var i = 0; i < winningLot.length; i++) {
            totalDepositToBeUsed = totalDepositToBeUsed + winningLot[i].deposit;
          }
          totalDepositToBeUsed = totalDepositToBeUsed + self.lotDetails.deposit;
          var exceedwinning = true;
          // part ni penting utk setiap TM Staff bleh ada satu winning auction shaja
          if (
            self.session.publicOrPrivate == "public" ||
            self.session.publicOrPrivate != "TM Staff"
          ) {
            if (totalDepositToBeUsed > depositBalance) {
              exceedwinning = true;
            } else exceedwinning = false;
            callback(exceedwinning);
          } else {
            if (self.session.publicOrPrivate == "TM Staff") {
              if (self.winnings > 0) {
                self.permission.exceedwinningPrivate = true;
                self.addMinusBiding("reset");
              } else callback(false);
            }
          }
        })
        .catch(function() {});
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
