<template>
  <div class="text-center mb-5">
    <v-btn color="cyan" block dark @click="show.reserveUnitModel = true"
      >Reserve Unit</v-btn
    >

    <v-dialog v-model="show.reserveUnitModel" max-width="600px">
      <v-card class="pa-2 mb-1">
        <v-row class="pa-2 ma-0">
          <h3>Reserve Unit</h3>
        </v-row>
        <v-card class="pa-2 mb-1">
          <v-col class="pa-1 ma-1">
            <div>
              <span class="font-weight-bold">Bidder No: </span>{{ bidder.id }}
            </div>
            <div>
              <span
                v-if="bidder.accountType == 'Individual'"
                class="font-weight-bold"
                >Name:</span
              >
              <span
                v-if="bidder.accountType == 'Organization'"
                class="font-weight-bold"
                >Organization:</span
              >
              {{ bidder.firstName }}
            </div>
            <div>
              <span
                v-if="bidder.accountType == 'Individual'"
                class="font-weight-bold"
                >IC No:</span
              >
              <span
                v-if="bidder.accountType == 'Organization'"
                class="font-weight-bold"
                >SSM No:</span
              >
              {{ bidder.ic }}
            </div>
            <div v-if="bidder.accountType == 'Organization'">
              <span class="font-weight-bold">Representative Name:</span>
              {{ bidder.representativeName }}
            </div>
            <div v-if="bidder.accountType == 'Organization'">
              <span class="font-weight-bold">Representative IC No:</span>
              {{ bidder.representativeIc }}
            </div>
          </v-col>
        </v-card>
        <v-col class="pa-2 ma-0">
          Choose an auction session..
          <v-select
            class="pa-0"
            filled
            dense=""
            v-model="selectAuction"
            :items="auctionHistoryList"
            :hint="`${selectAuction.session} - Date: ${selectAuction.startDate}`"
            :item-text="(item) => item.session + ' (' + item.startDate + ')'"
            item-value="session"
            hide-details="auto"
            label="Auction Name"
            return-object
          >
          </v-select>
          <!-- item-text="session" -->
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-row class="pa-0 ma-0">
            <v-text-field
              prepend-inner-icon="mdi-account"
              class="pa-0"
              filled
              dense=""
              v-model="regnNo"
              hide-details="auto"
              label="Regn No"
            >
            </v-text-field>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-row class="pa-0 ma-0">
            <v-text-field
              prepend-inner-icon="mdi-account"
              class="pa-0"
              filled
              dense=""
              v-model="soldPrice"
              hide-details="auto"
              label="Sold Price"
              type="number"
            >
            </v-text-field>
          </v-row>
        </v-col>

        <div class="text-center">
          <v-btn color="blue" dark @click="reserveUnit()">Reserve Unit</v-btn>
        </div>
        <v-btn
          @click="
            () => {
              show.reserveUnitModel = false;
            }
          "
          >Close</v-btn
        >
      </v-card>
    </v-dialog>

    <SBAlert :alert="alert"></SBAlert>
  </div>
</template>

<script>
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
import storage from "../jscontroller/storage";
import adminApi from "../api/adminApi";
import SBAlert from "@/components/SBAlert";
export default {
  components: { SBAlert },
  props: ["bidder"],
  data: () => ({
    serverUrl: serverConfig.hostname,
    show: {
      reserveUnitModel: false,
    },
    alert: {
      showLoading: false,
      showStatus: false,
      isSuccess: true,
      message: "",
    },
    selectAuction: {},
    auctionHistoryList: [],
    regnNo: "",
    soldPrice: 0,
  }),
  created() {},
  mounted() {
    this.user = storage.GetUser();
    this.getAuctionName();
  },
  methods: {
    async reserveUnit() {
      const self = this;
      try {
        self.alert.showLoading = true;

        const bodyForm = {
          userId: self.bidder.id,
          regnNo: self.regnNo,
          auctionSession: self.selectAuction.session,
          soldPrice: self.soldPrice,
        };

        const response = await adminApi.reserveUnit(bodyForm);
        self.alert.showLoading = false;
        self.alert.isSuccess = true;
        self.alert.showStatus = true;
        self.alert.message = response.message;
        self.resetInput();

        self.show.reserveUnitModel = false;
      } catch (error) {
        self.alert.showLoading = false;
        self.alert.isSuccess = false;
        self.alert.showStatus = true;
        self.alert.message = error;
        self.resetInput();
      }
    },

    resetInput() {
      const self = this;

      self.regnNo = null;
      self.selectAuction = {};
      self.soldPrice = 0;
    },

    getAuctionName() {
      var self = this;
      var apiUrl = self.serverUrl + "/api/auctionmanager/session/history/all";
      axios
        .get(apiUrl)
        .then(function (response) {
          var tempAuctionHList = response.data;
          self.auctionHistoryList = [];
          for (var i = 0; i < tempAuctionHList.length; i++) {
            self.auctionHistoryList.unshift(tempAuctionHList[i]);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
  },
  watch: {},
};
</script>
