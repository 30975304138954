<template>
  <div>
    <div class="d-none d-sm-flex">
      <v-app-bar class="" height="380" :color="navColor(showNav)">
        <!-- <v-app-bar height="310" app>     -->
        <v-col class="pa-0 ma-0">
          <v-row align="center" justify="center" class=" text-right">
            <v-img
              class=""
              max-height="500"
              max-width="500"
              src="../assets/amsauction_logo.png"
              lazy-src="../assets/amsauction_logo.png"
            >
            </v-img>
          </v-row>
          <div class="subheading text-right white--text">
            Auction Management Services Sdn Bhd
            <span class="black--text">(1322220-W)</span>
          </div>
          <div class="body-2 text-right">
            (formerly known as Fleet Management Solution Sdn Bhd)
          </div>
          <v-row>
            <v-col cols="10" class="pa-0 ma-0 flex-grow-0 flex-shrink-1">
              <Navbar2
                :storageUser="user"
                :noOfLoseBid="noOfLoseBid"
                width="90%"
                v-if="!showNav"
              />
              <AdminNavbar :storageUser="user" v-if="showNav" />
            </v-col>
            <v-col cols="2" class="flex-grow-1 flex-shrink-0 pa-0 ma-0">
              <v-tabs
                v-if="
                  (user.role == 'admin' || user.role == 'vendor') && showNav
                "
                background-color="rgb(15, 106, 210)"
                dark
                color=""
                align="center"
              >
                <v-spacer></v-spacer>
                <v-btn
                  @click="logout()"
                  color=""
                  class="ma-1"
                  v-if="checkLoggedIn()"
                  >Logout</v-btn
                >
              </v-tabs>
              <v-tabs
                v-if="
                  (user.role != 'admin' && user.role != 'vendor') || !showNav
                "
                background-color="#d26e0f"
                dark
                color=""
                align="center"
              >
                <v-spacer></v-spacer>
                <router-link
                  v-if="!checkLoggedIn()"
                  class="ma-1"
                  style="text-decoration:none"
                  :to="{ path: '/login' }"
                  ><v-btn color="">Login</v-btn></router-link
                >
                <v-btn
                  @click="logout()"
                  color=""
                  class="ma-1"
                  v-if="checkLoggedIn()"
                  >Logout</v-btn
                >
              </v-tabs>
            </v-col>
          </v-row>
        </v-col>
      </v-app-bar>
    </div>

    <div class="d-flex d-sm-none mx-auto overflow-hidden">
      <v-app-bar color="#ffa11f" dark :app="removeAppMargin()">
        <v-badge
          overlap
          left
          offset-x=""
          offset-y="25"
          v-if="noOfLoseBid > 0"
          color="red"
          :content="noOfLoseBid"
        >
          <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
        </v-badge>
        <v-app-bar-nav-icon
          @click="drawer = true"
          v-if="!noOfLoseBid > 0"
        ></v-app-bar-nav-icon>
        <v-toolbar-title>AMS Auction</v-toolbar-title>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" app temporary height="100%">
        <!-- <v-img :aspect-ratio="16/9" src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"> -->
        <v-card>
          <v-img
            class="text-center"
            src="../assets/amsauction_logo.png"
            gradient="to top right, rgba(197, 197, 197, 0.39), rgba(255, 255, 255, 0.17)"
          ></v-img>
        </v-card>

        <v-list nav dense>
          <div v-if="user != null" class="ml-2 font-italic">
            Welcome <span class="font-weight-bold">{{ user.name }}</span>
          </div>
          <v-divider></v-divider>
          <v-list-item-group active-class="deep-orange--text text--accent-4">
            <v-list-item
              v-for="(page, i) in pages"
              :key="i"
              @click="
                drawer = false;
                navigate(page.path);
              "
            >
              <v-list-item-icon>
                <v-icon>{{ page.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ page.name }}</v-list-item-title>
            </v-list-item>

            <v-list-group>
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>mdi-help-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Help</v-list-item-title>
              </template>

              <!-- <v-list-item class="ml-5 pl-5" @click="drawer = false;navigate('/help/faq')">                                
                                <v-list-item-title>FAQ</v-list-item-title>
                            </v-list-item> -->
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/help/termsAndCondition');
                "
              >
                <v-list-item-title>T&C</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/help/bidderProcess');
                "
              >
                <v-list-item-title>Bidder Process</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/help/onlinePayment');
                "
              >
                <v-list-item-title>Online Payment</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/help/tutorial');
                "
              >
                <v-list-item-title>Tutorial</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/help/contactUs');
                "
              >
                <!-- <v-list-item-icon>
                                    <v-icon>mdi-phone</v-icon>
                                </v-list-item-icon> -->
                <v-list-item-title>Contact Us</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-btn
              @click="navigate('/login')"
              v-if="!checkLoggedIn()"
              block
              color="orange"
              dark
              >Login</v-btn
            >

            <v-list-group v-if="checkLoggedIn()">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-badge
                    offset-x=""
                    offset-y="10"
                    v-if="noOfLoseBid > 0"
                    color="red"
                    :content="noOfLoseBid"
                  >
                    <v-icon>mdi-account</v-icon>
                  </v-badge>
                  <v-icon v-if="!noOfLoseBid > 0">mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Account</v-list-item-title>
              </template>

              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/myaccount/mybid');
                "
              >
                <v-list-item-title>My Bid</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/myaccount/bidonline');
                "
              >
                <v-badge
                  offset-x=""
                  offset-y="10"
                  v-if="noOfLoseBid > 0"
                  color="red"
                  :content="noOfLoseBid"
                >
                  <v-list-item-title>Bid In Progress</v-list-item-title>
                </v-badge>
                <v-list-item-title v-if="!noOfLoseBid > 0"
                  >Bid In Progress</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/myaccount/transaction');
                "
              >
                <v-list-item-title>Transaction</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/myaccount/myprofile');
                "
              >
                <v-list-item-title>My Profile</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-group v-if="user.role == 'admin' && user.id <= 2">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>mdi-head-minus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Admin</v-list-item-title>
              </template>

              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/adminPage');
                "
              >
                <v-list-item-title>Homepage Manager</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/adminPage/mailing');
                "
              >
                <v-list-item-title>Mailing</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/adminPage/auctionManager');
                "
              >
                <v-list-item-title>Auction Manager</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/adminPage/userManager');
                "
              >
                <v-list-item-title>User Manager</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/adminPage/documentManager');
                "
              >
                <v-list-item-title>Document Manager</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/adminPage/unitMonitor');
                "
              >
                <v-list-item-title>Unit Monitor</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/adminPage/reports');
                "
              >
                <v-list-item-title>Reports</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/adminPage/itemRecords');
                "
              >
                <v-list-item-title>Item Records</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-group
              v-if="
                user.role == 'vendor' || (user.role == 'admin' && user.id > 2)
              "
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>mdi-head-minus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Admin</v-list-item-title>
              </template>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/adminPage/userManager');
                "
              >
                <v-list-item-title>User Manager</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/adminPage/unitMonitor');
                "
              >
                <v-list-item-title>Unit Monitor</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/adminPage/reports');
                "
              >
                <v-list-item-title>Reports</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="ml-5 pl-5"
                @click="
                  drawer = false;
                  navigate('/adminPage/itemRecords');
                "
              >
                <v-list-item-title>Item Records</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-btn
              block
              @click="logout()"
              color=""
              class="ma-0 pa-0"
              v-if="checkLoggedIn()"
              >Logout</v-btn
            >
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>

    <!-- User bid notification -->
    <v-snackbar
      :class="snackbar.class1"
      v-model="snackbar.show1"
      :timeout="snackbar.timeout"
    >
      <v-row class="pa-0 ma-0" justify="center">
        <v-icon large color="green">mdi-arrow-top-right-thick</v-icon>
        <v-col class="pa-0 ma-0 headline white--text">
          {{ snackbar.text1 }}
        </v-col>
      </v-row>
    </v-snackbar>
    <v-snackbar
      :class="snackbar.class2"
      v-model="snackbar.show2"
      :timeout="snackbar.timeout"
    >
      <v-row class="pa-0 ma-0" justify="center">
        <v-icon large color="green">mdi-arrow-top-right-thick</v-icon>
        <v-col class="pa-0 ma-0 headline white--text">
          {{ snackbar.text2 }}
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import Navbar2 from "@/components/Navbar2";
import AdminNavbar from "@/components/AdminNavbar";
import axios from "axios";
import io from "socket.io-client";
import serverConfig from "../jscontroller/serverConfig";
import storage from "../jscontroller/storage";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Navbar2, AdminNavbar, storage },
  data() {
    return {
      showNav: false,
      drawer: false,
      storageUser: null,
      pages: [
        { name: "Home", path: "/", icon: "mdi-home" },
        {
          name: "Online Auction",
          path: "/onlineAuction/onAuction/1",
          icon: "mdi-gavel",
        },
        // {name: "Contact Us", path: "/help/contactUs", icon: "mdi-phone"},
      ],
      serverUrl: serverConfig.hostname,
      socket: {},
      user: {},
      userInLot: [],
      LotSocket: [],
      noOfLoseBid: 0,
      snackbar: {
        show1: false,
        show2: false,
        text1: "",
        text2: "",
        class1: "",
        class2: "",
        cycle: 1,
        timeout: 10000,
      },
    };
  },
  created() {
    this.checkLoggedIn();
    this.showNav = storage.showNavBar();
    this.user = storage.GetUser();
    this.socket = io.connect(serverConfig.hostnameSocket, {
      path: "/socket.io",
    });
  },
  mounted() {
    if (this.showNav == true) {
      var currentPage = this.$route.path;
      if (this.user.role == "admin") {
        if (currentPage.includes("/adminPage")) {
          // this.$router.push(currentPage)
        } else this.$router.push("/adminPage");
      } else if (this.user.role == "vendor") {
        if (currentPage.includes("/adminPage")) {
          // this.$router.push(currentPage)
        } else this.$router.push("/adminPage/reports");
      }
    }
    this.getAllLotUserIsIn(this.user.id);
    this.socket.on("displayBidding", (data) => {
      // this.getAllLotUserIsIn(this.user.email);
      this.showWorldBidNotification(data);
    });
  },
  methods: {
    navigate: function(pagePath) {
      if (this.$route.path != pagePath) {
        this.$router.push(pagePath);
      }
    },
    removeAppMargin() {
      if (window.innerWidth >= 600) {
        return false;
      }
      if (window.innerWidth < 600) {
        return true;
      }
    },
    logout() {
      storage.SetUser("", "", "", "");
      storage.switchNavBar("website");
      this.$router.push("/");
      this.$router.go();
    },
    checkLoggedIn() {
      var bool = false;
      var self = this;
      if (self.user != null) {
        if (self.user.email != "") {
          bool = true;
        }
      }
      return bool;
    },
    navColor(bool) {
      var color = "#ffa11f";
      if (bool == true) {
        color = "rgb(31, 161, 255)";
      }
      return color;
    },
    getAllLotUserIsIn(id) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/socket/userinlot/findall";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      axios
        .post(apiUrl, bodyFormData)
        .then(function(response) {
          self.userInLot = response.data;
          self.LotSocket = [];
          for (var i = 0; i < self.userInLot.length; i++) {
            self.getAuctionSocketById(self.userInLot[i].lotNo);
          }
        })
        .catch(function() {});
    },
    getAuctionSocketById(id) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/socket/auctionlot/" + id;
      axios
        .get(apiUrl)
        .then(function(response) {
          if (response.data == "") {
            self.LotSocket.push({
              id: id,
              latestBid: 0,
              latestEmail: "",
              bidderNo: null,
            });
          } else self.LotSocket.push(response.data);

          self.countLoseBid(self.LotSocket);
        })
        .catch(function() {});
    },
    countLoseBid(lotSocket) {
      var self = this;
      var tempNo = 0;
      for (var i = 0; i < lotSocket.length; i++) {
        if (lotSocket[i].bidderNo != self.user.id) {
          tempNo = tempNo + 1;
        }
      }
      self.noOfLoseBid = tempNo;
    },
    showWorldBidNotification(data) {
      var self = this;
      if (self.snackbar.cycle == 1) {
        self.snackbar.show1 = true;
        if (data.startOrRaise == "start")
          self.snackbar.text1 =
            "Bidder #" +
            data.bidderNo +
            " has started bidding on Lot #" +
            data.id +
            " for RM" +
            data.latestBid;
        else
          self.snackbar.text1 =
            "Bidder #" +
            data.bidderNo +
            " has raised RM " +
            data.offerBidPrice +
            " on Lot #" +
            data.id;
        self.snackbar.class1 = "pa-3";
        self.snackbar.class2 = "mb-12";
      } else {
        self.snackbar.show2 = true;
        if (data.startOrRaise == "start")
          self.snackbar.text2 =
            "Bidder #" +
            data.bidderNo +
            " has started bidding on Lot #" +
            data.id +
            " for RM" +
            data.latestBid;
        else
          self.snackbar.text2 =
            "Bidder #" +
            data.bidderNo +
            " has raised RM" +
            data.offerBidPrice +
            " on Lot #" +
            data.id;
        self.snackbar.class1 = "mb-12";
        self.snackbar.class2 = "pa-3";
      }
      self.snackbar.cycle = self.snackbar.cycle + 1;
      if (self.snackbar.cycle > 2) {
        self.snackbar.cycle = 1;
      }
    },
  },
};
</script>
