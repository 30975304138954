<template>
    <div>
        <v-row class="pa-0 ma-0">
            <v-col class="pa-1 ma-0 px-2">
                <v-card dark="" class="title">
                    <v-row class="pa-3 ma-0 ml-2">Payment Method</v-row>
                </v-card>
                <v-row class="pa-1 ma-1 mb-0 pb-0 title">FPX</v-row>
                <v-row class="pa-1 ma-1">
                    FPX is a convenient and secure online payment solution that allows real-time debiting of customer's internet banking account of multiple banks. It also allows immediate and direct crediting into merchant's account by banks. FPX service is suitable for all businesses that offer products or services via the internet.
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-1 ma-1 mb-0 pb-0 title">Banks supported by Billplz for online banking</v-row>
                <v-row class="pa-2 ma-0" justify="center" align="center">
                    <v-row class="pa-1 ma-1" justify="center">
                        <v-img      
                        src="https://www.ewallzsolutions.com/wp-content/uploads/2017/01/billplz.jpg"
                        max-width="100"
                        ></v-img>                    
                    </v-row>
                    <v-card>                        
                        <v-img 
                        :src="serverUrl + '/api/file/image/general/website/online_banks.jpg'"
                        max-width="500"
                        ></v-img>                    
                    </v-card>
                </v-row>
            </v-col>

            <v-col cols="3" class="d-none d-md-flex pr-0 ">
                <HelpNav></HelpNav>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import HelpNav from '@/components/HelpNav'
import serverConfig from "../jscontroller/serverConfig"
export default {
    // eslint-disable-next-line vue/no-unused-components
    components: {HelpNav},
    data: ()=>({
       serverUrl: serverConfig.hostname,
    })
}
</script>