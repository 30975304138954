<template>
  <div>
    <v-row class="pa-0 ma-0">
      <v-col cols="2" class="d-none d-md-flex pr-0">
        <MyAccountNav></MyAccountNav>
      </v-col>
      <v-col class="pa-0 ma-0">
        <v-col class="pa-2 ma-0">
          <v-card class="mb-3">
            <v-row justify="center" class="pa-0 ma-0">
              <v-col class="text-center pa-5">
                <div>Deposit Balance</div>
                <v-row justify="center">
                  <div class="display-1">
                    {{ ringgitFormat(depositBalance) }}
                  </div>
                  <v-tooltip right="">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="
                          getDepositBalace(user.id);
                          getTransactionsByPage(user.id);
                        "
                        text=""
                        ><v-icon large="" color="green"
                          >mdi-refresh-circle</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>Refresh</span>
                  </v-tooltip>
                </v-row>
                <v-divider class="mx-5"></v-divider>
                <v-btn
                  @click="
                    getDepositBalace(user.id);
                    getTransactionsByPage(user.id);
                    checkTopupCondition();
                  "
                  dark
                  class="ma-2 title"
                  large
                  color="green darken-4"
                  >Top Up</v-btn
                >

                <!-- <v-btn @click="topup.show = true" class="ma-2 title" large color="red" dark>Top Up</v-btn> -->
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col class="pa-0 ma-0 px-2 pb-2">
          <v-card color="" class="pa-1 mx-0 mt-2">
            <v-row class="pa-0 ma-0">
              <v-col class="pa-1 mx-0">
                <div>Transactions</div>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">ID</th>
                        <th class="text-left pl-5">Date</th>
                        <th class="text-left pl-5">Type</th>
                        <th class="text-left pl-5">Amount</th>
                        <th class="text-left pl-5">Detail</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(transaction, i) in transactions" :key="i">
                        <td>{{ transaction.id }}</td>
                        <td>{{ transaction.time }}</td>
                        <td>{{ transaction.transactionType }}</td>
                        <td>{{ ringgitFormat(transaction.value) }}</td>
                        <td>{{ transaction.details }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <!-- page -->
            <template>
              <div class="text-center">
                <v-pagination
                  v-model="currentPage"
                  :length="page.length"
                  :total-visible="10"
                ></v-pagination>
              </div>
            </template>
          </v-card>
        </v-col>
      </v-col>
    </v-row>

    <v-dialog v-model="topup.show" persistent max-width="400px">
      <v-card class="pa-2">
        <v-row class="pa-0 ma-1" justify="center">Enter amount to top up</v-row>
        <v-text-field
          prepend-icon="RM"
          class="pa-0"
          solo
          full-width=""
          hide-details="auto"
          label="Amount.."
          type="number"
          v-model="topup.amount"
        >
        </v-text-field>
        <v-row class="pa-1 ma-0" justify="center">
          <v-btn
            class="ma-1"
            @click="directUserToPayment()"
            :disabled="topup.amount < 1"
            >Pay with FPX</v-btn
          >
          <v-btn class="ma-1" @click="topup.show = false">Cancel</v-btn>
        </v-row>
        <v-divider></v-divider>
        <v-row class="pa-0 ma-0 mr-5" align="center" justify="end">
          <span class="mr-2">Supported with</span>
          <v-img
            src="https://www.ewallzsolutions.com/wp-content/uploads/2017/01/billplz.jpg"
            max-width="50"
          ></v-img>
        </v-row>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>

    <v-dialog v-model="show.topupDisable" persistent max-width="600px">
      <v-card class="pa-2 mb-1">
        <v-row justify="center" class="pa-0 ma-0">
          <v-col class="text-center pa-0 ma-0">
            <v-row class="pa-0 ma-0" justify="center"
              ><v-icon size="50" color="orange"
                >mdi-alert-outline</v-icon
              ></v-row
            >
            <div class="pa-1 title orange--text">
              Topup deposit balance closed
            </div>
            <v-divider></v-divider>
            <div class="pa-1 text-center">
              An auction is currently in progress.
            </div>
            <div class="text-center">
              Toping up deposit balance is not allowed during this period.
            </div>
            <v-btn
              class="mx-5 mt-1"
              @click="show.topupDisable = false"
              color="grey"
              dark
              >Close</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="show.topupDisable2" persistent max-width="600px">
      <v-card class="pa-2 mb-1">
        <v-row justify="center" class="pa-0 ma-0">
          <v-col class="text-center pa-0 ma-0">
            <v-row class="pa-0 ma-0" justify="center"
              ><v-icon size="50" color="orange"
                >mdi-alert-outline</v-icon
              ></v-row
            >
            <div class="pa-1 title orange--text">
              Topup deposit balance closed
            </div>
            <v-divider></v-divider>
            <div class="pa-1 text-center">
              Your deposit balance is
              <span class="font-weight-bold">{{
                ringgitFormat(depositBalance)
              }}</span>
            </div>
            <div class="text-center">
              You can only topup your deposit balance at a maximum of
              <span class="font-weight-bold">RM500.00</span> for TM Staff
              Auction.
            </div>
            <v-btn
              class="mx-5 mt-1"
              @click="show.topupDisable2 = false"
              color="grey"
              dark
              >Close</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="show.topupDisable3" persistent max-width="600px">
      <v-card class="pa-2 mb-1">
        <v-row justify="center" class="pa-0 ma-0">
          <v-col class="text-center pa-0 ma-0">
            <v-row class="pa-0 ma-0" justify="center"
              ><v-icon size="50" color="orange"
                >mdi-alert-outline</v-icon
              ></v-row
            >
            <div class="pa-1 title orange--text">
              Topup deposit balance closed
            </div>
            <v-divider></v-divider>
            <div class="text-center">
              Toping up deposit balance is not allowed during this period.
            </div>
            <v-btn
              class="mx-5 mt-1"
              @click="show.topupDisable3 = false"
              color="grey"
              dark
              >Close</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
import storage from "../jscontroller/storage";
import MyAccountNav from "@/components/MyAccountNav";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { MyAccountNav },
  name: "OnlineAuction",
  data: () => ({
    serverUrl: serverConfig.hostname,
    user: {},
    depositBalance: 0,
    transactions: [],
    currentPage: 1,
    page: {
      length: 1,
      totalTransaction: 0,
      contentPerPage: 5,
    },
    topup: {
      show: false,
      amount: 0,
    },
    show: {
      topupDisable: false,
      topupDisable2: false,
      topupDisable3: false,
    },
    isTopupDisable: false,
    snackbar: {
      showProgress: false,
      showStatus: false,
      text: "",
      timeout: 5000,
      icon: "mdi-check-circle-outline",
      color: "green",
    },
  }),
  created() {
    this.user = storage.GetUser();
  },
  mounted() {
    if (!this.checkLoggedIn()) {
      this.navigate("/login");
    }
    this.scrollToTop();
    this.getDepositBalace(this.user.id);
    this.getTransactionsByPage(this.user.id);
    this.getTopupDisableStatus();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    ringgitFormat(amount) {
      if (amount == null) {
        return "RM " + (0).toFixed(2);
      } else {
        var num = amount.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        });
        var deci = num.split(".")[1];
        if (deci != null) {
          if (deci.length == 1) {
            num = num + "0";
          }
        } else {
          num = num + ".00";
        }
        return "RM " + num;
      }
    },
    checkLoggedIn() {
      var bool = false;
      var self = this;
      if (self.user != null) {
        if (self.user.email != "") {
          bool = true;
        }
      }
      return bool;
    },
    getDepositBalace(id) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/user/asset";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          self.depositBalance = response.data.depositBalance;
          self.permission.show = true;
        })
        .catch(function () {});
    },
    countTransactions(id) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/transaction/count";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          var tempTotal = parseInt(response.data) / self.page.contentPerPage;
          if (tempTotal > tempTotal.toFixed(0)) {
            tempTotal = parseInt(tempTotal.toFixed(0)) + 1;
          } else {
            tempTotal = parseInt(tempTotal.toFixed(0));
          }
          self.page.length = tempTotal;
        })
        .catch(function () {});
    },
    getTransactionsByPage(id) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/transaction/getbypage";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      bodyFormData.set("noOfContent", self.page.contentPerPage);
      bodyFormData.set("page", self.currentPage - 1);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          self.transactions = response.data;
        })
        .catch(function () {});
    },
    directUserToPayment() {
      var self = this;
      self.showSnackbar();
      self.topup.show = false;
      var apiUrl = serverConfig.hostname + "/api/transaction/createbillurl";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", self.user.id);
      bodyFormData.set("amount", self.topup.amount);
      bodyFormData.set("transactionType", "deposit");
      bodyFormData.set("currentHost", self.serverUrl);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          var paymentUrl = response.data;
          if (paymentUrl == "exceed limit") {
            self.show.topupDisable2 = true;
            self.hideSnackbar("Exceed topup limit", "red");
          } else {
            self.hideSnackbar(
              "We will direct you to the payment page, please wait..",
              "green"
            );
            window.location.href = paymentUrl;
          }
        })
        .catch(function () {
          self.hideSnackbar(
            "Invalid email or phone, please make sure they are correct.",
            "red"
          );
        });
    },
    checkTopupCondition() {
      var self = this;

      if (!self.isTopupDisable) {
        self.show.topupDisable3 = true;
        return;
      }

      self.checkIfthereIsAuction((thereIsAuction) => {
        self.checkStaffDepositLimit((staffExeedLimit) => {
          if (thereIsAuction) self.show.topupDisable = true;
          else if (staffExeedLimit) self.show.topupDisable2 = true;
          else self.topup.show = true;
        });
      });
    },
    checkIfthereIsAuction(callback) {
      var apiUrl = serverConfig.hostname + "/api/auctionmanager/sessions";
      axios
        .get(apiUrl)
        .then(function (response) {
          if (response.data.length == 0) callback(false);
          else callback(true);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          callback(true);
        });
    },
    checkStaffDepositLimit(callback) {
      var self = this;
      var apiUrl =
        serverConfig.hostname + "/api/toggleinfo/check/deposit/limit";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", self.user.id);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          callback(response.data);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          // callback(response.data)
        });
    },
    getTopupDisableStatus() {
      var self = this;
      var apiUrl = self.serverUrl + "/api/toggleinfo/get/id/19";
      axios
        .get(apiUrl)
        .then(function (response) {
          self.isTopupDisable = response.data.toggle;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
  },
  watch: {
    currentPage: function () {
      this.getTransactionsByPage(this.user.id);
    },
  },
};
</script>
