const jsPDF = require("jspdf");
require("jspdf-autotable");

function open(logo, itemDetails, toggleInfo, vendorContacts) {
  var doc = new jsPDF("landscape");
  var marginLeft = 20;
  var marginTop = 25;
  var lMargin = 15; //left margin in mm
  var rMargin = 15; //right margin in mm
  var pdfInMM = 297; // width of A4 in mm
  var pageCenter = pdfInMM / 2;

  doc.addImage(logo, "JPEG", marginLeft - 5, marginTop - 8, 60, 25);
  doc.setFontSize(10);
  doc.setFontType("bold");
  doc.text(80, marginTop - 3, "AUCTION MANAGEMENT SERVICES SDN BHD");
  doc.setFontSize(7);
  doc.setFontType("normal");
  doc.text(162, marginTop - 3, "(1322220-W)");
  doc.setFontSize(7);
  doc.setFontType("bold");
  doc.text(
    marginLeft + 60,
    marginTop,
    "(formerly known as Fleet Management Solution Sdn Bhd)"
  );
  doc.setFontSize(7);
  doc.setFontType("normal");
  doc.text(
    80,
    marginTop + 3,
    "Kompleks Telekom, Lot 1, Persiaran Jubli Perak, Seksyen 17, 40000 Shah Alam,"
  );
  doc.text(80, marginTop + 6, "Selangor Darul Ehsan.");
  doc.text(80, marginTop + 9, "Tel : 03-5545 1176");
  doc.text(105, marginTop + 9, "Fax : 03-5541 0617");
  doc.setFontSize(7);
  doc.setFontType("bold");
  doc.text(80, marginTop + 12, "Website : www.amsauction.com.my");

  doc.rect(200, marginTop + 8, 82, 6);
  doc.setFontType("bold");
  var auctionInfoSpace = doc.splitTextToSize(toggleInfo[11].info, 82);
  doc.text(auctionInfoSpace, 200 + 82 / 2, marginTop + 12, "center");

  marginTop = marginTop + 20;
  doc.setFontSize(9);
  doc.setFontType("bold");
  var text1 = "LIST OF " + toggleInfo[12].info + " ON " + toggleInfo[13].info;
  var lines1 = doc.splitTextToSize(text1, pdfInMM - lMargin - rMargin);
  doc.text(lines1, pageCenter, marginTop, "center");
  var text2 = "VENUE: " + toggleInfo[15].info;
  var lines2 = doc.splitTextToSize(text2, pdfInMM - lMargin - rMargin);
  doc.text(lines2, pageCenter, marginTop + 4, "center");
  var text3 = "AUCTION COMMENCES: " + toggleInfo[14].info;
  var lines3 = doc.splitTextToSize(text3, pdfInMM - lMargin - rMargin);
  doc.text(lines3, pageCenter, marginTop + 8, "center");
  var text4 = "CONTACT PERSON: " + toggleInfo[8].info;
  var lines4 = doc.splitTextToSize(text4, pdfInMM - lMargin - rMargin);
  doc.text(lines4, pageCenter, marginTop + 12, "center");

  marginTop = marginTop + 12;

  let columns = [
    { title: "LOT NO", dataKey: "id" },
    { title: "REGN NO./ TRAILER NO.", dataKey: "regnNo" },
    { title: "MAKE", dataKey: "make" },
    { title: "DESCRIPTION OF GOODS", dataKey: "descOfGood" },
    { title: "RESERVED PRICE", dataKey: "price" },
    { title: "TRANSMISSION", dataKey: "ma" },
    { title: "YEAR", dataKey: "year" },
    { title: "VOC", dataKey: "rc" },
    { title: "VENDOR", dataKey: "vendor" },
    { title: "LOCATION", dataKey: "location" },
    { title: "REMARKS", dataKey: "remarks" },
  ];
  let rows = [];
  // doc.autoTable({margin: { top: marginTop+10 },theme:'striped',columns:columns, body:rows});
  doc.autoTable({
    margin: { top: marginTop - 5 },
    theme: "striped",
    columns: [],
    body: [],
    styles: { fontSize: 9 },
  });
  rows = [];
  for (var i = 0; i < itemDetails.length; i++) {
    var itemDetail = itemDetails[i];
    // itemDetail.price = ringgitFormat(itemDetails[i].price)
    (itemDetail.id = {
      content: itemDetails[i].id,
      styles: { minCellWidth: 9 },
    }),
      (itemDetail.regnNo = {
        content: itemDetails[i].regnNo,
        styles: { minCellWidth: 18 },
      }),
      (itemDetail.make = {
        content: itemDetails[i].make,
        styles: { minCellWidth: 18 },
      }),
      (itemDetail.descOfGood = {
        content: itemDetails[i].descOfGood,
        styles: { minCellWidth: 23 },
      }),
      (itemDetail.price = {
        content: ringgitFormat(itemDetails[i].price),
        styles: { minCellWidth: 30 },
      }),
      (itemDetail.ma = {
        content: itemDetails[i].ma,
        styles: { minCellWidth: 25 },
      }),
      (itemDetail.year = {
        content: itemDetails[i].year,
        styles: { minCellWidth: 11 },
      }),
      (itemDetail.vendor = {
        content: itemDetails[i].vendor,
        styles: { minCellWidth: 18 },
      }),
      (itemDetail.location = {
        content: itemDetails[i].location,
        styles: { minCellWidth: 18 },
      }),
      (itemDetail.remarks = {
        content: itemDetails[i].remarks,
        styles: { minCellWidth: 20 },
      }),
      rows.push(itemDetail);
  }
  marginTop = 25;
  doc.autoTable({
    margin: { top: marginTop },
    theme: "striped",
    columns: columns,
    body: rows,
    styles: { fontSize: 8 },
  });

  marginTop = doc.previousAutoTable.finalY + 8;
  marginTop = checkMarginTop(marginTop, 180);
  if (marginTop == 25) doc.addPage();

  if (toggleInfo[16].toggle == true) {
    if (marginTop > 140) {
      doc.addPage();
      marginTop = checkMarginTop(marginTop, 140);
    }

    doc.setFontSize(7);
    doc.setFontType("bold");
    var in1 = doc.splitTextToSize(
      "IMPORTANT NOTICE:",
      pdfInMM - lMargin - rMargin
    );
    doc.text(in1, pageCenter, marginTop, "center");
    var in2Text =
      "FULL PAYMENT MUST BE MADE TOGETHER WITH THE ADMINISTRATION FEE OF RM300.00 PER UNIT (NOT REFUNDABLE). ALL LORRIES, PRIME MOVERS, TRAILERS, PORT TRAILERS AND SIDE LOADERS MUST BE INSPECTED BY PUSPAKOM AND TRANSFER OF OWNERSHIP (OFF-ROAD) TO BE DONE BEFORE DELIVERY (MANDATORY). BUYER IS REQUIRED TO ARRANGE WITH PUSPAKOM FOR THE INSPECTION TO BE DONE IN KNB PREMISES AND MAKE THE TRANSFER OF OWNERSHIP AFTERWARDS. ALL COSTS WILL BE BORNED BY THE BUYER. BUYER MUST TAKE DELIVERY OF AUCTION GOODS WITHIN THE STIPULATED TIME OR WHEN REQUESTED BY THE AUCTIONEER (ITEM 7 AMS E-AUCTION TERMS & CONDITIONS).";
    var in2 = doc.splitTextToSize(in2Text, pdfInMM - lMargin - rMargin);
    doc.text(in2, pageCenter, marginTop + 5, "center");
    var in3Text =
      "ALL INTENDED BIDDERS ARE REQUIRED TO PAY AUCTION DEPOSIT SUM OF RM1,500.00 PER LOT ON AUCTION DAY WHICH IS REFUNDABLE IF THE TRANSFER IS UNSUCCESSFUL DUE TO KNB'S DELAY IN PREPARING AND EXECUTING THE NECESSARY DOCUMENTATIONS FOR THE TRANSFER. IN CASE OF SUCCESSFUL BID, THE AMOUNT WILL BE TREATED AS SAFETY DEPOSIT AND WILL ONLY BE REFUNDED UPON CLEARANCE OF AUCTION ITEMS FROM KONTENA NASIONAL BERHAD PREMISE, SUBJECT TO THERE BEING NO DAMAGES ONTO KNB'S PREMISE AND/OR ANY EQUIPMENT/ITEMS/FIXTURES THEREON DURING THE INSPECTION OF THE EQUIPMENT AND/OR THE REMOVAL OF THE EQUIPMENT FROM KNB'S PREMISE. KINDLY BE INFORMED THAT SAFETY DEPOSIT RM1,500.00 IS NOT PART OF THE PAYMENT.";
    var in3 = doc.splitTextToSize(in3Text, pdfInMM - lMargin - rMargin);
    doc.text(in3, pageCenter, marginTop + 20, "center");
    var in4Text =
      'KINDLY TAKE NOTE THAT ALL ITEMS ARE SOLD ON "AS IS WHERE IS" BASIS. KINDLY ENSURE TO INSPECT ALL ITEMS CAREFULLY BEFORE THE AUCTION. BID AT YOUR OWN RISK. NO REFUND WILL BE ENTERTAINED AFTER THE AUCTION.';
    var in4 = doc.splitTextToSize(in4Text, pdfInMM - lMargin - rMargin);
    doc.text(in4, pageCenter, marginTop + 35, "center");

    marginTop = marginTop + 45;

    doc.setFontSize(7);
    doc.setFontType("normal");
    doc.text(15, marginTop, "NOTE:");
    doc.text(15 + 4, marginTop + 4, "1");
    var text5 =
      "SPECIAL TERMS & CONDITIONS FOR SCRAP ITEMS: FULL PAYMENT MUST BE MADE TOGETHER WITH THE ADMINISTRATION FEE OF RM300.00 PER UNIT (NOT REFUNDABLE). NO TRANSFER DOCUMENTS WILL BE GIVEN FOR SPARE PARTS / SCRAP USE ONLY. BUYER MUST CUT THE SCRAP ITEMS/TRAILERS AT KONTENA NASIONAL BERHAD DESIGNATED AREA BEFORE TAKING DELIVERY OF THE SCRAP ITEMS/TRAILERS. FOR SAFETY PURPOSES, BUYER MUST ENSURE THE CUTTING PROCESS FOLLOW OSHA RULES & REGULATIONS INCLUDING ANY PARTICULAR LICENSE OR PERMIT REQUIRED BY LAW IN MALAYSIA. ALL COST FOR CUTTING THE TRAILERS WILL BE BORNED BY THE BUYER. BUYER MUST COMPLY WITH ALL SAFETY REQUIREMENTS INCLUDING FULL PPE OUTFIT, USAGE OF PROPER CUTTING GAS TANK (INDUSTRIAL) AND FIRE EXTINGUISHERS. USAGE OF COOKING GAS TANK IS STRICTLY PROHIBITED. USAGE OF CRANE MUST BE ACCOMPANY BY PROPER PMA LISENCE. BUYER WILL HAVE TO FILL UP FORMS AND INDEMNITY LETTER IN ORDER TO AQUIRE WORK PERMIT FROM KONTENA NASIONAL BERHAD. A REPRESENTATIVE FROM KONTENA NASIONAL BERHAD WILL WITNESS AND VERIFY THE TRAILER BEFORE DELIVERY. BID AT YOUR OWN RISK.";
    var lines5 = doc.splitTextToSize(text5, 250);
    doc.text(lines5, 15 + 10, marginTop + 4);
    marginTop = marginTop + 30;
  }

  marginTop = checkMarginTop(marginTop, 135);
  if (marginTop == 25) doc.addPage();

  let columns2 = [
    { title: "NO.", dataKey: "no" },
    { title: "LOCATION", dataKey: "location" },
    { title: "CONTACT PERSON", dataKey: "contactPerson" },
    { title: "CONTACT NO.", dataKey: "contactNo" },
    { title: "ADDRESS", dataKey: "address" },
  ];
  if (toggleInfo[16].toggle == true) {
    doc.setFontSize(7);
    doc.setFontType("bold");
    doc.text(
      "CONTACT PERSON & ADDRESS FOR OFF-SITE AUCTION GOODS",
      15,
      marginTop
    );
    // let rows2 = getContactLists("knb");
    let rows2 = getVendorContacts(vendorContacts, "knb");
    doc.autoTable({
      margin: { top: marginTop },
      startY: marginTop,
      theme: "striped",
      columns: [],
      body: [],
      styles: { fontSize: 9 },
    });
    marginTop = doc.previousAutoTable.finalY;
    doc.autoTable({
      margin: { top: 25 },
      startY: marginTop + 2,
      theme: "striped",
      columns: columns2,
      body: rows2,
      styles: { fontSize: 9 },
    });

    marginTop = doc.previousAutoTable.finalY + 4;
    marginTop = checkMarginTop(marginTop, 185);
    if (marginTop == 25) doc.addPage();
  }

  if (toggleInfo[17].toggle == true) {
    doc.text(
      "CONTACT PERSON & ADDRESS FOR OFF-SITE AUCTION GOODS",
      15,
      marginTop
    );
    // let rows3 = getContactLists("tm1");
    let rows3 = getVendorContacts(vendorContacts, "tm1");
    doc.autoTable({
      margin: { top: marginTop },
      theme: "striped",
      columns: [],
      body: [],
      styles: { fontSize: 9 },
    });
    marginTop = doc.previousAutoTable.finalY;
    doc.autoTable({
      margin: { top: 25 },
      startY: marginTop + 2,
      theme: "striped",
      columns: columns2,
      body: rows3,
      styles: { fontSize: 9 },
    });

    marginTop = doc.previousAutoTable.finalY + 6;
    marginTop = checkMarginTop(marginTop, 260);
    if (marginTop == 25) doc.addPage();

    doc.text("CONTACT PERSON & ADDRESS FOR GENSET", 15, marginTop);
    // let rows4 = getContactLists("tm2");
    let rows4 = getVendorContacts(vendorContacts, "tm2");
    doc.autoTable({
      margin: { top: 25 },
      startY: marginTop + 2,
      theme: "striped",
      columns: columns2,
      body: rows4,
      styles: { fontSize: 9 },
    });

    marginTop = doc.previousAutoTable.finalY + 8;
    marginTop = checkMarginTop(marginTop, 180);
    if (marginTop == 25) doc.addPage();

    doc.text("Contact Person", 15, marginTop);
    let columns3 = [
      { title: "No.", dataKey: "no" },
      { title: "Description", dataKey: "description" },
      { title: "Contact Person", dataKey: "contactPerson" },
      { title: "Contact No.", dataKey: "contactNo" },
    ];
    let rows5 = getContactLists("tm3");
    doc.autoTable({
      margin: { top: 25 },
      startY: marginTop + 2,
      theme: "striped",
      columns: columns3,
      body: rows5,
      styles: { fontSize: 9 },
    });
  }

  if (toggleInfo[16].toggle == true) {
    doc.addPage();
    marginTop = 8;
    doc.setFontSize(9);
    doc.setFontType("bold");
    doc.text(
      "ADDITIONAL CONDITIONS FOR THE AUCTION OF KONTENA NASIONAL BERHAD’S EQUIPMENT (“KNB’) – revised as at 13 th August 2020",
      15,
      marginTop
    );
    doc.setDrawColor(0, 0, 0);
    doc.line(15, marginTop + 0.5, 225, marginTop + 0.5);
    doc.setFontSize(7);
    doc.setFontType("bold");
    doc.text("1.", 15, marginTop + 5);
    doc.text("DEPOSIT & FEES", 22, marginTop + 5);
    doc.line(22, marginTop + 5.5, 43, marginTop + 5.5);
    doc.text("1.1", 15, marginTop + 10);
    doc.text("Purchase Deposit", 22, marginTop + 10);
    doc.setFontSize(7);
    doc.setFontType("normal");
    var text6 =
      "Prior to the commencement of the public auction, the successful bidder (“Purchaser”) shall place a deposit of Ringgit Malaysia One Thousand Five Hundred (RM1,500.00) only per auction item (“Purchase Deposit”), which is refundable to the Purchaser in the event that the Purchaser is not successful in the public auction. The Purchase Deposit shall not form any part of the purchase price.";
    var lines6 = doc.splitTextToSize(text6, 265);
    doc.text("(a)", 15, marginTop + 13);
    doc.text(lines6, 22, marginTop + 13);

    // doc.text("(a)", 15,marginTop + 13); doc.text("Prior to the commencement of the public auction, the successful bidder (“Purchaser”) shall place a deposit of Ringgit Malaysia One Thousand", 22,marginTop + 13);
    // doc.text("Five Hundred (RM1,500.00) only per auction item (“Purchase Deposit”), which is refundable to the Purchaser in the event that the Purchaser is", 22,marginTop + 16);
    // doc.text("not successful in the public auction. The Purchase Deposit shall not form any part of the purchase price.", 22,marginTop + 19);
    var text7 =
      "In the event that the Purchaser shall fail to pay the full purchase price of the auction within fourteen (14) days from the day of the auction, Purchase Deposit shall be forfeited.";
    var lines7 = doc.splitTextToSize(text7, 265);
    doc.text("(b)", 15, marginTop + 19);
    doc.text(lines7, 22, marginTop + 19);
    // doc.text("(b)", 15,marginTop + 22); doc.text("In the event that the Purchaser shall fail to pay the full purchase price of the auction within fourteen (14) days from the day of the auction,", 22,marginTop + 22);
    // doc.text("Purchase Deposit shall be forfeited.", 22,marginTop + 25);
    doc.text("(c)", 15, marginTop + 22);
    doc.text(
      "The Purchase Deposit shall then be converted as HSE Deposit, upon the payment of the full purchase price.",
      22,
      marginTop + 22
    );
    //bold
    doc.text("(“Purchaser”)", 100.3, marginTop + 13);
    doc.text(
      "Ringgit Malaysia One Thousand Five Hundred (RM1,500.00)",
      141.3,
      marginTop + 13
    );
    doc.text("(“Purchase Deposit”)", 232.0, marginTop + 13);

    marginTop = marginTop + 22;
    doc.setFontSize(7);
    doc.setFontType("bold");
    doc.text("1.2", 15, marginTop + 5);
    doc.text("HSE Deposit", 22, marginTop + 5);
    doc.setFontSize(7);
    doc.setFontType("normal");
    var text8 =
      "The HSE Deposit is refundable to the Purchaser in the event that the PUSPAKOM Inspection process and/or upon the process of collecting the equipment from KNB’s yard is completed with no loss and/or damages incurred unto KNB’s premise and/or any equipment/machinery/fixtures and/or person thereon, arising from acts and or omissions by the Purchaser.";
    var lines8 = doc.splitTextToSize(text8, 265);
    doc.text("(a)", 15, marginTop + 8);
    doc.text(lines8, 22, marginTop + 8);
    // doc.text("(a)", 15,marginTop + 8);doc.text("The HSE Deposit is refundable to the Purchaser in the event that the PUSPAKOM Inspection process and/or upon the process of collecting the", 22,marginTop + 8);
    // doc.text("equipment from KNB’s yard is completed with no loss and/or damages incurred unto KNB’s premise and/or any equipment/machinery/fixtures", 22,marginTop + 11);
    // doc.text("and/or person thereon, arising from acts and or omissions by the Purchaser.", 22,marginTop + 14);
    doc.text("(b)", 15, marginTop + 14);
    doc.text(
      "In the event the loss and/or damages exceed the deposit amount, KNB shall not be barred from claiming the losses and/or damages from the Purchaser, beyond the HSE Deposit sum.",
      22,
      marginTop + 14
    );

    marginTop = marginTop + 14;
    doc.setFontSize(7);
    doc.setFontType("bold");
    doc.text("1.3", 15, marginTop + 5);
    doc.text("Administrative Fee", 22, marginTop + 5);
    doc.setFontSize(7);
    doc.setFontType("normal");
    doc.text("(a)", 15, marginTop + 8);
    doc.text(
      "The Purchaser shall place an administration fee of a sum of Ringgit Malaysia Three Hundred (RM300.00) only per equipment together with the payment of the full purchase price",
      22,
      marginTop + 8
    );
    doc.text("(b)", 15, marginTop + 11);
    doc.text(
      "The administration fee shall not be refundable to the Purchaser, for whatsoever reason.",
      22,
      marginTop + 11
    );
    //bold
    doc.text("Ringgit Malaysia Three Hundred (RM300.00)", 87.8, marginTop + 8);

    marginTop = marginTop + 11;
    doc.setFontSize(7);
    doc.setFontType("bold");
    doc.text("2.", 15, marginTop + 5);
    doc.text("PUSPAKOM INSPECTION", 22, marginTop + 5);
    doc.line(22, marginTop + 5.5, 52, marginTop + 5.5);
    doc.setFontSize(7);
    doc.setFontType("normal");
    doc.text("2.1", 15, marginTop + 10);
    doc.text(
      "Purchaser shall procure the PUSPAKOM Inspection to be conducted within thirty (30) days from the date of payment of full purchase price. ",
      22,
      marginTop + 10
    );
    doc.text("2.2", 15, marginTop + 15);
    doc.text(
      "The cost for PUSPAKOM Inspection amounting to Ringgit Malaysia One Hundred Fifty-Three (RM153.00) shall be borne by the Purchaser.",
      22,
      marginTop + 15
    );
    var text9 =
      "At least one representative of each party shall be present during the PUSPAKOM Inspection to oversee the PUSPAKOM Inspection process. The Purchaser shall provide a written notification KNB of the date of PUSPAKOM Inspection (“Notification”) within three (3) working days before";
    var lines9 = doc.splitTextToSize(text9, 265);
    doc.text("2.3", 15, marginTop + 20);
    doc.text(lines9, 22, marginTop + 20);
    // doc.text("2.3", 15,marginTop + 20); doc.text("At least one representative of each party shall be present during the PUSPAKOM Inspection to oversee the PUSPAKOM Inspection process.", 22,marginTop + 20);
    // doc.text("The Purchaser shall provide a written notification KNB of the date of PUSPAKOM Inspection (“Notification”) within three (3) working days before", 22,marginTop + 23);
    var text10 =
      "PUSPAKOM Inspection is conducted. The Notification shall include the name of the Purchaser’s representative who shall be present during the PUSPAKOM Inspection (“Purchaser’s Representative”), along with a copy of the Purchaser’s Representative’s identification card.";
    var lines10 = doc.splitTextToSize(text10, 265);
    doc.text("2.4", 15, marginTop + 28);
    doc.text(lines10, 22, marginTop + 28);
    // doc.text("2.4", 15,marginTop + 28); doc.text("PUSPAKOM Inspection is conducted. The Notification shall include the name of the Purchaser’s representative who shall be present during the", 22,marginTop + 28);
    // doc.text("PUSPAKOM Inspection (“Purchaser’s Representative”), along with a copy of the Purchaser’s Representative’s identification card.", 22,marginTop + 31);
    doc.text("2.5", 15, marginTop + 36);
    doc.text(
      "The PUSPAKOM Inspection shall be conducted within KNB’s premise.",
      22,
      marginTop + 36
    );
    // bold
    // doc.setTextColor("red");
    doc.text("thirty (30)", 104.8, marginTop + 10);
    doc.text(
      "Ringgit Malaysia One Hundred Fifty-Three (RM153.00)",
      77.3,
      marginTop + 15
    );
    doc.text("Notification", 23.8, marginTop + 22.9);
    doc.text("three (3)", 45.3, marginTop + 22.9);
    doc.text("Purchaser’s Representative", 207.1, marginTop + 28);

    marginTop = marginTop + 36;
    doc.setFontSize(7);
    doc.setFontType("bold");
    doc.text("3.", 15, marginTop + 5);
    doc.text("OWNERSHIP TRANSFER", 22, marginTop + 5);
    doc.line(22, marginTop + 5.5, 51, marginTop + 5.5);
    doc.setFontSize(7);
    doc.setFontType("normal");
    // doc.text("3.1", 15,marginTop + 10); doc.text("The Purchaser shall execute the ownership transfer process (“Ownership Transfer”) within five (5) working days from the date of PUSPAKOM Inspection.", 22,marginTop + 10);
    doc.text("3.1", 15, marginTop + 10);
    doc.text(
      "The Purchaser shall execute the ownership transfer process (“Ownership Transfer”) within thirty (30) days from the date of PUSPAKOM Inspection.",
      22,
      marginTop + 10
    );
    doc.text("(a)", 15, marginTop + 13);
    doc.text(
      "The ownership shall be transferred strictly to the Purchaser. Request for ownership transfer to a third party shall not be entertained.",
      22,
      marginTop + 13
    );
    doc.text("3.2", 15, marginTop + 18);
    doc.text(
      "The cost for transfer of Ownership Transfer amounting to Ringgit Malaysia One Hundred (RM100.00) only shall be borne by the Purchaser",
      22,
      marginTop + 18
    );
    doc.text("3.3", 15, marginTop + 23);
    doc.text(
      "On the day of ownership transfer, the Purchaser shall provide the following documents:",
      22,
      marginTop + 23
    );
    doc.text("(a)", 15, marginTop + 26);
    doc.text(
      "A certified true copy of Form 9 for each equipment;",
      22,
      marginTop + 26
    );
    doc.text("(b)", 15, marginTop + 29);
    doc.text(
      "A certified true copy of Form 20 for each equipment;",
      22,
      marginTop + 29
    );
    doc.text("(c)", 15, marginTop + 32);
    doc.text(
      "A certified true copy of Form 24 for each equipment;",
      22,
      marginTop + 32
    );
    doc.text("(d)", 15, marginTop + 35);
    doc.text(
      "A certified true copy of Form 49 for each equipment;",
      22,
      marginTop + 35
    );
    doc.text("(e)", 15, marginTop + 38);
    doc.text(
      "A copy of the latest Company Search for each equipment;",
      22,
      marginTop + 38
    );
    doc.text("(f)", 15, marginTop + 41);
    doc.text(
      "The duly completed and accurate JPJ K11 form for each equipment;",
      22,
      marginTop + 41
    );
    doc.text("(g)", 15, marginTop + 44);
    doc.text(
      "A copy of the identification card of the Purchaser (if the Purchaser is an individual), OR a copy of the identification card of the Purchaser’s Director (if the Purchaser is a company);",
      22,
      marginTop + 44
    );
    doc.text("(h)", 15, marginTop + 47);
    doc.text(
      "Letter of Authorisation (“Surat Wakil Kuasa”) and a copy of the identification card (front and back) of the authorised person (if the Purchaser is a company or if individual, is absent during the transfer);",
      22,
      marginTop + 47
    );
    doc.text("(i)", 15, marginTop + 50);
    doc.text("The B2 result for each equipment;", 22, marginTop + 50);
    doc.text("(j)", 15, marginTop + 53);
    doc.text(
      "The duly completed and accurate JPJ K8 form for each equipment; and",
      22,
      marginTop + 53
    );
    doc.text("(k)", 15, marginTop + 56);
    doc.text(
      "Any other forms and/or documentation as required by JPJ.",
      22,
      marginTop + 56
    );
    doc.text("3.4", 15, marginTop + 61);
    doc.text(
      "Collection of ownership transfer documents for buyer’s thumbprint to conclude ownership transfer shall be as follows:",
      22,
      marginTop + 61
    );
    doc.text("(a)", 15, marginTop + 64);
    doc.text(
      "Once KNB representative has submitted the ownership transfer documents at JPJ for the first thumbprint, KNB HQ will notify purchaser (if local) to collect the same from KNB HQ for his execution(second thumbprint) within 7 days.",
      22,
      marginTop + 64
    );
    doc.text("(b)", 15, marginTop + 67);
    doc.text(
      "If buyer is from outstation, KNB HQ will send the ownership transfer documents to the buyer’s registered address via courier immediately after giving the first thumbprint for the purchaser’s execution (second thumbprint) within 7 days.",
      22,
      marginTop + 67
    );

    // bold
    doc.text("Ownership Transfer", 90, marginTop + 10);
    doc.text("thirty (30) days", 121, marginTop + 10);
    doc.text("Ringgit Malaysia One Hundred (RM100.00)", 84.8, marginTop + 18);
    doc.text("OR", 112.9, marginTop + 44);
    doc.text("Surat Wakil Kuasa", 48.5, marginTop + 47);

    marginTop = marginTop + 67;
    doc.setFontSize(7);
    doc.setFontType("bold");
    doc.text("4.", 15, marginTop + 5);
    doc.text("ADDITIONAL CHARGES", 22, marginTop + 5);
    doc.line(22, marginTop + 5.5, 50, marginTop + 5.5);
    doc.setFontSize(7);
    doc.setFontType("normal");
    var text11 =
      "For the avoidance of doubt, any costs including without limitation to cost of repairs, transportation, penalties, summonses, late payment charges imposed by the relevant authority or any third parties, or any incidental charges, shall be payable by Purchaser save and except for such penalties, summonses, late payment charges and any other incidental charges incurred arising from direct acts or omission of, or caused by KNB.";
    var lines11 = doc.splitTextToSize(text11, 265);
    doc.text(lines11, 15, marginTop + 10);
    // doc.text("For the avoidance of doubt, any costs including without limitation to cost of repairs, transportation, penalties, summonses, late payment charges", 15,marginTop + 10);
    // doc.text("imposed by the relevant authority or any third parties, or any incidental charges, shall be payable by Purchaser save and except for such penalties,", 15,marginTop + 13);
    // doc.text("summonses, late payment charges and any other incidental charges incurred arising from direct acts or omission of, or caused by KNB.", 15,marginTop + 16);

    marginTop = marginTop + 13;
    doc.setFontSize(7);
    doc.setFontType("bold");
    doc.text("5.", 15, marginTop + 5);
    doc.text("STORAGE/RENTAL CHARGES", 22, marginTop + 5);
    doc.line(22, marginTop + 5.5, 58, marginTop + 5.5);
    doc.setFontSize(7);
    doc.setFontType("normal");
    doc.text("5.1", 15, marginTop + 10);
    doc.text(
      "The Purchaser shall execute the collection of their asset within fourteen (14) days upon successful transfer of ownership.",
      22,
      marginTop + 10
    );
    doc.text("5.2", 15, marginTop + 15);
    doc.text(
      "We shall impose a storage/rental charges of Ringgit Malaysia One Hundred (RM100.00) only per equipment per day on the fifteenth (15th) day onwards.",
      22,
      marginTop + 15
    );
    // bold
    doc.text(
      "of Ringgit Malaysia One Hundred (RM100.00) only per equipment per day",
      68,
      marginTop + 15
    );
    doc.text("5.3", 15, marginTop + 20);
    doc.text(
      "The storage/rental charges are applicable for sale as asset and sale as scrap.",
      22,
      marginTop + 20
    );
    marginTop = marginTop + 4;
    doc.text("5.4", 15, marginTop + 21);
    doc.text(
      "For collection of their asset at KNB's premises, the Purchaser shall provide the following:",
      22,
      marginTop + 21
    );
    doc.text("(a)", 15, marginTop + 24);
    doc.text("Letter of Authorisation (“Surat Kuasa”);", 22, marginTop + 24);
    doc.text("(b)", 15, marginTop + 27);
    doc.text("Asset registration no.;", 22, marginTop + 27);

    doc.addPage();
    marginTop = -17;

    doc.text("(c)", 15, marginTop + 30);
    doc.text("A copy of new grant card;", 22, marginTop + 30);
    doc.text("(d)", 15, marginTop + 33);
    doc.text(
      "A copy of “Resit Rasmi Pertukaran Hak Milik (Pemunya Baru)”;",
      22,
      marginTop + 33
    );
    doc.text("(e)", 15, marginTop + 36);
    doc.text(
      "Name of driver authorised to collect the asset;",
      22,
      marginTop + 36
    );
    doc.text("(f)", 15, marginTop + 39);
    doc.text(
      "Identification card no. of driver authorised to collect the asset;",
      22,
      marginTop + 39
    );
    doc.text("(g)", 15, marginTop + 42);
    doc.text(
      "Collection details such as lorry registration no./crane no./trade plate no. etc.;",
      22,
      marginTop + 42
    );
    doc.text("(h)", 15, marginTop + 45);
    doc.text(
      "The Purchaser shall notify FMS/KNB two (2) working days prior to the collection date and time for KNB’s arrangement on the asset.",
      22,
      marginTop + 45
    );
    doc.text("(i)", 15, marginTop + 48);
    doc.text(
      "KNB HQ will issue Release Letter to KNB branches prior to the collection upon submission of items (a) to (f) by Purchaser..",
      22,
      marginTop + 48
    );

    marginTop = marginTop + 48 - 21 + 5;

    doc.text("5.5", 15, marginTop + 21);
    doc.text(
      "For cutting and collection of their scrap asset (sale as scrap), the Purchaser shall provide the following:",
      22,
      marginTop + 21
    );
    doc.text("(a)", 15, marginTop + 24);
    doc.text("Indemnity letter for cutting exercise;", 22, marginTop + 24);
    doc.text("(b)", 15, marginTop + 27);
    doc.text(
      "Proposed date/working period for cutting of their scrap asset.",
      22,
      marginTop + 27
    );
    doc.text("(c)", 15, marginTop + 30);
    doc.text("A list of workers;", 22, marginTop + 30);
    doc.text("(d)", 15, marginTop + 33);
    doc.text("A list of vehicles;", 22, marginTop + 33);
    doc.text("(e)", 15, marginTop + 36);
    doc.text("Lists of equipment &amp; tools;", 22, marginTop + 36);
    doc.text("(f)", 15, marginTop + 39);
    doc.text(
      "Collection details such as lorry registration no./crane no./trade plate no. etc.;",
      22,
      marginTop + 39
    );
    doc.text("(g)", 15, marginTop + 42);
    doc.text(
      "Name of driver authorised to collect the asset;",
      22,
      marginTop + 42
    );
    doc.text("(h)", 15, marginTop + 45);
    doc.text(
      "identification card no. of driver authorised to collect the asset;",
      22,
      marginTop + 45
    );
    doc.text("(i)", 15, marginTop + 48);
    doc.text("Permit to Work;", 22, marginTop + 48);
    doc.text("(j)", 15, marginTop + 51);
    doc.text(
      "The Purchaser shall notify FMS/KNB three (3) working days prior to the arrangement of cutting exercise and collection date and time for KNB’s arrangement on the asset.",
      22,
      marginTop + 51
    );
    doc.text("(k)", 15, marginTop + 54);
    doc.text(
      "KNB HQ will issue Release Letter to KNB branches prior to the cutting and collection upon submission of items (a) to (h) by Purchaser.",
      22,
      marginTop + 54
    );
  }
  window.open(doc.output("bloburl"), "_blank");
}

function checkMarginTop(marginTop, limit) {
  if (marginTop > limit) {
    return 25;
  } else return marginTop;
}

function ringgitFormat(amount) {
  if (amount == null) {
    return "RM " + (0).toFixed(2);
  } else {
    var num = amount.toLocaleString(undefined, { maximumFractionDigits: 2 });
    var deci = num.split(".")[1];
    if (deci != null) {
      if (deci.length == 1) {
        num = num + "0";
      }
    } else {
      num = num + ".00";
    }
    return "RM " + num;
  }
}

// eslint-disable-next-line no-unused-vars
function getContactLists(vendor) {
  var list = [];
  var knb = [
    {
      no: 1,
      location: "KNB PORT KLANG",
      contactPerson: "EN. SHAZELAN SALLEH",
      contactNo: "019-2654747",
      address:
        "KONTENA NASIONAL BERHAD, LOT PT 1644, LEBUH RAJA LUMU, KAWASAN PERUSAHAAN PANDAMARAN, 42000 PORT KLANG, SELANGOR DARUL EHSAN",
    },
    {
      no: 2,
      location: "KNB PENANG",
      // contactPerson: "EN. AMRI ISHAK",
      // contactNo: "019-5789626",
      contactPerson: { content: "-", styles: { halign: "center" } },
      contactNo: { content: "-", styles: { halign: "center" } },
      address:
        "KONTENA NASIONAL BERHAD, 2443, LORONG PERUSAHAAN 1, KAWASAN PERINDUSTRIAN PERAI, 13600 PERAI, PULAU PINANG",
    },
    {
      no: 3,
      location: "KNB KUANTAN",
      // contactPerson: "EN. WAHIB ISMAIL",
      // contactNo: "019-9816777",
      contactPerson: { content: "-", styles: { halign: "center" } },
      contactNo: { content: "-", styles: { halign: "center" } },
      address:
        "KONTENA NASIONAL BERHAD, LOT 126, KAWASAN PERINDUSTRIAN GEBENG, 26080 KUANTAN, PAHANG DARUL MAKMUR",
    },
    {
      no: 4,
      location: "KNB PASIR GUDANG",
      // contactPerson: { content: "EN. ASMAWI AKASAH \nEN. IDRIS HUDZORI \nEN. SHAH AKMAL SHAARI",styles: {minCellWidth: 35} },
      // contactNo: { content: "019-9816777 \n017-7669717 \n019-7722907",styles: {minCellWidth: 20} },
      // contactPerson: "EN. SHAH AKMAL SHAARI",
      // contactNo: "019-7722907",
      contactPerson: { content: "-", styles: { halign: "center" } },
      contactNo: { content: "-", styles: { halign: "center" } },
      address:
        "KONTENA NASIONAL BERHAD, PLO 249, JALAN TEMBAGA 1, KAWASAN PERINDUSTRIAN PASIR GUDANG, 81750 PASIR GUDANG, JOHOR DARUL TAKZIM",
    },
    {
      no: 5,
      location: "KNB IPOH",
      // contactPerson: "EN. AHMAD FAZILA",
      // contactNo: "012-5040972",
      contactPerson: { content: "-", styles: { halign: "center" } },
      contactNo: { content: "-", styles: { halign: "center" } },
      address:
        "KONTENA NASIONAL BERHAD, NO. 55987 JALAN KLEBANG 1/1, KAWASAN PERINDUSTRIAN BEBAS KINTA, 31200 CHEMOR, PERAK DARUL RIDZUAN",
    },
  ];

  var tm1 = [
    {
      no: 1,
      location: "MELAKA/MUAR",
      contactPerson: "EN. MAZLAN SHAARI",
      contactNo: "06-2925140 \n013-6204322",
      address:
        "TMF AUTOLEASE SDN BHD, KOMPLEKS TM, JALAN AIR LELEH, 75050 MELAKA",
    },
    {
      no: 2,
      location: "CHERAS",
      contactPerson: "EN. SAMSOL ANUAR BIN MOHD SA'AT",
      contactNo: "03-90753355 \n013-3361122",
      address:
        "TMF AUTOLEASE SDN BHD, BANGUNAN TELEKOM, BATU 10, JALAN CHERAS, 43200 SELANGOR",
    },
    {
      no: 3,
      location: "ALOR SETAR/LANGKAWI",
      contactPerson: "EN. MOHD ABIYAMIN HJ. ALI MUSA",
      contactNo: "04-7202215 \n011-10196531",
      address:
        "TMF AUTOLEASE SDN BHD, BANGUNAN TELEKOM, JLN PERAK 10, MERGONG, 05150 ALOR SETAR, KEDAH",
    },
    {
      no: 4,
      location: "SG. PETANI",
      contactPerson: {
        content: "EN. MOHD ABIYAMIN HJ. ALI MUSA",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "04-7202215 \n011-10196531",
        styles: { minCellWidth: 20 },
      },
      address:
        "TMF AUTOLEASE SDN BHD, BANGUNAN TELEKOM, LORONG 4, KAWASAN PERINDUSTRIAN RINGAN BAKAR ARANG, 08000 SG. PETANI, KEDAH",
    },
    {
      no: 5,
      location: "SEBERANG PERAI",
      contactPerson: {
        content: "PN. NAZIRAH MOHD ZAIN",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "04-3903066 \n013-4218808",
        styles: { minCellWidth: 20 },
      },
      address:
        "TMF AUTOLEASE SDN BHD, KOMPLEKS TM, SOLOK PERUSAHAAN 2, PERINDUSTRIAN PERAI, SEBERANG PERAI, 13600 PENANG",
    },
    {
      no: 6,
      location: "PULAU PINANG",
      contactPerson: {
        content: "PN. NAZIRAH MOHD ZAIN",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "04-3903066 \n013-4218808",
        styles: { minCellWidth: 20 },
      },
      address: "IBUSAWAT TM, JLN. BURMAH, 10050 GEORGETOWN, PENANG",
    },
    {
      no: 7,
      location: "BAYAN BARU",
      contactPerson: {
        content: "PN. NAZIRAH MOHD ZAIN",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "04-3903066 \n013-4218808",
        styles: { minCellWidth: 20 },
      },
      address:
        "IBUSAWAT TM, JLN. TENGAH, BANDAR BAYAN BARU, 11900 BAYAN LEPAS, PENANG",
    },
    {
      no: 8,
      location: "IPOH",
      contactPerson: {
        content: "CIK KHAIRUL NOOR AZWA \nPN. HASNAH DARUS",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "019-4140500 \n019-4335848",
        styles: { minCellWidth: 20 },
      },
      address:
        "TMF AUTOLEASE SDN BHD, BANGUNAN TELEKOM, JALAN TASEK, 31400 IPOH, PERAK",
    },
    {
      no: 9,
      location: "KAMPAR",
      contactPerson: {
        content: "CIK KHAIRUL NOOR AZWA \nPN. HASNAH DARUS",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "019-4140500 \n019-4335848",
        styles: { minCellWidth: 20 },
      },
      address: "IBUSAWAT TM, KG. MASJID, 31900 KAMPAR, PERAK",
    },
    {
      no: 10,
      location: "GUNUNG RAPAT",
      contactPerson: {
        content: "CIK KHAIRUL NOOR AZWA \nPN. HASNAH DARUS",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "019-4140500 \n019-4335848",
        styles: { minCellWidth: 20 },
      },
      address:
        "IBUSAWAT TM, LALUAN SRI AMPANG 10, TMN SRI AMPANG, 31350 IPOH, PERAK",
    },
    {
      no: 11,
      location: "KG TAWAS",
      contactPerson: {
        content: "CIK KHAIRUL NOOR AZWA \nPN. HASNAH DARUS",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "019-4140500 \n019-4335848",
        styles: { minCellWidth: 20 },
      },
      address: "IBUSAWAT TM, KG. TAWAS, JALAN KUALA KANGSAR, 30010 IPOH, PERAK",
    },
    {
      no: 12,
      location: "BATU PAHAT",
      contactPerson: {
        content: "EN. MOHD NOOR BIN HAMID",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "013-7703646 \n07-4355350",
        styles: { minCellWidth: 20 },
      },
      address:
        "TMF AUTOLEASE SDN BHD, KOMPLEKS TM, JLN KLUANG, 83000 BATU PAHAT, JOHOR",
    },
    {
      no: 13,
      location: "TELUK INTAN",
      contactPerson: {
        content: "CIK KHAIRUL NOOR AZWA \nPN. HASNAH DARUS",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "019-4140500 \n019-4335848",
        styles: { minCellWidth: 20 },
      },
      address: "IBUSAWAT TM, LORONG TEMBIKAI, 36000 TELUK INTAN, PERAK",
    },
    {
      no: 14,
      location: "TEMERLOH",
      contactPerson: {
        content: "EN. MUHAMMAD RIZDWAN BIN ABD RAZAK",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "09-5682090 \n012-9229954",
        styles: { minCellWidth: 20 },
      },
      address: "IBU SAWAT TM, JALAN MENTAKAB-TEMERLOH, 28000 TEMERLOH, PAHANG",
    },
    {
      no: 15,
      location: "K. TERENGGANU",
      contactPerson: {
        content: "FATIHAYAH BT. KAMARUZAMAN",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "09-6202133 \n013-9071766",
        styles: { minCellWidth: 20 },
      },
      address:
        "TMF AUTOLEASE SDN BHD, KOMPLEKS TELEKOM, JLN PUSARA, 20400 K. TERENGGANU, TERENGGANU",
    },
    {
      no: 16,
      location: "KOTA KINABALU",
      contactPerson: {
        content: "EN. MOHAMMAD HAMZAH MAUTAR",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "088-299683 \n019-5384597",
        styles: { minCellWidth: 20 },
      },
      address:
        "TMF AUTOLEASE SDN BHD, KOMPLEKS TM KEPAYAN, 88200 KOTA KINABALU, SABAH",
    },
    {
      no: 17,
      location: "KUCHING",
      contactPerson: {
        content: "PN. SURAYA BASNEH \nEN. SULAIMAN ARBI",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "013-8168070 \n013-8506765",
        styles: { minCellWidth: 20 },
      },
      address:
        "TMF AUTOLEASE SDN BHD, KOMPLEKS TM, JLN PENDING, TANAH PUTIH, 93450 KUCHING, SARAWAK",
    },
    {
      no: 18,
      location: "MIRI",
      contactPerson: {
        content: "EN. KENNETH TADAM PADAN",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "013-8246577 \n085-615475",
        styles: { minCellWidth: 20 },
      },
      address:
        "TMF AUTOLEASE SDN BHD, BANGUNAN TELEKOM, JLN LIMBANG, 98000 MIRI, SARAWAK",
    },
    {
      no: 19,
      location: "SIBU",
      contactPerson: {
        content: "CIK NAFISAH BINTI DAUD",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "013-8041408 \n084310240",
        styles: { minCellWidth: 20 },
      },
      address:
        "TMF AUTOLEASE SDN BHD, BANGUNAN TM UNIT 1, PERSIARAN BROOKE, 96000 SIBU, SARAWAK",
    },
    {
      no: 20,
      location: "SEREMBAN",
      contactPerson: {
        content: "EN. SHAFARIZY ABDUL AZIZ",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "019-6048269 \n06-7651281",
        styles: { minCellWidth: 20 },
      },
      address:
        "TMF AUTOLEASE SDN BHD, KOMPLEKS TM, BATU 3, JALAN LABU, 70200 SEREMBAN, N.SEMBILAN",
    },
    {
      no: 21,
      location: "SRI AMAN",
      contactPerson: {
        content: "EN. SULAIMAN ARBI",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "013-8506765 \n082-343351",
        styles: { minCellWidth: 20 },
      },
      address: "IBUSAWAT TM, JALAN GELUMBA, 95000 SRI AMAN, SARAWAK",
    },
    {
      no: 22,
      location: "TAWAU",
      contactPerson: {
        content: "EN. MH YUSUP LABULLA",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "089-700353 \n019-8001272",
        styles: { minCellWidth: 20 },
      },
      address: "KOMPLEKS TM. JLN BELUNU, 01000 TAWAU, SABAH",
    },
    {
      no: 23,
      location: "KUALA KANGSAR",
      contactPerson: {
        content: "EN. WAN SUHAIZAM WAN DAUD",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "05-2499297 \n019-9332816",
        styles: { minCellWidth: 20 },
      },
      address: "BANGUNAN TM, JLN RAJA CHULAN, 33000 KUALA KANGSAR, PERAK",
    },
    {
      no: 24,
      location: "TAIPING",
      contactPerson: {
        content: "EN. WAN SUHAIZAM WAN DAUD",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "05-2499297 \n019-9332816",
        styles: { minCellWidth: 20 },
      },
      address: "KOMPLEKS TM, JLN LUMBA KUDA, 34672 TAIPING, PERAK",
    },
    {
      no: 25,
      location: "JOHOR BAHRU",
      contactPerson: {
        content: "EN. MOHD FIRDAUS HARUN",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "07-2281870 \n011-21740717",
        styles: { minCellWidth: 20 },
      },
      address: "KOMPLEKS TM, JLN TARUKA, LARKIN, 80350 JOHOR BAHRU, JOHOR",
    },
    {
      no: 26,
      location: "BENTONG",
      contactPerson: {
        content: "EN. MUHAMMAD RIDZWAN ABD RAZAK",
        styles: { minCellWidth: 35 },
      },
      contactNo: { content: "012-9229954", styles: { minCellWidth: 20 } },
      address:
        "IBUSAWAT TM, LEBUHRAYA K.LUMPUR - GUA MUSANG, 28700 BENTONG, PAHANG",
    },
    {
      no: 27,
      location: "RAUB",
      contactPerson: {
        content: "EN. MUHAMMAD RIDZWAN ABD RAZAK",
        styles: { minCellWidth: 35 },
      },
      contactNo: { content: "012-9229954", styles: { minCellWidth: 20 } },
      address: "IBUSAWAT TM, JLN KUALA LIPIS, 27600 RAUB, PAHANG",
    },
    {
      no: 28,
      location: "KOTA BHARU",
      contactPerson: {
        content: "EN. MOHD NUR AZRI KAMARUZAMAN",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "09-7415113 \n011-10063637",
        styles: { minCellWidth: 20 },
      },
      address: "KOMPLEKS TM, LUNDANG, 15150 KOTA BHARU, KELANTAN",
    },
    {
      no: 29,
      location: "KUANTAN",
      contactPerson: {
        content: "PN. SHARIFAH NORHAFIF SYED SAIFUDEEN",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "09-5682093 \n013-9218411",
        styles: { minCellWidth: 20 },
      },
      address:
        "BANGUNAN TM, LOT 156A, KAW. PERINDUSTRIAN SEMAMBU, 25350 KUANTAN, PAHANG",
    },
    {
      no: 30,
      location: "BINTULU",
      contactPerson: {
        content: "EN. KENNETH TADAM PADAN",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "085-615475 \n013-8246577",
        styles: { minCellWidth: 20 },
      },
      address: "IBUSAWAT TM, JALAN LAW GEK SOON, 97000 BINTULU, SARAWAK",
    },
    {
      no: 31,
      location: "LIMBANG",
      contactPerson: {
        content: "EN. KENNETH TADAM PADAN",
        styles: { minCellWidth: 35 },
      },
      contactNo: {
        content: "085-615475 \n013-8246577",
        styles: { minCellWidth: 20 },
      },
      address: "IBUSAWAT TM, JLN KUBU, 98700 LIMBANG, SARAWAK",
    },
  ];

  var tm2 = [
    {
      no: 1,
      location: "SHAH ALAM",
      contactPerson: "EN. MOHD FAIZAL ZAKARIA",
      contactNo: "019-3411132",
      address:
        "KOMPLEKS TELEKOM, LOT 1, PERSIARAN JUBLI PERAK, SEKSYEN 17, 40000 SHAH ALAM, SELANGOR",
    },
  ];

  var tm3 = [
    {
      no: 1,
      description: "Payment & delivery of vehicles after auction",
      contactPerson: "En. Nizam",
      contactNo: "012-6975866 / 03-55451176",
    },
    {
      no: 2,
      description: "Delivery of vehicles after auction at TM Kepayan",
      contactPerson: "En. Mohammad Hamzah Mautar",
      contactNo: "088-299683 / 010-6598980",
    },
    {
      no: 3,
      description: "Collection of documentations after auction at TM Kepayan",
      contactPerson: "Pn. Kalsum Mohd Dusi",
      contactNo: "016-8040531 / 088-299686",
    },
    {
      no: 4,
      description: "Appointment for JPJ transfer purposes in Sabah",
      contactPerson:
        "En. Paaizes Redzuan bin Abd Latib (Vehicles in Sabah only)",
      contactNo: "013-8860683 / 088-299685",
    },
    {
      no: 5,
      description:
        "Delivery of vehicles & collection of documentations after auction in Sarawak",
      contactPerson: "Pn. Suraya Basneh\nEn.Sulaiman Arbi",
      contactNo: "013-8168070\n013-8506765",
    },
    {
      no: 6,
      description: "Appointment for JPJ transfer purposes in Sarawak",
      contactPerson: "En. Mohamad Halmi bin Sidop (Vehicles in Sarawak only)",
      contactNo: "013-8354871 / 082-330570",
    },
    {
      no: 7,
      description:
        "Appointment for JPJ transfer purposes in Semenanjung Malaysia",
      contactPerson:
        "En. Sayrul Asreen Bin Walid (Vehicles in Sem. Malaysia only)",
      contactNo: "016-5368962",
    },
  ];

  if (vendor == "knb") {
    list = knb;
  } else if (vendor == "tm1") {
    list = tm1;
  } else if (vendor == "tm2") {
    list = tm2;
  } else if (vendor == "tm3") {
    list = tm3;
  }

  return list;
}

function getVendorContacts(vendorList, vendor) {
  var list = [];
  for (var i = 0; i < vendorList.length; i++) {
    if (vendorList[i].vendor.toLowerCase() == vendor) {
      vendorList[i].no = list.length + 1;
      var contactPerson = {
        content: vendorList[i].contactPerson,
        styles: { minCellWidth: 50 },
      };
      vendorList[i].contactPerson = contactPerson;
      list.push(vendorList[i]);
    }
  }
  return list;
}

module.exports = { open };
