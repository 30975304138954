function checkSuperAdminAuthorization(user) {
  if (user != null) {
    if (user.role != "admin") {
      return false;
    } else {
      if (user.id > 2) {
        return false;
      } else {
        return true;
      }
    }
  }

  return false;
}

function checkAdminAuthorization(user) {
  var bool = false;
  if (user != null) {
    if (user.role == "admin") {
      bool = true;
    } else bool = false;
  }
  return bool;
}

function checkVendorAuthorization(user) {
  var bool = false;
  if (user != null) {
    if (user.role == "admin" || user.role == "vendor") {
      bool = true;
    } else bool = false;
  }
  return bool;
}

module.exports = {
  checkSuperAdminAuthorization,
  checkAdminAuthorization,
  checkVendorAuthorization,
};
