const axios = require("axios");
const serverConfig = require("../jscontroller/serverConfig");
const storage = require("../jscontroller/storage");

const serverUrl = serverConfig.hostname;

async function reserveUnit(bodyForm) {
  const user = storage.GetUser();
  var apiUrl = serverUrl + "/api/update/reserve-unit-user/old-auction";

  try {
    var bodyFormData = new FormData();
    bodyFormData.set("userId", bodyForm.userId);
    bodyFormData.set("regnNo", bodyForm.regnNo);
    bodyFormData.set("auctionSession", bodyForm.auctionSession);
    bodyFormData.set("soldPrice", bodyForm.soldPrice);
    bodyFormData.set("shouldBalanceDeposit", false);

    const response = await axios.post(apiUrl, bodyFormData, {
      headers: {
        Authorization: `Bearer ${user.jwt}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

module.exports = { reserveUnit };
