const jsPDF = require("jspdf");
require("jspdf-autotable");

function open(mybid, itemDetails, user, logo, signature) {
  var doc = new jsPDF();
  var marginLeft = 20;
  var marginTop = 25;

  doc.addImage(logo, "JPEG", marginLeft - 5, marginTop - 8, 60, 25);
  doc.setFontSize(10);
  doc.setFontType("bold");
  doc.text(80, marginTop - 3, "AUCTION MANAGEMENT SERVICES SDN BHD");
  doc.setFontSize(7);
  doc.setFontType("normal");
  doc.text(162, marginTop - 3, "(1322220-W)");
  doc.setFontSize(7);
  doc.setFontType("bold");
  doc.text(
    marginLeft + 60,
    marginTop,
    "(formerly known as Fleet Management Solution Sdn Bhd)"
  );
  doc.setFontSize(7);
  doc.setFontType("normal");
  doc.text(
    80,
    marginTop + 3,
    "Kompleks Telekom, Lot 1, Persiaran Jubli Perak, Seksyen 17, 40000 Shah Alam,"
  );
  doc.text(80, marginTop + 6, "Selangor Darul Ehsan.");
  doc.text(80, marginTop + 9, "Tel : 03-5545 1176");
  doc.text(105, marginTop + 9, "Fax : 03-5541 0617");
  doc.text(80, marginTop + 12, "Website : www.amsauction.com.my");

  marginTop = marginTop + 15;

  doc.setFontSize(10);
  doc.setFontType("bold");
  doc.text(marginLeft, marginTop + 10, "OFFICIAL AUCTION GOODS DELIVERY ORDER");
  doc.setFontSize(9);
  doc.setFontType("normal");
  doc.text(marginLeft + 130, marginTop + 10, "No. :");

  doc.rect(marginLeft, marginTop + 15, 170, 150);
  doc.line(marginLeft + 110, marginTop + 27, 190, marginTop + 27);
  doc.line(marginLeft, marginTop + 35, 190, marginTop + 35);
  doc.line(marginLeft + 110, marginTop + 15, marginLeft + 110, marginTop + 35);

  doc.text(marginLeft + 2, marginTop + 20, "Name :");
  doc.text(marginLeft + 112, marginTop + 20, "Invoice No :");
  doc.text(marginLeft + 2, marginTop + 28, "NRIC :");
  doc.text(marginLeft + 2, marginTop + 32, "(Customer)");
  doc.text(marginLeft + 112, marginTop + 32, "Date :");

  doc.setFontSize(9);
  doc.setFontType("bold");
  doc.text(marginLeft + 150, marginTop + 10, bidNoFormat(mybid.id));
  // doc.text(marginLeft + 150, marginTop+10, 'bidNoFormat(mybid.id)')
  const displayName = doc.splitTextToSize(user.firstName.toUpperCase(), 88);
  doc.text(marginLeft + 20, marginTop + 20, displayName);
  doc.text(marginLeft + 132, marginTop + 20, mybid.auctionName);
  doc.text(marginLeft + 20, marginTop + 28, nricFormat(user.ic));
  var date = new Date();
  doc.text(
    marginLeft + 132,
    marginTop + 32,
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );

  marginTop = marginTop + 35;
  doc.setFontSize(9);
  doc.setFontType("normal");
  doc.text(
    marginLeft + 2,
    marginTop + 6,
    "Please receive the following item in order and good condition :"
  );
  doc.line(marginLeft, marginTop + 10, 190, marginTop + 10);

  marginTop = marginTop + 10;
  doc.line(marginLeft + 75, marginTop, marginLeft + 75, marginTop + 120);
  doc.line(marginLeft, marginTop + 20, marginLeft + 75, marginTop + 20);
  doc.line(marginLeft, marginTop + 30, 190, marginTop + 30);

  doc.text(marginLeft + 2, marginTop + 5, "Reg. No :");
  doc.text(marginLeft + 77, marginTop + 5, "Location :");
  doc.text(marginLeft + 2, marginTop + 10, "Description :");
  doc.text(marginLeft + 2, marginTop + 25, "Lot No. :");

  doc.setFontSize(9);
  doc.setFontType("bold");
  doc.text(marginLeft + 25, marginTop + 5, mybid.particular);
  var lines = doc.splitTextToSize(itemDetails.location, 90);
  doc.text(marginLeft + 80, marginTop + 10, lines);
  var description = doc.splitTextToSize(itemDetails.descOfGood, 50);
  doc.text(marginLeft + 25, marginTop + 10, description);
  doc.text(marginLeft + 25, marginTop + 25, checkText(mybid.lotNo).toString());

  marginTop = marginTop + 25;
  doc.line(marginLeft, marginTop + 15, 190, marginTop + 15);

  doc.setFontSize(9);
  doc.setFontType("normal");
  doc.text(marginLeft + 2, marginTop + 10, "Bidder No. :");
  doc.text(marginLeft + 77, marginTop + 10, "Auction Date :");

  doc.setFontSize(9);
  doc.setFontType("bold");
  doc.text(marginLeft + 25, marginTop + 10, checkText(user.id).toString());
  var auctionDate = new Date(mybid.startDate);
  doc.text(
    marginLeft + 100,
    marginTop + 10,
    auctionDate.getDate() +
      "/" +
      (auctionDate.getMonth() + 1) +
      "/" +
      auctionDate.getFullYear()
  );

  marginTop = marginTop + 15;
  doc.line(marginLeft, marginTop + 20, 190, marginTop + 20);

  doc.setFontSize(9);
  doc.setFontType("normal");
  doc.text(marginLeft + 13, marginTop + 3, "CHECK AND DELIVERED BY :");
  doc.text(
    marginLeft + 1,
    marginTop + 17,
    "..................................................................................."
  );
  doc.text(marginLeft + 77, marginTop + 5, "Delivered by :");
  doc.text(
    marginLeft + 98,
    marginTop + 6,
    "..............................................................................."
  );
  doc.text(marginLeft + 77, marginTop + 15, "Date :");
  doc.text(
    marginLeft + 87,
    marginTop + 17,
    "......................................."
  );
  doc.text(marginLeft + 125, marginTop + 15, "Time :");
  doc.text(
    marginLeft + 135,
    marginTop + 17,
    "......................................"
  );

  doc.setFontSize(9);
  doc.setFontType("bold");
  doc.text(
    marginLeft + 97,
    marginTop + 15,
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
  var ampm = "AM";
  if (date.getHours() >= 12) ampm = "PM";
  var seconds = date.getSeconds();
  var minutes = date.getMinutes();
  if (seconds < 10) seconds = "0" + seconds;
  if (minutes < 10) minutes = "0" + minutes;
  doc.text(
    marginLeft + 145,
    marginTop + 15,
    (date.getHours() % 12) + ":" + minutes + ":" + seconds + " " + ampm
  );

  marginTop = marginTop + 20;
  doc.text(marginLeft + 25, marginTop + 5, "UNDERTAKING");
  doc.line(marginLeft + 25, marginTop + 5.5, marginLeft + 48, marginTop + 5.5);
  var lines2 = doc.splitTextToSize(
    "I/We agree to carry out the transfer of ownership within 7 days in accordance to the Malaysian Road Transport Act 1987. I/We agree to undertake all liabilities on any incumbrances including summons after taking delivery of the vehicle and release the auctioneer and registered owner from any claims, negligence and damages. I/We acknowledge that auctioneer and the registered owner will not be responsible for any summons imposed to the auctioned vehicles as stated in the terms and conditions of sales by public auction.",
    70
  );
  doc.text(marginLeft + 2, marginTop + 10, lines2);

  doc.text(
    marginLeft + 88,
    marginTop + 4,
    "RECEIVED IN ORDER AND GOOD CONDITION :"
  );
  doc.setFontSize(9);
  doc.setFontType("normal");
  doc.text(
    marginLeft + 75,
    marginTop + 36,
    "............................................................................................................"
  );
  doc.text(marginLeft + 77, marginTop + 42, "Name :");
  doc.text(marginLeft + 77, marginTop + 52, "Date :");
  doc.text(
    marginLeft + 87,
    marginTop + 54,
    "...................................."
  );
  doc.text(marginLeft + 120, marginTop + 52, "I.C. No. :");
  doc.text(
    marginLeft + 135,
    marginTop + 54,
    "......................................"
  );

  doc.setFontSize(9);
  doc.setFontType("bold");
  doc.text(marginLeft + 90, marginTop + 42, user.firstName);
  doc.text(
    marginLeft + 97,
    marginTop + 52,
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
  doc.text(marginLeft + 140, marginTop + 52, nricFormat(user.ic));

  marginTop = marginTop + 68;
  doc.setFontSize(9);
  doc.setFontType("normal");
  doc.text(marginLeft + 1, marginTop, "Delivery instructions :");
  doc.setFontSize(9);
  doc.setFontType("bold");
  doc.text(
    marginLeft + 35,
    marginTop,
    "........................................................................................................................................................."
  );
  doc.text(
    marginLeft + 37,
    marginTop - 1,
    "Please contact the person below for delivery appointment. Thank you."
  );
  doc.text(
    marginLeft + 1,
    marginTop + 8,
    "................................................................................................................................................................................................"
  );
  doc.text(marginLeft + 2, marginTop + 7, itemDetails.contactPerson);

  marginTop = marginTop + 12;
  doc.rect(marginLeft + 85, marginTop, 85, 55);
  doc.line(marginLeft + 85, marginTop + 40, marginLeft + 170, marginTop + 40);

  doc.setFontSize(30);
  doc.setFontType("normal");
  doc.text(marginLeft + 98, marginTop + 10, "GATEPASS");
  doc.setFontSize(9);
  doc.setFontType("normal");
  doc.text(marginLeft + 87, marginTop + 25, "Authorised Signature :");
  doc.text(marginLeft + 87, marginTop + 45, "Name :");
  doc.text(marginLeft + 87, marginTop + 50, "Position :");
  doc.text(marginLeft + 105, marginTop + 45, "Mohd Nizam Bin Jailani");
  doc.text(marginLeft + 105, marginTop + 50, "General Manager");

  doc.addImage(signature, "JPEG", 123, marginTop + 10, 60, 37.1);
  window.open(doc.output("bloburl"), "_blank");
}

// eslint-disable-next-line no-unused-vars
function checkText(text) {
  if (text == null) {
    return "";
  } else {
    return text;
  }
}

// eslint-disable-next-line no-unused-vars
function nricFormat(nric) {
  var returnNRIC = "";
  for (var i = 0; i < nric.length; i++) {
    if (i == 6) returnNRIC = returnNRIC + "-";
    if (i == 8) returnNRIC = returnNRIC + "-";
    returnNRIC = returnNRIC + nric[i];
  }

  if (nric.length < 12) returnNRIC = nric;
  // returnNRIC = nric[0]+nric[1]+nric[2]+nric[3]+nric[4]+nric[5]+"-";
  return returnNRIC;
}

// eslint-disable-next-line no-unused-vars
function bidNoFormat(num) {
  if (num < 10) return "00" + num;
  if (num > 9 && num < 100) return "0" + num;
  else return num.toString();
}

module.exports = { open };
