import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Itempage from "../views/Itempage.vue";
import Auctionpage from "../views/Auctionpage.vue";
import OnlineAuctionPage from "../views/OnlineAuctionPage.vue";
import ContactUsPage from "../views/ContactUsPage.vue";
import AdminPage from "../views/AdminPage.vue";
import AuctionManager from "../views/AuctionManager.vue";
import MyBidPage from "../views/MyBidPage.vue";
import Transaction from "../views/Transaction.vue";
import BidOnline from "../views/BidOnline.vue";
import UserManager from "../views/UserManager.vue";
import MyProfile from "../views/MyProfile.vue";
import Reports from "../views/Reports.vue";
import DocumentManager from "../views/DocumentManager.vue";
import TermsAndCondition from "../views/TermsAndCondition.vue";
import BidderProcess from "../views/BidderProcess.vue";
import ItemRecords from "../views/ItemRecords.vue";
import FPX from "../views/FPX.vue";
import SoldPage from "../views/SoldPage.vue";
import Tutorial from "../views/Tutorial.vue";
import Mailing from "../views/Mailing.vue";
import UnitMonitor from "../views/UnitMonitor";
import NextAuctionPage from "../views/NextAuctionPage";
import NextItemPage from "../views/NextItempage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    beforeEnter: (to, from, next) => {
      checkPasswordValidity();
      next();
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/login/verify",
    name: "verify",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/itempage/:lotId",
    name: "itempage",
    component: Itempage,
  },
  {
    path: "/itempagena/:lotId",
    name: "newtitempage",
    component: NextItemPage,
  },
  {
    path: "/auctionpage/:lotId",
    name: "auctionpage",
    component: Auctionpage,
  },
  {
    path: "/onlineAuction/onAuction/:page",
    name: "onlineAuction",
    component: OnlineAuctionPage,
    beforeEnter: (to, from, next) => {
      checkPasswordValidity();
      next();
    },
  },
  {
    path: "/onlineAuction/nextAuction/:page",
    name: "nextAuction",
    component: NextAuctionPage,
  },
  {
    path: "/onlineAuction/sold",
    name: "soldPage",
    component: SoldPage,
  },
  {
    path: "/help/contactUs",
    name: "contactUs",
    component: ContactUsPage,
  },
  {
    path: "/help/termsAndCondition",
    name: "termsAndCondition",
    component: TermsAndCondition,
  },
  {
    path: "/help/bidderProcess",
    name: "bidderProcess",
    component: BidderProcess,
  },
  {
    path: "/help/onlinePayment",
    name: "onlinePayment",
    component: FPX,
  },
  {
    path: "/help/tutorial",
    name: "tutorial",
    component: Tutorial,
  },
  {
    path: "/adminPage",
    name: "adminPage",
    component: AdminPage,
    beforeEnter: (to, from, next) => {
      checkVendorAuthorization();
      next();
    },
  },
  {
    path: "/adminPage/mailing",
    name: "mailing",
    component: Mailing,
    beforeEnter: (to, from, next) => {
      checkAdminAuthorization();
      next();
    },
  },
  {
    path: "/adminPage/auctionManager",
    name: "auctionManager",
    component: AuctionManager,
    beforeEnter: (to, from, next) => {
      checkAdminAuthorization();
      next();
    },
  },
  {
    path: "/adminPage/userManager",
    name: "userManager",
    component: UserManager,
    beforeEnter: (to, from, next) => {
      checkAdminAuthorization();
      next();
    },
  },
  {
    path: "/adminPage/unitMonitor",
    name: "unitMonitor",
    component: UnitMonitor,
    beforeEnter: (to, from, next) => {
      checkVendorAuthorization();
      next();
    },
  },
  {
    path: "/adminPage/reports",
    name: "reports",
    component: Reports,
    beforeEnter: (to, from, next) => {
      checkVendorAuthorization();
      next();
    },
  },
  {
    path: "/adminPage/documentManager",
    name: "documentManager",
    component: DocumentManager,
    beforeEnter: (to, from, next) => {
      checkAdminAuthorization();
      next();
    },
  },
  {
    path: "/adminPage/itemRecords",
    name: "itemRecords",
    component: ItemRecords,
    beforeEnter: (to, from, next) => {
      checkVendorAuthorization();
      next();
    },
  },
  {
    path: "/myaccount/mybid",
    name: "mybid",
    component: MyBidPage,
    beforeEnter: (to, from, next) => {
      checkPasswordValidity();
      next();
    },
  },
  {
    path: "/myaccount/transaction",
    name: "transaction",
    component: Transaction,
    beforeEnter: (to, from, next) => {
      checkPasswordValidity();
      next();
    },
  },
  {
    path: "/myaccount/bidonline",
    name: "bidonline",
    component: BidOnline,
    beforeEnter: (to, from, next) => {
      checkPasswordValidity();
      next();
    },
  },
  {
    path: "/myaccount/myprofile",
    name: "myprofile",
    component: MyProfile,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const axios = require("axios");
const serverConfig = require("../jscontroller/serverConfig");
const storage = require("../jscontroller/storage");

function checkPasswordValidity() {
  const user = storage.GetUser();
  const apiUrl = serverConfig.hostname + "/api/sc/check-password-validity";
  const token = user.jwt ?? "";

  if (user.role == "admin") {
    checkAdminAuthorization();
  }

  axios
    .get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function () {
      // continue next
    })
    .catch(function (error) {
      if (error.response.status == 403) {
        alert(error.response.data.message);
        window.location.href = "/myaccount/myprofile";
      }
    });
}

function checkAdminAuthorization() {
  const user = storage.GetUser();
  const apiUrl = serverConfig.hostname + "/api/sc/check-admin-authorization";
  const token = user.jwt ?? "";
  axios
    .get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function () {
      // continue next
    })
    .catch(function (error) {
      storage.switchNavBar("website");
      storage.clearUserStorage();

      alert(error.response.data.message);
      window.location.href = "/";
    });
}

function checkVendorAuthorization() {
  const user = storage.GetUser();
  const apiUrl = serverConfig.hostname + "/api/sc/check-vendor-authorization";
  const token = user.jwt ?? "";
  axios
    .get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function () {
      // continue next
    })
    .catch(function (error) {
      storage.switchNavBar("website");
      storage.clearUserStorage();

      alert(error.response.data.message);
      window.location.href = "/";
    });
}

export default router;
