const jsPDF = require("jspdf");
require("jspdf-autotable");

function open(mybid, itemDetails, user, logo){
    var doc = new jsPDF(); 
    var marginLeft = 25;  
    var marginTop = 25;    
    
    doc.addImage(logo, "JPEG", marginLeft-5, marginTop-8, 60, 25);   
    doc.setFontSize(10);doc.setFontType('bold');      
    doc.text(marginLeft + 60, marginTop-3, 'AUCTION MANAGEMENT SERVICES SDN BHD')
    doc.setFontSize(7);doc.setFontType('normal');      
    doc.text(marginLeft + 142, marginTop-3, '(1322220-W)')
    doc.setFontSize(7);doc.setFontType('bold');    
    doc.text(marginLeft + 60, marginTop, '(formerly known as Fleet Management Solution Sdn Bhd)')
    doc.setFontSize(7);doc.setFontType('normal');    
    doc.text(marginLeft + 60, marginTop+3, 'Kompleks Telekom, Lot 1, Persiaran Jubli Perak, Seksyen 17, 40000 Shah Alam,')
    doc.text(marginLeft + 60, marginTop+6, 'Selangor Darul Ehsan.')
    doc.text(marginLeft + 60, marginTop+9, 'Tel : 03-5545 1176')
    doc.text(marginLeft + 85, marginTop+9, 'Fax : 03-5541 0617')
    doc.text(marginLeft + 60, marginTop+12, 'Website : www.amsauction.com.my')
    doc.setFontType('bold');doc.setFontSize(8);
    // doc.text(marginLeft + 60, marginTop+16, 'GST No. : 000405397504')

    marginTop = marginTop + 20;
    doc.setFontSize(10); 
    doc.text(marginLeft, marginTop + 2, 'MEMORANDUM OF SALES')
    doc.setFontSize(9);
    doc.rect(marginLeft + 97, marginTop-2, 26, 6)
    doc.rect(marginLeft + 123, marginTop-2, 33, 6)
    doc.text(marginLeft + 100, marginTop + 2, 'Auction Date')
    var auctionDate = new Date(mybid.startDate); 
    doc.text(marginLeft + 132, marginTop + 2, auctionDate.getDate()+"-"+(auctionDate.getMonth()+1)+"-"+auctionDate.getFullYear())
    // margin right = 181
    
    marginTop = marginTop + 8;
    let columns = [
    {title: "LOT NO", dataKey: "lotno"},
    {title: "YEAR", dataKey: "year"},
    {title: "REGN / SERIAL NO", dataKey: "regn"},
    {title: "Make", dataKey: "make"},
    {title: "MODEL", dataKey: "model"},
    ];
    let rows = [{
        lotno: mybid.lotNo,
        year: itemDetails.year,
        regn: itemDetails.regnNo,
        make: checkText(itemDetails.make),
        model: checkText(itemDetails.descOfGood)
    }];
    // for(var i=0; i<session.length; i++){
    //     var item = {
            
    //     }    
    //     rows.push(item)
    // }      

    doc.rect(marginLeft, marginTop, 160, 7);
    doc.setFontType('bold');doc.setFontSize(14);
    doc.text(marginLeft + 57, marginTop+5, 'Description of Lot ')
    marginTop = marginTop + 7;
    doc.autoTable({margin: { top: marginTop , left: marginLeft, right: marginLeft},theme:'striped',columns:columns, body:rows});
    marginTop = marginTop + 7.5 + 7.5*rows.length;
    doc.setFontSize(8);doc.setFontType('bold');    
    var makeLines = 0;
    var makeModelsLength = rows[0].make.length + rows[0].model.length
    if(makeModelsLength > 23 && makeModelsLength < 34){
        makeLines = Math.floor(makeModelsLength / 23 /2);
        marginTop = marginTop + 4*makeLines;
    }
    if(makeModelsLength > 34){
        makeLines = Math.floor(makeModelsLength / 34 / 2);
        marginTop = marginTop + 8*makeLines;
    } 
    doc.setDrawColor("black")
    doc.rect(marginLeft, marginTop, 160, 7); 
    doc.text(marginLeft + 30, marginTop+5, checkText(itemDetails.location))

    marginTop = marginTop + 12;
    doc.setFontType('normal');
    var text1 = "Memorandum that on the above stated Auction Date, the bidder herein named (hereinafter referred to as the “Purchaser”) has agreed to purchase the item(s) under the aforesaid Lot No., particulars of which are as set out in the aforementioned Description of Lot, for the price stated herein.";
    var lines1 = doc.splitTextToSize(text1, 160)
    doc.text(marginLeft, marginTop, "Memorandum")
    doc.text(marginLeft, marginTop, lines1)
    var buyerPremiumOf = "";
    if(mybid.premium < 1 && mybid.premium > 0)
        buyerPremiumOf = "buyer premium of "+mybid.premium*100+"% from sale price or RM600.00 whichever is higher";
    else
        buyerPremiumOf = "buyer premium of RM"+ mybid.premium.toFixed(2);
    var text2 = "By bidding on the Lot, the Purchaser agrees to be bound by the Terms and Conditions of Sale and is obligated to complete the purchase in accordance with the Terms and Conditions of Sale. The Purchaser agrees that a required percentum of deposit and "+buyerPremiumOf+" shall be paid to Auction Management Services Sdn Bhd upon the fall of the hammer. The Purchaser further agrees to pay the balance of all monies outstanding and complete the purchase in accordance with the Terms and Conditions of Sale. The balance of the purchase price shall be paid in Cash, Bank Draft or Telegraphic Transfer within five (5) days from the fall of the hammer failing which the Auctioneer have the right to cancel the sale and forfeit all monies paid by the Purchaser.";
    var lines2 = doc.splitTextToSize(text2, 160)
    doc.text(marginLeft, marginTop+14+6.5, buyerPremiumOf)
    doc.text(marginLeft, marginTop+14, lines2)    
    doc.text(marginLeft+27.7, marginTop+41, '“AS IS WHERE IS"')
    doc.text(marginLeft, marginTop+41, 'All lots are sold on an “AS IS WHERE IS" basis')
    doc.text(marginLeft + 47.7, marginTop+48, 'TERMS AND CONDITIONS OF SALE')
    doc.text(marginLeft, marginTop+48, 'This Memorandum is subjected to the TERMS AND CONDITIONS OF SALE to be fulfilled by the Purchaser.')
    marginTop = marginTop + 52;
    doc.rect(marginLeft, marginTop, 160, 10); 
    doc.rect(marginLeft+0.5, marginTop+0.5, 159, 9); 
    doc.text(marginLeft + 5, marginTop+4, 'Note:')
    var text3 = 'Payments by bank draft / telegraphic transfer are only valid upon clearance of said instruments and receipt of funds by Auction Management Services Sdn Bhd';
    var lines3 = doc.splitTextToSize(text3, 140)
    doc.text(marginLeft + 15, marginTop+4, lines3)
    
    marginTop = marginTop + 14;
    marginTop = checkLine(marginTop, 186, doc);
    doc.rect(marginLeft, marginTop, 160, 9*6+0.5);
    for(var x = 0; x<6; x++) {
        doc.setFillColor(200, 200, 200)
        if(x==5)
        doc.setFillColor("white")
        doc.rect(marginLeft+0.5, marginTop+0.5 + x*9, 90, 8.5, 'FD'); 
        doc.rect(marginLeft+0.5 + 90.5, marginTop+0.5 + x*9, 68.5, 8.5);
    }
    doc.setLineWidth(0.5)
    doc.rect(marginLeft, marginTop+6*9+0.5, 91, 5);
    doc.rect(marginLeft + 91, marginTop+6*9+0.5, 69, 5);
    doc.setFontType('bold');doc.setFontSize(9);
    doc.text(marginLeft + 35, marginTop+4, 'SALE PRICE')
    doc.text(marginLeft + 20, marginTop+7.5, '(Sale Price inclusive of GST @ 0%)')
    doc.setFontSize(12);
    doc.text(marginLeft + 92, marginTop+6.5, 'RM '+ mybid.bidPrice.toFixed(2))
    doc.setFontSize(9);
    doc.text(marginLeft + 30, marginTop+6 + 9*1, "BUYER'S PREMIUM")
    doc.setFontSize(12);
    doc.text(marginLeft + 92, marginTop+6 + 9*1, 'RM ' + calculatePremium(mybid.premium, mybid.bidPrice).toFixed(2))
    doc.setFontSize(9);
    // doc.text(marginLeft + 22, marginTop+6 + 9*2, "0% GST ON BUYER'S PREMIUM")
    // doc.setFontSize(12);
    // doc.text(marginLeft + 92, marginTop+6 + 9*2, 'RM 0.00')
    // doc.setFontSize(9);
    doc.text(marginLeft + 37, marginTop+6 + 9*2, "ADMIN FEE")
    doc.setFontSize(12);
    doc.text(marginLeft + 92, marginTop+6 + 9*2, 'RM ' + (mybid.adminFee).toFixed(2))
    doc.setFontSize(9);
    doc.text(marginLeft + 40, marginTop+6 + 9*3, "TOTAL")
    doc.setFontSize(12);
    doc.text(marginLeft + 92, marginTop+6 + 9*3, 'RM ' + (mybid.bidPrice + mybid.adminFee + calculatePremium(mybid.premium, mybid.bidPrice)).toFixed(2))
    doc.setFontSize(9);
    doc.text(marginLeft + 37, marginTop+6 + 9*4, "BIDDER NO")
    doc.setFontSize(12);
    doc.text(marginLeft + 92, marginTop+6 + 9*4, ""+user.id)
    doc.setFontSize(9);
    var lMargin=0; //left margin in mm
    var rMargin=0; //right margin in mm
    var pdfInMM=210;  // width of A4 in mm
    var pageCenter=(marginLeft+116)/2;
    var nameCenter =doc.splitTextToSize(user.name, (pdfInMM-lMargin-rMargin));
    doc.text(nameCenter,pageCenter,marginTop+6 + 9*5,'center');
    doc.text(marginLeft + 23, marginTop+8 + 9*6 - 4, "BIDDER / AUTHORISED AGENT")
    doc.text(marginLeft + 115, marginTop+8 + 9*6 - 4, "SIGNATURE")
    
    marginTop = marginTop + 6*9 + 10;
    marginTop = checkLine(marginTop, 275, doc);

    doc.setFontType('bold');doc.setFontSize(8);doc.setLineWidth(0.1)
    doc.rect(marginLeft, marginTop, 160, 67);
    doc.rect(marginLeft+0.5, marginTop+0.5, 159, 66);
    doc.text(marginLeft + 2, marginTop + 4, "Special Terms:")
    doc.setFontType('normal');doc.setFontSize(7);

// Notes here
    var note1_t1 = "IN ORDER TO AVOID ANY FURTHER COMPLICATIONS ON FUTURE EVENTS THE BUYER MUST AGREES TO CHANGE OWNERSHIP AND REGISTER THE GOODS INTO HIS/HER/COMPANY NAME BY:"
    var note1_t2 = '1. INSPECTION WITH PUSPAKOM FOR "SIJIL PEMERIKSAAN TUKARNAMA - B5"'
    var note1_t3 = '2. INFORM TMFA PERSONNEL FOR THUMBPRINT PROCESS'
    var note1_t4 = '3. CHANGE OF OWNERSHIP WITH THE DEPARTMENT OF MOTOR VEHICLES (JABATAN PENGANGKUTAN JALAN - JPJ) WITHIN TWO (2) WEEKS IN-RELATION WITH SEKSYEN 13 - AKTA PENGANGKUTAN JALAN 1987'

    var note1_l1 = doc.splitTextToSize(note1_t1, 140)
    var note1_l2 = doc.splitTextToSize(note1_t2, 140)
    var note1_l3 = doc.splitTextToSize(note1_t3, 140)
    var note1_l4 = doc.splitTextToSize(note1_t4, 140)
    
    var note2_t1 = "SPECIAL TERMS & CONDITIONS FOR SCRAP ITEMS: FULL PAYMENT MUST BE MADE TOGETHER WITH THE ADMINISTRATION FEE OF RM300.00 PER UNIT (NOT REFUNDABLE). NO TRANSFER DOCUMENTS WILL BE GIVEN FOR SPARE PARTS / SCRAP USE ONLY. BUYER MUST CUT THE SCRAP ITEMS/TRAILERS AT KONTENA NASIONAL BERHAD DESIGNATED AREA BEFORE TAKING DELIVERY OF THE SCRAP ITEMS/TRAILERS. FOR SAFETY PURPOSES, BUYER MUST ENSURE THE CUTTING PROCESS FOLLOW OSHA RULES & REGULATIONS INCLUDING ANY PARTICULAR LICENSE OR PERMIT REQUIRED BY LAW IN MALAYSIA. ALL COST FOR CUTTING THE TRAILERS WILL BE BORNED BY THE BUYER. BUYER MUST COMPLY WITH ALL SAFETY REQUIREMENTS INCLUDING FULL PPE OUTFIT, USAGE OF PROPER CUTTING GAS TANK (INDUSTRIAL) AND FIRE EXTINGUISHERS. USAGE OF COOKING GAS TANK IS STRICTLY PROHIBITED. USAGE OF CRANE MUST BE ACCOMPANIED BY PROPER PMA LISENCE. BUYER WILL HAVE TO FILL UP FORMS AND INDEMNITY LETTER IN ORDER TO AQUIRE WORK PERMIT FROM KONTENA NASIONAL BERHAD. A REPRESENTATIVE FROM KONTENA NASIONAL BERHAD WILL WITNESS AND VERIFY THE TRAILER BEFORE DELIVERY. BID AT YOUR OWN RISK."
    var note2_l1 = doc.splitTextToSize(note2_t1, 140)

    var note3_t1 = "ALL INTENDED BIDDERS ARE REQUIRED TO PAY AUCTION DEPOSIT SUM OF RM1,500.00 PER LOT ON AUCTION DAY WHICH IS REFUNDABLE IF THE TRANSFER IS UNSUCCESSFUL DUE TO KNB'S DELAY IN PREPARING AND EXECUTING THE NECESSARY DOCUMENTATIONS FOR THE TRANSFER. IN CASE OF SUCCESSFUL BID, THE AMOUNT WILL BE TREATED AS SAFETY DEPOSIT AND WILL ONLY BE REFUNDED UPON CLEARANCE OF AUCTION ITEMS FROM KONTENA NASIONAL BERHAD PREMISE, SUBJECT TO THERE BEING NO DAMAGES ONTO KNB'S PREMISE AND/OR ANY EQUIPMENT/ITEMS/FIXTURES THEREON DURING THE INSPECTION OF THE EQUIPMENT AND/OR THE REMOVAL OF THE EQUIPMENT FROM KNB'S PREMISE."
    var note3_l1 = doc.splitTextToSize(note3_t1, 140)

    var note4_t1 = "FULL PAYMENT MUST BE MADE TOGETHER WITH THE ADMINISTRATION FEE OF RM300.00 PER UNIT (NOT REFUNDABLE). ALL LORRIES, PRIME MOVERS, TRAILERS, PORT TRAILERS AND SIDE LOADERS MUST BE INSPECTED BY PUSPAKOM AND TRANSFER OF OWNERSHIP (OFF-ROAD) TO BE DONE BEFORE DELIVERY (MANDATORY). BUYER IS REQUIRED TO ARRANGE WITH PUSPAKOM FOR THE INSPECTION TO BE DONE IN KNB PREMISES AND MAKE THE TRANSFER OF OWNERSHIP AFTERWARDS. ALL COSTS WILL BE BORNED BY THE BUYER. BUYER MUST TAKE DELIVERY OF AUCTION GOODS WITHIN THE STIPULATED TIME OR WHEN REQUESTED BY THE AUCTIONEER (ITEM 7 AMS E-AUCTION TERMS & CONDITIONS)."
    var note4_l1 = doc.splitTextToSize(note4_t1, 140)

    var note5_t1 = "THE BUYER MUST TAKE DELIVERY OF AUCTION GOODS WITHIN THE STIPULATED TIME OR WHEN REQUESTED BY THE AUCTIONEER (ITEM 7 AMS E-AUCTION TERMS & CONDITIONS OF SALE)."
    var note5_l1 = doc.splitTextToSize(note5_t1, 140)

    var note6_t1 = "Bidders are required to pay a sum of RM500/unit as a Safety Deposit (refundable upon completion of ownership transfer). Bidders are given 3 months (from the date of auction) to complete the transfer of ownership, failing which the Safety Deposit will be forfeited and any appeal will not be entertained. Refund of the Safety Deposit is subjected to :"
    var note6_t2 = '1. Bidder to submit proof of transfer of ownership (copy of registration card or E-Auto slip). '
    var note6_t3 = '2. Vehicle is free from any summons issued by JPJ/PDRM or any relevant authorities/local councils from the date of vehicle delivery until completion of transfer.'
    var note6_t4 = '3. Bidders agree to be responsible for any late issuance of summons due to system delay (if any). AMS will notify the bidder on any late summons.'
    var note6_t5 = '4. Approval by vendor.'
    var note6_t6 = 'Bidders must take delivery of auction goods within the stipulated time or when requested by the auctioneer (item 7 AMS E-Auction Terms & Conditions of Sale).'

    var note6_l1 = doc.splitTextToSize(note6_t1, 140)
    var note6_l2 = doc.splitTextToSize(note6_t2, 140)
    var note6_l3 = doc.splitTextToSize(note6_t3, 140)
    var note6_l4 = doc.splitTextToSize(note6_t4, 140)
    var note6_l5 = doc.splitTextToSize(note6_t5, 140)
    var note6_l6 = doc.splitTextToSize(note6_t6, 140)
// end notes
    
    var text4 = checkText(itemDetails.remark1);

    if(text4.toUpperCase() == "NOTE 1"){
        doc.text(marginLeft + 5, marginTop + 8, "i)")
        doc.text(marginLeft + 12, marginTop + 8, note1_l1)
        doc.text(marginLeft + 15, marginTop + 16, note1_l2)
        doc.text(marginLeft + 15, marginTop + 20, note1_l3)
        doc.text(marginLeft + 15, marginTop + 24, note1_l4)
    }

    else if(text4.toUpperCase() == "NOTE 2"){
        doc.text(marginLeft + 5, marginTop + 8, "i)")
        doc.text(marginLeft + 12, marginTop + 8, note2_l1)
    }

    else if(text4.toUpperCase() == "NOTE 3"){
        doc.text(marginLeft + 5, marginTop + 8, "i)")
        doc.text(marginLeft + 12, marginTop + 8, note3_l1)
    }

    else if(text4.toUpperCase() == "NOTE 4"){
        doc.text(marginLeft + 5, marginTop + 8, "i)")
        doc.text(marginLeft + 12, marginTop + 8, note4_l1)
    }

    else if(text4.toUpperCase() == "NOTE 5"){
        doc.text(marginLeft + 5, marginTop + 8, "i)")
        doc.text(marginLeft + 12, marginTop + 8, note5_l1)
    }

    else if(text4.toUpperCase() == "NOTE 6"){
        doc.text(marginLeft + 5, marginTop + 8, "i)")
        doc.text(marginLeft + 12, marginTop + 8, note6_l1)
        doc.text(marginLeft + 15, marginTop + 17, note6_l2)
        doc.text(marginLeft + 15, marginTop + 20, note6_l3)
        doc.text(marginLeft + 15, marginTop + 26, note6_l4)
        doc.text(marginLeft + 15, marginTop + 32, note6_l5)
        doc.text(marginLeft + 15, marginTop + 35, note6_l6)
    }

    else{
        var lines4 = doc.splitTextToSize(text4, 140)
        doc.text(marginLeft + 5, marginTop + 8, "i)")
        doc.text(marginLeft + 12, marginTop + 8, lines4)
    }

    var text5 = checkText(itemDetails.remark2);    

    var tempSpace = 35

    if(text5.toUpperCase() == "NOTE 1"){
        marginTop = marginTop + tempSpace
        doc.text(marginLeft + 5, marginTop + 8, "ii)")
        doc.text(marginLeft + 12, marginTop + 8, note1_l1)
        doc.text(marginLeft + 15, marginTop + 16, note1_l2)
        doc.text(marginLeft + 15, marginTop + 20, note1_l3)
        doc.text(marginLeft + 15, marginTop + 24, note1_l4)
        marginTop = marginTop - tempSpace
    }

    else if(text5.toUpperCase() == "NOTE 2"){
        doc.text(marginLeft + 5, marginTop + 8 + tempSpace, "ii)")
        doc.text(marginLeft + 12, marginTop + 8 + tempSpace, note2_l1)
    }

    else if(text5.toUpperCase() == "NOTE 3"){
        doc.text(marginLeft + 5, marginTop + 8 + tempSpace, "ii)")
        doc.text(marginLeft + 12, marginTop + 8 + tempSpace, note3_l1)
    }

    else if(text5.toUpperCase() == "NOTE 4"){
        doc.text(marginLeft + 5, marginTop + 8 + tempSpace, "ii)")
        doc.text(marginLeft + 12, marginTop + 8 + tempSpace, note4_l1)
    }

    else if(text5.toUpperCase() == "NOTE 5"){
        doc.text(marginLeft + 5, marginTop + 8 + tempSpace, "ii)")
        doc.text(marginLeft + 12, marginTop + 8 + tempSpace, note5_l1)
    }

    else if(text5.toUpperCase() == "NOTE 6"){
        marginTop = marginTop + tempSpace
        doc.text(marginLeft + 5, marginTop + 8, "ii)")
        doc.text(marginLeft + 12, marginTop + 8, note6_l1)
        doc.text(marginLeft + 15, marginTop + 17, note6_l2)
        doc.text(marginLeft + 15, marginTop + 20, note6_l3)
        doc.text(marginLeft + 15, marginTop + 26, note6_l4)
        doc.text(marginLeft + 15, marginTop + 32, note6_l5)
        doc.text(marginLeft + 15, marginTop + 35, note6_l6)
        marginTop = marginTop - tempSpace
    }

    else{
        var lines5 = doc.splitTextToSize(text5, 140)
        doc.text(marginLeft + 5, marginTop + 8 + 38, "ii)")
        doc.text(marginLeft + 12, marginTop + 8 + 38, lines5)
    }
    
    window.open(doc.output('bloburl'), '_blank');    
}

function checkLine(marginTop, check, doc){
    var returnMarginTop = marginTop;
    if (marginTop > check){
        returnMarginTop = 25;
        doc.addPage();
    }
    return returnMarginTop
}
function calculatePremium(premium, salePrice){
    if(premium > 1){
        return premium;
    }
    if(premium < 1 && premium> 0){
        var rtrnPremium = salePrice*premium;
        if(rtrnPremium < 600)
        rtrnPremium = 600;
        return rtrnPremium;
    }
    else
        return 0;
}

function checkText(text){
    if( text == null){
        return "";
    }
    else{
        return text;
    }
}

module.exports = {open};