<template>
  <div>
    <v-card color="" class="pa-2 mx-2 mt-2">
      <div color="" class="pa-1 title" style="text-decoration: underline">
        User Manager
      </div>
      <v-row class="pa-1 ma-1">
        <v-col class="pa-0 ma-0">
          <span>Filter by: </span>
          <v-chip
            v-if="isAdmin"
            @click="
              chip = 'All';
              showSearch = false;
              manageRefund.showBtn1 = false;
              manageRefund.showBtn2 = false;
              searchAllUsers();
              showPagination = true;
            "
            class="mx-1"
            >All</v-chip
          >
          <v-chip
            v-if="isAdmin"
            @click="
              chip = 'Name';
              users = [];
              showSearch = true;
              manageRefund.showBtn1 = false;
              manageRefund.showBtn2 = false;
              showPagination = false;
            "
            class="mx-1"
            >Name</v-chip
          >
          <v-chip
            v-if="isAdmin"
            @click="
              chip = 'Email';
              users = [];
              showSearch = true;
              manageRefund.showBtn1 = false;
              manageRefund.showBtn2 = false;
              showPagination = false;
            "
            class="mx-1"
            >Email</v-chip
          >
          <v-chip
            v-if="isAdmin"
            @click="
              chip = 'With Balance Deposit';
              users = [];
              showSearch = false;
              manageRefund.showBtn1 = true;
              manageRefund.showBtn2 = false;
              showPagination = false;
              searchUsersWithDepositBalance('all');
            "
            class="mx-1"
            >With Balance Deposit</v-chip
          >
          <v-chip
            v-if="isAdmin"
            @click="
              chip = 'Unverified';
              showSearch = false;
              manageRefund.showBtn1 = false;
              manageRefund.showBtn2 = false;
              showPagination = false;
              getUserWithEmailVerificationStatus(false);
            "
            class="mx-1"
            >Unverified email</v-chip
          >
          <v-chip
            v-if="isAdmin"
            @click="
              chip = 'Bank Status';
              users = [];
              bankStatus = '';
              showSearch = false;
              manageRefund.showBtn1 = false;
              manageRefund.showBtn2 = false;
              showPagination = false;
            "
            class="mx-1"
            >Bank Status</v-chip
          >
          <v-chip
            v-if="isAdmin"
            @click="
              chip = 'Payment Status';
              users = [];
              bankStatus = '';
              showSearch = false;
              manageRefund.showBtn1 = false;
              manageRefund.showBtn2 = false;
              showPagination = false;
            "
            class="mx-1"
            >Payment Status</v-chip
          >
          <v-chip
            v-if="isAdmin"
            @click="
              chip = 'Blacklisted';
              users = [];
              bankStatus = '';
              showSearch = false;
              manageRefund.showBtn1 = false;
              manageRefund.showBtn2 = false;
              showPagination = true;
            "
            class="mx-1"
            >Blacklisted</v-chip
          >
          <v-chip
            v-if="isAdmin"
            @click="
              chip = 'Deactivated Account';
              users = [];
              bankStatus = '';
              showSearch = false;
              manageRefund.showBtn1 = false;
              manageRefund.showBtn2 = false;
              showPagination = true;
            "
            class="mx-1"
            >Deactivated Account</v-chip
          >
          <v-chip
            @click="
              chip = 'Verified Staff';
              users = [];
              bankStatus = '';
              showSearch = false;
              manageRefund.showBtn1 = false;
              manageRefund.showBtn2 = false;
              showPagination = true;
            "
            class="mx-1"
            >Verified Staff</v-chip
          >
          <v-chip
            @click="
              chip = 'Unverified Staff';
              users = [];
              bankStatus = '';
              showSearch = false;
              manageRefund.showBtn1 = false;
              manageRefund.showBtn2 = false;
              showPagination = true;
            "
            class="mx-1"
            >Unverified Staff</v-chip
          >
          <!-- <v-chip @click="showSearch = false; manageRefund.showBtn2=true;manageRefund.showBtn1=false; searchUsersWithDepositBalance('lostbidders')" class="mx-1">Lost in latest auction</v-chip> -->
        </v-col>
      </v-row>
      <v-row
        v-if="users.length > 0"
        class="pa-1 ma-1"
        justify="start"
        align="start"
      >
        <v-btn @click="selectAllEmail()" dark color="blue" class="ma-1 mt-2"
          >Select All</v-btn
        >
        <v-btn @click="deselectAllEmail()" dark color="grey" class="ma-1 mt-2"
          >Clear All</v-btn
        >
      </v-row>
      <v-row
        v-if="manageRefund.showBtn1 || manageRefund.showBtn2"
        class="pa-1 ma-1"
        justify="end"
        align="end"
      >
        <v-btn
          @click="exportUserWithDepositBalanceCSV(users)"
          dark
          color="blue"
          class="ma-1 mt-2"
          >export Users with Deposit Balance - CSV</v-btn
        >
        <span class="ma-1 red--text">Total to be refunded: </span>
        <span class="ma-1 font-weight-bold">{{
          ringgitFormat(manageRefund.totalDepositBalance)
        }}</span>
        <!-- <v-btn v-if="manageRefund.showBtn2" @click="manageRefund.show = true; manageRefund.currentFunction ='refund lost bidders';" :disabled="users.length < 1" class="ma-1">Refund lost Bidders</v-btn> -->
        <!-- <v-btn v-if="manageRefund.showBtn1" @click="manageRefund.show = true; manageRefund.currentFunction ='refund all';" :disabled="users.length < 1" class="ma-1">Refund All</v-btn> -->
      </v-row>
      <v-divider></v-divider>
      <v-row v-if="chip == 'Bank Status'" class="pa-1 ma-1">
        <v-select
          class="pa-0"
          filled
          dense=""
          v-model="bankStatus"
          :items="['pending', 'rejected']"
          hide-details="auto"
          label="Bank Status"
        >
        </v-select>
      </v-row>
      <v-row v-if="chip == 'Payment Status'" class="pa-1 ma-1">
        <v-select
          class="pa-0"
          filled
          dense=""
          v-model="auctionName"
          :items="auctionHistoryList"
          item-text="session"
          item-value="session"
          hide-details="auto"
          label="Auction"
        >
        </v-select>
      </v-row>
      <v-row v-if="auctionName != ''" class="pa-1 ma-1">
        <v-col class="pa-0 ma-0">
          <span>Filter by: </span>
          <v-chip @click="paymentStatus = 'all'" class="mx-1">All</v-chip>
          <v-chip @click="paymentStatus = 'paid'" class="mx-1">Paid</v-chip>
          <v-chip @click="paymentStatus = 'unpaid'" class="mx-1">Unpaid</v-chip>
        </v-col>
      </v-row>
      <v-row v-if="showSearch" class="pa-1 ma-1">
        <v-text-field
          prepend-inner-icon="mdi-account-search-outline"
          class="pa-0"
          solo
          background-color="rgba(232, 232, 232, 0.8)"
          v-model="search"
          hide-details="auto"
          label="Search user.."
        >
        </v-text-field>
        <v-btn @click="searchUsers()" dark color="blue" class="ma-1 mt-2"
          >Search</v-btn
        >
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">No.</th>
              <th class="text-left">Bidder Id</th>
              <th class="text-left pl-5">Email</th>
              <th class="text-left pl-5">Name</th>
              <th v-if="chip == 'With Balance Deposit'" class="text-left pl-5">
                Deposit Balance
              </th>
              <th v-if="chip == 'With Balance Deposit'" class="text-left pl-5">
                Recent Deposit Date
              </th>
              <th class="text-left pl-5">to email</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, i) in users" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ user.id }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.firstName }}</td>
              <td v-if="chip == 'With Balance Deposit'">
                {{ ringgitFormat(user.depositBalance) }}
              </td>
              <td v-if="chip == 'With Balance Deposit'">
                {{ user.recentDepositDate }}
              </td>
              <td>
                <v-checkbox
                  v-model="emailToSendList"
                  :value="user.email"
                  hide-details="auto"
                  color="primary"
                  class="ma-0 pa-0"
                ></v-checkbox>
              </td>
              <td>
                <v-btn @click="manageUserBtn(user)" color="#b4dcfb"
                  >manage user
                </v-btn>
              </td>
              <td v-if="mybidList.length > 0">
                <div v-if="mybidList[i].status != 'forfeited'">
                  <span
                    v-if="checkPaid(mybidList[i]) == 'Paid'"
                    class="green--text font-weight-bold"
                    >{{ checkPaid(mybidList[i]) }}</span
                  >
                  <span
                    v-if="checkPaid(mybidList[i]) != 'Paid'"
                    class="red--text font-weight-bold"
                    >{{ checkPaid(mybidList[i]) }}</span
                  >
                </div>
                <span
                  v-if="mybidList[i].status == 'forfeited'"
                  class="red--text font-weight-bold"
                  >Forfeited</span
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <template v-if="showPagination">
        <div class="text-center mt-2">
          <v-pagination
            v-model="usersCurrentPage"
            :length="userLength"
            :total-visible="usersPerPage"
          ></v-pagination>
        </div>
      </template>
    </v-card>

    <v-dialog v-model="manageUser.show" persistent max-width="600px">
      <v-card class="pa-2">
        <v-row class="pa-2 ma-0 title">
          Bidder ID: {{ manageUser.currentUser.id }}
        </v-row>
        <v-card class="pa-2 mb-1">
          <v-col class="pa-1 ma-1">
            <div>
              <span
                v-if="manageUser.currentUser.accountType == 'Individual'"
                class="font-weight-bold"
                >Name:</span
              >
              <span
                v-if="manageUser.currentUser.accountType == 'Organization'"
                class="font-weight-bold"
                >Organization:</span
              >
              {{ manageUser.currentUser.firstName }}
            </div>
            <div>
              <span
                v-if="manageUser.currentUser.accountType == 'Individual'"
                class="font-weight-bold"
                >IC No:</span
              >
              <span
                v-if="manageUser.currentUser.accountType == 'Organization'"
                class="font-weight-bold"
                >SSM No:</span
              >
              {{ manageUser.currentUser.ic }}
            </div>
            <div v-if="manageUser.currentUser.accountType == 'Organization'">
              <span class="font-weight-bold">Representative Name:</span>
              {{ manageUser.currentUser.representativeName }}
            </div>
            <div v-if="manageUser.currentUser.accountType == 'Organization'">
              <span class="font-weight-bold">Representative IC No:</span>
              {{ manageUser.currentUser.representativeIc }}
            </div>
            <div v-if="manageUser.currentUser.staff">
              <span class="font-weight-bold">Staff: </span>
              <v-btn
                v-if="!manageUser.currentUser.staffVerified"
                color="red"
                @click="staff.show = true"
              >
                <span class="font-weight-bold">Not verified</span>
              </v-btn>
              <v-btn
                v-if="manageUser.currentUser.staffVerified"
                color="green"
                @click="staff.show = true"
              >
                <span class="font-weight-bold">Verified</span>
              </v-btn>
            </div>
            <div>
              <span class="font-weight-bold">Email:</span>
              {{ manageUser.currentUser.email }}
            </div>
            <div>
              <span class="font-weight-bold">Phone No:</span>
              {{ manageUser.currentUser.phoneNo }}
            </div>
            <div>
              <span class="font-weight-bold">Address:</span>
              {{ manageUser.currentUser.address }},
              {{ manageUser.currentUser.city }}
              {{ manageUser.currentUser.postcode }},
              {{ manageUser.currentUser.state }}
            </div>
            <div>
              <span class="font-weight-bold">Deposit Balance:</span>
              <span
                v-if="manageUser.depositBalance > 0"
                class="green--text headline"
              >
                {{ ringgitFormat(manageUser.depositBalance) }}</span
              >
              <span
                v-if="manageUser.depositBalance <= 0"
                class="red--text headline"
              >
                {{ ringgitFormat(manageUser.depositBalance) }}</span
              >
              <span
                v-if="manageUser.depositBalance == null"
                class="red--text headline"
              >
                {{ ringgitFormat(0) }}</span
              >
            </div>
            <div>
              <span class="font-weight-bold">Bank Name:</span>
              {{ manageUser.currentUser.bankName }}
            </div>
            <div>
              <span class="font-weight-bold">Bank Account:</span>
              {{ manageUser.currentUser.bankAccNo }}
            </div>
            <div>
              <span class="font-weight-bold">Bank Account Status:</span>
              <span
                v-if="manageUser.bankInfo.status == 'verified'"
                class="ml-1 green--text"
                >{{ manageUser.bankInfo.status }}</span
              >
              <span
                v-if="manageUser.bankInfo.status == 'Fetching info..'"
                class="ml-1 grey--text title"
                >{{ manageUser.bankInfo.status }}</span
              >
              <span
                v-if="
                  manageUser.bankInfo.status != 'verified' &&
                  manageUser.bankInfo.status != 'Fetching info..'
                "
                class="ml-1 red--text title"
                >{{ manageUser.bankInfo.status }}</span
              >
            </div>
            <div v-if="manageUser.bankInfo.status == 'rejected'">
              <span class="">Reject Description: </span>
              {{ manageUser.currentUser.reject_desc }}
            </div>
            <div>
              <span class="font-weight-bold">Account Status:</span>
              <span
                v-if="!manageUser.currentUser.inactive"
                class="font-weight-bold green--text"
              >
                ACTIVE
              </span>
              <span
                v-if="manageUser.currentUser.inactive"
                class="font-weight-bold orange--text"
              >
                INACTIVE
              </span>
              <span v-if="user.role == 'admin'">
                <v-btn
                  v-if="!manageUser.currentUser.inactive"
                  color="red"
                  @click="deactivateUser()"
                >
                  <span class="font-weight-bold">Deactivate User</span>
                </v-btn>
                <v-btn
                  v-if="manageUser.currentUser.inactive"
                  color="green"
                  @click="deactivateUser()"
                >
                  <span class="font-weight-bold">Activate User</span>
                </v-btn>
              </span>
            </div>
          </v-col>

          <v-col v-if="user.role == 'admin'" class="pa-1 ma-1">
            <v-btn
              v-if="
                manageUser.bankInfo.status == 'Has not yet verified.' ||
                manageUser.bankInfo.status == 'rejected'
              "
              @click="
                () => {
                  verifyBankAcount(manageUser.currentUser);
                }
              "
              color=""
              block
              class="mb-1"
            >
              Verify Bank Account
            </v-btn>
            <v-btn
              v-if="isSuperAdmin"
              @click="
                () => {
                  addDepositBalance.show = true;
                }
              "
              color="green"
              dark
              block
              class="mb-1"
              >Add Deposit Balance</v-btn
            >
            <v-btn
              v-if="manageUser.depositBalance < 0"
              @click="resetDeposit()"
              color="red"
              dark
              block
              class="mb-1"
              >Reset Deposit Balance</v-btn
            >
            <v-btn
              @click="viewPurchasedLot(manageUser.currentUser.id)"
              color="blue"
              dark
              block
              class="mb-1"
              >View Purchased Lot</v-btn
            >
            <ReserveUnit
              v-if="isSuperAdmin"
              :bidder="manageUser.currentUser"
            ></ReserveUnit>
            <v-row
              v-if="isSuperAdmin"
              class="pa-0 ma-0 mb-1"
              justify="space-between"
            >
              <!-- <v-col
                cols="12"
                sm="6"
                class="pa-0 ma-0 mb-1"
                @click="manageSingleRefund.show = true"
                v-if="
                  manageUser.depositBalance > 0 &&
                  manageUser.bankInfo.status == 'verified'
                "
              >
                <v-btn color="purple" dark block>Refund</v-btn>
              </v-col>
              <span
                v-if="manageUser.bankInfo.status == 'verified'"
                class="mx-1 d-none d-sm-flex"
              ></span> -->
              <v-col
                class="pa-0 ma-0"
                @click="manageManualRefund.show = true"
                v-if="manageUser.depositBalance > 0"
              >
                <v-btn color="#f13f9d" dark block>Manual Refund</v-btn>
              </v-col>
            </v-row>
            <v-btn
              @click="showBlacklist()"
              color="black"
              dark
              block
              class="mb-1"
              >Blacklist</v-btn
            >
          </v-col>
        </v-card>
        <v-btn
          @click="
            () => {
              manageUser.show = false;
            }
          "
          >Close</v-btn
        >
      </v-card>
    </v-dialog>

    <v-dialog v-model="staff.show" max-width="800px">
      <v-card class="pa-2">
        <v-card-title>Staff</v-card-title>
        <div class="pl-2">
          <span class="font-weight-bold">Staff:</span>
          {{ manageUser.currentUser.staffOf }}
        </div>
        <div class="pl-2">
          <span class="font-weight-bold">Staff No:</span>
          {{ manageUser.currentUser.staffId }}
        </div>
        <v-card class="ma-2">
          <v-img
            ref="displayStaffIDphoto"
            max-height="300"
            contain
            :src="staff.staffIDImgUrl"
            :lazy-src="staff.staffIDImgUrl"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <!-- <v-img max-height="250" style="transform: rotate(-50deg);"
                            contain :src="serverUrl + '/api/file/image/general/website/sample.png'"></v-img>         -->
              <div
                class="font-weight-bold"
                style="
                  font-size: 100px;
                  transform: rotate(-30deg);
                  color: rgb(255 0 0 / 42%);
                "
              >
                SAMPLE
              </div>
            </v-row>
          </v-img>
        </v-card>
        <v-btn
          v-if="!manageUser.currentUser.staffVerified"
          class="ma-1"
          @click="verifyStaff()"
          block
          color="green"
          dark
          >Verify</v-btn
        >
        <v-btn
          v-if="manageUser.currentUser.staffVerified"
          class="ma-1"
          @click="verifyStaff()"
          block
          color="red"
          dark
          >Refute</v-btn
        >
        <v-btn
          class="ma-1"
          @click="staff.show = false"
          block
          color="grey lighten-1"
          >Close</v-btn
        >
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDepositBalance.show" max-width="400px">
      <v-card class="pa-2 text-center" v-if="addDepositBalance.show1">
        <v-row class="pa-2 ma-0" justify="center"
          >Enter amount to add into deposit balance.</v-row
        >
        <v-row class="pa-2 ma-0">
          <v-text-field
            prepend-icon="RM"
            class="pa-0"
            solo
            full-width=""
            hide-details="auto"
            label="Amount.."
            type="number"
            v-model="addDepositBalance.amount"
          >
          </v-text-field>
        </v-row>
        <v-row class="pa-1 ma-0" justify="center">
          <v-btn @click="confirmAdd()" class="mx-1" dark color="green"
            >add</v-btn
          >
          <v-btn @click="addDepositBalance.show = false">cancel</v-btn>
        </v-row>
      </v-card>
      <v-card class="pa-2 text-center" v-if="!addDepositBalance.show1">
        <v-row class="pa-2 ma-0" justify="center"
          >Are you sure you want to add
          <span class="font-weight-bold green--text mx-1">
            {{ ringgitFormat(parseInt(addDepositBalance.amount)) }} </span
          >into
          <span class="font-weight-bold mx-1">
            {{ manageUser.currentUser.firstName }} </span
          >deposit balance?
        </v-row>
        <v-row class="pa-2 ma-0" justify="center">
          <v-btn @click="addDepositBalanceQuery()" color="green" class="mx-1"
            >Yes</v-btn
          >
          <v-btn
            @click="
              () => {
                addDepositBalance.show1 = true;
              }
            "
            color="red"
            class="mx-1"
            >No</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mybid.show" max-width="600px">
      <v-card class="pa-2 ma-0">
        <v-row class="pa-2 ma-0" v-if="mybid.all.length == 0" justify="center"
          >There are no Auction Lot purchased by this user.</v-row
        >
        <v-row
          class="pa-2 ma-0 title"
          v-if="mybid.all.length != 0"
          justify="center"
          >({{ showDate(mybid.date[myBidCurrentPage - 1]) }})</v-row
        >
        <v-col class="pa-0 ma-0" v-for="(session, i) in mybid.all" :key="i">
          <v-row class="pa-2 ma-0 font-weight-bold"
            >Auction: {{ showAuctionName(session[0].auctionName) }}</v-row
          >
          <v-divider></v-divider>
          <v-col
            v-if="session[0].startDate == mybid.date[myBidCurrentPage - 1]"
            class="pa-0 ma-0"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr v-for="(mybid, j) in session" :key="j">
                    <td>Lot {{ mybid.lotNo }}</td>
                    <td>{{ mybid.particular }}</td>
                    <td>{{ ringgitFormat(calculateTotalItemPrice(mybid)) }}</td>
                    <td
                      v-if="checkPaid(mybid) == 'Paid'"
                      class="green--text font-weight-bold"
                    >
                      {{ checkPaid(mybid) }}
                    </td>
                    <td
                      v-if="checkPaid(mybid) != 'Paid'"
                      class="red--text font-weight-bold"
                    >
                      {{ checkPaid(mybid) }}
                    </td>
                    <td>
                      <v-btn
                        @click="showManagePayment(mybid)"
                        v-if="mybid.status != 'forfeited'"
                        color="blue"
                        dark
                        >Manage Payment</v-btn
                      >
                      <span
                        v-if="mybid.status == 'forfeited'"
                        class="red--text font-weight-bold"
                        >Forfeited</span
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-col>
        <template>
          <div class="text-center mt-2">
            <v-pagination
              v-model="myBidCurrentPage"
              :length="mybid.date.length"
              :total-visible="10"
            ></v-pagination>
          </div>
        </template>
        <v-btn @click="mybid.show = false">Close</v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="managePayment.show" max-width="600px">
      <v-card class="pa-2 ma-0">
        <v-row class="pa-2 ma-0 title"
          >Order No: {{ managePayment.item.auctionName }}</v-row
        >
        <v-divider></v-divider>
        <div class="pl-2 pt-2">
          <span class="font-weight-bold">Particular: </span
          >{{ managePayment.item.particular }}
        </div>
        <div class="pl-2">
          <span class="font-weight-bold">Total Price to be Paid: </span
          >{{ ringgitFormat(calculateTotalItemPrice(managePayment.item)) }}
        </div>
        <div class="pl-2">
          <span class="font-weight-bold">Total Deposit Paid: </span
          >{{
            ringgitFormat(
              parseFloat(managePayment.item.deposit) +
                parseFloat(managePayment.item.additionalDepositPaid)
            )
          }}
        </div>
        <div class="pl-2">
          <span class="font-weight-bold">Total Paid: </span
          >{{ ringgitFormat(managePayment.item.amountPaid) }}
        </div>
        <div class="pl-2">
          <span class="font-weight-bold">Status: </span>
          <span
            v-if="checkPaid(managePayment.item) == 'Paid'"
            class="green--text font-weight-bold"
            >{{ checkPaid(managePayment.item) }}</span
          >
          <span
            v-if="checkPaid(managePayment.item) != 'Paid'"
            class="red--text font-weight-bold"
            >{{ checkPaid(managePayment.item) }}</span
          >
        </div>
        <v-btn
          v-if="isSuperAdmin"
          class="mb-2"
          @click="
            managePayment.show1 = true;
            managePayment.amount = 0;
          "
          block
          color="green"
          >Pay</v-btn
        >
        <v-btn
          v-if="isSuperAdmin"
          class="mb-2"
          @click="
            manageLotDeposit.show = true;
            manageLotDeposit.amount = 0;
          "
          block
          color="green"
          >Adjust Deposit Paid</v-btn
        >
        <v-btn
          class="mb-2"
          @click="viewReceipt(managePayment.item, manageUser.currentUser)"
          block
          color="orange"
          >View Full Settlement</v-btn
        >
        <v-btn
          class="mb-2"
          @click="viewMemorandum(managePayment.item, manageUser.currentUser)"
          block
          color="orange"
          >View Memorandum</v-btn
        >
        <v-btn
          class="mb-2"
          @click="viewGatePass(managePayment.item, manageUser.currentUser)"
          block
          color="orange"
          >View Gate Pass</v-btn
        >
        <v-btn
          @click="
            manageForfeit.show = true;
            manageForfeit.mybid = managePayment.item;
          "
          v-if="checkPaid(managePayment.item) != 'Paid'"
          class="mb-2"
          block
          color="red"
          >Forfeit</v-btn
        >
        <v-btn @click="managePayment.show = false" block color="grey lighten-1"
          >Close</v-btn
        >
      </v-card>
    </v-dialog>

    <v-dialog v-model="managePayment.show1" max-width="400px">
      <v-card class="pa-2 ma-0" v-if="managePayment.show2">
        <v-text-field
          prepend-icon="RM"
          class="pa-0"
          solo
          full-width=""
          hide-details="auto"
          label="Amount.."
          type="number"
          v-model="managePayment.amount"
        >
        </v-text-field>
        <v-row class="pa-2 ma-0" justify="center">
          <v-btn class="mx-1" @click="showPayAmount()" color="green" dark
            >Pay Amount</v-btn
          >
          <v-btn
            class="mx-1"
            @click="managePayment.show1 = false"
            color="grey"
            dark
            >Cancel</v-btn
          >
        </v-row>
      </v-card>
      <v-card class="pa-2 ma-0" v-if="!managePayment.show2">
        <v-row class="pa-2 ma-0" justify="center"
          >Confirm payment of
          <span class="font-weight-bold mx-1">{{
            ringgitFormat(parseInt(managePayment.amount))
          }}</span>
          for item
          <span class="font-weight-bold mx-1">{{
            managePayment.item.particular
          }}</span>
          in lot
          <span class="font-weight-bold mx-1"
            >{{ managePayment.item.lotNo }}.</span
          >
        </v-row>
        <v-row class="pa-2 ma-0" justify="center">
          <v-btn @click="payLot()" color="green" class="mx-1" dark>Yes</v-btn>
          <v-btn
            @click="
              managePayment.show2 = true;
              managePayment.show1 = false;
            "
            color="red"
            class="mx-1"
            dark
            >No</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="manageLotDeposit.show" max-width="400px">
      <v-card class="pa-2 ma-0">
        <v-text-field
          prepend-icon="RM"
          class="pa-0"
          solo
          full-width=""
          hide-details="auto"
          label="Amount.."
          type="number"
          v-model="manageLotDeposit.amount"
        >
        </v-text-field>
        <v-row class="pa-2 ma-0" justify="center">
          <v-btn class="mx-1" @click="adjustDepositPaid()" color="green" dark
            >Change Amount</v-btn
          >
          <v-btn
            class="mx-1"
            @click="manageLotDeposit.show = false"
            color="grey"
            dark
            >Cancel</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="manageRefund.show" max-width="400px">
      <v-card class="pa-2 ma-0">
        <v-row class="pa-0 ma-0" justify="center"
          ><v-icon size="50" color="red">mdi-alert-outline</v-icon></v-row
        >
        <v-row class="pa-0 ma-0 title text-center" justify="center"
          >Type "{{ manageRefund.currentFunction }}" below and press button "{{
            manageRefund.currentFunction
          }}" to confirm.</v-row
        >
        <v-text-field
          class="pa-0"
          solo
          full-width=""
          hide-details="auto"
          v-model="manageRefund.value"
        >
        </v-text-field>
        <v-row class="pa-0 ma-1" justify="center">
          <v-btn
            @click="refundMultipleUsers(manageRefund.value)"
            class="mx-1"
            color="blue"
            dark
            >{{ manageRefund.currentFunction }}</v-btn
          >
          <v-btn
            @click="
              manageRefund.show = false;
              manageRefund.value = '';
            "
            class="mx-1"
            >cancel</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="manageSingleRefund.show" max-width="400px">
      <v-card class="pa-2 ma-0">
        <v-row class="pa-0 ma-0" justify="center"
          ><v-icon size="50" color="red">mdi-alert-outline</v-icon></v-row
        >
        <v-row class="pa-0 ma-0 title text-center" justify="center"
          >Confirm refund {{ ringgitFormat(manageUser.depositBalance) }} to
          {{ manageUser.currentUser.firstName }}?</v-row
        >
        <v-row class="pa-0 ma-0 text-center" justify="center"
          >Type "refund" below and press button "confirm".</v-row
        >
        <v-text-field
          class="pa-0"
          solo
          full-width=""
          hide-details="auto"
          v-model="manageSingleRefund.value"
        >
        </v-text-field>
        <v-row class="pa-2 ma-0" justify="center">
          <v-btn @click="refund()" color="green" class="mx-1" dark
            >Confirm</v-btn
          >
          <v-btn
            @click="
              manageSingleRefund.show = false;
              manageSingleRefund.value = '';
            "
            color="red"
            class="mx-1"
            dark
            >No</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="manageManualRefund.show" max-width="400px">
      <v-card class="pa-2 ma-0">
        <v-row class="pa-0 ma-0" justify="center"
          ><v-icon size="50" color="red">mdi-alert-outline</v-icon></v-row
        >
        <v-row class="pa-0 ma-0 title text-center" justify="center"
          >Confirm refund {{ ringgitFormat(manageUser.depositBalance) }} to
          {{ manageUser.currentUser.firstName }}?</v-row
        >
        <v-row class="pa-0 ma-0 text-center" justify="center"
          >Proceed with this process only if you have already refunded to this
          bidder manually.</v-row
        >
        <v-row class="pa-0 ma-0 text-center" justify="center"
          >Type "refund manually" below and press button "confirm".</v-row
        >
        <v-text-field
          class="pa-0"
          solo
          full-width=""
          hide-details="auto"
          v-model="manageManualRefund.value"
        >
        </v-text-field>
        <v-row class="pa-2 ma-0" justify="center">
          <v-btn @click="refundManuallyQuery()" color="green" class="mx-1" dark
            >Confirm</v-btn
          >
          <v-btn
            @click="
              manageManualRefund.show = false;
              manageManualRefund.value = '';
            "
            color="red"
            class="mx-1"
            dark
            >No</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="manageForfeit.show" max-width="400px">
      <v-card class="pa-2 ma-0">
        <v-row class="pa-0 ma-0" justify="center"
          ><v-icon size="50" color="red">mdi-alert-outline</v-icon></v-row
        >
        <v-row class="pa-0 ma-0 title text-center" justify="center"
          >Confirm to forfeit this Lot?</v-row
        >
        <v-row class="pa-0 ma-0 text-center" justify="center"
          >Type "forfeit" below and press button "confirm".</v-row
        >
        <v-text-field
          class="pa-0"
          solo
          full-width=""
          hide-details="auto"
          v-model="manageForfeit.value"
        >
        </v-text-field>
        <v-row class="pa-2 ma-0" justify="center">
          <v-btn @click="ForfeitLotQuery()" color="green" class="mx-1" dark
            >Confirm</v-btn
          >
          <v-btn
            @click="
              manageForfeit.show = false;
              manageForfeit.value = '';
            "
            color="red"
            class="mx-1"
            dark
            >No</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="blacklist.show" max-width="800px">
      <v-card class="pa-2 text-center">
        <v-card-title>Blacklisted by Vendors</v-card-title>
        <v-row class="pa-2 ma-0" align="center">
          <v-select
            class="pa-2"
            filled
            dense=""
            v-model="blacklist.vendor"
            :items="vendors"
            hide-details="auto"
            label="vendors"
          >
          </v-select>
          <v-btn @click="blacklistByvendor()" color="red" dark>Blacklist</v-btn>
        </v-row>
        <v-row class="pa-2 ma-0" v-if="blacklist.vendors.length == 0">
          This user has not been blacklisted by any vendors..
        </v-row>
        <v-col
          class="pa-0 ma-0 mb-3"
          v-for="(vendorObj, i) in blacklist.vendors"
          :key="i"
        >
          <v-row class="pa-1 ma-0" align="center">
            <v-icon color="red" large="" class="mx-5"
              >mdi-minus-circle-off</v-icon
            >
            <span class="font-weight-bold">{{ vendorObj.vendor }}</span>
            <v-row class="pa-0 ma-0" justify="end">
              <v-btn @click="removeVendorFromBlacklist(vendorObj.vendor)" dark
                >Remove</v-btn
              >
            </v-row>
          </v-row>
        </v-col>
        <v-btn @click="blacklist.show = false" block color="grey lighten-1"
          >Close</v-btn
        >
      </v-card>
    </v-dialog>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
    <br />
    <CreateBidder v-if="isSuperAdmin"></CreateBidder>
    <Mailing
      :emailToSendList="emailToSendList"
      :setEmailToSendList="setEmailToSendList"
      v-if="isAdmin"
    ></Mailing>
  </div>
</template>

<script>
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
import receiptPDF from "../jscontroller/receiptPDF";
import gatepassPDF from "../jscontroller/gatepassPDF";
import memorandumPDF from "../jscontroller/memorandumPDF";
import storage from "../jscontroller/storage";
import { allVendors } from "../constants/vendors";
import authorization from "../jscontroller/authorization";
import userApi from "../api/userApi";
import { downloadUserWithDepositBalance } from "../jscontroller/csvExports";
import Mailing from "@/components/Mailing";
import CreateBidder from "@/components/CreateBidder";
import ReserveUnit from "@/components/ReserveUnit";

export default {
  name: "UserManager",
  components: { Mailing, CreateBidder, ReserveUnit },
  data: () => ({
    user: {},
    serverUrl: serverConfig.hostname,
    logo: "",
    signature: "",
    search: "",
    showSearch: true,
    users: [],
    manageUser: {
      show: false,
      currentUser: {},
      depositBalance: 0,
      bankInfo: {},
    },
    addDepositBalance: {
      show: false,
      show1: true,
      amount: 0,
    },
    snackbar: {
      showProgress: false,
      showStatus: false,
      text: "",
      timeout: 6000,
      icon: "mdi-check-circle-outline",
      color: "green",
    },
    mybid: {
      all: [],
      time: [],
      date: [],
      show: false,
    },
    managePayment: {
      show: false,
      show1: false,
      show2: true,
      item: {},
      amount: 0,
      totalDepositBalance: 0,
    },
    manageLotDeposit: {
      show: false,
      amount: 0,
    },
    manageRefund: {
      showBtn1: false,
      showBtn2: false,
      show: false,
      value: "",
      currentFunction: "",
    },
    manageSingleRefund: {
      show: false,
      value: "",
    },
    manageManualRefund: {
      show: false,
      value: "",
    },
    manageForfeit: {
      show: false,
      value: "",
      mybid: {},
    },
    myBidCurrentPage: 1,
    usersCurrentPage: 1,
    userLength: 0,
    usersPerPage: 10,
    blackListUsersPerPage: 50,
    showPagination: false,
    bankStatus: "",
    auctionName: "",
    paymentStatus: "",
    auctionHistoryList: [],
    mybidList: [],
    chip: "",
    vendors: [],
    blacklist: {
      show: false,
      vendors: [],
      vendor: "",
    },
    staff: {
      show: false,
      staffIDImgUrl: "",
    },
    isAdmin: true,
    isSuperAdmin: true,
    emailToSendList: [],
  }),
  created() {
    this.user = storage.GetUser();
    if (!authorization.checkVendorAuthorization(this.user)) {
      storage.switchNavBar("website");
      this.$router.push("/");
      this.$router.go();
    }
    if (!authorization.checkAdminAuthorization(this.user)) {
      this.isAdmin = false;
    }
    if (!authorization.checkSuperAdminAuthorization(this.user)) {
      this.isSuperAdmin = false;
    }
    this.imgLoader(this.serverUrl + "/api/file/image/pdf/logo/logo2.png").then(
      (picture) => {
        this.logo = picture;
      }
    );
    this.imgLoader(
      this.serverUrl + "/api/file/image/general/website/signature.png"
    ).then((picture) => {
      this.signature = picture;
    });
    this.vendors = allVendors;
  },
  methods: {
    ringgitFormat(amount) {
      var currency = "RM";
      if (amount < 0) {
        currency = "-RM";
      }
      if (amount == null) {
        return currency + (0).toFixed(2);
      } else {
        var num = Math.abs(amount).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        });
        var deci = num.split(".")[1];
        if (deci != null) {
          if (deci.length == 1) {
            num = num + "0";
          }
        } else {
          num = num + ".00";
        }
        return currency + num;
      }
    },
    showAuctionName(auctionName) {
      var str = auctionName.split("/");
      return str[0];
    },
    showManagePayment(item) {
      var self = this;
      self.managePayment.item = item;
      self.managePayment.show = true;
    },
    showPayAmount() {
      var self = this;
      var amount = parseFloat(self.managePayment.amount);
      if (amount >= 0) {
        self.managePayment.show2 = false;
      }
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
    confirmAdd() {
      var self = this;
      var amount = parseInt(self.addDepositBalance.amount);
      if (amount > 0) {
        self.addDepositBalance.show1 = false;
      }
    },

    manageUserBtn(user) {
      var self = this;
      self.manageUser.currentUser = user;
      self.manageUser.show = true;

      // check if photo is available or not
      axios
        .get(
          self.serverUrl +
            "/api/file/image/staffIdCard/" +
            user.staffId +
            "/" +
            user.staffId +
            "_" +
            user.id +
            ".jpg"
        )
        .then(function () {
          self.staff.staffIDImgUrl =
            self.serverUrl +
            "/api/file/image/staffIdCard/" +
            user.staffId +
            "/" +
            user.staffId +
            "_" +
            user.id +
            ".jpg";
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function () {
          self.staff.staffIDImgUrl =
            "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1200px-No_image_available.svg.png";
        });
      self.getUserAsset(user.id);
      self.getBankAccount(user.bankAccNo);
    },

    searchUsers() {
      var self = this;
      if (self.chip == "Name") self.searchUsersByName();
      else if (self.chip == "Email") self.searchUsersByEmail();
    },
    searchUsersByName() {
      var self = this;
      if (self.search != "") {
        self.showSnackbar();
        var name = self.search;
        var apiUrl = self.serverUrl + "/api/user/get/byname";
        var bodyFormData = new FormData();
        bodyFormData.set("name", name);
        axios
          .post(apiUrl, bodyFormData, {
            headers: {
              Authorization: `Bearer ${self.user.jwt}`,
            },
          })
          .then(function (response) {
            self.users = response.data;
            self.hideSnackbar("Finish fetching data..", "green");
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            if (error.response.status == 401) {
              alert(error.response.data.message);
              self.logout();
            }
          });
      }
    },
    searchUsersByEmail() {
      var self = this;
      if (self.search != "") {
        var email = self.search;
        self.showSnackbar();
        var apiUrl = self.serverUrl + "/api/user/getall/by/email";
        var bodyFormData = new FormData();
        bodyFormData.set("email", email);
        axios
          .post(apiUrl, bodyFormData, {
            headers: {
              Authorization: `Bearer ${self.user.jwt}`,
            },
          })
          .then(function (response) {
            self.users = response.data;
            self.hideSnackbar("Finish fetching data..", "green");
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            if (error.response.status == 401) {
              alert(error.response.data.message);
              self.logout();
            }
          });
      }
    },
    searchAllUsers() {
      var self = this;
      self.getUserCount(self.usersPerPage, () => {
        self.searchUsersByPage(1, self.usersPerPage);
      });
    },
    searchUsersWithDepositBalance(url) {
      var self = this;
      self.showSnackbar();
      var apiUrl = self.serverUrl + "/api/user/get/with/balancedeposit/" + url;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          var data = response.data;
          self.manageRefund.totalDepositBalance = 0;
          self.users = [];
          self.users = data.users;
          self.manageRefund.totalDepositBalance = data.totalDepositBalance;
          self.hideSnackbar("Finish fetching data..", "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    exportUserWithDepositBalanceCSV(users) {
      downloadUserWithDepositBalance(users);
    },
    logout() {
      storage.clearUserStorage();
      storage.switchNavBar("website");
      this.$router.push("/");
      this.$router.go();
    },
    searchUsersByPage(page, noOfContent) {
      var self = this;
      self.showSnackbar();
      var apiUrl =
        self.serverUrl +
        "/api/user/getbypage/" +
        (page - 1) +
        "/" +
        noOfContent;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          self.users = response.data;
          self.hideSnackbar("Finish fetching data..", "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    searchAllBlacklistUsersByPage(page) {
      var self = this;

      var apiUrl =
        self.serverUrl +
        "/api/user/blacklist/getbypage/" +
        (page - 1) +
        "/" +
        self.blackListUsersPerPage;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          var tempTotal =
            parseInt(response.data.totalUsers) / self.blackListUsersPerPage;
          if (tempTotal > tempTotal.toFixed(0)) {
            tempTotal = parseInt(tempTotal.toFixed(0)) + 1;
          } else {
            tempTotal = parseInt(tempTotal.toFixed(0));
          }
          self.userLength = tempTotal;
          self.users = response.data.users;
          self.hideSnackbar("Finish fetching data..", "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    searchAllDeactivatedUsersByPage(page) {
      var self = this;

      var apiUrl =
        self.serverUrl +
        "/api/user/deactivate/getbypage/" +
        (page - 1) +
        "/" +
        self.blackListUsersPerPage;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          var tempTotal =
            parseInt(response.data.totalUsers) / self.blackListUsersPerPage;
          if (tempTotal > tempTotal.toFixed(0)) {
            tempTotal = parseInt(tempTotal.toFixed(0)) + 1;
          } else {
            tempTotal = parseInt(tempTotal.toFixed(0));
          }
          self.userLength = tempTotal;
          self.users = response.data.users;
          self.hideSnackbar("Finish fetching data..", "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    searchAllStaffByPage(page, isVerified) {
      var self = this;
      userApi.searchAllStaffOfByPage(
        "TM Staff",
        isVerified,
        page,
        self.blackListUsersPerPage,
        (data) => {
          var tempTotal =
            parseInt(data.totalUsers) / self.blackListUsersPerPage;
          if (tempTotal > tempTotal.toFixed(0)) {
            tempTotal = parseInt(tempTotal.toFixed(0)) + 1;
          } else {
            tempTotal = parseInt(tempTotal.toFixed(0));
          }
          self.userLength = tempTotal;
          self.users = data.users;
          self.hideSnackbar("Finish fetching data..", "green");
        }
      );
    },
    getUserWithEmailVerificationStatus(status) {
      var self = this;
      self.users = [];
      self.showSnackbar();
      var apiUrl =
        self.serverUrl + "/api/user/getall/by/email/verification/status";
      var bodyFormData = new FormData();
      bodyFormData.set("status", status);
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          self.users = response.data;
          self.hideSnackbar("Finish fetching data..", "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    searchUsersWithStatus(status) {
      var self = this;
      self.showSnackbar();
      var apiUrl = self.serverUrl + "/api/user/bank/getall/" + status;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          self.hideSnackbar("Search Done", "green");
          self.users = response.data;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    getUserCount(usersPerPage, callback) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/user/count";
      axios
        .get(apiUrl)
        .then(function (response) {
          var tempTotal = parseInt(response.data) / usersPerPage;
          if (tempTotal > tempTotal.toFixed(0)) {
            tempTotal = parseInt(tempTotal.toFixed(0)) + 1;
          } else {
            tempTotal = parseInt(tempTotal.toFixed(0));
          }
          self.userLength = tempTotal;
          callback();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    getUserWithPaymentStatus(auctionName, status) {
      var self = this;
      self.users = [];
      self.mybidList = [];
      self.showSnackbar();
      var apiUrl = self.serverUrl + "/api/mybid/withuser/get/paymentstatus";
      var bodyFormData = new FormData();
      bodyFormData.set("auctionName", auctionName);
      bodyFormData.set("status", status);
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          var data = response.data;
          for (var i = 0; i < data.length; i++) {
            self.users.push(data[i].user);
            self.mybidList.push(data[i].mybid);
          }
          self.hideSnackbar("Finish fetching data..", "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    getAuctionName() {
      var self = this;
      var apiUrl = self.serverUrl + "/api/auctionmanager/session/history/all";
      axios
        .get(apiUrl)
        .then(function (response) {
          var tempAuctionHList = response.data;
          self.auctionHistoryList = [];
          for (var i = 0; i < tempAuctionHList.length; i++) {
            self.auctionHistoryList.unshift(tempAuctionHList[i]);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    calculateTotalItemPrice(mybid) {
      var self = this;
      var total =
        mybid.bidPrice +
        self.calculatePremium(mybid.premium, mybid.bidPrice) -
        mybid.additionalDepositPaid -
        mybid.deposit +
        mybid.adminFee +
        mybid.safetyDeposit;
      return total;
    },
    calculatePremium(premium, salePrice) {
      if (premium > 1) {
        return premium;
      }
      if (premium < 1 && premium > 0) {
        var rtrnPremium = salePrice * premium;
        if (rtrnPremium < 600) rtrnPremium = 600;
        return rtrnPremium;
      } else return 0;
    },
    addDepositBalanceQuery() {
      var self = this;
      self.showSnackbar();
      var apiUrl = self.serverUrl + "/api/transaction/save";
      var bodyFormData = {
        email: self.manageUser.currentUser.email,
        userId: self.manageUser.currentUser.id,
        transactionType: "deposit",
        value: parseFloat(self.addDepositBalance.amount),
        details: "",
      };
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function () {
          self.hideSnackbar("Transaction successful", "green");
          self.addDepositBalance.show1 = true;
          self.addDepositBalance.show = false;
          self.getUserAsset(self.manageUser.currentUser.id);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Transaction failed", "red");
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    payLot() {
      var self = this;
      self.showSnackbar();
      var apiUrl = self.serverUrl + "/api/transaction/paylot";
      var mybid = self.managePayment.item;
      var date = new Date();
      mybid.date =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      mybid.amountPaid = parseFloat(self.managePayment.amount);
      var bodyFormData = mybid;
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function () {
          self.hideSnackbar("Payment confirmed", "green");
          self.managePayment.show2 = true;
          self.managePayment.show1 = false;
          self.viewPurchasedLot(self.manageUser.currentUser.id);
          self.getUserAsset(self.manageUser.currentUser.id);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          } else {
            // eslint-disable-next-line no-console
            console.log(error.response);
            self.hideSnackbar("Pay lot failed", "red");
          }
        });
    },
    adjustDepositPaid() {
      var self = this;
      self.showSnackbar();
      var apiUrl = self.serverUrl + "/api/transaction/add-lot-deposit";
      const mybid = self.managePayment.item;

      var bodyFormData = new FormData();
      bodyFormData.set("id", mybid.id);
      bodyFormData.set("deposit_amount", self.manageLotDeposit.amount);
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function () {
          self.hideSnackbar("Deposit paid Successfully changed", "green");
          self.manageLotDeposit.show = false;
          self.viewPurchasedLot(self.manageUser.currentUser.id);
          self.getUserAsset(self.manageUser.currentUser.id);
          self.managePayment.item.additionalDepositPaid =
            self.manageLotDeposit.amount - self.managePayment.item.deposit;
          self.manageLotDeposit.amount = 0;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          } else {
            self.hideSnackbar("Deposit paid change failed", "red");
          }
        });
    },
    getUserAsset(id) {
      var self = this;
      var apiUrl = self.serverUrl + "/api/user/asset";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          self.manageUser.depositBalance = response.data.depositBalance;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    viewPurchasedLot(id) {
      var self = this;
      self.getMyBidByEmail(id, () => {
        self.mybid.show = true;
      });
    },
    checkPaid(mybid) {
      var self = this;
      var total = self.calculateTotalItemPrice(mybid);
      if (mybid.amountPaid >= total) return "Paid";
      else return "Not paid";
    },
    showDate(date) {
      var d = new Date(date);
      var rd = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
      return rd;
    },
    getMyBidByEmail(id, callback) {
      var self = this;
      var apiUrl = self.serverUrl + "/api/mybid/get/userid";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          self.mybid.all = [];
          self.mybid.time = [];
          self.mybid.date = [];
          var mybid = response.data;
          for (var i = 0; i < mybid.length; i++) {
            var exist = false;
            for (var j = 0; j < self.mybid.time.length; j++) {
              if (self.mybid.time[j] == mybid[i].startDate + mybid[i].time) {
                exist = true;
              }
            }
            if (exist == false) {
              self.mybid.time.unshift(mybid[i].startDate + mybid[i].time);
            }

            var dateExist = false;
            for (var k = 0; k < self.mybid.date.length; k++) {
              if (self.mybid.date[k] == mybid[i].startDate) {
                dateExist = true;
              }
            }
            if (dateExist == false) {
              self.mybid.date.unshift(mybid[i].startDate);
            }
          }

          for (var x = 0; x < self.mybid.time.length; x++) {
            var session = [];
            for (var y = 0; y < mybid.length; y++) {
              if (self.mybid.time[x] == mybid[y].startDate + mybid[y].time) {
                session.push(mybid[y]);
              }
            }
            self.mybid.all.push(session);
          }
          callback();
        })
        .catch(function () {});
    },
    getBankAccount(bankAccNo) {
      var self = this;
      var apiUrl = self.serverUrl + "/api/billplz/get/" + bankAccNo;
      self.manageUser.bankInfo = { status: "Fetching info.." };
      axios
        .get(apiUrl)
        .then(function (response) {
          if (response.data.statusCode == 200)
            self.manageUser.bankInfo = response.data;
          else {
            self.manageUser.bankInfo = { status: "Has not yet verified." };
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    verifyBankAcount(user) {
      var self = this;
      var apiUrl = self.serverUrl + "/api/billplz/verify";
      var bodyFormData = new FormData();
      var organization = false;
      if (user.accountType == "Organization") organization = true;
      self.showSnackbar();
      bodyFormData.set("name", user.firstName);
      bodyFormData.set("ic", user.ic);
      bodyFormData.set("bankAccNo", user.bankAccNo);
      bodyFormData.set("bankName", user.bankName);
      bodyFormData.set("organization", organization);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          if (response.data.statusCode != 200) {
            self.hideSnackbar(response.data.error.message[0], "red");
          } else {
            self.hideSnackbar(
              "Verification will take up to 3 days to complete.",
              "green"
            );
            self.getBankAccount(user.bankAccNo);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Error", "red");
        });
    },
    refundMultipleUsers(usersToRefund) {
      // usersToRefund: all, lostBidders
      var self = this;
      self.showSnackbar();
      var refundCommand = "";
      if (usersToRefund == "refund all") refundCommand = "all";
      else if (usersToRefund == "refund lost bidders")
        refundCommand = "lostBidders";
      if (refundCommand == "all" || refundCommand == "lostBidders") {
        var apiUrl = self.serverUrl + "/api/transaction/refund/multipleusers";
        var bodyFormData = new FormData();
        self.showSnackbar();
        bodyFormData.set("usersToRefund", refundCommand);
        axios
          .post(apiUrl, bodyFormData, {
            headers: {
              Authorization: `Bearer ${self.user.jwt}`,
            },
          })
          .then(function (response) {
            if (response.data.statusCode != 200) {
              self.hideSnackbar(
                response.data.failed + ". " + response.data.message,
                "red"
              );
              self.manageRefund.show = false;
              self.manageRefund.value = "";
            } else self.hideSnackbar(response.data.message, "green");
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            self.hideSnackbar("Error", "red");
            if (error.response.status == 401) {
              alert(error.response.data.message);
              self.logout();
            }
          });
      } else {
        self.hideSnackbar(
          "there is no command for " + '"' + usersToRefund + '"',
          "orange"
        );
      }
    },
    refund() {
      var self = this;
      self.showSnackbar();
      var apiUrl = self.serverUrl + "/api/transaction/refund/singleuser";
      var bodyFormData = new FormData();
      bodyFormData.set("email", self.manageUser.currentUser.email);
      bodyFormData.set("userId", self.manageUser.currentUser.id);
      bodyFormData.set("bankAccNo", self.manageUser.currentUser.bankAccNo);
      bodyFormData.set("name", self.manageUser.currentUser.firstName);
      bodyFormData.set("total", self.manageUser.depositBalance);
      if (self.manageSingleRefund.value == "refund") {
        axios
          .post(apiUrl, bodyFormData, {
            headers: {
              Authorization: `Bearer ${self.user.jwt}`,
            },
          })
          .then(function (response) {
            if (response.data.statusCode != 200) {
              self.hideSnackbar(response.data.error.message[0], "red");
              self.manageSingleRefund.show = false;
            } else {
              self.manageSingleRefund.show = false;
              self.hideSnackbar("Refund successful", "green");
              self.getUserAsset(self.manageUser.currentUser.id);
            }
            self.manageSingleRefund.value = "";
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            self.hideSnackbar(error, "red");
            if (error.response.status == 401) {
              alert(error.response.data.message);
              self.logout();
            }
          });
      } else self.hideSnackbar("Wrong input.", "red");
    },
    refundManuallyQuery() {
      var self = this;
      self.showSnackbar();
      var apiUrl = self.serverUrl + "/api/transaction/refund/manually";
      var bodyFormData = new FormData();
      bodyFormData.set("email", self.manageUser.currentUser.email);
      bodyFormData.set("userId", self.manageUser.currentUser.id);
      bodyFormData.set("name", self.manageUser.currentUser.firstName);
      bodyFormData.set("amount", self.manageUser.depositBalance);
      if (self.manageManualRefund.value == "refund manually") {
        axios
          .post(apiUrl, bodyFormData, {
            headers: {
              Authorization: `Bearer ${self.user.jwt}`,
            },
          })
          .then(function (response) {
            self.hideSnackbar(response.data, "green");
            self.manageManualRefund.show = false;
            self.getUserAsset(self.manageUser.currentUser.id);
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            self.hideSnackbar(error, "red");
            self.manageManualRefund.show = false;
            if (error.response.status == 401) {
              alert(error.response.data.message);
              self.logout();
            }
          });
      } else self.hideSnackbar("Wrong input.", "red");
    },
    resetDeposit() {
      var self = this;
      self.showSnackbar();
      var apiUrl = self.serverUrl + "/api/transaction/deposit/reset";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", self.manageUser.currentUser.id);
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function () {
          self.hideSnackbar("Reset Deposit Balance successfull", "green");
          self.getUserAsset(self.manageUser.currentUser.id);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar(error, "red");
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    viewReceipt(mybid, currentUser) {
      var user = {
        email: currentUser.email,
        name: currentUser.firstName,
        id: currentUser.id,
      };
      this.getAuctionLotLog(mybid.particular, (itemDetails) => {
        receiptPDF.open(mybid, itemDetails, user, this.logo, this.signature);
      });
    },
    viewGatePass(mybid, currentUser) {
      // var user = {
      //     email: currentUser.email,
      //     name: currentUser.firstName,
      //     id: currentUser.id,
      // }
      this.getAuctionLotLog(mybid.particular, (itemDetails) => {
        gatepassPDF.open(
          mybid,
          itemDetails,
          currentUser,
          this.logo,
          this.signature
        );
      });
    },
    viewMemorandum(mybid, currentUser) {
      this.getAuctionLotLog(mybid.particular, (itemDetails) => {
        var user = {
          email: currentUser.email,
          name: currentUser.firstName,
          role: currentUser.role,
          id: currentUser.id,
        };

        memorandumPDF.open(mybid, itemDetails, user, this.logo);
      });
    },
    getAuctionLotLog(particular, callback) {
      var apiUrl =
        serverConfig.hostname + "/api/auctionlot/get/log/" + particular;
      axios
        .get(apiUrl)
        .then(function (response) {
          var itemDetails = response.data;
          callback(itemDetails);
        })
        .catch(function () {});
    },
    showBlacklist() {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/user/blacklist/get/all";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", self.manageUser.currentUser.id);
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          self.blacklist.vendors = response.data;
          self.blacklist.show = true;
        })
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    blacklistByvendor() {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/user/blacklist";
      var body = {
        userId: self.manageUser.currentUser.id,
        vendor: self.blacklist.vendor,
      };
      axios
        .post(apiUrl, body, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          self.hideSnackbar(response.data, "orange");
          self.showBlacklist();
        })
        .catch(function (error) {
          self.hideSnackbar(error.response.data, "red");
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    removeVendorFromBlacklist(vendor) {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/user/blacklist/delete/vendor";
      var body = {
        userId: self.manageUser.currentUser.id,
        vendor: vendor,
      };

      axios
        .post(apiUrl, body, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          self.hideSnackbar(response.data, "green");
          self.showBlacklist();
        })
        .catch(function (error) {
          self.hideSnackbar(error.response.data, "red");
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    ForfeitLotQuery() {
      var self = this;
      self.showSnackbar();
      var apiUrl = self.serverUrl + "/api/mybid/forfeit/lot";
      var bodyFormData = self.manageForfeit.mybid;
      if (self.manageForfeit.value == "forfeit") {
        axios
          .post(apiUrl, bodyFormData, {
            headers: {
              Authorization: `Bearer ${self.user.jwt}`,
            },
          })
          .then(function (response) {
            self.hideSnackbar(response.data, "green");
            self.manageForfeit.show = false;
            self.managePayment.show = false;
            self.viewPurchasedLot(self.manageUser.currentUser.id);
            self.getUserAsset(self.manageUser.currentUser.id);
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            self.hideSnackbar(error, "red");
            if (error.response.status == 401) {
              alert(error.response.data.message);
              self.logout();
            }
            self.manageForfeit.show = false;
          });
      } else self.hideSnackbar("Wrong input.", "red");
    },
    deactivateUser() {
      var self = this;
      self.showSnackbar();
      var apiUrl = self.serverUrl + "/api/user/deactivate";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", self.manageUser.currentUser.id);
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          self.hideSnackbar(response.data, "green");
          self.manageUser.currentUser.inactive =
            !self.manageUser.currentUser.inactive;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar(error.response.data, "red");
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    verifyStaff() {
      var self = this;
      self.showSnackbar();
      var apiUrl = self.serverUrl + "/api/user/verify/staff";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", self.manageUser.currentUser.id);
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          self.hideSnackbar(response.data, "green");
          self.manageUser.currentUser.staffVerified =
            !self.manageUser.currentUser.staffVerified;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar(error.response.data, "red");
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    imgLoader(url) {
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.src = url;
      });
    },
    setEmailToSendList(emailList) {
      this.emailToSendList = emailList;
    },
    selectAllEmail() {
      var self = this;
      if (self.users.length > 0) {
        for (var i = 0; i < self.users.length; i++) {
          var emailInList = false;
          var tempEmail = self.users[i].email;
          for (var j = 0; j < self.emailToSendList.length; j++) {
            if (self.emailToSendList[j] == tempEmail) emailInList = true;
          }
          if (!emailInList) self.emailToSendList.push(tempEmail);
        }
      }
    },
    deselectAllEmail() {
      var self = this;
      for (var i = 0; i < self.users.length; i++) {
        var tempEmail = self.users[i].email;
        for (var j = 0; j < self.emailToSendList.length; j++) {
          if (self.emailToSendList[j] == tempEmail)
            self.emailToSendList.splice(j, 1);
        }
      }
    },
  },
  watch: {
    usersCurrentPage: function () {
      var self = this;
      self.users = [];
      if (self.chip == "All") {
        self.searchUsersByPage(self.usersCurrentPage, self.usersPerPage);
      } else if (self.chip == "Blacklisted") {
        self.searchAllBlacklistUsersByPage(self.usersCurrentPage);
      } else if (self.chip == "Deactivated Account") {
        self.searchAllDeactivatedUsersByPage(self.usersCurrentPage);
      } else if (self.chip == "Verified Staff") {
        self.searchAllStaffByPage(self.usersCurrentPage, true);
      } else if (self.chip == "Unverified Staff") {
        self.searchAllStaffByPage(self.usersCurrentPage, false);
      }
      // this.getAuctionLotsByPage(this.lot.contentInPage)
      // this.scrollToTop();
    },
    bankStatus: function () {
      var self = this;
      if (self.bankStatus != "") {
        self.searchUsersWithStatus(self.bankStatus);
      }
    },
    chip: function () {
      var self = this;
      self.users = [];
      self.auctionHistoryList = [];
      self.mybidList = [];
      self.paymentStatus = "";
      self.auctionName = "";
      self.usersCurrentPage = 1;
      if (self.chip == "Payment Status") {
        self.getAuctionName();
      }
      if (self.chip == "Blacklisted") {
        self.searchAllBlacklistUsersByPage(1);
      }
      if (self.chip == "Deactivated Account") {
        self.searchAllDeactivatedUsersByPage(1);
      }
      if (self.chip == "Verified Staff") {
        self.searchAllStaffByPage(1, true);
      }
      if (self.chip == "Unverified Staff") {
        self.searchAllStaffByPage(1, false);
      }
    },
    auctionName: function () {
      var self = this;
      self.paymentStatus = "";
    },
    paymentStatus: function () {
      var self = this;
      self.getUserWithPaymentStatus(self.auctionName, self.paymentStatus);
    },
    emailToSendList: function () {},
  },
};
</script>
