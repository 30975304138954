<template>
    <div>
        <v-card class="ma-2 pa-3" color="#ffd991">
            <div class="headline font-weight-bold">Auction Management Services Sdn. Bhd.</div>
            <div class="caption">(formerly known as Fleet Management Solution Sdn Bhd)</div>
            <div class="mt-2 body-2">
                {{contactInfos.addressLine1}} <br/>
                {{contactInfos.addressLine2}}<br/>
                {{contactInfos.addressLine3}}<br/>
                {{contactInfos.addressLine4}}
            </div>
            <p class="mt-5 body-2">
                Tel: {{contactInfos.tel}} <br>
                Email: {{contactInfos.email}}
            </p>            
            
        </v-card>

        <v-card class="ma-3 pa-3" >
            <v-row justify="center">
                <iframe :src="contactInfos.mapURL" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
            </v-row>
            
        </v-card>
        
    </div>
</template>

<script>
import axios from "axios"
import serverConfig from "../jscontroller/serverConfig"

export default {
  name: 'OnlineAuction',
  data: () => ({    
    contactInfos: {}
  }),
  created(){
  },
  mounted(){
    this.scrollToTop();
    this.getContactInfo();
  },
  methods:{
    scrollToTop(){
      window.scrollTo(0, 0);
    },
    getContactInfo(){
      var apiUrl = serverConfig.hostname + "/api/admin/contactinfo"
      axios.get(apiUrl).then((response) => {                
      this.contactInfos = response.data;      
    })
    }
  }
}
</script>
