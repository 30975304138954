const axios = require("axios");
const serverConfig = require("../jscontroller/serverConfig");
const storage = require("../jscontroller/storage");

const serverUrl = serverConfig.hostname;

function searchAllStaffOfByPage(
  staffOf,
  isVerified,
  page,
  noOfContent,
  callback
) {
  const user = storage.GetUser();
  var apiUrl = serverUrl + "/api/user/get-staff";
  var bodyFormData = new FormData();
  bodyFormData.set("staffOf", staffOf);
  bodyFormData.set("isVerified", isVerified);
  bodyFormData.set("page", page - 1);
  bodyFormData.set("noOfContent", noOfContent);

  axios
    .post(apiUrl, bodyFormData, {
      headers: {
        Authorization: `Bearer ${user.jwt}`,
      },
    })
    .then(function (response) {
      callback(response.data);
    })
    // eslint-disable-next-line no-unused-vars
    .catch(function (error) {
      // eslint-disable-next-line no-console
      console.log(error.response.data);
    });
}

async function createInstanceBidder(bodyForm) {
  const user = storage.GetUser();
  var apiUrl = serverUrl + "/api/user/add-instance-bidder";

  try {
    const response = await axios.post(apiUrl, bodyForm, {
      headers: {
        Authorization: `Bearer ${user.jwt}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

module.exports = { searchAllStaffOfByPage, createInstanceBidder };
