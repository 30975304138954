<template>
  <div>
    <!-- <v-row class="pt-2 ma-5" justify="center" align="center">
        <v-col class="pa-0 ma-0">        
            <v-row justify="center">
                <v-card flat max-width="200">
                    <v-img  src="https://5.imimg.com/data5/TU/IM/JT/SELLER-6983250/under-construction-sign-board-500x500.jpg"></v-img>
                </v-card>                
            </v-row>
            <v-row justify="center">
                We will be right back.
            </v-row>                        
        </v-col>
    </v-row>     -->
    <v-col class="ma-0 pa-0">
      <v-row class="pa-3 ma-0">
        <a @click="navigate('/onlineAuction/onAuction/1')">In Auction</a>
        <span class="mx-3">|</span>
        <a @click="navigate('/onlineAuction/nextAuction/1')">Next Auction</a>
        <span class="mx-3">|</span>
        <a @click="navigate('/onlineAuction/sold')">Sold</a>
      </v-row>
      <v-divider class="ma-1 pa-0"></v-divider>
      <v-divider class="ma-1 pa-0"></v-divider>
      <v-row
        v-if="countdownStatus == 'not started'"
        justify="center"
        class="headline pa-0 ma-0 text-center"
        >Auction starts at {{ session.startTime }} on {{ session.startDate }}
      </v-row>
      <v-row
        v-if="countdownStatus == 'started'"
        justify="center"
        class="headline red--text pa-0 ma-0 text-center"
        >Auction ends in: {{ timeLeft }}</v-row
      >
      <v-row
        v-if="countdownStatus == 'end'"
        justify="center"
        class="headline red--text pa-0 ma-0 text-center"
        >Auction has ended</v-row
      >
      <v-row
        v-if="frontMessage != null"
        justify="center"
        class="headline red--text pa-0 ma-0 text-center"
        >{{ frontMessage.info }}</v-row
      >
      <v-divider class="ma-1 pa-0"></v-divider>
      <v-col
        v-if="countdownStatus == 'started'"
        justify="center"
        class="pa-0 ma-0 mb-2 text-center"
      >
        <div class="pa-0 ma-0 text-center">
          Click the refresh button to keep track with the latest Ongoing bidding
        </div>
        <v-btn
          color="green"
          dark
          @click="getAuctionLotsByPage(lot.contentInPage)"
          >Refresh</v-btn
        >
      </v-col>
      <v-divider></v-divider>
    </v-col>
    <v-row class="ma-0 pa-0">
      <v-col class="mx-auto" v-for="item in lot.list" v-bind:key="item.id">
        <itemcard :lot="item" :ongoingBid="ongoingBid"></itemcard>
      </v-col>
    </v-row>
    <!-- page -->
    <v-card justify="center" flat class="pa-1">
      <template>
        <div class="text-center">
          <v-pagination
            v-model="currentPage"
            :length="lot.lotLength"
            :total-visible="10"
          ></v-pagination>
        </div>
      </template>
    </v-card>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import Itemcard from "@/components/Itemcard";
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
// import io from "socket.io-client";
import storage from "../jscontroller/storage";

export default {
  name: "OnlineAuction",
  components: { Itemcard },
  data: () => ({
    serverUrl: serverConfig.hostname,
    // socket: {},
    currentPage: 1,
    lot: {
      list: [],
      lotLength: 1,
      contentInPage: 6,
    },
    ongoingBid: [],
    session: {},
    timeLeft: "",
    countdownStatus: "no",
    frontMessage: null,
    snackbar: {
      showProgress: false,
      showStatus: false,
      text: "",
      timeout: 4000,
      icon: "mdi-check-circle-outline",
      color: "green",
    },
  }),
  created() {
    this.currentPage = parseInt(this.$route.params.page);
    this.user = storage.GetUser();
    // this.socket = io.connect(serverConfig.hostnameSocket, {path: '/socket.io'});
  },
  mounted() {
    var self = this;
    this.scrollToTop();
    this.getAuctionLotsByPage(this.lot.contentInPage);
    this.getAuctionLotsCount();
    this.getFrontMessageQuery();
    this.getLatestSession((session) => {
      if (session != "") {
        self.session = session;
        self.getServerTime((serverTime) => {
          var currentTime = new Date();
          setInterval(() => {
            self.countdown(session, serverTime, currentTime);
          }, 1000);
        });
      }
    });
    // this.socket.on("updateBidInProgress", (data)=>{
    //   var tempOngoingBidList = self.ongoingBid
    //   tempOngoingBidList[data.id].latestBid = data.latestBid
    //   self.ongoingBid = tempOngoingBidList
    // })
  },
  methods: {
    navigate: function(pagePath) {
      if (this.$route.path != pagePath) {
        this.$router.push(pagePath);
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getFrontMessageQuery() {
      var self = this;
      var apiUrl = self.serverUrl + "/api/toggleinfo/get/id/10";
      axios
        .get(apiUrl)
        .then(function(response) {
          self.frontMessage = response.data;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {});
    },
    getAuctionLotsByPage(noOfContents, callback) {
      var self = this;
      self.showSnackbar();
      var currentPage = self.currentPage - 1;
      if (currentPage < 0) {
        currentPage = 0;
      }
      var apiUrl =
        serverConfig.hostname +
        "/api/auctionlot/ongoingbid/getbypage/" +
        currentPage +
        "/" +
        noOfContents;
      axios
        .get(apiUrl)
        .then(function(response) {
          self.ongoingBid = [];
          self.lot.list = response.data.auctionLotList;
          var tempOngoingBid = response.data.ongoingBid;
          var tempOngoingBid2 = [];
          for (var i = 0; i < tempOngoingBid.length; i++) {
            tempOngoingBid2[tempOngoingBid[i].id] = tempOngoingBid[i];
          }
          self.ongoingBid = tempOngoingBid2;
          self.hideSnackbar("Finish fetching latest bid..", "green");
          callback(response.data);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {});
    },
    getAuctionLotsCount(callback) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/auctionlot/count";
      axios
        .get(apiUrl)
        .then(function(response) {
          var tempTotal = parseInt(response.data) / self.lot.contentInPage;
          if (tempTotal > tempTotal.toFixed(0)) {
            tempTotal = parseInt(tempTotal.toFixed(0)) + 1;
          } else {
            tempTotal = parseInt(tempTotal.toFixed(0));
          }
          self.lot.lotLength = tempTotal;
          callback();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {});
    },
    getLatestSession(callback) {
      // var self = this;
      var apiUrl =
        serverConfig.hostname + "/api/auctionmanager/get/session/latest";
      axios
        .get(apiUrl)
        .then(function(response) {
          callback(response.data);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {});
    },
    getServerTime(callback) {
      var apiUrl = serverConfig.hostname + "/api/time/get/monthdayformat";
      axios
        .get(apiUrl)
        .then(function(response) {
          callback(response.data);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {});
    },
    countdown(session, serverTime, currentTime) {
      var self = this;

      var countDownEnd = new Date(
        `${session.endDate} ${session.endTime} +8:00`
      ).getTime();
      var countDownStart = new Date(
        `${session.startDate} ${session.startTime} +8:00`
      ).getTime();
      var timeLeft = "";
      var now =
        new Date(serverTime).getTime() +
        new Date().getTime() -
        new Date(currentTime).getTime();
      if (now < countDownStart && now < countDownEnd) {
        self.countdownStatus = "not started";
      } else if (now > countDownStart && now < countDownEnd) {
        self.countdownStatus = "started";
      } else if (now > countDownStart && now > countDownEnd) {
        self.countdownStatus = "end";
        // self.navigate('/itempage/' + self.lotId);
      }
      // Find the distance between now and the count down date
      var distance = countDownEnd - now;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      timeLeft = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
      this.timeLeft = timeLeft;
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red" || color == "orange") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
  },
  watch: {
    currentPage: function() {
      // var self = this
      this.navigate("/onlineAuction/onAuction/" + this.currentPage);
      this.getAuctionLotsByPage(this.lot.contentInPage);
      this.scrollToTop();
    },
  },
};
</script>
