<template>
<div>
  <v-card
    class="mx-auto ma-2"
    max-width="374"
  >
    <v-img
      max-height="250"
      :src="imgCover"
      :lazy-src="imgCover">  
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
      <v-row
        class="fill-height ma-0"
        align="center"
        justify="center"
      >
        <v-img max-height="250" style="transform: rotate(-50deg);"
        contain :src="serverUrl + '/api/file/image/general/website/sold_stamp.png'"></v-img>        
      </v-row>
    </v-img>

    <!-- item name -->
    <v-card-title>Lot {{lot.id}}</v-card-title>
    
    <v-divider class="mx-4"></v-divider>
    <v-card-text>      
        <div class="my-0 subtitle-1 black--text">
            Regn No: {{lot.regnNo}}
        </div>
        <div class="my-0 subtitle-1 black--text">
        Model: {{lot.descOfGood}}
        </div>
        <div class="my-0 subtitle-1 black--text">
        Reserved price: <span class="font-weight-bold">{{ringgitFormat(lot.price)}}</span> 
        </div>
        <div v-if="lot.status == 'sold'" class="my-0 subtitle-1 red--text font-weight-bold">
        Sold Price: {{ringgitFormat(lot.salePrice)}}
        </div>

      <div>{{lot.remarks}}</div>
    </v-card-text>
    <div class="text-center">
        <v-btn @click="activateViewUnit(lot)" dark color="orange" class="mb-5">View Details</v-btn>
    </div>
    

  </v-card>

  <v-dialog v-model="viewUnit.show" persistent>
          <v-card ref="viewUnitScroll">
            <v-row class="pa-1 ma-0" justify="center" >
              <v-col>
                  <v-row align="center" justify="center">
                      <v-card  class="mx-auto pa-1 text-center" width="400">
                          <v-img
                          max-height="250"
                          contain
                          :src="currentPhoto"
                          >
                              <v-row v-if="viewUnit.lot.status == 'sold'"
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                              <v-img max-height="250" style="transform: rotate(-50deg);"
                              contain :src="this.serverUrl + '/api/file/image/general/website/sold_stamp.png'"></v-img>
                            </v-row>
                          </v-img>
                      </v-card>
                  </v-row> 
                  <!-- part slider                -->
                  <v-col class="ma-1">
                  <template >
                      <v-sheet
                          class="mx-auto"
                          elevation="4"
                          max-width="800"
                      >
                          <v-slide-group
                          :v-model="null"
                          class="pa-4"
                          center-active
                          show-arrows
                          >
                          <v-slide-item
                              v-for="photoUrl in photos"
                              :key="photoUrl"
                              v-slot:default="{ active, toggle }"
                          >
                              <v-card
                              :color="active ? 'primary' : 'grey lighten-1'"
                              class="ma-4"
                              height="100"
                              width="150"
                              @click="toggle"
                              >
                              <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center"
                              >
                                  <v-img
                                      @click="changePhoto(photoUrl)"
                                      max-height="105"
                                      contain
                                      :src="photoUrl"
                                  ></v-img>
                              </v-row>
                              </v-card>
                          </v-slide-item>
                          </v-slide-group>
                      </v-sheet>
                      </template>
                      </v-col>
                      <!-- end part slider -->
              </v-col>
            </v-row>
            <UnitDetails :lot="viewUnit.lot"></UnitDetails>
            <v-row class="pa-1 ma-0 pb-3" justify="center">
              <v-btn @click="viewUnit.show = false">Close</v-btn>              
            </v-row>
          </v-card>
        </v-dialog>
</div>
</template>

<script>
import axios from "axios"
import serverConfig from "../jscontroller/serverConfig"
import UnitDetails from '@/components/UnitDetails'

export default {
  props: ['lot'],
  components: {UnitDetails},
  data: () => ({    
    serverUrl: serverConfig.hostname,    
    imgCover: "",
    user: {},
    viewUnit: {
      show: false,
      lot: {},
    },
    photos: [],
    currentPhoto: ''
  }),
  created(){
  },
  mounted(){
    this.getAuctionLotFileNamesQuery(this.lot.regnNo)
  },
  methods:{
    ringgitFormat(amount){
      if(amount == null){
          return "RM " + (0).toFixed(2);
      }
      else{
        var num = amount.toLocaleString(undefined, {maximumFractionDigits:2})
        var deci = num.split(".")[1]
        if(deci != null){
          if(deci.length == 1){
            num = num + "0"
          }
          
        }
        else{
          num = num + ".00"
        }
        return "RM " + num;
      }
    },
    getAuctionLotFileNamesQuery(regnNo){        
      var self = this;      
      var apiUrl = self.serverUrl + "/api/file/filenames"
      var bodyFormData = new FormData();
      bodyFormData.set('folderName', regnNo);
      bodyFormData.set('folderName2', 'auctionlot');
      bodyFormData.set('folderType', 'image');
      axios.post(apiUrl, bodyFormData)
      .then(function (response) {
          var photos = response.data;
          if(response.data != ""){
            self.imgCover = self.serverUrl + '/api/file/image/auctionlot/'+ regnNo +'/'+photos[0];               
            for(var i=0; i<photos.length; i++){
              if(photos[i] == "0" || photos[i] =="0.jpg")
                self.imgCover = self.serverUrl + '/api/file/image/auctionlot/'+ regnNo +'/'+photos[i];
            }
          }
          else
            self.imgCover = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1200px-No_image_available.svg.png"    
      })
      .catch(function () {      
        self.imgCover = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1200px-No_image_available.svg.png"
      });
    },
    activateViewUnit(lot){
      var self = this;
      self.viewUnit.show = true;
      self.viewUnit.lot = lot;
      self.getAuctionLotFileNames2Query(lot.regnNo)
    },
    getAuctionLotFileNames2Query(regnNo){        
      var self = this;      
      var apiUrl = serverConfig.hostname + "/api/file/filenames"
      var bodyFormData = new FormData();
      bodyFormData.set('folderName', regnNo);
      bodyFormData.set('folderName2', 'auctionlot');
      bodyFormData.set('folderType', 'image');
      axios.post(apiUrl, bodyFormData)
      .then(function (response) {
          var photos = response.data;
          self.photos = [];
          if(response.data != ""){
              self.currentPhoto = self.serverUrl + '/api/file/image/auctionlot/'+ regnNo +'/'+photos[0];
              for(var i=0; i< photos.length; i++){
                self.photos.push(self.serverUrl + '/api/file/image/auctionlot/'+ regnNo +'/'+photos[i]);
                if(photos[i] =="0" || photos[i] =="0.jpg")
                  self.currentPhoto = self.serverUrl + '/api/file/image/auctionlot/'+ regnNo +'/'+photos[i];
              }                
          }
          else{
              self.photos = [];
              self.currentPhoto = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1200px-No_image_available.svg.png"
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch(function () {      
          self.photos = [];
          self.currentPhoto = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1200px-No_image_available.svg.png"
      });
    },
  }
}
</script>