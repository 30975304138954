<template>
    <div>
        <v-row class="pa-0 ma-0">
            <v-col class="pa-1 ma-0 px-2">
                <v-card dark="" class="title">
                    <v-row class="pa-3 ma-0 ml-2">Bidder Process</v-row>
                </v-card>
                <v-row class="pa-2 ma-0" justify="center">
                    <v-card>
                        <v-img 
                        :src="serverUrl + '/api/file/image/general/website/bidderProcess.jpg'"
                        max-width="500"
                        ></v-img>                    
                    </v-card>
                </v-row>
            </v-col>

            <v-col cols="3" class="d-none d-md-flex pr-0 ">
                <HelpNav></HelpNav>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import HelpNav from '@/components/HelpNav'
import serverConfig from "../jscontroller/serverConfig"
export default {
    // eslint-disable-next-line vue/no-unused-components
    components: {HelpNav},
    data: ()=>({
       serverUrl: serverConfig.hostname,
    })
}
</script>