<template>
  <div>
    <v-card color="" class="pa-2 mx-2 mt-2">
      <div color="" class="pa-1 title" style="text-decoration: underline">
        Document Manager
      </div>
      <v-col class="pa-2 ma-0" v-if="toggleInfo.length != 0">
        <v-row
          class="pa-2 ma-0 mb-2 white--text"
          style="background-color: #afafaf"
          >CATALOGUE</v-row
        >
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[0].name }}:</span>
          <input style="width: 50%" v-model="toggleInfo[0].info" type="text" />
        </v-row>
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[1].name }}:</span>
          <v-checkbox
            v-model="toggleInfo[1].toggle"
            hide-details="auto"
            color="primary"
            class="ma-0 pa-0"
          ></v-checkbox>
        </v-row>
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[2].name }}:</span>
          <v-checkbox
            v-model="toggleInfo[2].toggle"
            hide-details="auto"
            color="primary"
            class="ma-0 pa-0"
          ></v-checkbox>
        </v-row>
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[16].name }}:</span>
          <v-checkbox
            v-model="toggleInfo[16].toggle"
            hide-details="auto"
            color="primary"
            class="ma-0 pa-0"
          ></v-checkbox>
        </v-row>
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[17].name }}:</span>
          <v-checkbox
            v-model="toggleInfo[17].toggle"
            hide-details="auto"
            color="primary"
            class="ma-0 pa-0"
          ></v-checkbox>
        </v-row>
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[3].name }}:</span>
          <input v-model="toggleInfo[3].info" type="text" style="width: 50%" />
        </v-row>
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[4].name }}:</span>
          <input v-model="toggleInfo[4].info" type="text" style="width: 50%" />
        </v-row>
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[5].name }}:</span>
          <input v-model="toggleInfo[5].info" type="text" style="width: 50%" />
        </v-row>
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[6].name }}:</span>
          <input v-model="toggleInfo[6].info" type="text" style="width: 50%" />
        </v-row>
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[8].name }}:</span>
          <input v-model="toggleInfo[8].info" type="text" style="width: 50%" />
        </v-row>
      </v-col>
      <v-divider></v-divider>
      <v-col class="pa-2 ma-0">
        <div>Upload catalogue..</div>
        <input ref="csvFile" type="file" @change="csvReader()" accept=".csv" />
        <v-btn color="blue" v-if="csv.showBtn" @click="csv.show = true"
          >Save Catalogue</v-btn
        >
      </v-col>
      <v-col class="pa-2 ma-0" v-if="toggleInfo.length != 0">
        <v-row
          class="pa-2 ma-0 mb-2 white--text"
          style="background-color: #afafaf"
          >Next Auction CATALOGUE</v-row
        >
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[11].name }}:</span>
          <input style="width: 50%" v-model="toggleInfo[11].info" type="text" />
        </v-row>
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[12].name }}:</span>
          <input v-model="toggleInfo[12].info" type="text" style="width: 50%" />
        </v-row>
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[13].name }}:</span>
          <input v-model="toggleInfo[13].info" type="text" style="width: 50%" />
        </v-row>
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[14].name }}:</span>
          <input v-model="toggleInfo[14].info" type="text" style="width: 50%" />
        </v-row>
        <v-row class="pa-0 ma-0 pl-1">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[15].name }}:</span>
          <input v-model="toggleInfo[15].info" type="text" style="width: 50%" />
        </v-row>
      </v-col>
      <v-col class="pa-2 ma-0">
        <v-row
          class="pa-2 ma-0 mb-2 white--text"
          style="background-color: #afafaf"
          >DEPOSIT CONTROL</v-row
        >
        <v-row class="pa-0 ma-0 pl-1" v-if="toggleInfo.length != 0">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[7].name }}:</span>
          <v-checkbox
            v-model="toggleInfo[7].toggle"
            hide-details="auto"
            color="primary"
            class="ma-0 pa-0"
          ></v-checkbox>
        </v-row>
        <v-row class="pa-0 ma-0 pl-1" v-if="toggleInfo.length != 0">
          <span class="mr-1 font-weight-bold">{{ toggleInfo[18].name }}:</span>
          <v-checkbox
            v-model="toggleInfo[18].toggle"
            hide-details="auto"
            color="primary"
            class="ma-0 pa-0"
          ></v-checkbox>
        </v-row>
      </v-col>
      <v-col class="pa-2 ma-0">
        <v-row
          class="pa-2 ma-0 mb-2 white--text"
          style="background-color: #afafaf"
          >NOTIFICATION CONTROL</v-row
        >
        <v-col class="pa-0 ma-0 pl-1" v-if="toggleInfo.length != 0">
          <v-row class="pa-0 ma-0 red--text"
            >* If ONLINE AUCTION PAGE MESSAGE is not empty, it will show on the
            front of Online Auction Page.</v-row
          >
          <v-row class="pa-0 ma-0 pl-1">
            <span class="mr-1 font-weight-bold">{{ toggleInfo[9].name }}:</span>
            <input
              v-model="toggleInfo[9].info"
              type="text"
              style="width: 50%"
            />
          </v-row>
          <v-row class="pa-0 ma-0 pl-1">
            <span class="mr-1 font-weight-bold"
              >{{ toggleInfo[10].name }}:</span
            >
            <input
              v-model="toggleInfo[10].info"
              type="text"
              style="width: 50%"
            />
          </v-row>
        </v-col>
      </v-col>
      <v-row class="pa-2 ma-0" justify="center">
        <v-btn @click="saveAllToggleInfoQuery()" color="green" dark
          >Save Changes</v-btn
        >
      </v-row>
    </v-card>

    <v-card color="" class="pa-2 mx-2 mt-2">
      <v-col class="pa-2 ma-0">
        <v-row
          class="pa-2 ma-0 mb-2 white--text"
          style="background-color: #afafaf"
          >Vendor Contacts</v-row
        >
      </v-col>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Contact Person</th>
              <th class="text-left pl-5">Contact No</th>
              <th class="text-left pl-5">Location</th>
              <th class="text-left pl-5">Vendor</th>
              <th class="text-left pl-5">Display</th>
              <th class="text-left pl-5"></th>
              <th class="text-left pl-5"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vc, i) in vendorContacts" :key="i">
              <td>
                <v-row justify="center">{{ vc.contactPerson }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{ vc.contactNo }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{ vc.location }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{ vc.vendor }}</v-row>
              </td>
              <td>
                <v-checkbox
                  v-model="vc.toggle"
                  hide-details="auto"
                  color="primary"
                  class="ma-0 pa-0"
                ></v-checkbox>
              </td>
              <td>
                <v-btn
                  @click="editVendorContact(vendorContacts[i])"
                  color="orange lighten-1"
                  dark=""
                  >Edit</v-btn
                >
              </td>
              <td>
                <v-col>
                  <v-btn
                    @click="removeVendorContacts(vendorContacts[i])"
                    fab
                    x-small
                    color="red"
                    ><v-icon>mdi-close-thick</v-icon></v-btn
                  >
                </v-col>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-col>
        <v-row class="mb-3 mx-3" justify="center"
          ><v-btn
            @click="addVendorContact()"
            dark=""
            color="blue lighten-2"
            block
            ><v-icon>mdi-plus</v-icon></v-btn
          ></v-row
        >
      </v-col>
      <v-row class="pa-2 ma-0" justify="center">
        <v-btn @click="saveVendorContacts('all')" color="green" dark
          >Save All Vendor Contacts</v-btn
        >
      </v-row>
    </v-card>

    <v-dialog v-model="csv.show" persistent max-width="400px">
      <v-card class="pa-2 ma-0">
        <v-row class="pa-1 ma-0 title text-center" justify="center"
          >Are you sure you want to proceed? The old catalogue will be replaced
          with the new one.</v-row
        >
        <v-row class="pa-2 ma-0" justify="center">
          <v-btn @click="saveCatalogue()" color="green" dark class="mx-1 pa-0"
            >Yes</v-btn
          >
          <v-btn @click="csv.show = false" color="red" dark class="mx-1 pa-0"
            >No</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <!-- part edit vendorContacts -->
    <v-dialog v-model="vendorEdit.show" persistent max-width="600px">
      <v-card class="pa-2">
        <v-row class="pa-1 ma-0">
          <v-text-field
            v-model="vendorEdit.details.vendor"
            label="Vendor"
            hide-details="auto"
            class="pa-1 ma-2"
          ></v-text-field>
        </v-row>
        <v-row class="pa-1 ma-0">
          <v-text-field
            v-model="vendorEdit.details.location"
            label="Location"
            hide-details="auto"
            class="pa-1 ma-2"
          ></v-text-field>
        </v-row>
        <v-textarea
          class="ma-2"
          outlined
          label="Contact Person"
          v-model="vendorEdit.details.contactPerson"
          rows="3"
          hide-details="auto"
        ></v-textarea>
        <v-textarea
          class="ma-2"
          outlined
          label="Contact No"
          v-model="vendorEdit.details.contactNo"
          rows="3"
          hide-details="auto"
        ></v-textarea>
        <v-textarea
          class="ma-2"
          outlined
          label="Address"
          v-model="vendorEdit.details.address"
          rows="3"
          hide-details="auto"
        ></v-textarea>
        <v-row class="pa-0 ma-0" justify="end">
          <v-btn class="mx-1" @click="vendorEdit.show = false" color="grey" dark
            >Close</v-btn
          >
          <v-btn @click="saveVendorContacts()" color="green" dark=""
            >save</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
import storage from "../jscontroller/storage";
import authorization from "../jscontroller/authorization";

export default {
  data: () => ({
    user: {},
    serverUrl: serverConfig.hostname,
    toggleInfo: [],
    vendorContacts: [],
    vendorEdit: {
      show: false,
      details: {},
    },
    snackbar: {
      showProgress: false,
      showStatus: false,
      text: "",
      timeout: 2000,
      icon: "mdi-check-circle-outline",
      color: "green",
    },
    csv: {
      showBtn: false,
      show: false,
      auctionLotList: [],
    },
  }),
  created() {},
  mounted() {
    this.user = storage.GetUser();
    if (!authorization.checkSuperAdminAuthorization(this.user)) {
      storage.switchNavBar("website");
      this.$router.push("/");
      this.$router.go();
    }
    this.getToggleInfoQuery();
    this.getVendorContacts();
  },
  methods: {
    getToggleInfoQuery() {
      var self = this;
      var apiUrl = self.serverUrl + "/api/toggleinfo/getall";
      axios
        .get(apiUrl)
        .then(function (response) {
          self.toggleInfo = response.data;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    saveAllToggleInfoQuery() {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/toggleinfo/save/all";
      axios
        .post(apiUrl, self.toggleInfo, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function () {
          self.hideSnackbar("Update successfull", "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Update failed", "red");
        });
    },
    csvReader() {
      var self = this;
      var file = self.$refs.csvFile.files[0];
      if (file == null) self.csv.showBtn = false;
      var auctionLots = [];
      var reader = new FileReader();
      reader.onload = function () {
        var lotLines = reader.result.split("\n");
        var variables = lotLines[0].split(",");
        for (var i = 1; i < lotLines.length; i++) {
          var arrStr1 = lotLines[i].split('"');
          var line = lotLines[i].split('"')[0];
          var j = 1;
          while (j < arrStr1.length) {
            // change ',' to '&&&'
            line = line + arrStr1[j].replace(/,/g, "&&&");
            if (arrStr1[j + 1] != "") line = line + arrStr1[j + 1];
            j = j + 2;
          }
          var dataArray = line.split(",");
          var objData = {};
          for (var x = 0; x < dataArray.length; x++) {
            // change '&&&' back to ','
            // put data into object
            objData[variables[x].replace(/\r/g, "")] = dataArray[x].replace(
              /&&&/g,
              ","
            );
          }
          auctionLots.push(objData);
        }

        self.csv.auctionLotList = auctionLots;
        self.csv.showBtn = true;
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(file);
    },
    saveCatalogue() {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/auctionlot/catalogue/save";
      var body = self.csv.auctionLotList;
      axios
        .post(apiUrl, body, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          self.hideSnackbar(response.data, "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Saving failed", "red");
        });
      self.csv.show = false;
      self.csv.showBtn = false;
    },
    getVendorContacts() {
      var self = this;
      var apiUrl = self.serverUrl + "/api/toggleinfo/vendorcontacts/get/all";
      axios
        .get(apiUrl)
        .then(function (response) {
          self.vendorContacts = response.data;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    saveVendorContacts(filter) {
      var self = this;
      var apiUrl = self.serverUrl + "/api/toggleinfo/vendorcontacts/save";
      var body = self.vendorEdit.details;
      if (filter == "all") {
        apiUrl = apiUrl + "/all";
        body = self.vendorContacts;
      }
      axios
        .post(apiUrl, body, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          self.hideSnackbar(response.data, "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Saving failed", "red");
        });
    },
    removeVendorContacts(vendorContact) {
      var self = this;
      var apiUrl = self.serverUrl + "/api/toggleinfo/vendorcontacts/delete";
      var body = vendorContact;
      axios
        .post(apiUrl, body, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          self.hideSnackbar(response.data, "orange");
          self.getVendorContacts();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Removing failed", "red");
        });
    },
    editVendorContact(vendorContact) {
      var self = this;
      self.vendorEdit.details = vendorContact;
      self.vendorEdit.show = true;
    },
    addVendorContact() {
      var self = this;
      self.vendorContacts.push({});
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
  },
};
</script>
