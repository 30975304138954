<template>
  <div>
    <v-card color="" class="pa-2 mx-2 mt-2">
      <div color="" class="pa-1 title" style="text-decoration: underline">
        Auction Unit Records
      </div>
      <v-row class="pa-1 ma-1">
        <v-col class="pa-0 ma-0">
          <span>Filter by: </span>
          <v-chip @click="filter = 'all'" class="mx-1">All</v-chip>
          <v-chip @click="filter = 'regnNo'" class="mx-1">Regn No</v-chip>
          <v-chip @click="filter = 'sold'" class="mx-1">Sold by Auction</v-chip>
          <!-- <v-chip @click="showSearch = false; manageRefnd.showBtn2=true;manageRefund.showBtn1=false; searchUsersWithDepositBalance('lostbidders')" class="mx-1">Lost in latest auction</v-chip> -->
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row v-if="filter == 'regnNo'" class="pa-1 ma-1">
        <v-text-field
          prepend-inner-icon="mdi-account-search-outline"
          class="pa-0"
          solo
          background-color="rgba(232, 232, 232, 0.8)"
          v-model="searchRegn.value"
          hide-details="auto"
          label="Search user.."
        >
        </v-text-field>
        <v-btn
          @click="searchAuctionLotsContaining(searchRegn.value)"
          dark
          color="blue"
          class="ma-1 mt-2"
          >Search</v-btn
        >
      </v-row>
      <v-row v-if="filter == 'sold'" class="pa-1 ma-1">
        <v-col class="pa-2 ma-0">
          Choose an auction session..
          <v-select
            class="pa-0"
            filled
            dense=""
            v-model="selectAuction"
            :items="auctionHistoryList"
            :hint="`${selectAuction.session} - Date: ${selectAuction.startDate}`"
            :item-text="(item) => item.session + ' (' + item.startDate + ')'"
            item-value="session"
            hide-details="auto"
            label="Auction Name"
            return-object
          >
          </v-select>
          <!-- item-text="session" -->
        </v-col>
      </v-row>
      <v-simple-table v-if="filter != 'sold'">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="">Regn No</th>
              <th class="">Make</th>
              <th class="">Description Of Good</th>
              <th class="">Starting Price</th>
              <th class="">Bidder No</th>
              <th class="">Bidder</th>
              <th class="">Auction</th>
              <th class="">Date</th>
              <th class=""></th>
              <th class="">Images</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lot, i) in lots" :key="i">
              <td>
                <v-row>{{ lot.auctionLotLog.regnNo }}</v-row>
              </td>
              <td>
                <v-row>{{ lot.auctionLotLog.make }}</v-row>
              </td>
              <td>
                <v-row>{{ lot.auctionLotLog.descOfGood }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{
                  ringgitFormat(lot.auctionLotLog.price)
                }}</v-row>
              </td>
              <td>
                <v-row>{{ lot.bidderNo }}</v-row>
              </td>
              <td>
                <v-row>{{ lot.bidderName }}</v-row>
              </td>
              <td>
                <v-row>{{ lot.auctionLotLog.session }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{ lot.date }}</v-row>
              </td>
              <td>...</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="
                        showPhotoPage(
                          lot.id,
                          lot.auctionLotLog.regnNo,
                          lot.auctionLotLog.session
                        )
                      "
                      color=""
                      icon
                      ><v-icon>mdi-image-multiple</v-icon></v-btn
                    >
                  </template>
                  <span>Add photos</span>
                </v-tooltip>
              </td>
              <td>
                <v-btn
                  @click="activateViewUnit(lots[i].auctionLotLog)"
                  color="orange lighten-1"
                  dark=""
                  >view unit</v-btn
                >
              </td>
              <!-- <td><v-btn @click="saveAuctionLot(lots[i])" color="green" dark="">save</v-btn></td> -->
              <!-- <td><v-btn @click="deleteLot(lot.id, i)" color="red" dark=""><v-icon>mdi-delete</v-icon></v-btn></td> -->
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-row class="ma-0 pa-0">
        <v-col class="mx-auto" v-for="item in soldLots" v-bind:key="item.id">
          <Soldcard :lot="item"></Soldcard>
        </v-col>
      </v-row>
      <v-row
        v-if="soldLots.length == 0 && selectAuction.session && filter == 'sold'"
        class="mx-2 px-2 mb-2"
      >
        There are no sold lots for {{ selectAuction.session }}
      </v-row>

      <!-- page -->
      <template v-if="filter == 'all'">
        <div class="text-center">
          <v-pagination
            v-model="lotPage"
            :length="lotLength"
            :total-visible="10"
          ></v-pagination>
        </div>
      </template>
    </v-card>

    <!-- part photo page -->
    <v-dialog v-model="photoPage.show" persistent max-width="600px">
      <v-card class="pa-2">
        <v-card class="pa-2 mb-1"
          ><div class="pa-1 title">Lot No - {{ photoPage.lotNo }}</div></v-card
        >
        <v-card class="pa-2">
          <v-row class="ma-2">
            <input
              type="file"
              ref="inputcover"
              style="display: none"
              accept="image/*"
              @change="showCoverInput()"
            />
            <input
              type="file"
              ref="inputfiles"
              style="display: none"
              accept="image/*"
              multiple
              @change="showPhotoInput()"
            />
            <v-btn @click="$refs.inputcover.click()" class="mr-2"
              >Upload Cover</v-btn
            >
            <v-btn @click="$refs.inputfiles.click()">Upload Photos</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="SavePhotoUrlToHomepage()"
              v-if="saveToHomepage.show == true"
              small
              >Save to Homepage</v-btn
            >
            <v-btn
              @click="deletePhotoUrlFromHomepage()"
              v-if="saveToHomepage.remove == true"
              color="red"
              small
              >Remove from Homepage</v-btn
            >
          </v-row>
          <v-divider></v-divider>
          <v-row class="pa-1 ma-1">
            <!--show cover photo -->
            <v-col
              v-if="photoPage.coverFile.file != null"
              class="pa-1 ma-0"
              cols="3"
            >
              <v-img
                max-height="200"
                :src="photoPage.coverFile.url"
                :lazy-src="photoPage.coverFile.url"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <v-row justify="end" class="ma-1">
                  <v-btn color="rgba(255, 255, 255, 0.6)" dark fab x-small>
                    <v-icon @click="photoPage.coverFile = {}" color="#560000b0"
                      >mdi-close-thick</v-icon
                    >
                  </v-btn>
                </v-row>
                <v-row
                  justify="center"
                  class="ma-0 pa-0 display-1 font-italic font-weight-medium white--text"
                  >Cover</v-row
                >
              </v-img>
            </v-col>

            <!-- show uploaded photos -->
            <v-col
              v-for="(fileName, i) in photoPage.photos"
              :key="i"
              class="pa-1 ma-0"
              cols="3"
            >
              <v-card
                :elevation="
                  checkElevation(
                    '/image/auctionlot/' + photoPage.regnNo + '/' + fileName
                  )
                "
              >
                <v-img
                  max-height="200"
                  @click="
                    checkSaveToHomepage(
                      '/image/auctionlot/' + photoPage.regnNo + '/' + fileName
                    )
                  "
                  :src="
                    serverUrl +
                    '/api/file/image/auctionlot/' +
                    photoPage.regnNo +
                    '/' +
                    fileName
                  "
                  :lazy-src="
                    serverUrl +
                    '/api/file/image/auctionlot/' +
                    photoPage.regnNo +
                    '/' +
                    fileName
                  "
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <v-row justify="end" class="ma-1">
                    <v-btn
                      @click="deletePhoto(fileName, photoPage.regnNo, i)"
                      color="rgba(255, 255, 255, 0.6)"
                      dark
                      fab
                      x-small
                    >
                      <v-icon color="#560000b0">mdi-delete</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row
                    v-if="i == 0 && photoPage.coverFile.file == null"
                    justify="center"
                    class="ma-0 pa-0 display-1 font-italic font-weight-medium white--text"
                    >Cover</v-row
                  >
                </v-img></v-card
              >
            </v-col>

            <!-- show files to be uploaded -->
            <v-col
              v-for="(info, i) in photoPage.files"
              :key="i"
              class="pa-1 ma-0"
              cols="3"
            >
              <v-img max-height="200" :src="info.url" :lazy-src="info.url">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <v-row justify="end" class="ma-1">
                  <v-btn
                    @click="clearPhoto(i)"
                    color="rgba(255, 255, 255, 0.6)"
                    dark
                    fab
                    x-small
                  >
                    <v-icon color="#560000b0">mdi-close-thick</v-icon>
                  </v-btn>
                </v-row>
              </v-img>
            </v-col>
          </v-row>
        </v-card>
        <v-row class="ma-0 pa-0 mt-2">
          <v-spacer></v-spacer>
          <v-btn class="mx-1" @click="hidePhotoPage()" color="grey" dark
            >Close</v-btn
          >
          <v-btn @click="saveAuctionLotPhotos()" color="green" dark>Save</v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewUnit.show" persistent>
      <v-card ref="viewUnitScroll">
        <v-row class="pa-1 ma-0" justify="center">
          <v-col>
            <v-row align="center" justify="center">
              <v-card class="mx-auto pa-1 text-center" width="400">
                <v-img max-height="250" contain :src="currentPhoto">
                  <v-row
                    v-if="viewUnit.lot.status == 'sold'"
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-img
                      max-height="250"
                      style="transform: rotate(-50deg)"
                      contain
                      :src="
                        this.serverUrl +
                        '/api/file/image/general/website/sold_stamp.png'
                      "
                    ></v-img>
                  </v-row>
                </v-img>
              </v-card>
            </v-row>
            <!-- part slider                -->
            <v-col class="ma-1">
              <template>
                <v-sheet class="mx-auto" elevation="4" max-width="800">
                  <v-slide-group
                    :v-model="null"
                    class="pa-4"
                    center-active
                    show-arrows
                  >
                    <v-slide-item
                      v-for="photoUrl in photos"
                      :key="photoUrl"
                      v-slot:default="{ active, toggle }"
                    >
                      <v-card
                        :color="active ? 'primary' : 'grey lighten-1'"
                        class="ma-4"
                        height="100"
                        width="150"
                        @click="toggle"
                      >
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                          <v-img
                            @click="changePhoto(photoUrl)"
                            max-height="105"
                            contain
                            :src="photoUrl"
                          ></v-img>
                        </v-row>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
                </v-sheet>
              </template>
            </v-col>
            <!-- end part slider -->
          </v-col>
        </v-row>
        <UnitDetails :lot="viewUnit.lot"></UnitDetails>
        <v-row class="pa-1 ma-0 pb-3" justify="center">
          <v-btn @click="viewUnit.show = false">Close</v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
import UnitDetails from "@/components/UnitDetails";
import storage from "../jscontroller/storage";
import authorization from "../jscontroller/authorization";
import Soldcard from "@/components/Soldcard";
export default {
  name: "AuctionManager",
  components: { UnitDetails, Soldcard },
  data: () => ({
    user: {},
    serverUrl: serverConfig.hostname,
    snackbar: {
      showProgress: false,
      showStatus: false,
      text: "",
      timeout: 3000,
      icon: "mdi-check-circle-outline",
      color: "green",
    },
    lots: [],
    lotPage: 1,
    auctionLotPerPage: 10,
    lotLength: 1,
    photoPage: {
      lotNo: 0,
      regnNo: "",
      session: "",
      show: false,
      photos: [],
      files: [],
      coverFile: {},
      noOfPhotos: 0,
      photosUploaded: 0,
    },
    saveToHomepage: {
      show: false,
      imageUrl: "",
      remove: false,
    },
    searchRegn: {
      show: false,
      value: "",
    },
    viewUnit: {
      show: false,
      lot: {},
    },
    // for view unit
    photos: [],
    currentPhoto: "",
    filter: "all",
    soldLots: [],
    auctionHistoryList: [],
    selectAuction: {},
  }),
  created() {
    this.user = storage.GetUser();
    if (!authorization.checkVendorAuthorization(this.user)) {
      storage.switchNavBar("website");
      this.$router.push("/");
      this.$router.go();
    }
  },
  mounted() {
    this.getAuctionLotsByPage(this.auctionLotPerPage);
  },
  methods: {
    getAuctionLotsByPage(noOfContents, callback) {
      var self = this;
      var currentPage = self.lotPage - 1;
      if (currentPage < 0) {
        currentPage = 0;
      }
      var apiUrl =
        serverConfig.hostname +
        "/api/auctionlot/get/logs/page/" +
        currentPage +
        "/" +
        noOfContents;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          self.lots = response.data.records;
          // eslint-disable-next-line no-console
          // console.log(self.lots);
          var tempTotal =
            parseInt(response.data.totalRecords) / self.auctionLotPerPage;
          if (tempTotal > tempTotal.toFixed(0)) {
            tempTotal = parseInt(tempTotal.toFixed(0)) + 1;
          } else {
            tempTotal = parseInt(tempTotal.toFixed(0));
          }
          self.lotLength = tempTotal;
          callback();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    searchAuctionLotsContaining(regnNo, callback) {
      var self = this;
      self.showSnackbar();
      var apiUrl =
        serverConfig.hostname + "/api/auctionlot/search/log/" + regnNo;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          self.lots = response.data.records;
          self.hideSnackbar(self.lots.length + " unit/s found.", "green");
          callback();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function () {});
    },
    ringgitFormat(amount) {
      if (amount == null) {
        return "RM " + (0).toFixed(2);
      } else {
        var num = amount.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        });
        var deci = num.split(".")[1];
        if (deci != null) {
          if (deci.length == 1) {
            num = num + "0";
          }
        } else {
          num = num + ".00";
        }
        return "RM " + num;
      }
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
    showPhotoPage(lotNo, regnNo, session) {
      var self = this;
      self.photoPage.show = true;
      self.photoPage.lotNo = lotNo;
      self.photoPage.regnNo = regnNo;
      self.photoPage.session = session;
      self.photoPage.photos = [];
      self.getAuctionLotFileNamesQuery(regnNo);
      // self.countAuctionLotPhotosQuery(lotNo);
    },
    hidePhotoPage() {
      var self = this;
      self.photoPage.show = false;
      self.photoPage.files = [];
      self.photoPage.coverFile = {};
      self.saveToHomepage.imageUrl = "";
      self.saveToHomepage.show = false;
      self.saveToHomepage.remove = false;
    },
    getAuctionLotFileNamesQuery(regnNo) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/file/filenames";
      var bodyFormData = new FormData();
      bodyFormData.set("folderName", regnNo);
      bodyFormData.set("folderName2", "auctionlot");
      bodyFormData.set("folderType", "image");
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          var tempPhotos = response.data;
          self.photoPage.photos = [];
          for (var i = 0; i < tempPhotos.length; i++) {
            if (tempPhotos[i] == "0" || tempPhotos[i] == "0.jpg")
              self.photoPage.photos.unshift(tempPhotos[i]);
            else self.photoPage.photos.push(tempPhotos[i]);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function () {
          self.photoPage.photos = [];
        });
    },
    checkElevation(imageUrl) {
      var self = this;
      if (imageUrl == self.saveToHomepage.imageUrl) return 15;
      else return 1;
    },
    checkSaveToHomepage(imageUrl) {
      var self = this;
      self.saveToHomepage.imageUrl = imageUrl;
      var apiUrl =
        serverConfig.hostname + "/api/admin/homepagephotos/getbyimageurl";
      var bodyFormData = new FormData();
      bodyFormData.set("imageUrl", imageUrl);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          if (response.data != "") {
            self.saveToHomepage.remove = true;
            self.saveToHomepage.show = false;
          } else {
            self.saveToHomepage.show = true;
            self.saveToHomepage.remove = false;
          }
        })
        .catch(function () {});
    },
    showCoverInput() {
      var self = this;
      var tempPhoto = this.$refs.inputcover.files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        self.photoPage.coverFile = { file: tempPhoto, url: e.target.result };
      };
      reader.readAsDataURL(tempPhoto); // convert to base64 string
    },
    saveAuctionLotPhotos() {
      var self = this;
      self.photoPage.photosUploaded = 0;
      if (self.photoPage.coverFile.file != null)
        self.uploadAuctionLotPhotosQuery(
          self.photoPage.coverFile.file,
          self.photoPage.regnNo,
          "0.jpg"
        );
      for (var i = 0; i < self.photoPage.files.length; i++) {
        var temp = self.photoPage.files[i].file;
        // var fileNo = self.photoPage.noOfPhotos + i + 1;
        self.uploadAuctionLotPhotosQuery(
          temp,
          self.photoPage.regnNo,
          temp.name
        );
      }
      self.photoPage.files = [];
      self.photoPage.coverFile = {};
    },
    uploadAuctionLotPhotosQuery(file, regnNo, name) {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/file/upload/photo";
      var bodyFormData = new FormData();
      bodyFormData.set("file", file);
      bodyFormData.set("size", file.size);
      bodyFormData.set("folderName", "auctionlot");
      bodyFormData.set("folderName2", regnNo);
      bodyFormData.set("folderType", "image");
      bodyFormData.set("fileName", name);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          self.hideSnackbar(response.data, "green");
          self.photoPage.photosUploaded = self.photoPage.photosUploaded + 1;
          self.getAuctionLotFileNamesQuery(regnNo);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Failed to save photo.", "red");
        });
    },
    showPhotoInput() {
      var self = this;
      var tempPhotos = this.$refs.inputfiles.files;
      for (var i = 0; i < tempPhotos.length; i++) {
        self.fileReader(tempPhotos[i]);
      }
    },
    fileReader(file) {
      var self = this;
      var reader = new FileReader();
      reader.onload = function (e) {
        // $('#blah').attr('src', e.target.result);
        self.photoPage.files.push({ file: file, url: e.target.result });
      };
      reader.readAsDataURL(file); // convert to base64 string
    },
    clearPhoto(index) {
      var self = this;
      self.photoPage.files.splice(index, 1);
    },
    deletePhoto(fileName, lotNo, index) {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/file/delete";
      var bodyFormData = new FormData();
      var url = "/image/auctionlot/" + lotNo + "/" + fileName;
      self.deletePhotoUrlFromHomepage(url);
      bodyFormData.set("url", url);
      axios
        .post(apiUrl, bodyFormData)
        // eslint-disable-next-line no-unused-vars
        .then(function (response) {
          self.photoPage.photos.splice(index, 1);
          self.hideSnackbar(response.data, "orange");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Delete photo failed.", "red");
        });
    },
    deletePhotoUrlFromHomepage() {
      var self = this;
      self.showSnackbar();
      var imageUrl = self.saveToHomepage.imageUrl;
      var apiUrl =
        serverConfig.hostname + "/api/admin/homepagephotos/photourl/delete";
      var bodyFormData = new FormData();
      bodyFormData.set("imageUrl", imageUrl);
      axios
        .post(apiUrl, bodyFormData)
        .then(function () {
          self.saveToHomepage.remove = false;
          self.saveToHomepage.show = true;
          self.hideSnackbar("Photo removed from Homepage", "orange");
        })
        .catch(function () {});
    },
    SavePhotoUrlToHomepage() {
      var self = this;
      self.showSnackbar();
      var imageUrl = self.saveToHomepage.imageUrl;
      const session = self.photoPage.session;
      var apiUrl = serverConfig.hostname + "/api/admin/savelotphototohomepage";
      var bodyFormData = new FormData();
      bodyFormData.set("imageUrl", imageUrl);
      bodyFormData.set("session", session);
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function () {
          self.saveToHomepage.remove = true;
          self.saveToHomepage.show = false;
          self.hideSnackbar("Photo saved to Homepage", "green");
        })
        .catch(function () {});
    },
    activateViewUnit(lot) {
      var self = this;
      self.viewUnit.show = true;
      self.viewUnit.lot = lot;
      self.getAuctionLotFileNames2Query(lot.regnNo);
    },
    getAuctionLotFileNames2Query(regnNo) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/file/filenames";
      var bodyFormData = new FormData();
      bodyFormData.set("folderName", regnNo);
      bodyFormData.set("folderName2", "auctionlot");
      bodyFormData.set("folderType", "image");
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          var photos = response.data;
          self.photos = [];
          if (response.data != "") {
            self.currentPhoto =
              self.serverUrl +
              "/api/file/image/auctionlot/" +
              regnNo +
              "/" +
              photos[0];
            for (var i = 0; i < photos.length; i++) {
              self.photos.push(
                self.serverUrl +
                  "/api/file/image/auctionlot/" +
                  regnNo +
                  "/" +
                  photos[i]
              );
              if (photos[i] == "0" || photos[i] == "0.jpg")
                self.currentPhoto =
                  self.serverUrl +
                  "/api/file/image/auctionlot/" +
                  regnNo +
                  "/" +
                  photos[i];
            }
          } else {
            self.photos = [];
            self.currentPhoto =
              "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1200px-No_image_available.svg.png";
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function () {
          self.photos = [];
          self.currentPhoto =
            "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1200px-No_image_available.svg.png";
        });
    },
    changePhoto(photoName) {
      var self = this;
      var temp = photoName;
      self.currentPhoto = temp;
    },
    getAuctionName() {
      var self = this;
      var apiUrl = self.serverUrl + "/api/auctionmanager/session/history/all";
      axios
        .get(apiUrl)
        .then(function (response) {
          var tempAuctionHList = response.data;
          self.auctionHistoryList = [];
          for (var i = 0; i < tempAuctionHList.length; i++) {
            self.auctionHistoryList.unshift(tempAuctionHList[i]);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    getSoldAuctionLots(session) {
      var self = this;
      self.showSnackbar();
      var apiUrl = `${serverConfig.hostname}/api/auctionlot/get/logs/sold/auction/${session}`;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          var items = response.data;
          self.soldLots = [];
          for (let i = 0; i < items.length; i++) {
            var tempItem = items[i].auctionLotLog;
            tempItem.id = items[i].mybid.lotNo;
            self.soldLots.push(tempItem);
          }
          if (self.soldLots.length > 0)
            self.hideSnackbar(
              `Finish fetching sold lots for ${session}`,
              "green"
            );
          else self.hideSnackbar("There are no sold lots for today..", "green");
        })
        .catch(function (error) {
          self.hideSnackbar(error.response.data.message, "green");
        });
    },
  },
  watch: {
    lotPage: function () {
      this.getAuctionLotsByPage(this.auctionLotPerPage);
    },
    filter: function () {
      if (this.filter == "all") {
        this.soldLots = [];
        this.lotPage = 1;
        this.getAuctionLotsByPage(this.auctionLotPerPage);
      } else if (this.filter == "regnNo") {
        this.lots = [];
        this.soldLots = [];
      } else if (this.filter == "sold") {
        this.lots = [];
        this.soldLots = [];
        this.selectAuction = {};
        this.getAuctionName();
      }
    },
    selectAuction: function () {
      this.soldLots = [];
      this.getSoldAuctionLots(this.selectAuction.session);
    },
  },
};
</script>
