<template>
  <div>
    <v-row class="ma-0 pa-0">
      <v-col cols="2" class="d-none d-md-flex pr-0">
        <MyAccountNav></MyAccountNav>
      </v-col>
      <v-col class="pa-2 ma-0">
        <v-card>
          <v-card-title class="pa-0 ma-0 pt-2 mt-2 ml-5"
            >Personal info</v-card-title
          >
          <v-divider class="pa-0 mx-2"></v-divider>
          <div
            v-if="isPasswordExpired"
            class="pa-0 ma-0 px-3 py-2 red--text text-center"
          >
            <v-row justify="center">
              <v-icon color="red" large="" class="mx-5"
                >mdi-alert-circle-outline</v-icon
              >
            </v-row>
            <div>You have used your password for more than 3 months.</div>
            <div>Please Update your password.</div>
          </div>

          <v-row class="pa-2 ma-0 pb-4">
            <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 px-3 py-2">
              <span
                >Bidder ID:
                <span class="font-weight-bold title">{{ user.id }}</span></span
              >
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 px-3 py-2">
              <span v-if="userInfo.accountType != 'Organization'">Name:</span>
              <span v-if="userInfo.accountType == 'Organization'"
                >Organization:</span
              >
              <v-text-field
                prepend-inner-icon="mdi-account"
                class="pa-0"
                filled
                hide-details="auto"
                v-model="userInfo.firstName"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 px-3 py-2">
              <span v-if="userInfo.accountType != 'Organization'">IC No:</span>
              <span v-if="userInfo.accountType == 'Organization'">SSM No:</span>
              <v-text-field
                prepend-inner-icon="mdi-card-account-details"
                class="pa-0"
                hide-details="auto"
                filled
                v-model="userInfo.ic"
              >
              </v-text-field>
            </v-col>
            <v-col
              v-if="userInfo.accountType == 'Organization'"
              cols="12"
              sm="12"
              md="12"
              class="pa-0 ma-0 px-3 py-2"
            >
              <span>Representative Name:</span>
              <v-text-field
                prepend-inner-icon="mdi-account"
                class="pa-0"
                filled
                hide-details="auto"
                v-model="userInfo.representativeName"
              >
              </v-text-field>
            </v-col>
            <v-col
              v-if="userInfo.accountType == 'Organization'"
              cols="12"
              sm="12"
              md="12"
              class="pa-0 ma-0 px-3 py-2"
            >
              <span>Representative IC No:</span>
              <v-text-field
                prepend-inner-icon="mdi-card-account-details"
                class="pa-0"
                hide-details="auto"
                filled
                label="Representative IC No (e.g. 9xxxxx1x1xxx)"
                v-model="userInfo.representativeIc"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 px-3 py-2">
              Email:
              <v-text-field
                readonly
                prepend-inner-icon="mdi-email"
                class="pa-0"
                filled
                hide-details="auto"
                v-model="userInfo.email"
              >
              </v-text-field>
            </v-col>
            <!-- <v-col class="pa-0 ma-0 px-3 py-2" cols="12">
              <v-btn @click="show.changeEmail = true" color="purple" dark
                >Change Email</v-btn
              >
            </v-col> -->
            <v-col class="pa-0 ma-0 px-3 py-2" cols="12">
              <v-btn @click="show.changePassword = true" color="purple" dark
                >Change Password</v-btn
              >
            </v-col>

            <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 px-3 py-2">
              Phone No:
              <v-text-field
                prepend-inner-icon="mdi-phone"
                class="pa-0"
                filled
                type="tel"
                hide-details="auto"
                label="Phone Number (e.g. +601xxxxxxxx)"
                v-model="userInfo.phoneNo"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 px-3 pt-2">
              Address:
              <v-text-field
                class="pa-0"
                filled
                label="Address Line"
                type="address"
                hide-details="auto"
                v-model="userInfo.address"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 px-3 py-2">
              <v-text-field
                class="pa-0"
                filled
                v-model="userInfo.state"
                hide-details="auto"
                label="State"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pa-0 ma-0 px-3 py-1">
              <v-text-field
                class="pa-0"
                v-model="userInfo.city"
                filled
                hide-details="auto"
                label="City"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pa-0 ma-0 px-3 py-1">
              <v-text-field
                class="pa-0"
                filled
                v-model="userInfo.postcode"
                hide-details="auto"
                label="Postcode"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card>

        <!-- <v-card class="mt-5">
          <v-card-title class="pa-0 ma-0 pt-2 mt-2 ml-5"
            >Staff info</v-card-title
          >
          <v-divider class="pa-0 mx-2"></v-divider>
          <v-col
            v-if="userInfo.accountType == 'Individual'"
            cols="12"
            sm="12"
            md="12"
          >
            <v-row class="pa-0 ma-0 ml-2">
              <v-checkbox
                v-model="userInfo.staff"
                hide-details="auto"
                color="primary"
                class="ma-0 pa-0"
              ></v-checkbox>
              <span class="red--text"
                >Only tick this checkbox if you're registered as a staff.</span
              >
            </v-row>
          </v-col>
          <v-col v-if="userInfo.staff" cols="12" sm="12" md="12">
            <v-row class="pa-0 ma-0">
              <v-select
                class="pa-0"
                filled
                dense=""
                v-model="userInfo.staffOf"
                :items="['TM Staff', 'KNB Staff']"
                hide-details="auto"
                label="Staff.."
              >
              </v-select>
            </v-row>
          </v-col>
          <v-col
            v-if="userInfo.staff"
            cols="12"
            sm="12"
            md="12"
            class="pa-0 ma-0 px-3 py-2"
          >
            <span>Staff ID:</span>
            <v-text-field
              prepend-inner-icon="mdi-card-account-details"
              class="pa-0"
              hide-details="auto"
              filled
              v-model="userInfo.staffId"
            >
            </v-text-field>
          </v-col>
          <v-col
            v-if="
              userInfo.staff && userInfo.staffId != '' && userInfo.staffOf != ''
            "
            class="pa-0 ma-0 px-3 py-2"
          >
            <div
              v-if="!userInfo.staffVerified"
              class="font-weight-bold red--text"
            >
              Please upload your Staff ID card to be verified.
            </div>
            <div
              v-if="!userInfo.staffVerified"
              class="font-weight-bold red--text"
            >
              Make sure your Staff ID is correct before uploading your Staff ID
              card photo.
            </div>
            <input
              type="file"
              ref="staffIDphoto"
              accept="image/*"
              @change="showPhotoInput()"
            />
            <v-col class="ma-0 pa-0" v-if="userInfo.staff">
              <v-card class="ma-2">
                <v-img
                  ref="displayStaffIDphoto"
                  max-height="300"
                  max-width="500"
                  contain
                  :src="imgUrl"
                  :lazy-src="imgUrl"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-col>
            <v-row
              v-if="userInfo.staffVerified"
              justify="center"
              class="text-center ma-0 pa-0"
            >
              <v-icon size="100" color="green" class="mx-5"
                >mdi-shield-check</v-icon
              >
              <v-col class="font-weight-bold green--text ma-0 pa-0" cols="12"
                >Your status as staff has been verified</v-col
              >
            </v-row>
            <v-row
              v-if="!userInfo.staffVerified"
              justify="center"
              class="text-center ma-0 pa-0"
            >
              <v-col class="orange--text ma-0 pa-0" cols="12"
                >Once your status as staff has been verified, you can bid for
                the respective staff auction</v-col
              >
            </v-row>
          </v-col>
        </v-card> -->

        <v-card class="mt-5">
          <v-card-title class="pa-0 ma-0 pt-2 mt-2 ml-5"
            >Bank info</v-card-title
          >
          <v-divider class="pa-0 mx-2"></v-divider>
          <v-row class="pa-2 ma-0" align="center">
            <v-icon color="orange" large="" class="mx-5"
              >mdi-alert-circle-outline</v-icon
            >
            <span class="orange--text font-weight-bold"
              >Note: Please make sure your Bank Details are correct for refund
              purposes</span
            >
          </v-row>
          <v-row class="pa-2 ma-0">
            <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 px-3 py-2">
              Bank Name:
              <v-select
                class="pa-0"
                filled
                dense=""
                v-model="userInfo.bankName"
                :items="banklist"
                :hint="userInfo.bankName"
                item-text="name"
                item-value="name"
                hide-details="auto"
                label="Bank Name"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 px-3 py-2">
              Bank Account:
              <v-text-field
                class="pa-0"
                hide-details="auto"
                filled
                type="number"
                v-model="userInfo.bankAccNo"
              >
              </v-text-field>
            </v-col>
            <!-- <v-col class="pa-0 ma-0">
              <v-row class="pa-0 ma-0 title" justify="center">
                Status:
                <span
                  class="ml-1 green--text"
                  v-if="bankInfo.status == 'verified'"
                  >{{ bankInfo.status }}</span
                >
                <span
                  class="ml-1 red--text"
                  v-if="bankInfo.status != 'verified'"
                  >{{ bankInfo.status }}</span
                >
              </v-row>
              <v-row
                v-if="bankInfo.status == 'pending'"
                justify="center"
                class="red--text pa-1 px-4"
                >Your bank account is currently being queued for verification.
                Come back after 3 working days to check status.</v-row
              >
              <v-divider></v-divider>
              <v-row
                v-if="bankInfo.status != 'verified'"
                justify="center"
                class="red--text pa-1 px-4"
                >Your bank account must be verified in order for any refund
                process to work smoothly.</v-row
              >
              <v-row class="pa-0 ma-0" justify="center">
                <v-btn
                  @click="verifyCheck()"
                  color="yellow"
                  v-if="
                    bankInfo.status != 'verified' &&
                      bankInfo.status != 'pending'
                  "
                  >Verify</v-btn
                >
              </v-row>
            </v-col> -->
          </v-row>
        </v-card>
        <v-row class="pa-0 ma-2" justify="center">
          <v-btn @click="updateProfile()" color="green" dark
            >Save Changes</v-btn
          >
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="show.changeEmail" persistent max-width="500px">
      <v-card color="orange darken-2" dark class="pa-2 ma-0">
        <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 px-3 pt-2">
          New Email:
          <v-text-field
            class="pa-0"
            filled
            label="email"
            type="email"
            hide-details="auto"
            v-model="email"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 px-3 pt-2">
          Confirm Email:
          <v-text-field
            class="pa-0"
            filled
            label="confirm email"
            type="email"
            hide-details="auto"
            v-model="confirmEmail"
          >
          </v-text-field>
        </v-col>
        <v-row class="pa-1 ma-1" justify="center">
          <v-btn
            @click="changeEmailQuery()"
            color="orange lighten-1"
            class="mx-1"
            >Confirm change</v-btn
          >
          <v-btn
            @click="
              show.changeEmail = false;
              email = '';
              confirmEmail = '';
            "
            class="mx-1"
            >Cancel</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="show.changePassword" persistent max-width="500px">
      <v-card color="orange darken-2" dark class="pa-2 ma-0">
        <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 px-3 pt-2">
          Password:
          <v-text-field
            class="pa-0"
            filled
            label="password"
            type="password"
            hide-details="auto"
            v-model="pwd"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 px-3 pt-2">
          Confirm Password:
          <v-text-field
            class="pa-0"
            filled
            label="confirm password"
            type="password"
            hide-details="auto"
            v-model="confirmPwd"
          >
          </v-text-field>
        </v-col>
        <v-row class="pa-1 ma-1" justify="center">
          <v-btn @click="requestOTP()" color="orange lighten-1" class="mx-1"
            >Request OTP</v-btn
          >
          <v-btn
            @click="
              show.changePassword = false;
              pwd = '';
              confirmPwd = '';
            "
            class="mx-1"
            >Cancel</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="show.otpRequested" persistent max-width="500px">
      <v-card color="white" class="pa-2 ma-0">
        <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 px-3 pt-2">
          Please insert OTP code recieved from your email.
          <v-text-field
            class="pa-0"
            filled
            label="OTP Code"
            type="text"
            hide-details="auto"
            v-model="otp"
            maxlength="6"
            :rules="[
              (v) => (v && v.length === 6) || 'OTP must be 6 characters',
            ]"
          >
          </v-text-field>
        </v-col>
        <v-row class="pa-1 ma-1" justify="center">
          <v-btn
            @click="changePasswordQuery()"
            color="orange lighten-1"
            class="mx-1"
            >Send</v-btn
          >
          <v-btn
            @click="
              show.otpRequested = false;
              pwd = '';
              confirmPwd = '';
            "
            class="mx-1"
            >Cancel</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
import storage from "../jscontroller/storage";
import MyAccountNav from "@/components/MyAccountNav";

export default {
  components: { MyAccountNav },
  data: () => ({
    serverUrl: serverConfig.hostname,
    user: {},
    userInfo: {},
    bankInfo: {},
    banklist: [],
    snackbar: {
      showProgress: false,
      showStatus: false,
      text: "",
      timeout: 8000,
      icon: "mdi-check-circle-outline",
      color: "green",
    },
    show: {
      changePassword: false,
      changeEmail: false,
      otpRequested: false,
    },
    pwd: "",
    confirmPwd: "",
    email: "",
    confirmEmail: "",
    imgUrl: "",
    otp: "",
    isPasswordExpired: false,
  }),
  created() {
    this.getAllBankList();
    this.user = storage.GetUser();
  },
  mounted() {
    if (!this.checkLoggedIn()) {
      this.navigate("/login");
    }
    this.getUserProfile();
  },
  methods: {
    checkLoggedIn() {
      var bool = false;
      var self = this;
      if (self.user != null) {
        if (self.user.email != "") {
          bool = true;
        }
      }
      return bool;
    },
    getUserProfile() {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/user/get-profile";
      const token = self.user.jwt;

      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          self.userInfo = response.data;
          if (new Date(self.userInfo.pwd_expiry_date) < new Date()) {
            self.isPasswordExpired = true;
          }
          self.getBankAccount(self.userInfo.bankAccNo);
          self.imgUrl =
            self.serverUrl +
            "/api/file/image/staffIdCard/" +
            self.userInfo.staffId +
            "/" +
            self.userInfo.staffId +
            "_" +
            self.userInfo.id +
            ".jpg";
        })
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    getAllBankList() {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/bank/getall";
      axios
        .get(apiUrl)
        .then(function (response) {
          self.banklist = response.data;
        })
        .catch(function () {});
    },
    getBankAccount(bankAccNo) {
      var self = this;
      var apiUrl = self.serverUrl + "/api/billplz/get/" + bankAccNo;
      self.bankInfo = { status: "Fetching info.." };
      axios
        .get(apiUrl)
        .then(function (response) {
          if (response.data.statusCode == 200) self.bankInfo = response.data;
          else {
            self.bankInfo = { status: "Has not yet verified." };
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    updateProfile(callback) {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/user/update";
      const headers = {
        headers: {
          Authorization: `Bearer ${self.user.jwt}`,
        },
      };

      if (!self.userInfo.staff) {
        self.userInfo.staffOf = "";
        self.userInfo.staffId = "";
      }
      var body = self.userInfo;
      axios
        .post(apiUrl, body, headers)
        .then(function (response) {
          self.hideSnackbar(response.data.message, "green");
          self.getBankAccount(self.userInfo.bankAccNo);
          storage.SetUser(
            self.userInfo.email,
            self.userInfo.firstName,
            self.userInfo.role,
            self.userInfo.id,
            self.user.jwt
          );
          callback();
        })
        .catch(function (err) {
          self.hideSnackbar(err.response.data.message, "orange");
        });
    },

    changeEmailQuery() {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/user/change/email";
      if (self.email == "")
        self.hideSnackbar("Please enter your new email address.", "red");
      else if (!self.validateEmail(self.email))
        self.hideSnackbar("Please enter a valid email address.", "red");
      else if (self.confirmEmail == "")
        self.hideSnackbar("Please confirm your email address.", "red");
      else if (self.email != self.confirmEmail)
        self.hideSnackbar(
          "Your email address confirmation is not the same.",
          "red"
        );
      else {
        var bodyFormData = new FormData();
        // bodyFormData.set('email', self.userInfo.email);
        bodyFormData.set("userId", self.userInfo.id);
        bodyFormData.set("email", self.email);
        axios
          .post(apiUrl, bodyFormData)
          .then(function (response) {
            alert(response.data);
            self.logout();
          })
          .catch(function (err) {
            self.hideSnackbar(err.response.data, "red");
            self.show.changeEmail = false;
            self.email = "";
            self.confirmEmail = "";
          });
      }
    },

    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(String(email).toLowerCase());
    },

    requestOTP() {
      var self = this;
      self.showSnackbar();
      var apiUrl =
        serverConfig.hostname + "/api/user/change/password/request-otp";
      if (self.pwd == "")
        self.hideSnackbar("Please enter your password.", "red");
      else if (self.pwd.length < 8)
        self.hideSnackbar(
          "Your password must contain at least 8 characters",
          "red"
        );
      else if (self.confirmPwd == "")
        self.hideSnackbar("Please confirm your password.", "red");
      else if (self.pwd != self.confirmPwd)
        self.hideSnackbar("Your confirmation password is not the same.", "red");
      else {
        var bodyFormData = new FormData();
        bodyFormData.set("pwd", self.pwd);
        axios
          .post(apiUrl, bodyFormData, {
            headers: {
              Authorization: `Bearer ${self.user.jwt}`,
            },
          })
          .then(function (response) {
            self.hideSnackbar(response.data.message, "green");
            self.show.changePassword = false;
            self.show.otpRequested = true;
          })
          .catch(function (error) {
            self.hideSnackbar(error.response.data.message, "red");
          });
      }
    },

    changePasswordQuery() {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/user/change/password";
      if (self.pwd == "")
        self.hideSnackbar("Please enter your password.", "red");
      else if (self.pwd.length < 8)
        self.hideSnackbar(
          "Your password must contain at least 8 characters",
          "red"
        );
      else if (self.confirmPwd == "")
        self.hideSnackbar("Please confirm your password.", "red");
      else if (self.pwd != self.confirmPwd)
        self.hideSnackbar("Your confirmation password is not the same.", "red");
      else {
        var bodyFormData = new FormData();
        bodyFormData.set("pwd", self.pwd);
        bodyFormData.set("otp", self.otp);
        axios
          .post(apiUrl, bodyFormData, {
            headers: {
              Authorization: `Bearer ${self.user.jwt}`,
            },
          })
          .then(function (response) {
            self.hideSnackbar(response.data.message, "green");
            self.show.otpRequested = false;
            self.pwd = "";
            self.confirmPwd = "";
          })
          .catch(function (error) {
            self.hideSnackbar(error.response.data.message, "red");
          });
      }
    },
    verifyCheck() {
      var self = this;
      if (self.userInfo.firstName == "") {
        self.hideSnackbar("Your name is empty.", "red");
      } else if (self.userInfo.ic == "") {
        self.hideSnackbar("Your IC no is empty.", "red");
      } else if (self.userInfo.bankName == "") {
        self.hideSnackbar("Your bank name no is empty.", "red");
      } else if (self.userInfo.bankAccNo == "") {
        self.hideSnackbar("Your bank account no is empty.", "red");
      } else {
        self.updateProfile(() => {
          self.verifyBankAccount();
        });
      }
    },
    verifyBankAccount() {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/billplz/verify";
      var bodyFormData = new FormData();
      var organization = false;
      if (self.userInfo.accountType == "Organization") organization = true;
      bodyFormData.set("name", self.userInfo.firstName);
      bodyFormData.set("ic", self.userInfo.ic.replace(/-/g, ""));
      bodyFormData.set("bankAccNo", self.userInfo.bankAccNo);
      bodyFormData.set("bankName", self.userInfo.bankName);
      bodyFormData.set("organization", organization);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          if (response.data.statusCode != 200) {
            self.hideSnackbar(response.data.error.message[0], "red");
          } else {
            self.hideSnackbar(
              "Verification may take up to 3 days to complete.",
              "green"
            );
            self.getBankAccount(self.userInfo.bankAccNo);
          }
        })
        .catch(function () {
          self.hideSnackbar("Verification failed", "red");
        });
    },
    showPhotoInput() {
      var self = this;
      // var photoInputFile = self.$refs.staffIDphoto.files;
      var photoInput = this.$refs.staffIDphoto.files[0];
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/file/upload/photo";
      var bodyFormData = new FormData();
      bodyFormData.set("file", photoInput);
      bodyFormData.set("size", photoInput.size);
      bodyFormData.set("folderType", "image");
      bodyFormData.set("folderName", "staffIdCard");
      bodyFormData.set("folderName2", self.userInfo.staffId.toUpperCase());
      bodyFormData.set(
        "fileName",
        self.userInfo.staffId.toUpperCase() + "_" + self.userInfo.id + ".jpg"
      );
      self.updateProfile(() => {
        axios
          .post(apiUrl, bodyFormData)
          .then(function () {
            // self.$refs.staffIDphoto.value = '';
            self.hideSnackbar("Photo successfully saved.", "green");

            var reader = new FileReader();
            reader.onload = function (e) {
              self.imgUrl = e.target.result;
            };
            reader.readAsDataURL(photoInput);
          })

          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            self.hideSnackbar("Save photo unsuccessful", "red");
          });
      });
    },
    logout() {
      storage.clearUserStorage();
      storage.switchNavBar("website");
      this.$router.push("/");
      this.$router.go();
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red" || color == "orange") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
  }, //end methods
};
</script>
