<template>
  <div>
    <v-card-title>Item Information</v-card-title>
    <v-divider></v-divider>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="6" md="5">
        <v-row align="center" justify="center">
          <v-card class="mx-auto pa-4 text-center" width="400">
            <v-img max-height="250" contain :src="currentPhoto">
              <v-row
                v-if="lotDetails.status == 'sold'"
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-img
                  max-height="250"
                  style="transform: rotate(-50deg)"
                  contain
                  src="https://i.dlpng.com/static/png/6920333_preview.png"
                ></v-img>
              </v-row>
            </v-img>
          </v-card>
        </v-row>
        <!-- part slider                -->
        <v-col class="ma-1" v-if="photos.length > 0">
          <template>
            <v-sheet class="mx-auto" elevation="4" max-width="800">
              <v-slide-group
                :v-model="null"
                class="pa-4"
                center-active
                show-arrows
              >
                <v-slide-item
                  v-for="photoUrl in photos"
                  :key="photoUrl"
                  v-slot:default="{ active, toggle }"
                >
                  <v-card
                    :color="active ? 'primary' : 'grey lighten-1'"
                    class="ma-4"
                    height="100"
                    width="150"
                    @click="toggle"
                  >
                    <v-row class="fill-height" align="center" justify="center">
                      <v-img
                        @click="changePhoto(photoUrl)"
                        max-height="105"
                        contain
                        :src="photoUrl"
                      ></v-img>
                    </v-row>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </template>
        </v-col>
        <!-- end part slider -->
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-card-title class="ma-1 display-2">Lot: {{ id }}</v-card-title>
        <v-divider></v-divider>
        <v-card flat class="mx-auto pa-5">
          <div v-if="lotDetails.status != 'sold'">
            <!-- <v-row v-if="countdownStatus == 'not started'" justify="center" class="headline">Auction starts at {{session.startTime}} on {{session.startDate}} </v-row>                                  
                        <v-row v-if="countdownStatus == 'end'" justify="center" class="headline red--text">Auction has ended</v-row> -->
            <v-row
              v-if="frontMessage != null"
              justify="center"
              class="headline red--text"
              >{{ frontMessage.info }}</v-row
            >
            <!-- <v-row v-if="countdownStatus == 'started'" justify="center" align="center">
                            <div>Time till auction ends: </div><div class="headline red--text ml-2"> {{timeLeft}}</div>              
                        </v-row> -->
            <v-row justify="center">
              <div class="title">Starting Price:</div>
              <div class="headline grey--text text--darken-2 ml-1">
                {{ ringgitFormat(lotDetails.price) }}
              </div>
            </v-row>
            <v-row
              v-if="
                higestBidPrice >= lotDetails.price &&
                countdownStatus != 'not started' &&
                lotDetails.status != 'forfeited'
              "
              justify="center"
            >
              <div v-if="currentBidder != user.id" class="title">
                Latest Bid Price:
              </div>
              <div v-if="currentBidder == user.id" class="title">Your Bid:</div>
              <div class="headline orange--text ml-1">
                {{ ringgitFormat(higestBidPrice) }}
              </div>
            </v-row>
          </div>
          <v-row
            class="mb-2 pa-0"
            v-if="lotDetails.status == 'sold'"
            justify="center"
          >
            <div class="title red--text">Sold Price:</div>
            <div class="headline grey--text text--darken-2 ml-1">
              {{ ringgitFormat(lotDetails.salePrice) }}
            </div>
          </v-row>
          <v-divider></v-divider>

          <v-col>
            <v-row dense="">
              <div :class="style.info1">Regn No :</div>
              <v-col :class="style.info2">{{ lotDetails.regnNo }}</v-col>
            </v-row>
            <v-row dense="">
              <div :class="style.info1">Model :</div>
              <v-col :class="style.info2">{{ lotDetails.descOfGood }}</v-col>
            </v-row>
            <v-row dense="">
              <div :class="style.info1">Make :</div>
              <v-col :class="style.info2">{{ lotDetails.make }}</v-col>
            </v-row>
            <v-row dense="">
              <div :class="style.info1">Transmission :</div>
              <v-col :class="style.info2"> {{ lotDetails.ma }}</v-col>
            </v-row>
            <v-row dense="">
              <div :class="style.info1">Year :</div>
              <v-col :class="style.info2"> {{ lotDetails.year }}</v-col>
            </v-row>
            <v-row dense="">
              <div :class="style.info1">VOC :</div>
              <v-col :class="style.info2"> {{ lotDetails.rc }}</v-col>
            </v-row>
            <v-row dense="">
              <div :class="style.info1">Vendor :</div>
              <v-col :class="style.info2">{{ lotDetails.vendor }}</v-col>
            </v-row>
            <v-row dense="">
              <div :class="style.info1">Location :</div>
              <v-col :class="style.info2">{{ lotDetails.location }}</v-col>
            </v-row>
            <v-row dense="">
              <div :class="style.info1">Contact Person :</div>
              <v-col :class="style.info2">{{ lotDetails.contactPerson }}</v-col>
            </v-row>
            <v-row class="mt-1 pa-0" dense="">
              <div :class="style.info1">Remark :</div>
              <div :class="style.info2">{{ lotDetails.remarks }}</div>
            </v-row>
          </v-col>
        </v-card>
        <v-row class="pa-2 ma-0" justify="center">
          <v-btn @click="previousPage()" color="grey lighten-1">Back</v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="permission.show" persistent max-width="600px">
      <v-card class="pa-2">
        <v-card
          class="pa-2 mb-1"
          v-if="!permission.confirm && !permission.exceedwinning"
        >
          <v-row
            v-if="depositBalance == null || depositBalance < lotDetails.deposit"
            justify="center"
            class="pa-0 ma-0"
          >
            <v-col class="text-center pa-0 ma-0">
              <div class="pa-1 title red--text">
                Insufficient Deposit Balance
              </div>
              <v-divider></v-divider>
              <div class="pt-2">
                Your deposit balance is {{ ringgitFormat(depositBalance) }}.
              </div>
              <div class="py-0">
                Please top up your 'deposit balance' in order to make a deposit
                into a unit lot.
              </div>
              <div class="pb-2">
                <a href="/myaccount/transaction"
                  >Click here to topup deposit balance</a
                >
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" class="pa-0 ma-0">
            <v-col class="text-center pa-0 ma-0">
              <v-col
                class="pa-0 ma-0"
                v-if="
                  !(
                    depositBalance == null ||
                    depositBalance < lotDetails.deposit
                  )
                "
              >
                <div class="pa-1 title">Deposit</div>
                <v-divider></v-divider>
              </v-col>
              <div
                v-if="
                  !(
                    depositBalance == null ||
                    depositBalance < lotDetails.deposit
                  )
                "
                class="pt-2"
              >
                Your deposit balance is
                {{ ringgitFormat(depositBalance - totalDepositUsed) }}.
              </div>
              <!-- <div class="pa-1 text-center">Deposit <span class="font-weight-bold">{{ringgitFormat(lotDetails.deposit)}}</span> to participate in this unit.</div> -->
              <div class="pa-1 text-center">
                Deposit amount required to participate:
                <span class="font-weight-bold">{{
                  ringgitFormat(lotDetails.deposit)
                }}</span>
              </div>
              <v-divider></v-divider>
              <v-row class="pa-1 ma-0" justify="center">
                <v-checkbox
                  v-model="agree"
                  hide-details="auto"
                  color="primary"
                  class="ma-0 pa-0"
                ></v-checkbox>
                <span class="caption font-weight-bold mt-1" style="width: 80%">
                  I agree to purchase this item on "as is where is basis" and
                  confirm that I have viewed the sold item. I hereby agree with
                  the
                  <span class="blue--text" @click="openTermsAndCondition()"
                    >terms & condition</span
                  >
                </span>
              </v-row>
              <v-btn
                :disabled="
                  depositBalance == null ||
                  depositBalance < lotDetails.deposit ||
                  agree == false
                "
                class="mx-1"
                @click="countWinningAuctions(user.id)"
                color="orange"
                :dark="
                  !(
                    depositBalance == null ||
                    depositBalance < lotDetails.deposit ||
                    agree == false
                  )
                "
                >Deposit</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
        <v-card class="pa-2 mb-1" v-if="permission.exceedwinning">
          <v-row justify="center" class="pa-0 ma-0">
            <v-col class="text-center pa-0 ma-0">
              <div class="pa-1 title red--text">
                Exceeded number of winning auction lot
              </div>
              <v-divider></v-divider>
              <div
                v-if="
                  !(
                    depositBalance == null ||
                    depositBalance < lotDetails.deposit
                  )
                "
                class="pt-2"
              >
                Your deposit balance is
                {{ ringgitFormat(depositBalance - totalDepositUsed) }}.
              </div>
              <div class="pa-1 text-center">
                You can only bid in an auction lot with
                <span class="font-weight-bold">{{ winnings }}</span> winning/s
                at a time.
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="pa-2 mb-1" v-if="permission.confirm">
          <v-row justify="center" class="pa-0 ma-0">
            <v-col class="text-center pa-0 ma-0">
              <div class="pa-1 title orange--text">Confirm Deposit</div>
              <v-divider></v-divider>
              <!-- <div class="pt-2 font-weight-bold">{{ringgitFormat(lotDetails.deposit)}} will be deducted from your deposit balance.</div> -->
              <div class="pt-2 font-weight-bold">
                You will still be able to participate in other auction lot as
                long as your auction lot winnings doesn't exeed your deposit
                balance.
              </div>
              <div class="pa-1 text-center">Do you wish to proceed?</div>
              <v-btn
                class="mx-1"
                @click="putUserInLot(user.id, id, lotDetails.deposit)"
                color="green"
                dark=""
                >Yes</v-btn
              >
              <v-btn
                class="mx-1"
                @click="
                  () => {
                    permission.show = false;
                    permission.confirm = false;
                    agree = false;
                  }
                "
                color="red"
                dark=""
                >No</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
        <v-card flat v-if="!permission.confirm">
          <v-row justify="end" class="pa-0 ma-0">
            <v-btn
              class="mx-5 mt-1"
              @click="
                () => {
                  permission.show = false;
                  permission.exceedwinning = false;
                  agree = false;
                }
              "
              color="grey"
              dark
              >Close</v-btn
            >
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog v-model="permission.showLogin" persistent max-width="600px">
      <v-card class="pa-2 text-center">
        <v-row justify="center" class="pa-1 ma-0 font-weight-bold"
          >Please Log in before participating in an auction.</v-row
        >
        <v-row justify="center" class="pa-1 ma-0">
          <v-btn color="orange" dark @click="navigate('/login')">OK</v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="permission.private" persistent max-width="600px">
      <v-card class="pa-2 text-center">
        <v-row justify="center" class="pa-1 ma-0 font-weight-bold"
          >This auction is only available for
          {{ session.publicOrPrivate }} only.</v-row
        >
        <v-row justify="center" class="pa-1 ma-0">
          <v-btn color="orange" dark @click="permission.private = false"
            >OK</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="permission.exceedwinningPrivate"
      persistent
      max-width="600px"
    >
      <v-card class="pa-2 text-center">
        <v-row justify="center" class="pa-1 ma-0 font-weight-bold"
          >You already have the highest bid for a lot.
          {{ session.publicOrPrivate }} auction only allows one winning lot per
          person in one session.</v-row
        >
        <v-row justify="center" class="pa-1 ma-0">
          <v-btn
            color="orange"
            dark
            @click="permission.exceedwinningPrivate = false"
            >OK</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
// import io from "socket.io-client";
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
import storage from "../jscontroller/storage";
export default {
  data() {
    return {
      // socket: {},
      serverUrl: serverConfig.hostname,
      id: this.$route.params.lotId,
      user: {},
      lotDetails: {},
      photos: [],
      currentPhoto: "",
      session: {},
      timeLeft: "",
      countdownStatus: "",
      higestBidPrice: 0,
      frontMessage: null,
      currentBidder: null,
      depositBalance: 0,
      totalDepositUsed: 0,
      winnings: 0,
      agree: false,
      permission: {
        show: false,
        asset: false,
        confirm: false,
        showLogin: false,
        exceedwinning: false,
        private: false,
        exceedwinningPrivate: false,
      },
      snackbar: {
        showProgress: false,
        showStatus: false,
        text: "",
        timeout: 4000,
        icon: "mdi-check-circle-outline",
        color: "green",
      },
      style: {
        info1: "ma-0 grey--text d-flex align-center",
        info2: "mx-1 pa-0",
      },
    };
  },
  created() {
    this.user = storage.GetUser();
  },
  mounted() {
    this.getAuctionLotsById(this.id);
    this.getFrontMessageQuery();
  },
  methods: {
    navigate: function (pagePath) {
      if (this.$route.path != pagePath) {
        this.$router.push(pagePath);
      }
    },
    previousPage() {
      window.history.back();
    },
    ringgitFormat(amount) {
      if (amount == null) {
        return "RM " + (0).toFixed(2);
      } else {
        var num = amount.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        });
        var deci = num.split(".")[1];
        if (deci != null) {
          if (deci.length == 1) {
            num = num + "0";
          }
        } else {
          num = num + ".00";
        }
        return "RM " + num;
      }
    },
    // participate(){
    //     var self = this;
    //     if (self.user.role == "admin" || self.user.role == "user" && self.user.role != "vendor"){
    //         if(self.session.publicOrPrivate == 'public')
    //             self.checkUserinLot(self.user.id, self.id)
    //         else{
    //             self.getUserQuery((user)=>{
    //                 if(user.staffOf == self.session.publicOrPrivate)
    //                     self.checkUserinLot(self.user.id, self.id)
    //                 else
    //                     self.permission.private = true
    //             })
    //         }
    //     }
    //     else if(self.user.role == ""){
    //         self.permission.showLogin = true
    //         // self.navigate("/login")
    //     }
    // },
    checkBoolean(status) {
      if (status == "started") {
        return true;
      } else {
        return false;
      }
    },
    getAuctionLotsById(id) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/nextauctionlot/get/" + id;
      axios
        .get(apiUrl)
        .then(function (response) {
          self.lotDetails = response.data;
          self.getSession(self.lotDetails.session);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    getCurrentBidder(id) {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/socket/auctionlot/" + id;
      axios
        .get(apiUrl)
        .then(function (response) {
          var data = response.data;
          if (data != null && data != "") {
            self.higestBidPrice = data.latestBid;
            self.currentBidder = data.bidderNo;
          }
          self.hideSnackbar("Finish fetching latest bid..", "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Failed to refresh", "red");
        });
    },
    getAuctionLotFileNamesQuery(regnNo, isShowAll) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/file/filenames";
      var bodyFormData = new FormData();
      bodyFormData.set("folderName", regnNo);
      bodyFormData.set("folderName2", "auctionlot");
      bodyFormData.set("folderType", "image");
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          var photos = response.data;
          if (response.data != "") {
            self.currentPhoto =
              self.serverUrl +
              "/api/file/image/auctionlot/" +
              regnNo +
              "/" +
              photos[0];
            for (var i = 0; i < photos.length; i++) {
              if (isShowAll)
                self.photos.push(
                  self.serverUrl +
                    "/api/file/image/auctionlot/" +
                    regnNo +
                    "/" +
                    photos[i]
                );
              if (photos[i] == "0" || photos[i] == "0.jpg")
                self.currentPhoto =
                  self.serverUrl +
                  "/api/file/image/auctionlot/" +
                  regnNo +
                  "/" +
                  photos[i];
            }
          } else {
            self.photos = [];
            self.currentPhoto =
              "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1200px-No_image_available.svg.png";
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function () {
          self.photos = [];
          self.currentPhoto =
            "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1200px-No_image_available.svg.png";
        });
    },
    getSession(sessionNo) {
      var self = this;
      var apiUrl =
        serverConfig.hostname + "/api/auctionmanager/session/" + sessionNo;
      axios
        .get(apiUrl)
        .then(function (response) {
          self.session = response.data;
          self.getServerTime((serverTime) => {
            var currentTime = new Date();
            setInterval(() => {
              self.countdown(self.session, serverTime, currentTime);
            }, 1000);
          });
          if (self.session != "") {
            self.getAuctionLotFileNamesQuery(self.lotDetails.regnNo, false);
          } else {
            self.getAuctionLotFileNamesQuery(self.lotDetails.regnNo, true);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    changePhoto(photoName) {
      var self = this;
      var temp = photoName;
      self.currentPhoto = temp;
    },
    getServerTime(callback) {
      var apiUrl = serverConfig.hostname + "/api/time/get/monthdayformat";
      axios
        .get(apiUrl)
        .then(function (response) {
          callback(response.data);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    countdown(session, serverTime, currentTime) {
      var self = this;
      var countDownEnd = new Date(
        `${session.endDate} ${session.endTime} +8:00`
      ).getTime();
      var countDownStart = new Date(
        `${self.session.startDate} ${self.session.startTime} +8:00`
      ).getTime();
      var timeLeft = "";
      var now =
        new Date(serverTime).getTime() +
        new Date().getTime() -
        new Date(currentTime).getTime();
      if (now < countDownStart && now < countDownEnd) {
        self.countdownStatus = "not started";
      } else if (now > countDownStart && now < countDownEnd) {
        self.countdownStatus = "started";
      } else if (now > countDownStart && now > countDownEnd) {
        self.countdownStatus = "end";
      }
      // Find the distance between now and the count down date
      var distance = countDownEnd - now;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      timeLeft = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
      this.timeLeft = timeLeft;
    },
    checkUserinLot(id, lotNo) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/socket/userinlot/find";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      bodyFormData.set("lotNo", lotNo);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          if (response.data == "user not in lot") {
            self.getDepositBalace(id);
            self.countDepositLeft(id);
          } else if (response.data == "user in lot") {
            self.navigate("/auctionpage/" + self.id);
          }
        })
        .catch(function () {});
    },
    getDepositBalace(id) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/user/asset";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          self.depositBalance = response.data.depositBalance;
          self.permission.show = true;
        })
        .catch(function () {});
    },
    countWinningAuctions(id) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/socket/winning/id";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          var winningLot = response.data;
          var totalDepositToBeUsed = 0;
          self.winnings = response.data.length;
          for (var i = 0; i < winningLot.length; i++) {
            totalDepositToBeUsed = totalDepositToBeUsed + winningLot[i].deposit;
          }
          totalDepositToBeUsed = totalDepositToBeUsed + self.lotDetails.deposit;
          if (self.session.publicOrPrivate == "public") {
            if (totalDepositToBeUsed > self.depositBalance) {
              self.permission.exceedwinning = true;
            } else self.permission.confirm = true;
          } else {
            if (self.session.publicOrPrivate == "TM Staff") {
              if (self.winnings > 0)
                self.permission.exceedwinningPrivate = true;
              else self.permission.confirm = true;
            }
          }
        })
        .catch(function () {});
    },
    countDepositLeft(id) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/socket/winning/id";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          var winningLot = response.data;
          var totalDepositUsed = 0;
          for (var i = 0; i < winningLot.length; i++) {
            totalDepositUsed = totalDepositUsed + winningLot[i].deposit;
          }
          self.totalDepositUsed = totalDepositUsed;
        })
        .catch(function () {});
    },
    putUserInLot(id, lotNo, deposit) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/socket/lotunit/enter";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      bodyFormData.set("lotNo", lotNo);
      bodyFormData.set("deposit", deposit);
      axios
        .post(apiUrl, bodyFormData)
        .then(function () {
          self.permission.confirm = false;
          self.permission.show = false;
          setTimeout(function () {
            self.navigate("/auctionpage/" + self.id);
          }, 500);
        })
        .catch(function () {});
    },
    openTermsAndCondition() {
      var self = this;
      window.open(self.serverUrl + "/api/file/document/tnc/pdf/tnc.pdf");
    },
    getUserQuery(callback) {
      var self = this;
      const apiUrl = serverConfig.hostname + "/api/user/get-profile";
      const token = self.user.jwt;

      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          callback(response.data);
        })
        .catch(function (error) {
          alert(error.response.data.message);
        });
    },
    getFrontMessageQuery() {
      var self = this;
      var apiUrl = self.serverUrl + "/api/toggleinfo/get/id/11";
      axios
        .get(apiUrl)
        .then(function (response) {
          self.frontMessage = response.data;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red" || color == "orange") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
  },
};
</script>
