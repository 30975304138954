<template>
    <v-navigation-drawer
        :permanent="true"        
        height="100%"
        class="pa-5"
        width="300"
        right
        >
        <v-list
            dense
        >
            <div class="subtitle-1 font-weight-bold pa-0 orange--text">Help Center</div>     
            <v-divider></v-divider>                  
            <!-- <v-row @click="navigate('/help/faq')" class="pa-0 ma-0 my-1">
                <v-col class="pa-0 ma-0 mr-3" cols="1"><v-icon>mdi-play</v-icon></v-col>
                <v-col class="pa-0 ma-0"><span class="">Frequently Asked Questions </span></v-col>
                
            </v-row> -->
            <v-row @click="navigate('/help/termsAndCondition')" class="pa-0 ma-0 my-1">
                <v-col class="pa-0 ma-0 mr-3" cols="1"><v-icon class="mr-2">mdi-play</v-icon></v-col>
                <span class="">Terms & Condition</span>
            </v-row>
            <v-row @click="navigate('/help/bidderProcess')" class="pa-0 ma-0 my-1">
                <v-col class="pa-0 ma-0 mr-3" cols="1"><v-icon class="mr-2">mdi-play</v-icon></v-col>
                <span class="">Bidder Process</span>
            </v-row>
            <v-row @click="navigate('/help/onlinePayment')" class="pa-0 ma-0 my-1">
                <v-col class="pa-0 ma-0 mr-3" cols="1"><v-icon class="mr-2">mdi-play</v-icon></v-col>
                <span class="">Online Payment</span>
            </v-row>
            <v-row @click="navigate('/help/tutorial')" class="pa-0 ma-0 my-1">
                <v-col class="pa-0 ma-0 mr-3" cols="1"><v-icon class="mr-2">mdi-play</v-icon></v-col>
                <span class="">Tutorial</span>
            </v-row>
            <v-row @click="navigate('/help/contactUs')" class="pa-0 ma-0 my-1">
                <v-col class="pa-0 ma-0 mr-3" cols="1"><v-icon class="mr-2">mdi-play</v-icon></v-col>
                <span class="">Contact Us</span>
            </v-row>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    
    methods:{
        navigate: function(pagePath){
            if(this.$route.path != pagePath){
                this.$router.push(pagePath);
            }  
        },
    }
}
</script>