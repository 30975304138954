<template>
  <div>
    <v-card color="" class="pa-2 mx-2 mt-2">
      <div color="" class="pa-1 title" style="text-decoration: underline">
        Email
      </div>
      <v-btn block color="blue" dark @click="draft.show = true"
        >Compose message</v-btn
      >
    </v-card>

    <v-dialog v-model="draft.show" persistent max-width="100%">
      <v-card class="pa-2 mb-1">
        <v-row justify="center" class="pa-0 ma-0">
          <v-col class="text-center pa-0 ma-0">
            <v-row align="center" class="pa-1 ma-1">
              <div>To:</div>
              <v-col class="pa-2 ma-0">
                <v-select
                  class="pa-0"
                  filled
                  dense=""
                  v-model="recipientsType"
                  :items="recipientsList"
                  hide-details="auto"
                  label="Recipients"
                  return-object
                >
                </v-select>
              </v-col>
              <v-btn
                @click="draft.showUserSelection = true"
                v-if="recipientsType == 'Individual'"
                >Select emails</v-btn
              >
            </v-row>
            <v-row
              align="center"
              class="pa-0 ma-0"
              v-if="recipientsType == 'Individual'"
            >
              <v-text-field
                class="pa-1 ma-1"
                solo
                v-model="emailValue"
                hide-details="auto"
                label="add email by typing.."
              >
              </v-text-field>
              <v-btn @click="addEmail()"><v-icon>mdi-plus</v-icon></v-btn>
            </v-row>
            <v-row class="pa-0 ma-0">
              <v-col
                v-for="(selection, i) in draft.emailList"
                :key="selection.text"
                class="shrink pa-1 ma-0"
              >
                <v-chip
                  close
                  hide-details="auto"
                  @click:close="draft.emailList.splice(i, 1)"
                >
                  {{ selection }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row
              v-if="draft.emailList.length > 0"
              class="pa-2 ma-0"
              justify="center"
            >
              <v-btn @click="draft.emailList = []">Clear All</v-btn>
            </v-row>
            <v-divider></v-divider>
            <v-text-field
              class="pa-1 ma-1"
              solo
              v-model="draft.subject"
              hide-details="auto"
              label="Subject"
            >
            </v-text-field>
            <v-textarea
              class="pa-1 ma-1"
              solo
              v-model="draft.content"
              label="Content"
              hide-details="auto"
            ></v-textarea>
            <v-btn
              class="mx-5 mt-1"
              @click="promptConfirmEmail()"
              color="green"
              dark
              >Send <v-icon>mdi-arrow-top-right-thick</v-icon></v-btn
            >
            <v-btn
              class="mx-5 mt-1"
              @click="draft.show = false"
              color="grey"
              dark
              >Close</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="draft.showUserSelection" persistent max-width="600px">
      <v-card class="pa-2 mb-1">
        <v-row justify="center" class="pa-1 ma-0">
          Filter by:
          <v-chip @click="chip = 'All'" class="mx-1">All</v-chip>
          <v-chip @click="chip = 'Name'" class="mx-1">Name</v-chip>
          <v-chip @click="chip = 'Email'" class="mx-1">Email</v-chip>
        </v-row>
        <v-row v-if="draft.showSearch" class="pa-1 ma-1">
          <v-text-field
            prepend-inner-icon="mdi-account-search-outline"
            class="pa-0"
            solo
            background-color="rgba(232, 232, 232, 0.8)"
            v-model="search"
            hide-details="auto"
            :label="'Search ' + recipientsType + '..'"
          >
          </v-text-field>
          <v-btn @click="searchUsers()" dark color="blue" class="ma-1 mt-2"
            >Search</v-btn
          >
        </v-row>
        <v-row v-if="chip == 'All'" class="pa-1 ma-1" justify="end">
          <v-btn @click="selectAllEmail()" dark color="blue" class="ma-1 mt-2"
            >Select All</v-btn
          >
          <v-btn @click="deselectAllEmail()" dark color="grey" class="ma-1 mt-2"
            >Clear All</v-btn
          >
        </v-row>
        <v-divider></v-divider>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Bidder Id</th>
                <th class="text-left pl-5">Email</th>
                <th class="text-left pl-5">Name</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, i) in users" :key="i">
                <td>{{ user.id }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.firstName }}</td>
                <v-col>
                  <v-checkbox
                    v-model="draft.emailList"
                    :value="user.email"
                    hide-details="auto"
                    color="primary"
                    class="ma-0 pa-0"
                  ></v-checkbox>
                </v-col>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <template v-if="showPagination">
          <div class="text-center mt-2">
            <v-pagination
              v-model="usersCurrentPage"
              :length="userLength"
              :total-visible="usersPerPage"
            ></v-pagination>
          </div>
        </template>
        <v-col class="text-center pa-0 ma-0">
          <v-btn
            class="mx-5 mt-1"
            @click="draft.showUserSelection = false"
            color="grey"
            dark
            >Close</v-btn
          >
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="error.show" persistent max-width="600px">
      <v-card class="pa-2 mb-1">
        <v-row justify="center" class="pa-0 ma-0">
          <v-col class="text-center pa-0 ma-0">
            <v-row class="pa-0 ma-0" justify="center"
              ><v-icon size="50" color="orange"
                >mdi-alert-outline</v-icon
              ></v-row
            >
            <div class="pa-1 title orange--text">{{ error.message }}</div>
            <v-btn
              class="mx-5 mt-1"
              @click="error.show = false"
              color="grey"
              dark
              >Close</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirm.show" persistent max-width="600px">
      <v-card class="pa-2 mb-1">
        <v-row justify="center" class="pa-0 ma-0">
          <v-col class="text-center pa-0 ma-0">
            <v-row class="pa-0 ma-0" justify="center"
              ><v-icon size="50" color="orange"
                >mdi-alert-outline</v-icon
              ></v-row
            >
            <div class="pa-1 title orange--text">{{ confirm.message }}</div>
            <v-btn
              class="mx-5 mt-1"
              @click="confirmFunction(confirm.functionName)"
              color="green"
              dark
              >Yes</v-btn
            >
            <v-btn
              class="mx-5 mt-1"
              @click="confirm.show = false"
              color="red"
              dark
              >No</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
import storage from "../jscontroller/storage";
import authorization from "../jscontroller/authorization";
export default {
  data: () => ({
    user: {},
    serverUrl: serverConfig.hostname,
    recipientsType: "",
    recipientsList: ["All Users", "TM Staff", "Individual"],
    draft: {
      show: false,
      subject: "",
      content: "",
      emailList: [],
      showUserSelection: false,
      showSearch: false,
    },
    emailValue: "",
    error: {
      show: false,
      message: "",
    },
    confirm: {
      show: false,
      message: "",
      functionName: "",
    },
    snackbar: {
      showProgress: false,
      showStatus: false,
      text: "",
      timeout: 5000,
      icon: "mdi-check-circle-outline",
      color: "green",
    },
    show: {
      registration: true,
      registerDisable: false,
    },
    usersCurrentPage: 1,
    userLength: 0,
    usersPerPage: 10,
    showPagination: false,
    users: [],
    chip: "",
    search: "",
  }),
  created() {},
  mounted() {
    this.user = storage.GetUser();
    if (!authorization.checkSuperAdminAuthorization(this.user)) {
      storage.switchNavBar("website");
      this.$router.push("/");
      this.$router.go();
    }
  },
  methods: {
    checkRecipients() {
      var self = this;
      if (self.recipientsType == "") {
        self.error.show = true;
        self.error.message = "Choose email recipients";
      } else self.draft.show = true;
    },
    confirmFunction(functionName) {
      var self = this;
      if (functionName == "sendEmail") {
        self.sendEmail();
      }
    },
    promptConfirmEmail() {
      var self = this;
      if (self.recipientsType == "") {
        self.error.show = true;
        self.error.message = "Please choose recipients";
      } else if (
        self.recipientsType == "Individual" &&
        self.draft.emailList.length < 1
      ) {
        self.error.show = true;
        self.error.message = "Please choose recipients";
      } else if (self.draft.subject == "") {
        self.error.show = true;
        self.error.message = "Please enter a subject";
      } else if (self.draft.content == "") {
        self.error.show = true;
        self.error.message = "Please put in the content to be emailed";
      } else {
        self.confirm.show = true;
        self.confirm.functionName = "sendEmail";
        self.confirm.message = "Confirm send message";
      }
    },
    addEmail() {
      var self = this;
      if (self.emailValue != "") {
        self.draft.emailList.push(self.emailValue);
      }
      self.emailValue = "";
    },
    sendEmail() {
      var self = this;
      self.showSnackbar();
      self.confirm.show = false;
      var apiUrl = self.serverUrl + "/api/mail/multiple/emails";
      var body = {
        subject: self.draft.subject,
        content: self.draft.content,
        recipientsType: self.recipientsType,
        emailList: self.draft.emailList,
      };
      axios
        .post(apiUrl, body)
        .then(function () {
          self.hideSnackbar("email successfully sent", "green");
          self.draft.show = false;
          self.draft.subject = "";
          self.draft.content = "";
          self.recipientsType = "";
          self.draft.emailList = [];
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Failed to send email", "red");
        });
    },
    searchAllUsers() {
      var self = this;
      self.getUserCount(() => {
        self.searchUsersByPage(1, self.usersPerPage);
      });
    },
    selectAllEmail() {
      var self = this;
      if (self.users.length > 0) {
        for (var i = 0; i < self.users.length; i++) {
          var emailInList = false;
          var tempEmail = self.users[i].email;
          for (var j = 0; j < self.draft.emailList.length; j++) {
            if (self.draft.emailList[j] == tempEmail) emailInList = true;
          }
          if (!emailInList) self.draft.emailList.push(tempEmail);
        }
      }
    },
    deselectAllEmail() {
      var self = this;
      for (var i = 0; i < self.users.length; i++) {
        var tempEmail = self.users[i].email;
        for (var j = 0; j < self.draft.emailList.length; j++) {
          if (self.draft.emailList[j] == tempEmail)
            self.draft.emailList.splice(j, 1);
        }
      }
    },
    searchUsersByPage(page, noOfContent) {
      var self = this;
      var apiUrl =
        self.serverUrl +
        "/api/user/getbypage/" +
        (page - 1) +
        "/" +
        noOfContent;
      axios
        .get(apiUrl)
        .then(function (response) {
          self.users = response.data;
          self.showPagination = true;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    searchUsers() {
      var self = this;
      if (self.chip == "Name") self.searchUsersByName();
      else if (self.chip == "Email") self.searchUsersByEmail();
    },
    searchUsersByName() {
      var self = this;
      if (self.search != "") {
        var name = self.search;
        var apiUrl = self.serverUrl + "/api/user/get/byname";
        var bodyFormData = new FormData();
        bodyFormData.set("name", name);
        axios
          .post(apiUrl, bodyFormData, {
            headers: {
              Authorization: `Bearer ${self.user.jwt}`,
            },
          })
          .then(function (response) {
            self.users = response.data;
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            alert(error.response.data.message);
          });
      }
    },
    searchUsersByEmail() {
      var self = this;
      if (self.search != "") {
        var email = self.search;
        var apiUrl = self.serverUrl + "/api/user/getall/by/email";
        var bodyFormData = new FormData();
        bodyFormData.set("email", email);
        axios
          .post(apiUrl, bodyFormData)
          .then(function (response) {
            self.users = response.data;
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {});
      }
    },
    getUserCount(callback) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/user/count";
      axios
        .get(apiUrl)
        .then(function (response) {
          var tempTotal = parseInt(response.data) / self.usersPerPage;
          if (tempTotal > tempTotal.toFixed(0)) {
            tempTotal = parseInt(tempTotal.toFixed(0)) + 1;
          } else {
            tempTotal = parseInt(tempTotal.toFixed(0));
          }
          self.userLength = tempTotal;
          callback();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red" || color == "orange") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
  },
  watch: {
    usersCurrentPage: function () {
      var self = this;
      self.users = [];
      self.searchUsersByPage(self.usersCurrentPage, self.usersPerPage);
      // this.getAuctionLotsByPage(this.lot.contentInPage)
      // this.scrollToTop();
    },
    recipientsType: function () {
      var self = this;
      if (self.recipientsType != "Individual") {
        self.draft.emailList = [];
      }
    },
    chip: function () {
      var self = this;
      if (self.chip == "All") {
        self.searchAllUsers();
        self.draft.showSearch = false;
      } else if (self.chip == "Name") {
        self.showPagination = false;
        self.draft.showSearch = true;
        self.users = [];
      } else if (self.chip == "Email") {
        self.showPagination = false;
        self.draft.showSearch = true;
        self.users = [];
      }
    },
  },
};
</script>
