<template>
<div>
  <v-bottom-navigation
    background-color="#ffc14d"
    height="145"
    class="pa-0 ma-0"
  >
  <v-row class="pa-0 ma-0">
    <v-col class="pa-0 ma-0">
      <v-row justify="center" class="pa-2 pb-0 ma-0">
          <router-link v-for="(link,i) in links" v-bind:key="i" class="mr-2 caption d-flex d-sm-none" style="text-decoration:none" :to="{path: link.path}">{{link.name}}<span class="ml-2">|</span></router-link>        
      </v-row>
      <v-row justify="center" class="pa-0 ma-0 mt-2 d-none d-sm-flex">
          <router-link v-for="(link,j) in links" v-bind:key="j" class="mr-2 pa-0" style="text-decoration:none" :to="{path: link.path}">{{link.name}}<span class="ml-2">|</span></router-link>        
      </v-row>
      <v-row justify="center" class="caption my-1 mx-3 text-center">Copyright 2020 Auction Management Services. All Right Reserved.</v-row>
      <v-row justify="center" class="caption my-1 mx-3 text-center d-flex d-sm-none">
        <span class="mx-2">Tel: {{contactInfos.tel}}</span>
        <span class="mx-2">Fax: {{contactInfos.fax}}</span>
        <span class="mx-2">Email: {{contactInfos.email}}</span>
      </v-row>
      <v-row class="pa-0 ma-0 d-none d-sm-flex" justify="center">
        <v-col class="pa-0" cols="12" lg="7" md="10">
          <v-row class="pa-0 ma-0 caption" justify="center">          
            <span class="mx-4">Tel: {{contactInfos.tel}}</span>
            <span class="mx-4">Fax: {{contactInfos.fax}}</span>
            <span class="mx-4">Email: {{contactInfos.email}}</span>
          </v-row>       
        </v-col>
      </v-row>  
    </v-col>
  </v-row>
  </v-bottom-navigation>
</div>
</template>

<script>
import axios from "axios"
import serverConfig from "../jscontroller/serverConfig"

export default {
    data: () => ({    
    links: [
      {name: "Home", path: "/"},
      {name: "Online Auction", path: "/onlineAuction/onAuction/1"},
      // {name: "About Us",path: ""},
      {name: "Contact Us", path: "/help/contactUs"},      
    ],
    contactInfos: {},   
    
  }),
  mounted(){
    this.getContactInfo();
  },
  methods:{
      scrollToTop(){
        window.scrollTo(0,0);
      },
      getContactInfo(){
        var apiUrl = serverConfig.hostname + "/api/admin/contactinfo"
        axios.get(apiUrl).then((response) => {                
        this.contactInfos = response.data;        
    })
    }
  }
}
</script>