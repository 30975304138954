<template>
  <v-app>
    <Navbar v-if="!serverDown"/>
    <v-content>
      <div v-if="serverDown" >        
          <v-row justify="center" align-content="center" class="pa-0 ma-0">
            <v-card class="pa-2 mx-2 mt-2">
              <v-row justify="center">
                <v-icon color="orange" size="100" class="mx-5">mdi-alert-circle-outline</v-icon>          
              </v-row>
              <div class="pa-1 title text-center" >We'll be right back!</div>
              <div class="pa-1 text-center" >Our website under maintenance until further notice.</div>
              <div class="pa-1 text-center" >We apologize for any inconvenience caused.</div>
              <v-divider></v-divider>
              <div class="pa-1 text-center font-weight-bold" >Please try again later.</div>
            </v-card>
          </v-row>
      </div>
      <router-view v-if="!serverDown"></router-view>
    </v-content>
    <Bottombar/>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
import Bottombar from '@/components/Bottombar'
import axios from "axios"
import serverConfig from "./jscontroller/serverConfig"

export default {
  name: 'App',
  // eslint-disable-next-line vue/no-unused-components
  components: {Navbar, Bottombar},
  data: () => ({
    serverDown: false,
    serverUrl: serverConfig.hostname,
  }),
  mounted(){
    this.pingServer()
  },
  methods:{
    pingServer(){
    var self = this;      
    var apiUrl = self.serverUrl + "/api/sc/pingserver";
    axios.get(apiUrl)
    .then(function (response) {
      // eslint-disable-next-line no-console
      console.log(response.data)
    })
    // eslint-disable-next-line no-unused-vars
    .catch(function (error) {     
      // eslint-disable-next-line no-console  
      console.log("api server is down")    
      self.serverDown = true 
    });
    },
  }
};
</script>
