<template>
  <div>
    <v-card color="" class="pa-2 mx-2 mt-2">
      <div color="" class="pa-1 title" style="text-decoration: underline">
        Session Manager
      </div>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Session No</th>
              <th class="text-left pl-5">Start Session</th>
              <th class="text-left pl-5">End Session</th>
              <th class="text-left pl-5">Accessibility</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(session, i) in sessions" :key="i">
              <td>
                <v-row justify="center">
                  <v-text-field v-model="sessions[i].id"></v-text-field>
                </v-row>
              </td>
              <!-- start session -->
              <td>
                <v-row>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-menu
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="sessions[i].startDate"
                        label="Picker without buttons"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="sessions[i].startDate"
                    ></v-date-picker>
                  </v-menu>

                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="sessions[i].startTime"
                        label="Picker in menu"
                        prepend-icon="mdi-clock-outline"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="sessions[i].startTime"
                      full-width
                    ></v-time-picker>
                  </v-menu>
                </v-row>
              </td>

              <!-- end session               -->
              <td>
                <v-row>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-menu
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="sessions[i].endDate"
                        label="Picker without buttons"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="sessions[i].endDate"
                    ></v-date-picker>
                  </v-menu>

                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="sessions[i].endTime"
                        label="Picker in menu"
                        prepend-icon="mdi-clock-outline"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="sessions[i].endTime"
                      full-width
                    ></v-time-picker>
                  </v-menu>
                </v-row>
              </td>
              <td>
                <v-row justify="center">
                  <v-select
                    class="pa-0"
                    filled
                    dense=""
                    v-model="sessions[i].publicOrPrivate"
                    :items="['public', 'TM Staff']"
                    hide-details="auto"
                    label="Accessibility.."
                  >
                  </v-select>
                </v-row>
              </td>
              <td>
                <v-btn
                  :disabled="endSession[i].disabled"
                  color="blue"
                  @click="
                    showEndSession.show = true;
                    showEndSession.currentSession = session.id;
                    showEndSession.column = i;
                  "
                  >End Session</v-btn
                >
              </td>
              <td>
                <v-btn
                  @click="deleteSession(session.id, i)"
                  fab
                  x-small
                  color="red"
                  ><v-icon>mdi-close-thick</v-icon></v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-row class="mb-3 mx-3" justify="center"
            ><v-btn @click="addSession()" dark="" color="blue lighten-2" block
              ><v-icon>mdi-plus</v-icon></v-btn
            ></v-row
          >
          <v-row justify="center"
            ><v-btn @click="saveAuctionSessions()" dark color="#45b916"
              >Save</v-btn
            ></v-row
          >
          <v-row justify="center" class="mt-3">
            <v-progress-circular
              v-if="progress.session"
              color="green"
              indeterminate
            ></v-progress-circular>
            <div v-if="!progress.session" :class="classStyle.session">
              {{ message.session }}
            </div>
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <div color="" class="pa-1 title" style="text-decoration: underline">
        End Auction Manager
      </div>
      <v-row class="pa-0 ma-0">
        <v-col class="pa-1 ma-1">
          <div class="red--text">
            All the following processes must be executed to record all the
            Auction data.
          </div>
          <div class="red--text">
            All the processes must be executed in the right order.
          </div>
          <div class="red--text">
            All the processes must only be executed once.
          </div>
          <v-col v-if="outcomeManager.session == ''" class="pa-1 ma-1"
            ><v-btn @click="getLatestSessionHistory()" color="grey lighten-1"
              >Insert Auction Session</v-btn
            ></v-col
          >
          <v-col class="pa-1 ma-1" v-if="outcomeManager.session != ''">
            <input type="text" v-model="outcomeManager.session" />
          </v-col>
          <v-col class="pa-1 ma-1"
            >1.
            <v-btn
              @click="
                outcomeManager.show = true;
                outcomeManager.process = 'record winning lot';
                outcomeManager.message =
                  'This will record all lot won by bidders into sales record.';
              "
              color="purple"
              dark=""
              >Record winning lot</v-btn
            >
          </v-col>
          <v-col class="pa-1 ma-1"
            >2.
            <v-btn
              @click="
                outcomeManager.show = true;
                outcomeManager.process = 'balance out deposit';
                outcomeManager.message =
                  'This will balance out all bidders(winners) deposit.';
              "
              color="purple lighten-1"
              dark=""
              >Balance out deposit</v-btn
            >
          </v-col>
          <v-col class="pa-1 ma-1"
            >3.
            <v-btn
              @click="
                outcomeManager.show = true;
                outcomeManager.process = 'send email to auction winner';
                outcomeManager.message =
                  'This process will send email to all the bidders who`ve won a unit lot.';
              "
              color="purple lighten-2"
              >Send email to auction winner</v-btn
            >
          </v-col>
          <v-col class="pa-1 ma-1"
            >4.
            <v-btn
              @click="
                outcomeManager.show = true;
                outcomeManager.process = 'clear running auction data';
                outcomeManager.message =
                  'This process will clear out data from the selected auction session. It should not be executed until auction sales has been recorded and emailed to the winners. This process must be executed before starting any new auction.';
              "
              color="red"
              class="yellow--text"
              >Clear Running Auction Data</v-btn
            ></v-col
          >
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="outcomeManager.show" persistent max-width="400px">
      <v-card class="pa-2 ma-0">
        <v-row class="pa-0 ma-0" justify="center"
          ><v-icon size="50" color="red">mdi-alert-outline</v-icon></v-row
        >
        <v-row class="pa-1 ma-0 title text-center" justify="center">{{
          outcomeManager.message
        }}</v-row>
        <div class="text-center">
          Please type in "{{ outcomeManager.process }}" and click ok to
          continue.
        </div>
        <v-text-field
          class="pa-0"
          solo
          full-width=""
          hide-details="auto"
          v-model="outcomeManager.value"
        >
        </v-text-field>
        <v-row class="pa-2 ma-0" justify="center">
          <v-btn
            @click="runEndAuctionProcess()"
            color="green"
            dark
            class="mx-1 pa-0"
            >OK</v-btn
          >
          <v-btn
            @click="outcomeManager.show = false"
            color="red"
            dark
            class="mx-1 pa-0"
            >Close</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showEndSession.show" persistent max-width="400px">
      <v-card class="pa-2 ma-0">
        <v-row class="pa-1 ma-0 title text-center" justify="center"
          >Do you want to close the current auction session? This will
          officially end auction {{ showEndSession.currentSession }}.</v-row
        >
        <v-row class="pa-2 ma-0" justify="center">
          <v-btn
            @click="endSessionQuery(showEndSession.column)"
            color="green"
            dark
            class="mx-1 pa-0"
            >Yes</v-btn
          >
          <v-btn
            @click="showEndSession.show = false"
            color="red"
            dark
            class="mx-1 pa-0"
            >No</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="csv.show" persistent max-width="400px">
      <v-card class="pa-2 ma-0">
        <v-row class="pa-1 ma-0 title text-center" justify="center"
          >Are you sure you want to proceed? All "Auction Lot" with the same
          "Lot No" will be replaced with new ones.</v-row
        >
        <v-row class="pa-2 ma-0" justify="center">
          <v-btn
            v-if="!csv.isNextView"
            @click="saveAllAuctionLots()"
            color="green"
            dark
            class="mx-1 pa-0"
            >Yes</v-btn
          >
          <v-btn
            v-if="csv.isNextView"
            @click="saveAllNextAuctionLots()"
            color="green"
            dark
            class="mx-1 pa-0"
            >Yes</v-btn
          >
          <v-btn @click="csv.show = false" color="red" dark class="mx-1 pa-0"
            >No</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="clearLot.show" persistent max-width="400px">
      <v-card class="pa-2 ma-0">
        <v-row class="pa-0 ma-0" justify="center"
          ><v-icon size="50" color="red">mdi-alert-outline</v-icon></v-row
        >
        <v-row class="pa-1 ma-0 title text-center" justify="center"
          >This action will clear all lot currently in viewing.</v-row
        >
        <div v-if="!isNextView" class="text-center">
          Please type in "clear all lot" and click ok to continue.
        </div>
        <div v-if="isNextView" class="text-center">
          Please type in "clear" and click ok to continue.
        </div>
        <v-text-field
          class="pa-0"
          solo
          full-width=""
          hide-details="auto"
          v-model="clearLot.value"
        >
        </v-text-field>
        <v-row class="pa-2 ma-0" justify="center">
          <v-btn
            v-if="!isNextView"
            @click="clearAllLot()"
            color="green"
            dark
            class="mx-1 pa-0"
            >OK</v-btn
          >
          <v-btn
            v-if="isNextView"
            @click="clearAllLotNext()"
            color="green"
            dark
            class="mx-1 pa-0"
            >OK</v-btn
          >

          <v-btn
            @click="clearLot.show = false"
            color="red"
            dark
            class="mx-1 pa-0"
            >Cancel</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <!-- part Auction Unit Manager -->
    <v-card color="" class="pa-2 mx-2 mt-2">
      <div color="" class="pa-1 title" style="text-decoration: underline">
        Auction Unit Manager
      </div>
      <v-row class="pa-2 ma-0">
        <input ref="csvFile" type="file" @change="csvReader()" accept=".csv" />
        <v-btn
          v-if="csv.showBtn"
          @click="
            csv.show = true;
            csv.isNextView = false;
          "
          >Save All Auction Lot</v-btn
        >
        <v-btn
          v-if="lots.length > 0"
          @click="
            clearLot.show = true;
            isNextView = false;
          "
          color="red"
          dark
          >Clear all lot</v-btn
        >
      </v-row>
      <v-divider class=""></v-divider>
      <v-divider class="mb-2"></v-divider>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Lot No</th>
              <th class="text-left pl-5">Session</th>
              <th class="text-left pl-5">Regn No</th>
              <th class="text-left pl-5">Make</th>
              <th class="text-left pl-5">Description Of Good</th>
              <th class="text-left pl-5">Starting Price</th>
              <th class="text-left pl-5"></th>
              <th class="text-left pl-5">Images</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lot, i) in lots" :key="i">
              <td>
                <v-row justify="center">{{ lot.id }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{ lot.session }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{ lot.regnNo }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{ lot.make }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{ lot.descOfGood }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{ ringgitFormat(lot.price) }}</v-row>
              </td>
              <td>...</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="showPhotoPage(lot.id, lot.regnNo, lot.session)"
                      color=""
                      icon
                      ><v-icon>mdi-image-multiple</v-icon></v-btn
                    >
                  </template>
                  <span>Add photos</span>
                </v-tooltip>
              </td>
              <td>
                <v-btn
                  @click="editLot(lots[i], false)"
                  color="orange lighten-1"
                  dark=""
                  >Edit</v-btn
                >
              </td>
              <!-- <td><v-btn @click="saveAuctionLot(lots[i])" color="green" dark="">save</v-btn></td> -->
              <!-- <td><v-btn @click="deleteLot(lot.id, i)" color="red" dark=""><v-icon>mdi-delete</v-icon></v-btn></td> -->
              <td>
                <v-col>
                  <v-btn @click="deleteLot(lot.id, i)" fab x-small color="red"
                    ><v-icon>mdi-close-thick</v-icon></v-btn
                  >
                </v-col>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-col>
        <v-row class="mb-3 mx-3" justify="center"
          ><v-btn @click="addAuctionLot()" dark="" color="blue lighten-2" block
            ><v-icon>mdi-plus</v-icon></v-btn
          ></v-row
        >
      </v-col>
      <!-- page -->
      <template>
        <div class="text-center">
          <v-pagination
            v-model="lotPage"
            :length="lotLength"
            :total-visible="10"
          ></v-pagination>
        </div>
      </template>
    </v-card>

    <!-- part view next auction manager -->
    <v-card color="" class="pa-2 mx-2 mt-2">
      <div color="" class="pa-1 title" style="text-decoration: underline">
        View Next Auction Manager
      </div>
      <v-row class="pa-2 ma-0">
        <input
          ref="csvFile2"
          type="file"
          @change="csvReader2()"
          accept=".csv"
        />
        <v-btn
          v-if="csv.showBtn2"
          @click="
            csv.show = true;
            csv.isNextView = true;
          "
          >Save All Next Auction Lot View</v-btn
        >
        <v-btn
          v-if="nextLots.length > 0"
          @click="
            clearLot.show = true;
            isNextView = true;
          "
          color="red"
          dark
          >Clear all</v-btn
        >
      </v-row>
      <v-divider class=""></v-divider>
      <v-divider class="mb-2"></v-divider>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Lot No</th>
              <th class="text-left pl-5">Session</th>
              <th class="text-left pl-5">Regn No</th>
              <th class="text-left pl-5">Make</th>
              <th class="text-left pl-5">Description Of Good</th>
              <th class="text-left pl-5">Starting Price</th>
              <th class="text-left pl-5"></th>
              <th class="text-left pl-5">Images</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lot, i) in nextLots" :key="i">
              <td>
                <v-row justify="center">{{ lot.id }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{ lot.session }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{ lot.regnNo }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{ lot.make }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{ lot.descOfGood }}</v-row>
              </td>
              <td>
                <v-row justify="center">{{ ringgitFormat(lot.price) }}</v-row>
              </td>
              <td>...</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="showPhotoPage(lot.id, lot.regnNo, lot.session)"
                      color=""
                      icon
                      ><v-icon>mdi-image-multiple</v-icon></v-btn
                    >
                  </template>
                  <span>Add photos</span>
                </v-tooltip>
              </td>
              <td>
                <v-btn
                  @click="editLot(nextLots[i], true)"
                  color="orange lighten-1"
                  dark=""
                  >Edit</v-btn
                >
              </td>
              <!-- <td><v-btn @click="saveAuctionLot(lots[i])" color="green" dark="">save</v-btn></td> -->
              <!-- <td><v-btn @click="deleteLot(lot.id, i)" color="red" dark=""><v-icon>mdi-delete</v-icon></v-btn></td> -->
              <td>
                <v-col>
                  <v-btn
                    @click="deleteNextLot(lot.id, i)"
                    fab
                    x-small
                    color="red"
                    ><v-icon>mdi-close-thick</v-icon></v-btn
                  >
                </v-col>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-col>
        <v-row class="mb-3 mx-3" justify="center"
          ><v-btn
            @click="addNextAuctionLot()"
            dark=""
            color="blue lighten-2"
            block
            ><v-icon>mdi-plus</v-icon></v-btn
          ></v-row
        >
      </v-col>
      <!-- page -->
      <template>
        <div class="text-center">
          <v-pagination
            v-model="nextLotPage"
            :length="nextLotLength"
            :total-visible="10"
          ></v-pagination>
        </div>
      </template>
    </v-card>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>

    <!-- part edit auction lot unit -->
    <v-dialog v-model="lotEdit.show" persistent max-width="600px">
      <v-card class="pa-2">
        <v-card class="pa-0 pt-2 mb-1">
          <v-row class="pa-0 ma-0">
            <v-col class="pa-2 pl-5 title" hide-details="auto"
              >Lot No - {{ lotEdit.itemDetails.id }}</v-col
            >
            <v-col class="font-weight-bold"
              >Session:
              <input style="" type="text" v-model="lotEdit.itemDetails.session"
            /></v-col>
          </v-row>
        </v-card>
        <v-card class="pa-2 mb-1">
          <v-row class="pa-1">
            <v-text-field
              v-model="lotEdit.itemDetails.regnNo"
              label="Regn No."
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
            <v-text-field
              v-model="lotEdit.itemDetails.make"
              label="Make"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
          </v-row>
          <v-row class="pa-1">
            <v-text-field
              v-model="lotEdit.itemDetails.descOfGood"
              label="Model"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
            <v-text-field
              v-model="lotEdit.itemDetails.ma"
              label="Transmission"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
          </v-row>
          <v-row class="pa-1">
            <v-text-field
              v-model="lotEdit.itemDetails.year"
              label="Year"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
            <v-text-field
              v-model="lotEdit.itemDetails.rc"
              label="Registration card"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
          </v-row>
          <v-row class="pa-1">
            <v-text-field
              v-model="lotEdit.itemDetails.remarks"
              label="FMS Reminder"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
            <v-text-field
              v-model="lotEdit.itemDetails.vendor"
              label="Vendor"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
          </v-row>
          <v-row class="pa-1">
            <v-text-field
              v-model="lotEdit.itemDetails.price"
              label="Reserve Price (RM)"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
            <v-text-field
              v-model="lotEdit.itemDetails.adminFee"
              label="Admin Fee (RM)"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
          </v-row>
          <v-row class="pa-1">
            <v-text-field
              v-model="lotEdit.itemDetails.deposit"
              label="Deposit (RM)"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
            <v-text-field
              v-model="lotEdit.itemDetails.safetyDeposit"
              label="Safety Deposit (RM)"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
          </v-row>
          <v-row class="pa-1">
            <v-text-field
              v-model="lotEdit.itemDetails.premium"
              label="Premium (RM or %)"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
            <v-text-field
              v-model="lotEdit.itemDetails.addPrice"
              label="Minimum Bid Price (RM)"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
          </v-row>
          <v-row class="pa-1">
            <v-text-field
              v-model="lotEdit.itemDetails.location"
              label="Location"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
            <v-text-field
              v-model="lotEdit.itemDetails.contactPerson"
              label="Contact Person"
              hide-details="auto"
              class="pa-1 ma-2"
            ></v-text-field>
          </v-row>
          <v-textarea
            class="ma-2"
            outlined
            label="Remark 1"
            v-model="lotEdit.itemDetails.remark1"
            rows="3"
            hide-details="auto"
          ></v-textarea>
          <v-textarea
            class="ma-2"
            outlined
            label="Remark 2"
            v-model="lotEdit.itemDetails.remark2"
            rows="3"
            hide-details="auto"
          ></v-textarea>
        </v-card>
        <v-row class="pa-0 ma-0" justify="end">
          <v-btn class="mx-1" @click="lotEdit.show = false" color="grey" dark
            >Close</v-btn
          >
          <v-btn
            v-if="!isNextView"
            @click="saveAuctionLot(lotEdit.itemDetails)"
            color="green"
            dark=""
            >save</v-btn
          >
          <v-btn
            v-if="isNextView"
            @click="saveNextAuctionLot(lotEdit.itemDetails)"
            color="green"
            dark=""
            >save</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <!-- part photo page -->
    <v-dialog v-model="photoPage.show" persistent max-width="600px">
      <v-card class="pa-2">
        <v-card class="pa-2 mb-1"
          ><div class="pa-1 title">Lot No - {{ photoPage.lotNo }}</div></v-card
        >
        <v-card class="pa-2">
          <v-row class="ma-2">
            <input
              type="file"
              ref="inputcover"
              style="display: none"
              accept="image/*"
              @change="showCoverInput()"
            />
            <input
              type="file"
              ref="inputfiles"
              style="display: none"
              accept="image/*"
              multiple
              @change="showPhotoInput()"
            />
            <v-btn @click="$refs.inputcover.click()" class="mr-2"
              >Upload Cover</v-btn
            >
            <v-btn @click="$refs.inputfiles.click()">Upload Photos</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="savePhotoUrlToHomepage()"
              v-if="saveToHomepage.show == true"
              small
              >Save to Homepage</v-btn
            >
            <v-btn
              @click="deletePhotoUrlFromHomepage()"
              v-if="saveToHomepage.remove == true"
              color="red"
              small
              >Remove from Homepage</v-btn
            >
          </v-row>
          <v-divider></v-divider>
          <v-row class="pa-1 ma-1">
            <!--show cover photo -->
            <v-col
              v-if="photoPage.coverFile.file != null"
              class="pa-1 ma-0"
              cols="3"
            >
              <v-img
                max-height="200"
                :src="photoPage.coverFile.url"
                :lazy-src="photoPage.coverFile.url"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <v-row justify="end" class="ma-1">
                  <v-btn color="rgba(255, 255, 255, 0.6)" dark fab x-small>
                    <v-icon @click="photoPage.coverFile = {}" color="#560000b0"
                      >mdi-close-thick</v-icon
                    >
                  </v-btn>
                </v-row>
                <v-row
                  justify="center"
                  class="ma-0 pa-0 display-1 font-italic font-weight-medium white--text"
                  >Cover</v-row
                >
              </v-img>
            </v-col>

            <!-- show uploaded photos -->
            <v-col
              v-for="(fileName, i) in photoPage.photos"
              :key="i"
              class="pa-1 ma-0"
              cols="3"
            >
              <v-card
                :elevation="
                  checkElevation(
                    '/image/auctionlot/' + photoPage.regnNo + '/' + fileName
                  )
                "
              >
                <v-img
                  max-height="200"
                  @click="
                    checkSaveToHomepage(
                      '/image/auctionlot/' + photoPage.regnNo + '/' + fileName
                    )
                  "
                  :src="
                    serverUrl +
                    '/api/file/image/auctionlot/' +
                    photoPage.regnNo +
                    '/' +
                    fileName
                  "
                  :lazy-src="
                    serverUrl +
                    '/api/file/image/auctionlot/' +
                    photoPage.regnNo +
                    '/' +
                    fileName
                  "
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <v-row justify="end" class="ma-1">
                    <v-btn
                      @click="deletePhoto(fileName, photoPage.regnNo, i)"
                      color="rgba(255, 255, 255, 0.6)"
                      dark
                      fab
                      x-small
                    >
                      <v-icon color="#560000b0">mdi-delete</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row
                    v-if="i == 0 && photoPage.coverFile.file == null"
                    justify="center"
                    class="ma-0 pa-0 display-1 font-italic font-weight-medium white--text"
                    >Cover</v-row
                  >
                </v-img></v-card
              >
            </v-col>

            <!-- show files to be uploaded -->
            <v-col
              v-for="(info, i) in photoPage.files"
              :key="i"
              class="pa-1 ma-0"
              cols="3"
            >
              <v-img max-height="200" :src="info.url" :lazy-src="info.url">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <v-row justify="end" class="ma-1">
                  <v-btn
                    @click="clearPhoto(i)"
                    color="rgba(255, 255, 255, 0.6)"
                    dark
                    fab
                    x-small
                  >
                    <v-icon color="#560000b0">mdi-close-thick</v-icon>
                  </v-btn>
                </v-row>
              </v-img>
            </v-col>
          </v-row>
        </v-card>
        <v-row class="ma-0 pa-0 mt-2">
          <v-spacer></v-spacer>
          <v-btn class="mx-1" @click="hidePhotoPage()" color="grey" dark
            >Close</v-btn
          >
          <v-btn @click="saveAuctionLotPhotos()" color="green" dark>Save</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
import storage from "../jscontroller/storage";
import authorization from "../jscontroller/authorization";
export default {
  name: "AuctionManager",
  data: () => ({
    user: {},
    serverUrl: serverConfig.hostname,
    lots: [],
    nextLots: [],
    lotPage: 1,
    nextLotPage: 1,
    auctionLotPerPage: 10,
    isNextView: false,
    lotLength: 1,
    nextLotLength: 1,
    sessions: [],
    snackbar: {
      showProgress: false,
      showStatus: false,
      text: "",
      timeout: 3000,
      icon: "mdi-check-circle-outline",
      color: "green",
    },
    photoPage: {
      lotNo: 0,
      regnNo: "",
      session: "",
      show: false,
      photos: [],
      files: [],
      coverFile: {},
      noOfPhotos: 0,
      photosUploaded: 0,
    },
    saveToHomepage: {
      show: false,
      imageUrl: "",
      remove: false,
    },
    lotEdit: {
      show: false,
      itemDetails: {},
    },
    progress: { session: false },
    classStyle: { session: "green--text font-weight-bold" },
    message: { session: "" },
    endSession: [
      {
        disabled: true,
      },
    ],
    showEndSession: {
      show: false,
      currentSession: "",
      column: 0,
    },
    outcomeManager: {
      show: false,
      value: "",
      process: "",
      message: "",
      session: "",
    },
    csv: {
      showBtn: false,
      showBtn2: false,
      show: false,
      auctionLotList: [],
      isNextView: false,
    },
    clearLot: {
      show: false,
      value: "",
    },
  }),
  created() {},
  mounted() {
    this.user = storage.GetUser();
    if (!authorization.checkSuperAdminAuthorization(this.user)) {
      storage.switchNavBar("website");
      this.$router.push("/");
      this.$router.go();
    }
    this.scrollToTop();
    this.getAuctionLotsByPage(this.auctionLotPerPage);
    this.getSessions();
    this.getAuctionLotsCount();
    this.getViewAuctionLotsByPage(this.auctionLotPerPage);
    this.getNextAuctionLotsCount();
  },
  methods: {
    ringgitFormat(amount) {
      if (amount == null) {
        return "RM " + (0).toFixed(2);
      } else {
        var num = amount.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        });
        var deci = num.split(".")[1];
        if (deci != null) {
          if (deci.length == 1) {
            num = num + "0";
          }
        } else {
          num = num + ".00";
        }
        return "RM " + num;
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getAuctionLotsByPage(noOfContents, callback) {
      var self = this;
      var currentPage = self.lotPage - 1;
      if (currentPage < 0) {
        currentPage = 0;
      }
      var apiUrl =
        serverConfig.hostname +
        "/api/auctionlot/getbypage/" +
        currentPage +
        "/" +
        noOfContents;
      axios
        .get(apiUrl)
        .then(function (response) {
          self.lots = response.data;
          callback();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    getViewAuctionLotsByPage(noOfContents, callback) {
      var self = this;
      var currentPage = self.nextLotPage - 1;
      if (currentPage < 0) {
        currentPage = 0;
      }
      var apiUrl =
        serverConfig.hostname +
        "/api/nextauctionlot/getbypage/" +
        currentPage +
        "/" +
        noOfContents;
      axios
        .get(apiUrl)
        .then(function (response) {
          self.nextLots = response.data;
          callback();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    getSessions() {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/auctionmanager/sessions";
      axios
        .get(apiUrl)
        .then(function (response) {
          self.sessions = response.data;
          self.endSession = [];
          for (var i = 0; i < self.sessions.length; i++) {
            self.endSession.push({ disabled: true });
          }
          setInterval(() => {
            for (var i = 0; i < self.sessions.length; i++) {
              self.countdown(self.sessions[i], i);
            }
          }, 1000);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    getAuctionLotsCount(callback) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/auctionlot/count";
      axios
        .get(apiUrl)
        .then(function (response) {
          var tempTotal = parseInt(response.data) / self.auctionLotPerPage;
          if (tempTotal > tempTotal.toFixed(0)) {
            tempTotal = parseInt(tempTotal.toFixed(0)) + 1;
          } else {
            tempTotal = parseInt(tempTotal.toFixed(0));
          }
          self.lotLength = tempTotal;
          callback();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    getNextAuctionLotsCount(callback) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/nextauctionlot/count";
      axios
        .get(apiUrl)
        .then(function (response) {
          var tempTotal = parseInt(response.data) / self.auctionLotPerPage;
          if (tempTotal > tempTotal.toFixed(0)) {
            tempTotal = parseInt(tempTotal.toFixed(0)) + 1;
          } else {
            tempTotal = parseInt(tempTotal.toFixed(0));
          }
          self.nextLotLength = tempTotal;
          callback();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    saveAuctionSessions() {
      this.progress.session = true;
      var self = this;
      const token = self.user.jwt;

      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/auctionmanager/sessions/save";
      for (var i = 0; i < self.sessions; i++) {
        self.sessions[i].id = i + 1;
      }
      axios
        .post(apiUrl, self.sessions, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          self.message.session = response.data;
          self.classStyle.session = "green--text font-weight-bold";
          self.progress.session = false;
          self.hideSnackbar(response.data, "green");
          self.getAuctionLotsByPage(self.auctionLotPerPage);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
          self.message.session = "Save Auction Sessions failed.";
          self.classStyle.session = "red--text font-weight-bold";
          self.progress.session = false;
          self.hideSnackbar("Save Auction Sessions failed.", "red");
        });
    },
    logout() {
      storage.clearUserStorage();
      storage.switchNavBar("website");
      this.$router.push("/");
      this.$router.go();
    },
    saveAuctionLotPhotos() {
      var self = this;
      self.photoPage.photosUploaded = 0;
      if (self.photoPage.coverFile.file != null)
        self.uploadAuctionLotPhotosQuery(
          self.photoPage.coverFile.file,
          self.photoPage.regnNo,
          "0.jpg"
        );
      for (var i = 0; i < self.photoPage.files.length; i++) {
        var temp = self.photoPage.files[i].file;
        // var fileNo = self.photoPage.noOfPhotos + i + 1;
        self.uploadAuctionLotPhotosQuery(
          temp,
          self.photoPage.regnNo,
          temp.name
        );
      }
      self.photoPage.files = [];
      self.photoPage.coverFile = {};
    },
    countAuctionLotPhotosQuery(regnNo) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/file/countfiles";
      var bodyFormData = new FormData();
      bodyFormData.set("folderName", regnNo);
      bodyFormData.set("folderName2", "auctionlot");
      bodyFormData.set("folderType", "image");
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          self.photoPage.noOfPhotos = response.data;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    getAuctionLotFileNamesQuery(regnNo) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/file/filenames";
      var bodyFormData = new FormData();
      bodyFormData.set("folderName", regnNo);
      bodyFormData.set("folderName2", "auctionlot");
      bodyFormData.set("folderType", "image");
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          var tempPhotos = response.data;
          self.photoPage.photos = [];
          for (var i = 0; i < tempPhotos.length; i++) {
            if (tempPhotos[i] == "0" || tempPhotos[i] == "0.jpg")
              self.photoPage.photos.unshift(tempPhotos[i]);
            else self.photoPage.photos.push(tempPhotos[i]);
          }
          // eslint-disable-next-line no-console
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function () {
          self.photoPage.photos = [];
        });
    },
    uploadAuctionLotPhotosQuery(file, regnNo, name) {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/file/upload/photo";
      var bodyFormData = new FormData();
      bodyFormData.set("file", file);
      bodyFormData.set("size", file.size);
      bodyFormData.set("folderName", "auctionlot");
      bodyFormData.set("folderName2", regnNo);
      bodyFormData.set("folderType", "image");
      bodyFormData.set("fileName", name);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          self.hideSnackbar(response.data, "green");
          self.photoPage.photosUploaded = self.photoPage.photosUploaded + 1;
          self.getAuctionLotFileNamesQuery(regnNo);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Failed to save photo.", "red");
        });
    },
    editLot(itemDetails, isNext) {
      var self = this;
      self.lotEdit.itemDetails = itemDetails;
      self.lotEdit.show = true;
      self.isNextView = isNext;
    },
    saveAuctionLot(body) {
      var self = this;
      self.showSnackbar();
      const token = self.user.jwt;
      var apiUrl = serverConfig.hostname + "/api/auctionlot/post";
      axios
        .post(apiUrl, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          self.hideSnackbar(response.data, "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    saveNextAuctionLot(body) {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/nextauctionlot/post";
      const token = self.user.jwt;
      axios
        .post(apiUrl, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          self.hideSnackbar(response.data, "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    addSession() {
      var self = this;
      var apiUrl =
        serverConfig.hostname + "/api/auctionmanager/session/nextname";
      axios
        .get(apiUrl)
        .then(function (response) {
          self.endSession.push({ disabled: true });
          self.sessions.push({ id: response.data });
        })
        .catch(function () {});
    },
    addAuctionLot() {
      var self = this;
      self.lotPage = self.lotLength;
      self.getAuctionLotsCount(function () {
        self.getAuctionLotsByPage(self.auctionLotPerPage, function () {
          var nextSessionNo = 1;
          if (self.lots.length - 1 > -1) {
            nextSessionNo = self.lots[self.lots.length - 1].id + 1;
          }
          self.lots.push({ id: nextSessionNo });
        });
      });
    },
    addNextAuctionLot() {
      var self = this;
      self.nextLotPage = self.nextLotLength;
      self.getNextAuctionLotsCount(function () {
        self.getViewAuctionLotsByPage(self.auctionLotPerPage, function () {
          var nextSessionNo = 1;
          if (self.nextLots.length - 1 > -1) {
            nextSessionNo = self.nextLots[self.nextLots.length - 1].id + 1;
          }
          self.nextLots.push({ id: nextSessionNo });
        });
      });
    },
    deleteSession(id, index) {
      var self = this;
      self.showSnackbar();
      var apiUrl =
        serverConfig.hostname + "/api/auctionmanager/deletecolumn/" + id;
      const token = self.user.jwt;
      self.sessions.splice(index, 1);
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          self.message.session = response.data;
          self.classStyle.session = "orange--text font-weight-bold";
          self.progress.session = false;
          self.hideSnackbar(response.data, "orange");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
          self.message.session = "Delete Session no " + id + " failed.";
          self.classStyle.session = "red--text font-weight-bold";
          self.progress.session = false;
          self.hideSnackbar("Delete Session no " + id + " failed.", "red");
        });
    },
    deleteLot(id, index) {
      var self = this;
      self.showSnackbar();
      const token = self.user.jwt;
      var apiUrl = serverConfig.hostname + "/api/auctionlot/deletecolumn/" + id;
      self.lots.splice(index, 1);
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          self.hideSnackbar(response.data, "orange");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Delete Auction Lot no " + id + " failed.", "red");
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    deleteNextLot(id, index) {
      var self = this;
      self.showSnackbar();
      const token = self.user.jwt;
      var apiUrl =
        serverConfig.hostname + "/api/nextauctionlot/deletecolumn/" + id;
      self.nextLots.splice(index, 1);
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          self.hideSnackbar(response.data, "orange");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Delete Auction Lot no " + id + " failed.", "red");
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    clearAllLot() {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/auctionlot/delete/all";
      const token = self.user.jwt;
      if (self.clearLot.value == "clear all lot") {
        axios
          .get(apiUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(function () {
            self.lots = [];
            self.hideSnackbar("Auction lot Cleared", "green");
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            self.hideSnackbar("Clearing all lot failed", "red");
            if (error.response.status == 401) {
              alert(error.response.data.message);
              self.logout();
            }
          });
      } else {
        self.hideSnackbar("Command not available", "red");
      }
    },
    clearAllLotNext() {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/nextauctionlot/delete/all";
      const token = self.user.jwt;
      if (self.clearLot.value == "clear") {
        axios
          .get(apiUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(function () {
            self.nextLots = [];
            self.hideSnackbar("Auction lot Cleared", "green");
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            self.hideSnackbar("Clearing failed", "red");
            if (error.response.status == 401) {
              alert(error.response.data.message);
              self.logout();
            }
          });
      } else {
        self.hideSnackbar("Command not available", "red");
      }
    },
    deletePhoto(fileName, lotNo, index) {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/file/delete";
      var bodyFormData = new FormData();
      var url = "/image/auctionlot/" + lotNo + "/" + fileName;
      self.deletePhotoUrlFromHomepage(url);
      bodyFormData.set("url", url);
      axios
        .post(apiUrl, bodyFormData)
        // eslint-disable-next-line no-unused-vars
        .then(function (response) {
          self.photoPage.photos.splice(index, 1);
          self.hideSnackbar(response.data, "orange");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Delete photo failed.", "red");
        });
    },
    clearPhoto(index) {
      var self = this;
      self.photoPage.files.splice(index, 1);
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
    showPhotoPage(lotNo, regnNo, session) {
      var self = this;
      self.photoPage.show = true;
      self.photoPage.lotNo = lotNo;
      self.photoPage.regnNo = regnNo;
      self.photoPage.session = session;
      self.photoPage.photos = [];
      self.getAuctionLotFileNamesQuery(regnNo);
      // self.countAuctionLotPhotosQuery(lotNo);
    },
    hidePhotoPage() {
      var self = this;
      self.photoPage.show = false;
      self.photoPage.files = [];
      self.photoPage.coverFile = {};
      self.saveToHomepage.imageUrl = "";
      self.saveToHomepage.show = false;
      self.saveToHomepage.remove = false;
    },
    showPhotoInput() {
      var self = this;
      var tempPhotos = this.$refs.inputfiles.files;
      for (var i = 0; i < tempPhotos.length; i++) {
        self.fileReader(tempPhotos[i]);
      }
    },
    showCoverInput() {
      var self = this;
      var tempPhoto = this.$refs.inputcover.files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        self.photoPage.coverFile = { file: tempPhoto, url: e.target.result };
      };
      reader.readAsDataURL(tempPhoto); // convert to base64 string
    },
    fileReader(file) {
      var self = this;
      var reader = new FileReader();
      reader.onload = function (e) {
        // $('#blah').attr('src', e.target.result);
        self.photoPage.files.push({ file: file, url: e.target.result });
      };
      reader.readAsDataURL(file); // convert to base64 string
    },
    csvReader() {
      var self = this;
      var file = self.$refs.csvFile.files[0];
      if (file == null) self.csv.showBtn = false;
      var auctionLots = [];
      var reader = new FileReader();
      reader.onload = function () {
        var lotLines = reader.result.split("\n");
        var variables = lotLines[0].split(",");
        for (var i = 1; i < lotLines.length; i++) {
          var arrStr1 = lotLines[i].split('"');
          var line = lotLines[i].split('"')[0];
          var j = 1;
          while (j < arrStr1.length) {
            // change ',' to '&&&'
            line = line + arrStr1[j].replace(/,/g, "&&&");
            if (arrStr1[j + 1] != "") line = line + arrStr1[j + 1];
            j = j + 2;
          }
          var dataArray = line.split(",");
          var objData = {};
          for (var x = 0; x < dataArray.length; x++) {
            // change '&&&' back to ','
            // put data into object
            objData[variables[x].replace(/\r/g, "")] = dataArray[x].replace(
              /&&&/g,
              ","
            );
          }
          auctionLots.push(objData);
        }

        self.csv.auctionLotList = auctionLots;
        self.csv.showBtn = true;
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(file);
    },
    csvReader2() {
      var self = this;
      var file = self.$refs.csvFile2.files[0];
      if (file == null) self.csv.showBtn2 = false;
      var auctionLots = [];
      var reader = new FileReader();
      reader.onload = function () {
        var lotLines = reader.result.split("\n");
        var variables = lotLines[0].split(",");
        for (var i = 1; i < lotLines.length; i++) {
          var arrStr1 = lotLines[i].split('"');
          var line = lotLines[i].split('"')[0];
          var j = 1;
          while (j < arrStr1.length) {
            // change ',' to '&&&'
            line = line + arrStr1[j].replace(/,/g, "&&&");
            if (arrStr1[j + 1] != "") line = line + arrStr1[j + 1];
            j = j + 2;
          }
          var dataArray = line.split(",");
          var objData = {};
          for (var x = 0; x < dataArray.length; x++) {
            // change '&&&' back to ','
            // put data into object
            objData[variables[x].replace(/\r/g, "")] = dataArray[x].replace(
              /&&&/g,
              ","
            );
          }
          auctionLots.push(objData);
        }
        self.csv.auctionLotList = auctionLots;
        self.csv.showBtn2 = true;
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(file);
    },
    saveAllAuctionLots() {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/auctionlot/save/multiplelot";
      const token = self.user.jwt;
      var body = self.csv.auctionLotList;
      axios
        .post(apiUrl, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          self.hideSnackbar(response.data, "green");
          self.getAuctionLotsByPage(self.auctionLotPerPage);
          self.getAuctionLotsCount();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Saving failed", "red");
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
      self.csv.show = false;
      self.csv.showBtn = false;
    },
    saveAllNextAuctionLots() {
      var self = this;
      self.showSnackbar();
      var apiUrl =
        serverConfig.hostname + "/api/nextauctionlot/save/multiplelot";
      const token = self.user.jwt;
      var body = self.csv.auctionLotList;
      axios
        .post(apiUrl, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          self.hideSnackbar(response.data, "green");
          self.getViewAuctionLotsByPage(self.auctionLotPerPage);
          self.getNextAuctionLotsCount();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Saving failed", "red");
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
      self.csv.show = false;
      self.csv.showBtn2 = false;
    },
    checkSaveToHomepage(imageUrl) {
      var self = this;
      self.saveToHomepage.imageUrl = imageUrl;
      var apiUrl =
        serverConfig.hostname + "/api/admin/homepagephotos/getbyimageurl";
      var bodyFormData = new FormData();
      bodyFormData.set("imageUrl", imageUrl);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          if (response.data != "") {
            self.saveToHomepage.remove = true;
            self.saveToHomepage.show = false;
          } else {
            self.saveToHomepage.show = true;
            self.saveToHomepage.remove = false;
          }
        })
        .catch(function () {});
    },
    savePhotoUrlToHomepage() {
      var self = this;
      self.showSnackbar();
      const imageUrl = self.saveToHomepage.imageUrl;
      const session = self.photoPage.session;
      var apiUrl = serverConfig.hostname + "/api/admin/savelotphototohomepage";
      var bodyFormData = new FormData();
      bodyFormData.set("imageUrl", imageUrl);
      bodyFormData.set("session", session);
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function () {
          self.saveToHomepage.remove = true;
          self.saveToHomepage.show = false;
          self.hideSnackbar("Photo saved to Homepage", "green");
        })
        .catch(function () {});
    },
    deletePhotoUrlFromHomepage() {
      var self = this;
      self.showSnackbar();
      var imageUrl = self.saveToHomepage.imageUrl;
      var apiUrl =
        serverConfig.hostname + "/api/admin/homepagephotos/photourl/delete";
      var bodyFormData = new FormData();
      bodyFormData.set("imageUrl", imageUrl);
      axios
        .post(apiUrl, bodyFormData)
        .then(function () {
          self.saveToHomepage.remove = false;
          self.saveToHomepage.show = true;
          self.hideSnackbar("Photo removed from Homepage", "orange");
        })
        .catch(function () {});
    },
    endSessionQuery(index) {
      var self = this;
      // eslint-disable-next-line no-console
      console.log(self.sessions[index]);
      var tempSession = self.sessions[index];
      self.showSnackbar();
      self.showEndSession.show = false;
      var apiUrl =
        serverConfig.hostname + "/api/auctionmanager/session/saveinhistory";
      const token = self.user.jwt;
      var body = {
        session: tempSession.id,
        startDate: tempSession.startDate,
        endDate: tempSession.endDate,
        startTime: tempSession.startTime,
        endTime: tempSession.endTime,
        publicOrPrivate: tempSession.publicOrPrivate,
      };
      axios
        .post(apiUrl, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          if (response.data == "Auction Sessions Succesfully Saved") {
            self.hideSnackbar(response.data, "green");
            self.getSessions();
          } else self.hideSnackbar("Failed to end auction", "orange");
        })
        .catch(function (error) {
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    getLatestSessionHistory() {
      var self = this;
      self.showSnackbar();
      var apiUrl =
        serverConfig.hostname + "/api/auctionmanager/get/sessionhistory/latest";
      axios
        .get(apiUrl)
        .then(function (response) {
          self.hideSnackbar("Latest Auction was " + response.data, "green");
          self.outcomeManager.session = response.data;
        })
        .catch(function () {});
    },
    runEndAuctionProcess() {
      var self = this;
      if (self.outcomeManager.session == "")
        self.hideSnackbar("Select an Auction session to process.", "red");
      else {
        if (self.outcomeManager.value == "record winning lot")
          self.recordDataQuery(self.outcomeManager.session);
        else if (self.outcomeManager.value == "balance out deposit")
          self.balanceOutDepositQuery(self.outcomeManager.session);
        else if (self.outcomeManager.value == "clear running auction data")
          self.clearDataQuery(self.outcomeManager.session);
        else if (self.outcomeManager.value == "send email to auction winner")
          self.sendEmailQuery(self.outcomeManager.session);
        else self.hideSnackbar("wrong command input", "red");
      }
    },
    recordDataQuery(session) {
      var self = this;
      self.showSnackbar();
      var apiUrl =
        serverConfig.hostname +
        "/api/update/emergency/auctionlotsocket/to/mybid";
      const token = self.user.jwt;
      var bodyFormData = new FormData();
      bodyFormData.set("session", session);
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function () {
          self.hideSnackbar("Sales successfully Recorded", "green");
          self.outcomeManager.show = false;
          self.outcomeManager.value = "";
        })
        .catch(function (error) {
          self.hideSnackbar("Failed to record sales", "red");
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    balanceOutDepositQuery(session) {
      var self = this;
      self.showSnackbar();
      var apiUrl =
        serverConfig.hostname + "/api/update/emergency/balanceoutdeposit";
      const token = self.user.jwt;
      var bodyFormData = new FormData();

      bodyFormData.set("session", session);
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function () {
          self.hideSnackbar("Deposits successfully deducted", "green");
          self.outcomeManager.show = false;
          self.outcomeManager.value = "";
        })
        .catch(function (error) {
          self.hideSnackbar("Failed deduct balance", "red");
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    sendEmailQuery(session) {
      var self = this;
      self.showSnackbar();
      var apiUrl =
        serverConfig.hostname + "/api/update/emergency/send/emailtowinners";
      var bodyFormData = new FormData();
      const token = self.user.jwt;

      bodyFormData.set("session", session);
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function () {
          self.hideSnackbar("Emails successfully sent", "green");
          self.outcomeManager.show = false;
          self.outcomeManager.value = "";
        })
        .catch(function (error) {
          self.hideSnackbar("Failed to send emails", "red");
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    clearDataQuery(session) {
      var self = this;
      self.showSnackbar();
      var apiUrl =
        serverConfig.hostname + "/api/update/emergency/clearauctiondata";
      const token = self.user.jwt;

      var bodyFormData = new FormData();
      bodyFormData.set("session", session);
      axios
        .post(apiUrl, bodyFormData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function () {
          self.hideSnackbar("Auction data successfully cleared", "green");
          self.outcomeManager.show = false;
          self.outcomeManager.value = "";
        })
        .catch(function (error) {
          self.hideSnackbar("Failed to clear auction data", "red");
          if (error.response.status == 401) {
            alert(error.response.data.message);
            self.logout();
          }
        });
    },
    countdown(session, index) {
      var self = this;
      var countDownEnd = new Date(
        `${session.endDate} ${session.endTime} +8:00`
      ).getTime();
      var countDownStart = new Date(
        `${session.startDate} ${session.startTime} +8:00`
      ).getTime();
      // var timeLeft = "";
      var now = new Date().getTime();
      if (now < countDownStart && now < countDownEnd) {
        self.endSession[index].disabled = true;
      } else if (now > countDownStart && now < countDownEnd) {
        self.endSession[index].disabled = true;
      } else if (now > countDownStart && now > countDownEnd) {
        self.endSession[index].disabled = false;
      }

      // Find the distance between now and the count down date
      // var distance = countDownEnd - now;
      // Time calculations for days, hours, minutes and seconds
      // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      // var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      // var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      // var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // timeLeft = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
    },
    checkElevation(imageUrl) {
      var self = this;
      if (imageUrl == self.saveToHomepage.imageUrl) return 15;
      else return 1;
    },
  },
  watch: {
    lotPage: function () {
      this.getAuctionLotsByPage(this.auctionLotPerPage);
    },
    nextLotPage: function () {
      this.getViewAuctionLotsByPage(this.auctionLotPerPage);
    },
  },
};
</script>
