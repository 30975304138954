<template>
  <div>
    <v-tabs
      v-if="storageUser.role == 'admin' || storageUser.role == 'vendor'"
      v-model="tab"
      background-color="rgb(15, 106, 210)"
      dark
      color=""
      show-arrows=""
    >
      <v-btn text class="ml-5 mt-2 title" @click="gotoWebsite()">Website</v-btn>
      <v-divider class="mx-5" inset vertical></v-divider>
      <v-tab v-for="(page, i) in pages" :key="i" @click="navigate(page.path)">{{
        page.name
      }}</v-tab>
      <!-- <v-spacer></v-spacer> -->
      <!-- <v-btn @click="logout()" color="" class="ma-1" v-if="checkLoggedIn()">Logout</v-btn> -->
    </v-tabs>
  </div>
</template>

<script>
import storage from "../jscontroller/storage";
import authorization from "../jscontroller/authorization";

export default {
  props: ["storageUser"],
  data: () => ({
    pages: [],
    tab: null,
  }),
  mounted() {
    this.pagesAvailable();
    this.highlightTab();
  },
  methods: {
    navigate: function (pagePath) {
      if (this.$route.path != pagePath) {
        this.$router.push(pagePath);
      }
    },
    highlightTab() {
      var self = this;
      var currentPage = this.$route.path;
      // eslint-disable-next-line no-console
      console.log(currentPage);
      if (authorization.checkSuperAdminAuthorization(self.storageUser)) {
        if (currentPage.includes("/mailing")) self.tab = 1;
        if (currentPage.includes("/auctionManager")) self.tab = 2;
        else if (currentPage.includes("/userManager")) self.tab = 3;
        else if (currentPage.includes("/documentManager")) self.tab = 4;
        else if (currentPage.includes("/unitMonitor")) self.tab = 5;
        else if (currentPage.includes("/reports")) self.tab = 6;
        else if (currentPage.includes("/itemRecords")) self.tab = 7;
      } else if (authorization.checkAdminAuthorization(self.storageUser)) {
        if (currentPage.includes("/userManager")) self.tab = 0;
        else if (currentPage.includes("/unitMonitor")) self.tab = 1;
        else if (currentPage.includes("/reports")) self.tab = 2;
        else if (currentPage.includes("/itemRecords")) self.tab = 3;
      } else if (authorization.checkVendorAuthorization(self.storageUser)) {
        // if (currentPage.includes("/userManager")) self.tab = 0;
        if (currentPage.includes("/unitMonitor")) self.tab = 0;
        else if (currentPage.includes("/reports")) self.tab = 1;
        else if (currentPage.includes("/itemRecords")) self.tab = 2;
      }
    },
    pagesAvailable() {
      var self = this;
      if (authorization.checkSuperAdminAuthorization(self.storageUser)) {
        self.pages = [
          { name: "Homepage Manager", path: "/adminPage" },
          { name: "Mailing", path: "/adminPage/mailing" },
          { name: "Auction Manager", path: "/adminPage/auctionManager" },
          { name: "User Manager", path: "/adminPage/userManager" },
          { name: "Document Manager", path: "/adminPage/documentManager" },
          { name: "Unit Monitor", path: "/adminPage/unitMonitor" },
          { name: "Reports", path: "/adminPage/reports" },
          { name: "Item Records", path: "/adminPage/itemRecords" },
        ];
      } else if (authorization.checkAdminAuthorization(self.storageUser)) {
        self.pages = [
          { name: "User Manager", path: "/adminPage/userManager" },
          { name: "Unit Monitor", path: "/adminPage/unitMonitor" },
          { name: "Reports", path: "/adminPage/reports" },
          { name: "Item Records", path: "/adminPage/itemRecords" },
        ];
      } else if (authorization.checkVendorAuthorization(self.storageUser)) {
        self.pages = [
          // { name: "User Manager", path: "/adminPage/userManager" },
          { name: "Unit Monitor", path: "/adminPage/unitMonitor" },
          { name: "Reports", path: "/adminPage/reports" },
          { name: "Item Records", path: "/adminPage/itemRecords" },
        ];
      }
    },
    gotoWebsite() {
      storage.switchNavBar("website");
      this.navigate("/");
      this.$router.go();
    },
    checkLoggedIn() {
      var bool = false;
      var self = this;
      if (self.storageUser != null) {
        if (self.storageUser.email != "") {
          bool = true;
        }
      }
      return bool;
    },
    logout() {
      storage.switchNavBar("website");
      storage.SetUser("", "", "", "");
      this.$router.push("/");
      this.$router.go();
    },
  },
};
</script>
