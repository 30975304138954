<template>
  <div class="text-center mb-5">
    <v-snackbar class="mb-5" v-model="alert.showLoading">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar class="mb-5" v-model="alert.showStatus" :timeout="timeout">
      <v-row justify="center">
        <v-col class="title">
          {{ alert.message }}
        </v-col>
        <v-icon v-if="alert.isSuccess" color="green" large="" class="mx-5"
          >mdi-check-circle-outline</v-icon
        >
        <v-icon v-if="!alert.isSuccess" color="red" large="" class="mx-5"
          >mdi-alert-circle-outline</v-icon
        >
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
// import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
export default {
  props: ["alert"],
  // alert: {
  //     showLoading: false,
  //     showStatus: false,
  //     message: "",
  //     isSuccess: true,
  //   },
  data: () => ({
    serverUrl: serverConfig.hostname,
    timeout: 6000,
    icon: "mdi-check-circle-outline",
    color: "green",
  }),
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
