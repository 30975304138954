<template>
  <div class="text-center mb-5">
    <v-btn color="green" dark @click="show.bidderModel = true"
      >Create Instance Bidder</v-btn
    >

    <v-dialog v-model="show.bidderModel" max-width="600px">
      <v-card class="pa-2 mb-1">
        <v-row class="pa-2 ma-0">
          <h3>Create Instant Bidder</h3>
        </v-row>
        <v-col cols="12" sm="12" md="12">
          <v-row class="pa-0 ma-0">
            <v-select
              class="pa-0"
              filled
              dense=""
              v-model="bidder.accountType"
              :items="['Individual', 'Organization']"
              hide-details="auto"
              label="Choose your account type.."
            >
            </v-select>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-row class="pa-0 ma-0">
            <v-text-field
              prepend-inner-icon="mdi-account"
              class="pa-0"
              filled
              dense=""
              v-model="bidder.firstName"
              hide-details="auto"
              label="Full Name/Organization Name"
            >
            </v-text-field>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-row class="pa-0 ma-0">
            <v-text-field
              prepend-inner-icon="mdi-card-account-details"
              class="pa-0"
              filled
              dense=""
              v-model="bidder.ic"
              hide-details="auto"
              label="IC No/SSM No (e.g. 9xxxxx1x1xxx/1xxxxxxW)"
            >
            </v-text-field>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-row class="pa-0 ma-0">
            <v-text-field
              prepend-inner-icon="mdi-phone"
              class="pa-0"
              filled
              dense=""
              v-model="bidder.phoneNo"
              label="Phone Number (e.g. +601xxxxxxxx)"
              type="tel"
              hide-details="auto"
            >
            </v-text-field>
          </v-row>
        </v-col>

        <v-col
          v-if="bidder.accountType == 'Organization'"
          cols="12"
          sm="12"
          md="12"
        >
          <v-row class="pa-0 ma-0">
            <v-text-field
              prepend-inner-icon="mdi-account"
              class="pa-0"
              filled
              dense=""
              v-model="bidder.representativeName"
              hide-details="auto"
              label="Representative Name"
            >
            </v-text-field>
          </v-row>
        </v-col>

        <v-col
          v-if="bidder.accountType == 'Organization'"
          cols="12"
          sm="12"
          md="12"
        >
          <v-row class="pa-0 ma-0">
            <v-text-field
              prepend-inner-icon="mdi-card-account-details"
              class="pa-0"
              filled
              dense=""
              v-model="bidder.representativeIc"
              hide-details="auto"
              label="Representative IC No (e.g. 9xxxxx1x1xxx)"
            >
            </v-text-field>
          </v-row>
        </v-col>

        <div class="text-center">
          <v-btn color="green" dark @click="createInstanceBidder()"
            >Create</v-btn
          >
        </div>
      </v-card>
    </v-dialog>

    <SBAlert :alert="alert"></SBAlert>
  </div>
</template>

<script>
// import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
import storage from "../jscontroller/storage";
import userApi from "../api/userApi";
import SBAlert from "@/components/SBAlert";
export default {
  components: { SBAlert },
  data: () => ({
    serverUrl: serverConfig.hostname,
    bidder: {
      firstName: "",
      ic: "",
      phoneNo: "",
      accountType: "Individual",
      representativeName: "",
      representativeIc: "",
    },
    show: {
      bidderModel: false,
    },
    alert: {
      showLoading: false,
      showStatus: false,
      isSuccess: true,
      message: "",
    },
  }),
  created() {},
  mounted() {
    this.user = storage.GetUser();
  },
  methods: {
    async createInstanceBidder() {
      const self = this;
      try {
        self.alert.showLoading = true;
        const response = await userApi.createInstanceBidder(self.bidder);
        self.alert.showLoading = false;
        self.alert.isSuccess = true;
        self.alert.showStatus = true;
        self.alert.message = response.message;

        self.show.bidderModel = false;
        self.bidder = {
          firstName: "",
          ic: "",
          phoneNo: "",
          accountType: "Individual",
          representativeName: "",
          representativeIc: "",
        };
      } catch (error) {
        self.alert.showLoading = false;
        self.alert.isSuccess = false;
        self.alert.showStatus = true;
        self.alert.message = error;
      }
    },
  },
  watch: {},
};
</script>
