<template> 

       <v-tabs v-model="tab" background-color="#d26e0f" dark color="" align="center">
           <v-btn v-if="checkRole()" text class="ml-5 mt-2 title" @click="gotoAdmin()">Admin</v-btn>
            <v-divider v-if="checkRole()"
            class="mx-5"
            inset
            vertical
            ></v-divider>
            <v-tab  v-for="(page, i) in pages" :key="i" @click="navigate(page.path)">{{page.name}}</v-tab>            
            
            <v-menu offset-y dark>
                <template v-slot:activator="{ on }">
                    <v-tab v-on="on">
                        <span>Help</span> 
                        <v-icon right>mdi-menu-down</v-icon>               
                    </v-tab>
                </template>
                <v-list>
                    <!-- <v-list-item @click="navigate('/help/faq')">
                        FAQ
                    </v-list-item> -->
                    <v-list-item @click="navigate('/help/termsAndCondition')">
                        Terms & Condition
                    </v-list-item>
                    <v-list-item @click="navigate('/help/bidderProcess')">
                        Bidder Process
                    </v-list-item>
                    <v-list-item @click="navigate('/help/onlinePayment')">
                        Online Payment
                    </v-list-item>
                    <v-list-item @click="navigate('/help/tutorial')">
                        Tutorial
                    </v-list-item>
                    <v-list-item @click="navigate('/help/contactUs')">
                        Contact us
                    </v-list-item>                    
                </v-list>
            </v-menu>

            <v-menu offset-y dark>
                <template v-slot:activator="{ on }">
                    <v-tab v-if="checkLoggedIn()" v-on="on">
                        <v-badge v-if="noOfLoseBid > 0" color="red" :content="noOfLoseBid">
                            My Account
                        </v-badge>                
                        <span v-if="noOfLoseBid == 0">My Account</span> 
                        <v-icon right>mdi-menu-down</v-icon>               
                    </v-tab>
                </template>
                <v-list>
                    <v-list-item @click="navigate('/myaccount/bidonline')">
                        Bid In Progress
                    </v-list-item>
                    <v-list-item @click="navigate('/myaccount/mybid')">
                        My Bid
                    </v-list-item>
                    <v-list-item @click="navigate('/myaccount/transaction')">
                        Transaction
                    </v-list-item>
                    <v-list-item @click="navigate('/myaccount/myprofile')">
                        My Profile
                    </v-list-item>
                </v-list>
            </v-menu>
            <!-- <v-spacer></v-spacer>            
            <router-link v-if="!checkLoggedIn()" class="ma-1" style="text-decoration:none" :to="{path: '/login'}"><v-btn color="">Login</v-btn></router-link>
            <v-btn @click="logout()" color="" class="ma-1" v-if="checkLoggedIn()">Logout</v-btn> -->
        </v-tabs> 
    
</template>

<script>
import storage from "../jscontroller/storage"

export default {
    props: ['storageUser', 'noOfLoseBid'],
    data: () => ({    
        pages: [
            {name: "Home", path: "/"},
            {name: "Online Auction", path: "/onlineAuction/onAuction/1"},
        ],
        tab: null,
    }),
    created(){
    },
    mounted(){
        this.highlightTab();
    },
    methods: {
        navigate: function(pagePath){
            if(this.$route.path != pagePath){
                this.$router.push(pagePath);
            }            
        },
        highlightTab(){
            var self = this;
            var currentPage = this.$route.path;
            if(currentPage == ("/"))
                self.tab = 0;
            else if(currentPage.includes("/onlineAuction"))
                self.tab = 1;
            else if(currentPage.includes("/help"))
                self.tab = 2;
            else if(currentPage.includes("/myaccount"))
                self.tab = 3;
        },
        checkRole(){
            var bool = false;
            var self = this;
            if(self.storageUser != null){
                if(self.storageUser.role == "admin" || self.storageUser.role == "vendor"){
                    bool = true;
                }
            }
            return bool;
        },
        checkLoggedIn(){
            var bool = false;
            var self = this;
            if(self.storageUser != null){
                if(self.storageUser.email != ""){
                    bool = true;
                }
            }
            return bool;
        },
        logout(){
            storage.SetUser("","","","");
            storage.switchNavBar('website');
            this.$router.push("/");
            this.$router.go();
        },
        gotoAdmin(){
            storage.switchNavBar('admin');
            this.$router.go();
        }
    },
}
</script>