<template>
  <div>
    <v-row class="pa-0 ma-0">
      <v-col class="pa-1 ma-0 px-2">
        <v-card dark="" class="title">
          <v-row class="pa-3 ma-0 ml-2 ">Term and Conditions (E-Auction)</v-row>
        </v-card>
        <v-card class="pa-3 mx-0 mt-2">
          <v-row
            class="pa-0 ma-1 mb-3 font-weight-bold black--text"
            justify="center"
            >AMS E-AUCTION TERMS AND CONDITIONS OF SALE</v-row
          >
          <div class="pa-0 ma-0 mb-1 black--text">
            As a registered bidder, you are therefore agreed to the following
            terms and conditions. Please ensure that you have read the terms and
            conditions prior to bidder registration and making any bidding at
            AMS e-auction sale.
          </div>
          <div :class="classStyle.no">1. Use of AMS e-auction system</div>
          <v-row :class="classStyle.letter">
            <span class="mr-1">a. </span>
            <span style="width:93%">Access to online system.</span>
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">b. </span>
            <span style="width:93%"
              >The use of this website and the online system is governed by and
              subjected to you agreeing to the terms and conditions.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">c. </span>
            <span style="width:93%"
              >Bidders hereby agreed with the terms and conditions to apply when
              using the AMS e-auction system.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">d.</span>
            <span style="width:93%"
              >All trademarks, copyrights, database rights and other
              intellectual property rights of any nature in AMS e-auction,
              together with the underlying software codes, shall be the property
              of AMS e-auction. Bidders shall not, nor allow any third party to
              reproduce and distribute any copies of the application.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">e.</span>
            <span style="width:93%"
              >By participating in the e-auction, any person intending to bid in
              this auction hereby agreed and accept that:</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">i.</span>
            <span style="width:93%"
              >The physical condition of the goods is deemed to be satisfactory
              to the Bidders based on their own independent judgment.</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">ii.</span>
            <span style="width:93%"
              >Bidder participating in this auction on his/her own freewill and
              at his/her own risk.</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">iii.</span>
            <span style="width:93%"
              >Bidders have read, understood and agreed to be bound by these
              terms and conditions governing the auction of the goods. The terms
              and conditions wherever mentioned herein, shall be deemed to be of
              essence.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">f.</span>
            <span style="width:93%"
              >In the event of any dispute during the e-auction sale, AMS
              auctioneer shall be the sole arbiter and the decision thereto
              shall be final and acceptable to the parties.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">g.</span>
            <span style="width:93%"
              >AMS shall be at liberty to postpone, call off or adjourn the
              auction of the day or any lots at any material time without having
              to provide any reasons or grounds whatsoever and such decisions
              shall be final not be challenged in any court of law.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">h.</span>
            <span style="width:93%"
              >These terms and conditions shall be governed by and interpreted
              in accordance with the laws of Malaysia.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">i.</span>
            <span style="width:93%"
              >Notwithstanding anything contained herein, AMS e-auction reserves
              the right to cancel any sale and/or transaction, even after being
              finalized, in the event that it is discovered through any means
              that there has been any fraudulent act resulting in the sale
              and/or auction of the goods.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">j.</span>
            <span style="width:93%"
              >AMS reserves the right to impose any time extension for any
              auction session where it deems necessary. Where possible, AMS will
              notify Bidders in advance of the time extension in the AMS
              e-auction system, but does not represent or warrant that it will
              do so on every occasion, on any particular occasion or any
              occasion at all.</span
            >
          </v-row>
          <div :class="classStyle.no">2. Condition of auction goods</div>
          <v-row :class="classStyle.letter">
            <span class="mr-1">a.</span>
            <span style="width:93%"
              >Bidders are advised to view and inspect the physical condition
              and actual description of auction goods prior to bidding.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">b.</span>
            <span style="width:93%"
              >Bidders are required to set an appointment with the relevant
              person in- charge as stated in the auction catalogue. Please
              adhere to the viewing sessions (if any).</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">c.</span>
            <span style="width:93%"
              >Bidders may be required to comply with special terms and
              conditions set by the relevant parties.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">d.</span>
            <span style="width:93%"
              >The inspection and other related fees shall be at Bidders own
              cost at the time of the successful bid.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">e.</span>
            <span style="width:93%"
              >It is the responsibility of Bidders to view and inspect the
              condition and actual description of auction goods. AMS will not be
              liable for any mistake or damage resulting from any bidding due to
              the condition and actual description of auction goods.</span
            >
          </v-row>
          <div :class="classStyle.no">3. Private Auction</div>
          <v-row :class="classStyle.letter">
            <span class="mr-1">a.</span>
            <span style="width:93%"
              >AMS may from time to time conduct a private auction
              session.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">b.</span>
            <span style="width:93%"
              >This private auction is strictly conducted for a specified group
              of participant i.e Company Staff Auction.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">c.</span>
            <span style="width:93%"
              >A remark
              <span class="font-weight-bold">“PRIVATE AUCTION”</span> will be
              displayed at our website Home page.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">d.</span>
            <span style="width:93%"
              ><span class="font-weight-bold"
                >PUBLIC BIDDERS ARE STRICTLY PROHIBITED</span
              >
              from participating and bidding in our private auction.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">e.</span>
            <span style="width:93%"
              >In case public bidders were found participate and successful in
              the bidding, the
              <span class="font-weight-bold"
                >SALE WILL BE CANCELLED AND ANY DEPOSIT PAID WILL BE
                FORFEITED.</span
              ></span
            >
          </v-row>
          <div :class="classStyle.no">4. Bidders</div>
          <v-row :class="classStyle.letter">
            <span class="mr-1">a.</span>
            <span style="width:93%">Online registration</span>
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">i.</span>
            <span style="width:93%"
              >Bidders are required to furnish their personal details in the e-
              auction system. Please ensure the information given are correct as
              it will be used to generate receipt and memorandum of sale. AMS
              will not be responsible for any incorrect auction documents
              produced due to incorrect information provided by the
              Bidders.</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">ii.</span>
            <span style="width:93%"
              >Bidders are also required to furnish their bank name and account
              number for refund purposes. The system need 3 working days to
              verify the information. AMS will not be liable for any incorrect
              information provided by the Bidders.</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">iii.</span>
            <span style="width:93%"
              >Registered bidders are required to make an online payment of
              Auction Deposit via AMS e-auction system:</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">1.</span>
            <span style="width:93%">Motor Vehicles = RM1,500 per unit</span>
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">2.</span>
            <span style="width:93%">Luxury Vehicles = RM5,000 per unit</span>
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">3.</span>
            <span style="width:93%"
              >Lorries/Machinery/Heavy Machinery = RM1,500 per unit</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">4.</span>
            <span style="width:93%"
              >Prime Movers/Trailers = RM1,500 per unit</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">5.</span>
            <span style="width:93%"
              >General Goods/Scrap = RM1,500 per lot/unit</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span style="width:93%"
              >A finance charge of RM2 will be imposed for every
              transaction.</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">iv.</span>
            <span style="width:93%"
              >The Bidder shall pay Auction Premium to AMS for every successful
              bid:</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">1.</span>
            <span style="width:93%">Motorcycles = RM100 per unit</span>
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">2.</span>
            <span style="width:93%">Vehicles: </span>
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1 ml-5">a.</span>
            <span style="width:93%">Bid price RM5,000 and below = RM300 per unit</span>
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1 ml-5">b.</span>
            <span style="width:93%">Bid price RM5,001 and above = RM600 per unit</span>
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">3.</span>
            <span style="width:93%"
              >Luxury Vehicles = 2% of bid price per unit</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">4.</span>
            <span style="width:93%"
              >Lorries/Machinery/Heavy Machinery = 5% of bid price or RM600
              whichever is higher per unit</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">5.</span>
            <span style="width:93%"
              >Prime Movers/Trailers = 5% of bid price or RM600 whichever is
              higher per unit</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">6.</span>
            <span style="width:93%"
              >General Goods/Scrap = 5% of bid price or RM600 whichever is
              higher per unit</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">v.</span>
            <span style="width:93%"
              >The Bidder shall pay Admin Fee (non-refundable) to AMS for every
              successful bid:</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">1.</span>
            <span style="width:93%">Motorcycles = RM100 per unit</span>
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">2.</span>
            <span style="width:93%">Vehicles = RM300 per unit</span>
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">3.</span>
            <span style="width:93%">Luxury Vehicles = RM300 per unit</span>
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">4.</span>
            <span style="width:93%"
              >Lorries/Machinery/Heavy Machinery = RM300 per unit</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">5.</span>
            <span style="width:93%"
              >Prime Movers/Trailers (including scrap unit) = RM300 per
              unit</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">6.</span>
            <span style="width:93%">General Goods/Scrap = RM100 per unit</span>
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">vi.</span>
            <span style="width:93%"
              >The Bidder shall pay Security Deposit (refundable) to AMS for
              every successful bid:</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">1.</span>
            <span style="width:93%"
              >Lorries/Prime Movers/Trailers = RM1,500 per unit</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">2.</span>
            <span style="width:93%"
              >Applicable for certain auction goods only</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">3.</span>
            <span style="width:93%"
              >Security deposit will be refunded after collection of goods from
              relevant sites. Bidders must provide delivery order/gatepass as
              prove of collection</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">vii.</span>
            <span style="width:93%"
              >The Bidder shall pay Safety Deposit (refundable after completion
              of ownership transfer) to AMS for every successful bid:</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">1.</span>
            <span style="width:93%"
              >Vehicles (Cars/Vans/Pickups) = RM500 per unit</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">2.</span>
            <span style="width:93%">Motorcycles = RM100 per unit</span>
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">3.</span>
            <span style="width:93%"
              >AMS will hold the Safety Deposit for 3 months in order to provide
              Bidder enough time to complete the transfer of ownership failing
              which the Safety Deposit will be forfeited and any appeal will not
              be entertained.</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1">4.</span>
            <span style="width:93%"
              >Refund of Safety Deposit is subjected to:</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1 ml-5">a.</span>
            <span style="width:93%"
              >Bidder to submit proof of ownership transfer (copy of
              registration card or E-Auto slip) within 3 months from the date of
              auction.</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1 ml-5">b.</span>
            <span style="width:93%"
              >Vehicle is free from any summons or third-party claims issued by
              JPJ/PDRM or any relevant authorities/local councils from the date
              of vehicle delivery until completion of transfer.</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1 ml-5">c.</span>
            <span style="width:93%"
              >Bidder agree to be responsible for any late issuance of summons
              due to system delay. AMS will notify the Bidder of such summons
              (if any).</span
            >
          </v-row>
          <v-row :class="classStyle.no2">
            <span class="mr-1 ml-5">d.</span>
            <span style="width:93%">Approval by the vendor.</span>
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">viii.</span>
            <span style="width:93%"
              >Any error, misstatement or wrongful description of the goods in
              any documents may NOT annul or invalidate the auction and/or the
              sale of the goods nor shall the same grant any rights to the
              Bidders to make any claim or refund.</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">ix.</span>
            <span style="width:93%"
              >Notwithstanding anything contained herein, it is understood and
              agreed that it is the auction goods per se that shall be deemed as
              the subject matter of the auction while documentations and/or
              descriptions of the goods, even though obligatory, shall be deemed
              secondary.</span
            >
          </v-row>

          <div :class="classStyle.no">5. Bidding Process</div>
          <v-row :class="classStyle.letter">
            <span class="mr-1">a.</span>
            <span style="width:93%"
              >During the auction process, the goods shall be auctioned, in
              accordance with the lot number in ascending order, including but
              not limited to the followings:</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">i.</span>
            <span style="width:93%">The lot number of the goods</span>
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">ii.</span>
            <span style="width:93%">A set of photos of the goods</span>
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">iii.</span>
            <span style="width:93%">The particulars of the goods</span>
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">iv.</span>
            <span style="width:93%">The current bid price of the goods</span>
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">b.</span>
            <span style="width:93%"
              >The Bidder is given an option to start with Bid Now or Buy Now by
              pressing the appropriate button.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">c.</span>
            <span style="width:93%"
              >The Bidder would bid by pressing the Bid Now button in the
              system. The price increase in every bid shall be at minimum amount
              of RM50 for motorcycles and RM200 for other goods. Bidder may
              raise the bid more than the minimum amount by pressing the +
              button.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">d.</span>
            <span style="width:93%"
              >If the Bidder is out bid, a notification will appear at the
              screen. Bidder can raise his/her bid by accessing to Bid in
              Progress under My Account.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">e.</span>
            <span style="width:93%"
              >If there is no further bid for the good the Bidder with the
              highest bidding price shall be declared as the successful bidder
              of the good. The final bidding price shall be deemed as the
              purchase price of the good.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">f.</span>
            <span style="width:93%"
              >Bidder can access to his/her bids at My Bid under My Account.
              Bidder can view and print the receipt and memorandum of sale by
              pressing the View Receipt button and Memorandum of Sale button at
              My Bid.</span
            >
          </v-row>

          <div :class="classStyle.no">6. Balance Purchased Price</div>
          <v-row :class="classStyle.letter">
            <span class="mr-1">a.</span>
            <span style="width:93%"
              >The Purchased Price less Deposit (Balance Purchase Price) shall
              be paid in full by the Bidder to AMS within seven (7) days from the date of successful of bid (Payment Period). The payment
              has to be made manually at the time of the collection of the
              vehicle. The Bidder shall also execute the Memorandum of Sale with
              AMS simultaneous with the payment of Balance Purchase Price within
              the Payment Period</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">b.</span>
            <span style="width:93%"
              >The Payment Period may be extended by AMS for a period of not
              more than seven (7) days upon a written request by the Bidder
              before the expiry of Payment Period. Upon such extension being
              granted by AMS (Extended Payment Period), the Bidder shall not be
              entitle for any further extension.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">c.</span>
            <span style="width:93%"
              >In default of such payment of the Balance Purchase Price:</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">i.</span>
            <span style="width:93%"
              >The transaction will be deemed as a forfeited transaction, and
              the Auction Deposit shall be forfeited by AMS.</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">ii.</span>
            <span style="width:93%"
              >AMS may choose to put the good up for re-auction, and the
              defaulting Bidder shall be liable to AMS the cost of such
              re-auction together with the deficiency in price of the good in
              the subsequent auction (if any).</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">d.</span>
            <span style="width:93%"
              >Bidders shall be entitled to collect the goods from AMS or
              relevant parties upon full payment of the Purchased Price of the
              goods and execution of transfer of ownership (if relevant).</span
            >
          </v-row>

          <div :class="classStyle.no">7. Collection of auction goods:</div>
          <v-row :class="classStyle.letter">
            <span class="mr-1">a.</span>
            <span style="width:93%"
              >All items paid in full must be collected by the successful bidder
              at his or her own expense.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">b.</span>
            <span style="width:93%"
              >If the bidder fails to do so within 14 days from the date of
              auction, the bidder is responsible for paying a daily rate of
              RM100 per unit per day or any rate as may be specified in this
              terms & condition (refer to Item 12 – Storage Fee).
            </span>
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">c.</span>
            <span style="width:93%"
              >All goods not taken after 60 days from the date of auction,
              without notice to the bidder, may be resold through a public
              auction or private sale or disposed of at the risk and liability
              of the bidder and all costs and fees incurred on such resale with
              bribery charges imposed on bidders who fail to be deducted from
              the proceeds of sale or other disposal and are kept by the
              auctioneer.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">d.</span>
            <span style="width:93%"
              >Bidder is required by Malaysian Law to use the service of tow
              truck/carrier or valid trade plate for all auctioned vehicles to
              avoid any summons as these vehicles are mostly without valid road
              tax and proper insurance coverage.</span
            >
          </v-row>

          <div :class="classStyle.no">8. Transfer of ownership:</div>
          <v-row :class="classStyle.letter">
            <span class="mr-1">a.</span>
            <span style="width:93%"
              >Successful bidders MUST execute transfer of ownership before
              taking delivery of auction goods (for title goods).</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">b.</span>
            <span style="width:93%"
              >All ownership of goods sold will only be transferred to the
              registered bidder and the cost is to be borne by the bidder.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">c.</span>
            <span style="width:93%"
              >Transfer to any third party will not be entertained.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">d.</span>
            <span style="width:93%"
              >Bidders are required to perform their own PUSPAKOM inspection
              prior to transfer of ownership at their own cost. PUSPAKOM
              inspection must be carried out at the goods respective site.
              Bidders will be required to book for inspection date with PUSPAKOM
              mobile team and inform the confirmed inspection date to AMS.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">e.</span>
            <span style="width:93%"
              >Once the PUSPAKOM inspection report is obtained, transfer
              documents will be prepared. Bidders shall execute the transfer of
              ownership at JPJ after obtaining the transfer documents soonest
              possible in order to prevent PUSPAKOM inspection report from
              expired.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">f.</span>
            <span style="width:93%"
              >Bidders with E-Auto facility may be allowed to take delivery of
              goods after execution of E-Auto subject to approval.</span
            >
          </v-row>

          <div :class="classStyle.no">9. Refund</div>
          <v-row :class="classStyle.letter">
            <span class="mr-1">a.</span>
            <span style="width:93%"
              >The Bidder shall be entitled to claim for refund only in the
              following circumstances:</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">i.</span>
            <span style="width:93%">Unsuccessful bidding/out bid</span>
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">ii.</span>
            <span style="width:93%"
              >If transfer of ownership cannot be executed due to being under
              criminal investigation by the Authorities</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">iii.</span>
            <span style="width:93%"
              >If transfer of ownership cannot be executed due to the vehicle
              being identified as engine number tempered, chassis number
              tempered, and cut and joint cases</span
            >
          </v-row>
          <v-row :class="classStyle.roman">
            <span class="mr-1">iv.</span>
            <span style="width:93%"
              >Refund is strictly limited to Purchase Price and Auction Premium
              only. Admin Fee is non-refundable to whatever reason. Any other
              cost incurred by the Bidder including but not limited to Puspakom
              Inspection Fee, towing fee and repair fee are not claimable.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">b.</span>
            <span style="width:93%"
              >Refund for unsuccessful bid required ten (10) working days to be
              processed</span
            >
          </v-row>

          <div :class="classStyle.no">10. No Refund</div>
          <div class="black--text">
            The Bidder shall NOT be entitled to claim for refund only under the
            following circumstances:
          </div>
          <v-row :class="classStyle.letter">
            <span class="mr-1">a.</span>
            <span style="width:93%"
              >The goods cannot be registered with the Authorities due to
              traffic summons, fines or penalty unpaid/unresolved by the
              previous owner</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">b.</span>
            <span style="width:93%"
              >If the Authorities gave a conditional approval for the goods
              transfer of ownership registration, under which transfer of
              ownership registration can be made if any defects/parts of the
              Vehicle were to be rectified/replaced.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">c.</span>
            <span style="width:93%"
              >Any other reasons not specifically mentioned herein cited by the
              authorities which may render the registration of transfer of
              ownership impossible.</span
            >
          </v-row>

          <div :class="classStyle.no">11. Limit of AMS Liability</div>
          <div class="black--text">
            Notwithstanding anything contained herein, the liability of AMS
            shall be limited only to Buyers Premium or auctioneers fee,
            whichever is applicable.
          </div>

          <div :class="classStyle.no">12. Indemnity</div>
          <div class="black--text">
            The Bidders hereby undertake to indemnify AMS and keep AMS
            indemnified for any loss, damage and legal costs arising from any
            occurrence of AMS being subjected to any litigation and/or dispute
            that may arise as a result of the auction.
          </div>

          <div :class="classStyle.no">13. Storage Fee</div>
          <v-row :class="classStyle.letter">
            <span class="mr-1">a.</span>
            <span style="width:93%"
              >Bidders hereby expressly agreed that a storage fee of RM100 per
              unit per day to be imposed against the Bidders in the event of
              failure to take delivery of auction goods from AMS or relevant
              parties after fourteen (14) days from the date of auction (for
              non-title goods) and fourteen (14) days from date of transfer of
              ownership (for title goods).</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">b.</span>
            <span style="width:93%"
              >Delivery of goods under clause 11.a. will only be released after
              payment of storage fee is made.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">c.</span>
            <span style="width:93%"
              >AMS have the right to dispose any uncollected goods for more than
              sixty (60) days from date of auction (for non-title goods) and
              sixty (60) days from date of transfer of ownership (for title
              goods).</span
            >
          </v-row>

          <div :class="classStyle.no">
            14. Suspension of Service/Internet Connection/Compatible Devices
          </div>
          <v-row :class="classStyle.letter">
            <span class="mr-1">a.</span>
            <span style="width:93%"
              >AMS expressly disclaims any representation or warranty that the
              AMS e- auction system will be available for use by Bidders at all
              times or at any particular time, during and for the duration of
              any particular Sale Process or at all. No Warranty or
              Representation of Availability will be given.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">b.</span>
            <span style="width:93%"
              >If the AMS e-auction system is interrupted (either generally or
              to a particular Bidder(s) or person(s)) for any reason during a
              bidding process (whether by way of suspension by AMS under clause
              (or otherwise) AMS may, but is not obliged to, declare the Sale as
              void.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">c.</span>
            <span style="width:93%"
              >AMS may from time to time suspend the provision of the AMS
              e-auction system.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">d.</span>
            <span style="width:93%"
              >Where possible, AMS will notify Bidders in advance of the time
              and duration of any suspension of the AMS e-auction system, but
              does not represent or warrant that it will do so on every
              occasion, on any particular occasion or any occasion at all.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">e.</span>
            <span style="width:93%"
              >The minimum requirement for the internet connection speed is
              minimum 1Mbps in stable condition and the devices compatibility
              such as computer, tablet and smartphone must be 2GB RAM.</span
            >
          </v-row>
          <v-row :class="classStyle.letter">
            <span class="mr-1">f.</span>
            <span style="width:93%"
              >AMS expressly disclaims any warranty that AMS e-auction system
              will be compatible with all devices used by the buyer at all
              times. AMS e-auction system may not be compatible with certain
              devices especially Apple products.</span
            >
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="3" class="d-none d-md-flex pr-0 ">
        <HelpNav></HelpNav>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HelpNav from "@/components/HelpNav";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { HelpNav },
  data: () => ({
    classStyle: {
      no: "font-weight-bold black--text",
      letter: "ml-3 pa-1 black--text",
      roman: "ml-8 pa-1 black--text",
      no2: "ml-12 pa-1 black--text",
    },
  }),
};
</script>
