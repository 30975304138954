<template>
  <div>
    <v-card color="" class="pa-2 mx-2 mt-2">
      <div color="" class="pa-1 title" style="text-decoration: underline">
        Sales Report
      </div>
      <v-col class="pa-2 ma-0">
        Choose an auction session..
        <v-select
          class="pa-0"
          filled
          dense=""
          v-model="selectAuction"
          :items="auctionHistoryList"
          :hint="`${selectAuction.session} - Date: ${selectAuction.startDate}`"
          :item-text="(item) => item.session + ' (' + item.startDate + ')'"
          item-value="session"
          hide-details="auto"
          label="Auction Name"
          return-object
        >
        </v-select>
        <!-- item-text="session" -->
      </v-col>
      <v-col v-if="selectAuction.session != null" class="pa-2 ma-0">
        <span>Filter by: </span>
        <v-chip v-if="isAdmin" @click="filter = 'all'" class="mx-1">All</v-chip>
        <v-chip @click="filter = 'vendor'" class="mx-1">Vendors</v-chip>
        <v-chip @click="filter = 'buyer details'" class="mx-1"
          >Buyer Details by Vendors</v-chip
        >
      </v-col>
      <v-col
        v-if="filter == 'vendor' || filter == 'buyer details'"
        class="pa-2 ma-0"
      >
        Choose a vendor..
        <v-select
          class="pa-0"
          filled
          dense=""
          v-model="selectedVendor"
          :items="vendorsList"
          hide-details="auto"
          label="Vendor"
          return-object
        >
        </v-select>
      </v-col>
      <v-row class="pa-2 ma-0">
        <v-btn
          v-if="salesReport.length != 0"
          @click="downloadSalesReport()"
          color="blue"
          dark
          >Download Sales Report - CSV</v-btn
        >
        <div class="pa-0 ma-0" v-if="salesReport.length == 0">
          No Sales Report on selected filters..
        </div>
      </v-row>
      <v-divider></v-divider>
      <v-col class="pa-1 ma-1">
        <div color="" class="pa-1 title" style="text-decoration: underline">
          Bid records
        </div>
        <div class="pa-2 ma-0" v-if="selectAuction.session == null">
          No Bid Records for selected auction..
        </div>
        <v-btn
          @click="downloadBidRecords()"
          v-if="selectAuction.session != null"
          color="brown"
          dark
          >Download Bid Records - CSV</v-btn
        >
      </v-col>

      <v-divider></v-divider>

      <v-col v-if="isAdmin" class="pa-1 ma-1">
        <div color="" class="pa-1 title" style="text-decoration: underline">
          Registered User
        </div>
        <v-btn @click="downloadUsers()" dark>Download Users - CSV</v-btn>
      </v-col>
    </v-card>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
import storage from "../jscontroller/storage";
// import vendors from "../json-components/vendors";
import { REPORT_VENDORS, TM_VENDORS } from "../constants/vendors";
import authorization from "../jscontroller/authorization";
export default {
  name: "SalesReport",
  data: () => ({
    user: {},
    serverUrl: serverConfig.hostname,
    salesReport: [],
    selectAuction: {},
    auctionHistoryList: [],
    filter: "",
    vendorsList: [],
    selectedVendor: {},
    isAdmin: true,
    snackbar: {
      showProgress: false,
      showStatus: false,
      text: "",
      timeout: 5000,
      icon: "mdi-check-circle-outline",
      color: "green",
    },
  }),
  created() {},
  mounted() {
    this.user = storage.GetUser();
    if (!authorization.checkVendorAuthorization(this.user)) {
      storage.switchNavBar("website");
      this.$router.push("/");
      this.$router.go();
    }

    if (!authorization.checkAdminAuthorization(this.user)) {
      this.isAdmin = false;
      this.getUserProfile((vendorUser) => {
        // eslint-disable-next-line no-console
        if (vendorUser.staffOf == "TM Staff") {
          this.vendorsList = TM_VENDORS;
        }
      });
    } else {
      this.vendorsList = REPORT_VENDORS;
    }
    this.getAuctionName();
  },
  methods: {
    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }
        str += line + "\r\n";
      }
      return str;
    },
    exportCSVFile(headers, items, fileTitle) {
      var self = this;
      if (headers) {
        items.unshift(headers);
        items.unshift({});
        if (self.filter == "all")
          items.unshift({
            reports: "Sales Report:",
            space: "",
            auctioName: self.selectAuction.session,
            space1: "",
            date: self.selectAuction.startDate,
          });
        if (self.filter == "vendor")
          items.unshift({
            reports: "Sales Report:",
            space: "",
            auctioName: self.selectAuction.session,
            space1: "",
            date: self.selectAuction.startDate,
            space2: "",
            ven: self.selectedVendor,
          });
        if (self.filter == "buyer details")
          items.unshift({
            reports: "Buyer's Details:",
            space: "",
            auctioName: self.selectAuction.session,
            space1: "",
            date: self.selectAuction.startDate,
            space2: "",
            ven: self.selectedVendor,
          });
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + ".csv" || "export.csv";

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    downloadSalesReport() {
      var self = this;
      var headers = {
        status: "Status",
        lotNo: "Lot No",
        regnNo: "Regn No",
        make: "Make",
        desription: "Description",
        year: "Year",
        rc: "VOC",
        price: "Reserve Price",
        bidPrice: "Bid Price",
        vendor: "Vendor",
        location: "Location",
        bidderNo: "Bidder No",
        staffId: "Staff No",
        name: "Name",
        ic: "NRIC",
        email: "Email",
        tel: "Tel",
        address: "Address",
      };
      if (self.filter == "buyer details") {
        headers = {
          lotNo: "Lot No",
          bidderNo: "Bidder No",
          staffId: "Staff No",
          name: "Name",
          ic: "NRIC",
          email: "Email",
          tel: "Tel",
          address: "Address",
          regnNo: "Regn No",
          make: "Make",
          desc: "Desc",
        };
      }

      var items = [];
      for (var i = 0; i < self.salesReport.length; i++) {
        var auctionLotLog = self.salesReport[i].auctionLotLog;
        var mybid = self.salesReport[i].mybid;
        var record = self.salesReport[i].record;
        var user = self.salesReport[i].user;
        var csvName =
          "Sales Report - " +
          self.selectAuction.session +
          " - " +
          self.selectAuction.startDate;
        if (self.filter == "all" || self.filter == "vendor") {
          if (self.filter == "vendor")
            csvName = csvName + " - " + self.selectedVendor;
          items.push({
            status: auctionLotLog.status,
            lotNo: record.lotNo,
            regnNo: auctionLotLog.regnNo,
            make: auctionLotLog.make,
            desription: auctionLotLog.descOfGood,
            year: auctionLotLog.year,
            rc: auctionLotLog.rc,
            price: auctionLotLog.price,
            bidPrice: auctionLotLog.salePrice,
            vendor: auctionLotLog.vendor,
            location: auctionLotLog.location.replace(/,/g, ""),
            bidderNo: user.id,
            staffId: user.staffId,
            name: user.firstName,
            ic: user.ic,
            email: record.email,
            tel: user.phoneNo,
            address: (
              user.address +
              " " +
              user.city +
              " " +
              user.postcode +
              " " +
              user.state
            ).replace(/,/g, " "),
          });
        } else if (self.filter == "buyer details") {
          csvName =
            "Buyer Details - " +
            self.selectAuction.session +
            " - " +
            self.selectAuction.startDate +
            " - " +
            self.selectedVendor;
          items.push({
            lotNo: mybid.lotNo,
            bidderNo: user.id,
            staffId: user.staffId,
            name: user.firstName,
            ic: user.ic,
            email: user.email,
            tel: user.phoneNo,
            address: (
              user.address +
              " " +
              user.city +
              " " +
              user.postcode +
              " " +
              user.state
            ).replace(/,/g, " "),
            regnNo: mybid.particular,
            make: mybid.make,
            desc: mybid.description,
          });
        }
      }
      this.exportCSVFile(headers, items, csvName);
    },
    getSalesReport(session, filter, callback) {
      var self = this;
      self.showSnackbar();
      var apiUrl =
        self.serverUrl +
        "/api/salesreport/get/report/" +
        session +
        "/" +
        filter;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          callback(response.data);
          self.hideSnackbar("Finish fetching data..", "green");
        })
        .catch(function (error) {
          self.hideSnackbar("Failed", "red");
          alert(error.response.data.message);
        });
    },
    getBuyerDetails(session, filter) {
      var self = this;
      self.showSnackbar();
      var apiUrl =
        self.serverUrl +
        "/api/salesreport/get/buyerdetails/" +
        session +
        "/" +
        filter;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          self.salesReport = response.data;
          self.hideSnackbar("Finish fetching data..", "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    getAuctionName() {
      var self = this;
      var apiUrl = self.serverUrl + "/api/auctionmanager/session/history/all";
      axios
        .get(apiUrl)
        .then(function (response) {
          var tempAuctionHList = response.data;
          self.auctionHistoryList = [];
          for (var i = 0; i < tempAuctionHList.length; i++) {
            self.auctionHistoryList.unshift(tempAuctionHList[i]);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    downloadUsers() {
      var self = this;
      self.showSnackbar();
      var headers = {
        id: "BIDDER/USER ID",
        firstName: "NAME/ORGANIZATION",
        ic: "IC/SSM NO",
        phoneNo: "PHONE NO.",
        email: "EMAIL",
        staffOf: "STAFF",
        staffId: "STAFF ID",
      };
      var fileTitle = "All Users";
      var apiUrl = self.serverUrl + "/api/user/getall";
      if (self.user.role == "vendor")
        apiUrl = self.serverUrl + "/api/user/getall/by/vendor/" + self.user.id;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          var items = [];
          var users = response.data;
          for (var i = 0; i < users.length; i++) {
            items.push({
              id: users[i].id,
              firstName: users[i].firstName,
              ic: users[i].ic,
              phoneNo: users[i].phoneNo,
              email: users[i].email,
              staffOf: users[i].staffOf,
              staffId: users[i].staffId,
            });
          }
          if (self.user.role == "vendor")
            fileTitle = users[0].staffOf + " Users";
          self.hideSnackbar("Finish fetching data..", "green");
          self.exportCSVFileForGeneralUse(headers, items, fileTitle);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    downloadBidRecords() {
      var self = this;
      self.showSnackbar();

      var headers = {
        id: "NO",
        lotNo: "LOT NO",
        session: "AUCTION",
        bidderNo: "BIDDER NO",
        email: "EMAIL",
        biddingAmount: "BIDDING AMOUNT",
        totalAmount: "TOTAL AMOUNT",
        timeStamp: "TIMESTAMP",
      };
      var fileTitle = "Bid Records - " + self.selectAuction.session;
      var apiUrl =
        self.serverUrl +
        "/api/salesreport/get/bidrecords/" +
        self.selectAuction.session;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          var items = [];
          var bidRecords = response.data;
          for (var i = 0; i < bidRecords.length; i++) {
            items.push({
              id: bidRecords[i].id,
              lotNo: bidRecords[i].lotNo,
              session: bidRecords[i].session,
              bidderNo: bidRecords[i].bidderNo,
              email: bidRecords[i].email,
              biddingAmount: bidRecords[i].biddingAmount,
              totalAmount: bidRecords[i].totalAmount,
              timeStamp: bidRecords[i].timeStamp,
            });
          }
          self.hideSnackbar("Finish fetching data..", "green");
          self.exportCSVFileForGeneralUse(headers, items, fileTitle);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    exportCSVFileForGeneralUse(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + ".csv" || "export.csv";

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    getUserProfile(callback) {
      const self = this;
      const apiUrl = serverConfig.hostname + "/api/user/get-profile";
      const token = self.user.jwt;

      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          callback(response.data);
        })
        .catch(function (error) {
          alert(error.response.data.message);
          self.logout();
        });
    },
    logout() {
      storage.SetUser("", "", "", "", "");
      storage.switchNavBar("website");
      this.$router.push("/");
      this.$router.go();
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red" || color == "orange") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
  },
  watch: {
    filter: function () {
      var self = this;
      self.salesReport = [];
      self.selectedVendor = "";
      if (self.filter == "all") {
        self.getSalesReport(
          self.selectAuction.session,
          self.filter,
          (report) => {
            self.salesReport = report;
          }
        );
      }
    },
    selectAuction: function () {
      var self = this;
      self.salesReport = [];
      self.filter = "";
    },
    selectedVendor: function () {
      var self = this;
      if (self.selectedVendor == "") {
        return;
      }
      if (self.filter == "vendor") {
        self.getSalesReport(
          self.selectAuction.session,
          self.selectedVendor,
          (report) => {
            self.salesReport = report;
          }
        );
      } else if (self.filter == "buyer details") {
        self.getBuyerDetails(self.selectAuction.session, self.selectedVendor);
      }
    },
  },
};
</script>
