<template>
    <div>
    <v-col class="ma-0 pa-0">
      <v-row class="pa-3 ma-0">
        <a @click="navigate('/onlineAuction/onAuction/1')">In Auction</a>
        <span class="mx-3">|</span>
        <a @click="navigate('/onlineAuction/nextAuction/1')">Next Auction</a>
        <span class="mx-3">|</span>
        <a @click="navigate('/onlineAuction/sold')">Sold</a>
      </v-row>
      <v-divider></v-divider>
    </v-col>
    <v-row class="ma-0 pa-0">
      <v-col class="mx-auto" v-for="item in lots" v-bind:key="item.id">
        <Soldcard :lot="item"></Soldcard>
      </v-col>   
      <v-col v-if="lots.length == 0" class="ma-2 pa-2">
        There are no sold lots for today..
      </v-col>   
    </v-row>
    <!-- page -->
    <template>
      <div class="text-center pb-2">
        <!-- <v-pagination
          v-model="currentPage"
          :length="lot.lotLength"
          :total-visible="10"
        ></v-pagination> -->
      </div>
    </template>

    <v-snackbar
      class="mb-5"
      v-model="snackbar.showProgress"
    >
        <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>          
        </v-row>                
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
        <v-row justify="center">
        <v-col class="title">
            {{snackbar.text}}
        </v-col>          
        <v-icon :color="snackbar.color" large="" class="mx-5">{{snackbar.icon}}</v-icon>          
        </v-row>                
      </v-snackbar>

  </div>
</template>

<script>
import Soldcard from '@/components/Soldcard'
import axios from "axios"
import serverConfig from "../jscontroller/serverConfig"
// import io from "socket.io-client";
import storage from "../jscontroller/storage"

export default {
  name: 'OnlineAuction',
  components: {Soldcard},
  data: () => ({    
    serverUrl: serverConfig.hostname,   
    lots: [],
    snackbar: {
      showProgress: false,
      showStatus: false,
      text: '',
      timeout: 4000,
      icon: 'mdi-check-circle-outline',
      color: "green"  
    },
  }),
  created(){
    this.user = storage.GetUser();  
  },
  mounted(){
    this.scrollToTop();
    this.getSoldAuctionLots()
  },
  methods:{
    navigate: function(pagePath){
      if(this.$route.path != pagePath){
          this.$router.push(pagePath);
      }            
    },
    scrollToTop(){
      window.scrollTo(0, 0);
    },
    getSoldAuctionLots(callback){
      var self = this;
      self.showSnackbar()
      var apiUrl = serverConfig.hostname + "/api/auctionlot/get/logs/sold/today";
      axios.get(apiUrl)
      .then(function (response) {
        var items = response.data    
        self.lots = []
        for (let i = 0; i < items.length; i++) {
          var tempItem = items[i].auctionLotLog
          tempItem.id = items[i].mybid.lotNo
          self.lots.push(tempItem)
        }
        if(self.lots.length > 0)
          self.hideSnackbar("Finish fetching sold lot", "green")
        else        
          self.hideSnackbar("There are no sold lots for today..", "green")
        callback(response.data);
      })
      // eslint-disable-next-line no-unused-vars
      .catch(function (error) {            
      });
    },
    showSnackbar(){
      this.snackbar.showProgress = true;      
    },
    hideSnackbar(text, color){
      this.snackbar.showProgress = false;      
      this.snackbar.showStatus = true;    
      this.snackbar.text = text;
      this.snackbar.color = color;
      if(color == "red" || color == "orange"){
          this.snackbar.icon = "mdi-alert-circle-outline"
      }
      else{
          this.snackbar.icon = "mdi-check-circle-outline"
      }
    },
  },
  watch: {
   
  },
}
</script>
