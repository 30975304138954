<template>
  <div class="home" style="background-color: #906b26;">
    <div class="d-none d-sm-flex">
      <v-carousel
        cycle
        height="250"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item
          v-for="(slide, i) in slides"
          :key="i"
          :src="serverUrl + '/api/file' + slide.imgUrl"
        >
        </v-carousel-item>
      </v-carousel>
    </div>

    <div class="d-flex d-sm-none">
      <v-carousel
        cycle
        height="120"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item
          v-for="(slide, i) in slides"
          :key="i"
          :src="serverUrl + '/api/file' + slide.imgUrl"
        >
        </v-carousel-item>
      </v-carousel>
    </div>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" class="pa-0 ma-0">
        <v-card :color="style.color" class="ma-3 pa-3">
          <v-card-title>Auction Invitation</v-card-title>

          <v-col v-for="row in infoRows" :key="row.sessionOrder">
            <v-col>
              <v-row justify="center">
                <v-col
                  lg="2"
                  md="3"
                  cols="4"
                  class="ma-0 pa-0"
                  v-for="(photo, i) in homepagePhotos[row.sessionOrder]"
                  :key="i"
                >
                  <v-card class="ma-1 pa-1">
                    <v-img
                      contain
                      :src="serverUrl + '/api/file' + photo.imgUrl"
                      :lazy-src="serverUrl + '/api/file' + photo.imgUrl"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="pa-0 ma-0" xs="12" md="7">
              <v-row v-for="(info, i) in homepageInfos" :key="i">
                <v-col
                  v-if="info.sessionOrder == row.sessionOrder"
                  cols="4"
                  :class="'pa-0 ma-0 body-2 ' + checkIfBold(info.column1Bold)"
                  >{{ info.column1 }}</v-col
                >
                <v-col
                  v-if="info.sessionOrder == row.sessionOrder"
                  :style="checkIfUnderlined(info.valueUnderlined)"
                  :class="'pa-0 ma-0 body-2 ' + checkIfBold(info.valueBold)"
                  >{{ info.value }}</v-col
                >
              </v-row>
            </v-col>

            <v-col>
              <v-row justify="center">
                <!-- <v-btn @click="newTab(serverUrl+ '/api/file/document/catalogue/pdf/catalogue.pdf')" dark class="ma-2 headline" color="#ff8f13" height="100">Catalogue</v-btn> -->

                <v-btn
                  v-if="row.sessionOrder == 0"
                  @click="navigate('/onlineAuction/onAuction/1')"
                  dark
                  class="ma-2 headline"
                  color="#ff8f13"
                  height="100"
                  >View Auction Lot</v-btn
                >
                <v-btn
                  v-if="row.sessionOrder != 0"
                  @click="navigate('/onlineAuction/nextAuction/1')"
                  dark
                  class="ma-2 headline"
                  color="#ff8f13"
                  height="100"
                  >View Auction Lot</v-btn
                >
                <v-btn
                  @click="viewCatalogue(row.sessionOrder, row.session)"
                  dark
                  class="ma-2 headline"
                  color="#ff8f13"
                  height="100"
                  >Catalogue({{ row.session }})</v-btn
                >
                <v-btn
                  @click="
                    newTab(serverUrl + '/api/file/document/tnc/pdf/tnc.pdf')
                  "
                  dark
                  class="ma-2 headline"
                  color="#ff8f13"
                  height="100"
                  >Download<br />Terms & Condition</v-btn
                >
              </v-row>
            </v-col>

            <v-divider class="mt-3"></v-divider>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
// import cataloguePDF from "../jscontroller/cataloguePDF"
import cataloguePDF from "../jscontroller/cataloguePDF_Landscape";
import nextcataloguePDF from "../jscontroller/nextcataloguePDF_Landscape";

export default {
  name: "Home",
  data: () => ({
    infoRows: [],
    homepageInfos: [],
    homepagePhotos: [],
    serverUrl: serverConfig.hostname,
    slides: [],
    toggleInfo: [],
    vendorContacts: [],
    logo: "",
    style: {
      info: "ma-0 pa-0 font-weight-medium",
      info2: "ma-0 mp-0 font-weight-regular",
      color: "#f9d270",
    },
  }),
  created() {},
  mounted() {
    this.scrollToTop();
    this.getBanner();
    this.getHomepageInfo();
    this.imgLoader(this.serverUrl + "/api/file/image/pdf/logo/logo2.png").then(
      (picture) => {
        this.logo = picture;
      }
    );
    this.getToggleInfoQuery();
    this.getVendorContacts();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    imgLoader(url) {
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.src = url;
      });
    },
    viewCatalogue(sessionOrder, session) {
      var self = this;

      if (sessionOrder == 0) {
        self.getCatalogue((itemDetails) => {
          cataloguePDF.open(
            self.logo,
            itemDetails,
            self.toggleInfo,
            self.vendorContacts
          );
          // eslint-disable-next-line no-console
          // console.log(itemDetails)
        });
      } else {
        self.getNextCatalogue(session, (itemDetails) => {
          nextcataloguePDF.open(
            self.logo,
            itemDetails,
            self.toggleInfo,
            self.vendorContacts
          );
          // eslint-disable-next-line no-console
          // console.log(itemDetails)
        });
      }
    },
    checkIfBold(bool) {
      var style = "";
      if (bool === "true" || bool == true) {
        style = "font-weight-bold";
      }
      return style;
    },
    checkIfUnderlined(bool) {
      var style = "";
      if (bool === "true" || bool == true) {
        style = "text-decoration: underline";
      }
      return style;
    },
    getBanner() {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/admin/banners";
      axios.get(apiUrl).then((response) => {
        self.slides = response.data;
      });
    },
    getHomepagephotos() {
      var apiUrl = serverConfig.hostname + "/api/admin/homepagephotos";
      axios.get(apiUrl).then((response) => {
        let homepagePhotos = response.data;

        homepagePhotos.forEach((homepagePhoto) => {
          homepagePhoto.sessionOrder = parseInt(
            homepagePhoto.session.replace("AMS", "")
          );
        });

        homepagePhotos.sort(
          (a, b) => parseFloat(a.sessionOrder) - parseFloat(b.sessionOrder)
        );

        const auctionSession = [
          ...new Set(homepagePhotos.map((row) => row.session)),
        ];

        // set homepagePhotos to list of available auction first
        this.homepagePhotos = auctionSession;

        // each auction session push all auction session homepagephotos
        for (var i = 0; i < auctionSession.length; i++) {
          this.infoRows[i] = { sessionOrder: i, session: auctionSession[i] };
          this.homepagePhotos[i] = homepagePhotos.filter(
            (homepagePhoto) => homepagePhoto.session == auctionSession[i]
          );
        }
      });
    },
    getHomepageInfo() {
      var apiUrl = serverConfig.hostname + "/api/admin/homepageinfo";
      axios.get(apiUrl).then((response) => {
        this.homepageInfos = response.data;
        this.infoRows = [
          ...new Set(this.homepageInfos.map((row) => row.sessionOrder)),
        ];

        this.getHomepagephotos();
      });
    },
    getCatalogue(callback) {
      // var self = this;
      var apiUrl = serverConfig.hostname + "/api/auctionlot/catalogue/get";
      axios.get(apiUrl).then((response) => {
        callback(response.data);
      });
    },
    getNextCatalogue(auctionSession, callback) {
      // var self = this;
      var apiUrl =
        serverConfig.hostname + `/api/nextauctionlot/getall/${auctionSession}`;
      axios.get(apiUrl).then((response) => {
        callback(response.data);
      });
    },
    getToggleInfoQuery() {
      var self = this;
      var apiUrl = self.serverUrl + "/api/toggleinfo/getall";
      axios
        .get(apiUrl)
        .then(function(response) {
          self.toggleInfo = response.data;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {});
    },
    getVendorContacts() {
      var self = this;
      var apiUrl =
        self.serverUrl + "/api/toggleinfo/vendorcontacts/get/display/true";
      axios
        .get(apiUrl)
        .then(function(response) {
          self.vendorContacts = response.data;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {});
    },
    newTab(urlLink) {
      window.open(urlLink);
    },
    navigate: function(pagePath) {
      if (this.$route.path != pagePath) {
        this.$router.push(pagePath);
      }
    },
  },
};
</script>
