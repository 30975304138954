<template>
  <div>
    <v-row class="ma-0 pa-0">
      <v-col cols="2" class="d-none d-md-flex pr-0 ">
        <MyAccountNav></MyAccountNav>
      </v-col>
      
      <v-col cols="12" md="10">
        <v-card-title class="pa-0 ma-0 mt-2 ml-5">My Bid</v-card-title>        
        <v-divider class="ma-2"></v-divider>
        <v-col v-for="(session, i) in mybid.all" :key="i" color="" class="pa-0 ma-0">       
          <v-card v-if="session[0].startDate == mybid.date[currentPage-1]" color="" class="pa-1 mx-0 mt-2">
          <v-row class="px-5 py-2">
            <span>Auction:</span><span class="font-weight-bold ml-2">{{showAuctionName(session[0].auctionName)}}</span><v-spacer></v-spacer>
            <span class="">{{showDate(session[0].startDate)}}</span>            
          </v-row>          
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">Lot No</th>
                    <th class="text-left pl-5">Particular</th>
                    <th class="text-left pl-5">Bid Price</th>
                    <th class="text-left pl-5">Buyer's Premium</th>
                    <th class="text-left pl-5">Deposited</th>
                    <th class="text-left pl-5">Admin Fee</th>
                    <th class="text-left pl-5">Safety Deposit(Refundable)</th>
                    <th class="text-left pl-5">Total</th>                 
                    <th class="text-left pl-5">Receipt</th>
                    <th class="text-left pl-5">Memorandum</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(mybid, j) in session" :key="j">
                  <td>{{ mybid.lotNo }}</td>
                  <td>{{ mybid.particular }}</td>
                  <td>{{ ringgitFormat(mybid.bidPrice) }}</td>                          
                  <td>{{ ringgitFormat(calculatePremium(mybid.premium, mybid.bidPrice)) }}</td>
                  <td>{{ ringgitFormat(mybid.deposit) }}</td>
                  <td>{{ ringgitFormat(mybid.adminFee) }}</td>
                  <td>{{ ringgitFormat(mybid.safetyDeposit) }}</td>
                  <td v-if="mybid.status != 'forfeited'">{{ ringgitFormat(mybid.bidPrice + calculatePremium(mybid.premium, mybid.bidPrice) - mybid.deposit + mybid.adminFee + mybid.safetyDeposit) }}</td>
                  <td v-if="mybid.status == 'forfeited'" class="red--text font-weight-bold">Forfeited</td>
                  <td>
                    <v-btn v-if="mybid.status != 'forfeited'" @click="viewReceipt(mybid)">View Receipt</v-btn>
                    <span v-if="mybid.status == 'forfeited'" class="red--text font-weight-bold">Forfeited</span>
                  </td>
                  <td>
                    <v-btn v-if="mybid.status != 'forfeited'" @click="viewMemorandum(mybid)">Memorandum of Sales</v-btn>
                    <span v-if="mybid.status == 'forfeited'" class="red--text font-weight-bold">Forfeited</span>
                  </td>
                </tr>
                <tr>
                  <td></td><td></td><td></td><td></td><td></td><td></td><td></td>  
                  <td class="font-weight-bold">{{ ringgitFormat(calculateTotal(session)) }}</td>
                </tr>
                </tbody>
            </template>
          </v-simple-table>
          </v-card>
        </v-col>
        
        <!-- <v-row class="pt-2 ma-5" justify="center" align="center">
            <v-col class="pa-0 ma-0">        
                <v-row justify="center">
                    <v-card flat max-width="200">
                        <v-img  src="https://5.imimg.com/data5/TU/IM/JT/SELLER-6983250/under-construction-sign-board-500x500.jpg"></v-img>
                    </v-card>                
                </v-row>
                <v-row justify="center">
                    We will be right back.
                </v-row>                        
            </v-col>
        </v-row>     -->

        <!-- page -->
        <template>
          <div class="text-center mt-2">
            <v-pagination
              v-model="currentPage"
              :length="mybid.date.length"
              :total-visible="10"
            ></v-pagination>
          </div>
        </template>
      </v-col>  
    </v-row>
  </div>
</template>

<script>
import axios from "axios"
import serverConfig from "../jscontroller/serverConfig"
import storage from "../jscontroller/storage"
import receiptPDF from "../jscontroller/receiptPDF"
import memorandumPDF from "../jscontroller/memorandumPDF"
import MyAccountNav from '@/components/MyAccountNav'

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {MyAccountNav},
  name: 'OnlineAuction',
  data: () => ({    
    serverUrl: serverConfig.hostname,    
    user: {},
    mybid: {
      all: [],
      time: [],
      date: [],
    },
    currentPage: 1,
    logo: "",
    signature: "",    
  }),
  created(){
    this.user = storage.GetUser();
  },
  mounted(){
    if(!this.checkLoggedIn()){
      this.navigate("/login")
    }
    this.scrollToTop();
    this.getMyBidByEmail(this.user.id)    
    this.imgLoader(this.serverUrl + "/api/file/image/pdf/logo/logo2.png").then((picture)=>{
      this.logo = picture
    })
    this.imgLoader(this.serverUrl + "/api/file/image/general/website/signature.png").then((picture)=>{
      this.signature = picture
    })
  },
  methods:{
    navigate: function(pagePath){
      if(this.$route.path != pagePath){
        this.$router.push(pagePath);
      }            
    },
    scrollToTop(){
      window.scrollTo(0, 0);
    },
    ringgitFormat(amount){
      var currency = "RM"
      if(amount < 0){
        currency = "-RM"
      }
      if(amount == null){
        return currency + (0).toFixed(2);
      }
      else{
        var num = Math.abs(amount).toLocaleString(undefined, {maximumFractionDigits:2})
        var deci = num.split(".")[1]
        if(deci != null){
        if(deci.length == 1){
            num = num + "0"
        }
        
        }
        else{
        num = num + ".00"
        }
        return currency + num;
      }
    },
    checkLoggedIn(){
      var bool = false;
      var self = this;
      if(self.user != null){
        if(self.user.email != ""){
            bool = true;
        }
      }
      return bool;
    },
    showAuctionName(auctionName){
      var str = auctionName.split("/")
      return str[0] 
    },
    showDate(date){
      var d = new Date(date)
      var rd = d.getDate() + "/" + (d.getMonth()+1) + "/" + d.getFullYear()
      return rd
    },
    getMyBidByEmail(id){
      var self = this;      
      var apiUrl = self.serverUrl + "/api/mybid/get/userid"
      var bodyFormData = new FormData();
      bodyFormData.set('userId', id);
      axios.post(apiUrl, bodyFormData)
      .then(function (response) {
        var mybid = response.data; 
        for(var i = 0; i<mybid.length; i++){
          var exist = false;
          for(var j = 0; j<self.mybid.time.length; j++){
            if(self.mybid.time[j] == mybid[i].startDate+mybid[i].time){
              exist = true;
            }
          }          
          if(exist == false){
            self.mybid.time.unshift(mybid[i].startDate+mybid[i].time)
          }
          
          var dateExist = false
          for(var k = 0; k<self.mybid.date.length; k++){
            if(self.mybid.date[k] == mybid[i].startDate){
              dateExist = true;
            }
          }
          if(dateExist == false){
            self.mybid.date.unshift(mybid[i].startDate)
          }
        }

        for(var x=0; x<self.mybid.time.length; x++){
          var session = [];
          for(var y=0; y<mybid.length; y++){            
            if(self.mybid.time[x] == mybid[y].startDate+mybid[y].time){
              session.push(mybid[y]);
            }
          }
          self.mybid.all.push(session);
        }
      })
      .catch(function () {      
      });
    },
    calculateTotal(mybid){
      var total = 0;
      for(var i=0; i<mybid.length; i++){
        if(mybid[i].status != 'unsold')
          total = total + mybid[i].bidPrice + this.calculatePremium(mybid[i].premium, mybid[i].bidPrice) - mybid[i].deposit + mybid[i].adminFee + mybid[i].safetyDeposit
      }
      return total;
    },
    calculatePremium(premium, salePrice){
      if(premium > 1){
        return premium;
      }
      if(premium < 1 && premium> 0){
        var rtrnPremium = salePrice*premium;
        if(rtrnPremium < 600)
          rtrnPremium = 600;
        return rtrnPremium;
      }
      else
        return 0;
    },
    viewReceipt (mybid) {
      this.getAuctionLotLog(mybid, (itemDetails)=>{
        receiptPDF.open(mybid, itemDetails,this.user, this.logo, this.signature);       
      })
      
    },
    viewMemorandum(mybid){      
      this.getAuctionLotLog(mybid, (itemDetails)=>{
        memorandumPDF.open(mybid, itemDetails,this.user, this.logo)
      })
    },
    imgLoader(url){
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.src = url;
      })
    },
    getAuctionLotLog(mybid, callback){
      var apiUrl = serverConfig.hostname + "/api/auctionlot/get/log/" + mybid.particular      
      axios.get(apiUrl)
      .then(function (response) {
        var itemDetails = response.data
        callback(itemDetails)
      })
      .catch(function () {            
      });
    }, 
  },
  watch: {
    currentPage: function() {
      
      this.scrollToTop();
    }
  },
}
</script>
