<template>
  <v-card
    class="mx-auto ma-2"
    max-width="374"
  >
    <v-img
      max-height="250"
      :src="imgCover"
      :lazy-src="imgCover">  
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <!-- item name -->
    <v-card-title>Lot {{lot.id}}</v-card-title>
    
    <v-divider class="mx-4"></v-divider>
    <v-card-text>      
        <div class="my-0 subtitle-1 black--text">
            Regn No: {{lot.regnNo}}
        </div>
        <div class="my-0 subtitle-1 black--text">
        Model: {{lot.descOfGood}}
        </div>
        <div v-if="lot.status != 'sold'" class="my-0 subtitle-1 black--text">
        Starting price: <span class="font-weight-bold">{{ringgitFormat(lot.price)}}</span> 
        </div>
        <div v-if="lot.status == 'sold'" class="my-0 subtitle-1 black--text">
        Reserved price: <span class="font-weight-bold">{{ringgitFormat(lot.price)}}</span> 
        </div>
        <!-- <div v-if="lot.status == 'sold'" class="my-0 subtitle-1 red--text font-weight-bold">
        Sold Price: {{ringgitFormat(lot.salePrice)}}
        </div> -->

      <div>{{lot.remarks}}</div>
    </v-card-text>
    <div class="text-center">
        <router-link style="text-decoration:none" :to="'/itempagena/'+lot.id"><v-btn dark color="orange" class="mb-5">View Item</v-btn></router-link>
    </div>
    

  </v-card>
</template>

<script>
import axios from "axios"
import serverConfig from "../jscontroller/serverConfig"

export default {
  props: ['lot', 'ongoingBid'],
  data: () => ({    
    serverUrl: serverConfig.hostname,    
    imgCover: "",
    user: {},
  }),
  created(){
  },
  mounted(){
    this.getAuctionLotFileNamesQuery(this.lot.regnNo)
  },
  methods:{
    ringgitFormat(amount){
      if(amount == null){
          return "RM " + (0).toFixed(2);
      }
      else{
        var num = amount.toLocaleString(undefined, {maximumFractionDigits:2})
        var deci = num.split(".")[1]
        if(deci != null){
          if(deci.length == 1){
            num = num + "0"
          }
          
        }
        else{
          num = num + ".00"
        }
        return "RM " + num;
      }
    },
    getAuctionLotFileNamesQuery(regnNo){        
      var self = this;      
      var apiUrl = self.serverUrl + "/api/file/filenames"
      var bodyFormData = new FormData();
      bodyFormData.set('folderName', regnNo);
      bodyFormData.set('folderName2', 'auctionlot');
      bodyFormData.set('folderType', 'image');
      axios.post(apiUrl, bodyFormData)
      .then(function (response) {
          var photos = response.data;
          if(response.data != ""){
            self.imgCover = self.serverUrl + '/api/file/image/auctionlot/'+ regnNo +'/'+photos[0];               
            for(var i=0; i<photos.length; i++){
              if(photos[i] == "0" || photos[i] =="0.jpg")
                self.imgCover = self.serverUrl + '/api/file/image/auctionlot/'+ regnNo +'/'+photos[i];
            }
          }
          else
            self.imgCover = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1200px-No_image_available.svg.png"    
      })
      .catch(function () {      
        self.imgCover = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1200px-No_image_available.svg.png"
      });
    },
  }
}
</script>