<template>
    <div>
      
        <v-col class="pa-2 ma-0">
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Regn No:</span>
                <span>{{lot.regnNo}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Make:</span>
                <span>{{lot.make}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Particular:</span>
                <span>{{lot.descOfGood}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Reserved Price:</span>
                <span>{{ringgitFormat(lot.price)}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Status:</span>
                <span class="red--text font-weight-bold">{{lot.status}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Sold Price:</span>
                <span>{{ringgitFormat(lot.salePrice)}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Year:</span>
                <span>{{lot.year}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Transmission:</span>
                <span>{{lot.ma}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">VOC:</span>
                <span>{{lot.rc}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Vendor:</span>
                <span>{{lot.vendor}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Location:</span>
                <span>{{lot.location}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Contact Person:</span>
                <span>{{lot.contactPerson}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Remarks:</span>
                <span>{{lot.remarks}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Remark 1:</span>
                <span>{{lot.remark1}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Remark 2:</span>
                <span>{{lot.remark2}}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
                <span class="font-weight-bold mr-1">Deposit:</span>
                <span>{{lot.deposit}}</span>
            </v-row>
        </v-col>
    </div>
</template>

<script>
// import axios from "axios"
import serverConfig from "../jscontroller/serverConfig"

export default {
  props: ['lot','photos','currentPhoto'],
  data: () => ({    
    serverUrl: serverConfig.hostname,    
    // photos: [],   
    // currentPhoto: "",    
  }),
  mounted(){    
  },
  methods:{
    ringgitFormat(amount){
      if(amount == null){
          return "RM " + (0).toFixed(2);
      }
      else{
        var num = amount.toLocaleString(undefined, {maximumFractionDigits:2})
        var deci = num.split(".")[1]
        if(deci != null){
          if(deci.length == 1){
            num = num + "0"
          }
          
        }
        else{
          num = num + ".00"
        }
        return "RM " + num;
      }
    },
    
  }
}
</script>