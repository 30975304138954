<template>
  <div>
    <v-row class="ma-0 pa-0">
      <v-col cols="2" class="d-none d-md-flex pr-0">
        <MyAccountNav></MyAccountNav>
      </v-col>

      <v-col cols="12" md="10">
        <v-card-title class="pa-0 ma-0 mt-2 ml-5">Bid in Progress</v-card-title>
        <v-divider class="ma-2"></v-divider>
        <v-row
          v-if="countdownStatus == 'not started'"
          justify="center"
          class="headline"
          >Auction starts at {{ session.startTime }} on {{ session.startDate }}
        </v-row>
        <v-row
          v-if="countdownStatus == 'started'"
          justify="center"
          class="headline red--text"
          >Auction ends in: {{ timeLeft }}</v-row
        >
        <v-row
          v-if="countdownStatus == 'end'"
          justify="center"
          class="headline red--text"
          >Auction has ended</v-row
        >
        <v-row
          v-if="frontMessage != null"
          justify="center"
          class="headline red--text"
          >{{ frontMessage.info }}</v-row
        >
        <v-divider class="ma-1"></v-divider>
        <v-divider class="ma-1"></v-divider>
        <v-row
          v-if="countdownStatus == 'started'"
          class="ma-0 pa-0 text-center"
          justify="center"
        >
          <v-col class="ma-0 pa-0 ml-2" align-self="center">
            <div class="font-weight-bold">
              Click here to see the latest ongoing bidding.
            </div>
            <v-btn @click="getAllLotUserIsIn(user.id)" color="green" dark=""
              ><v-icon large="">mdi-refresh</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-1"
          >Your bidder no:
          <span class="font-weight-bold ml-1">{{ user.id }}</span></v-row
        >
        <v-card color="" class="pa-1 mx-0 mt-2">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Lot No</th>
                  <th class="text-left pl-5">Current Bid Price</th>
                  <th class="text-left pl-5">Status</th>
                  <th v-if="countdownStatus != 'end' && session != ''" class="text-left pl-5"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(lot, i) in LotSocket" :key="i">
                  <td>{{ lot.id }}</td>
                  <td>RM {{ ringgitFormat(lot.latestBid) }}</td>
                  <td
                    v-if="checkHighestBidder(lot.bidderNo)"
                    class="font-weight-bold green--text"
                  >
                    You are the highest bidder!
                  </td>
                  <td
                    v-if="!checkHighestBidder(lot.bidderNo)"
                    class="font-weight-bold red--text"
                  >
                    <span v-if="lot.bidderNo">Bidder #{{ lot.bidderNo }} is the highest bidder</span>
                    <span v-if="!lot.bidderNo">You have no bidding</span>
                  </td>
                  <td v-if="countdownStatus != 'end' && session != ''">
                    <v-btn
                      @click="navigate('/auctionpage/' + lot.id)"
                      color="orange lighten-4"
                      >Go to Auction Page</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
// import io from "socket.io-client";
import serverConfig from "../jscontroller/serverConfig";
import storage from "../jscontroller/storage";
import MyAccountNav from "@/components/MyAccountNav";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { MyAccountNav },
  name: "OnlineAuction",
  data: () => ({
    serverUrl: serverConfig.hostname,
    // socket: {},
    user: {},
    userInLot: [],
    LotSocket: [],
    logo: "",
    session: {},
    timeLeft: "",
    frontMessage: null,
    countdownStatus: "no",
    snackbar: {
      showProgress: false,
      showStatus: false,
      text: "",
      timeout: 4000,
      icon: "mdi-check-circle-outline",
      color: "green",
    },
  }),
  created() {
    this.user = storage.GetUser();
    // this.socket = io.connect(serverConfig.hostnameSocket, {path: '/socket.io'});
  },
  mounted() {
    var self = this;
    if (!this.checkLoggedIn()) {
      this.navigate("/login");
    }
    this.scrollToTop();
    this.getAllLotUserIsIn(this.user.id);
    this.getFrontMessageQuery();
    this.getLatestSession((session) => {
      if (session != "") {
        self.session = session;
        self.getServerTime((serverTime) => {
          var currentTime = new Date();
          setInterval(() => {
            self.countdown(session, serverTime, currentTime);
          }, 1000);
        });
      } else {
        self.session = "";
      }
    });
    // this.socket.on("updateBidInProgress", ()=>{
    //   this.getAllLotUserIsIn(this.user.email);
    // });
  },
  methods: {
    navigate: function(pagePath) {
      if (this.$route.path != pagePath) {
        this.$router.push(pagePath);
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    ringgitFormat(amount) {
      if (amount == null) {
        return (0).toFixed(2);
      } else {
        return amount.toFixed(2);
      }
    },
    checkLoggedIn() {
      var bool = false;
      var self = this;
      if (self.user != null) {
        if (self.user.email != "") {
          bool = true;
        }
      }
      return bool;
    },
    checkHighestBidder(id) {
      var self = this;
      if (id == self.user.id) return true;
      else return false;
    },
    getAllLotUserIsIn(id) {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/socket/userinlot/findall";
      var bodyFormData = new FormData();
      bodyFormData.set("userId", id);
      axios
        .post(apiUrl, bodyFormData)
        .then(function(response) {
          self.userInLot = response.data;
          self.LotSocket = [];
          for (var i = 0; i < self.userInLot.length; i++) {
            self.getAuctionSocketById(self.userInLot[i].lotNo);
          }
        })
        .catch(function() {});
    },
    getAuctionSocketById(id) {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/socket/auctionlot/" + id;
      axios
        .get(apiUrl)
        .then(function(response) {
          if (response.data == "") {
            self.LotSocket.push({
              id: id,
              latestBid: 0,
              latestEmail: "",
              bidderNo: null,
            });
          } else {
            if (self.countdownStatus != 'end' && self.session != '') {
              self.LotSocket.push(response.data);
            }else{
              if(response.data.bidderNo == self.user.id)
                self.LotSocket.push(response.data);      
            }
          }
          self.hideSnackbar("Finish fetching latest bid..", "green");
          self.LotSocket.sort(self.compare);
        })
        .catch(function() {});
    },
    compare(a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    },
    getLatestSession(callback) {
      // var self = this;
      var apiUrl =
        serverConfig.hostname + "/api/auctionmanager/get/session/latest";
      axios
        .get(apiUrl)
        .then(function(response) {
          callback(response.data);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {});
    },
    getServerTime(callback) {
      var apiUrl = serverConfig.hostname + "/api/time/get/monthdayformat";
      axios
        .get(apiUrl)
        .then(function(response) {
          callback(response.data);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {});
    },
    countdown(session, serverTime, currentTime) {
      var self = this;
      var countDownEnd = new Date(
        `${session.endDate} ${session.endTime} +8:00`
      ).getTime();
      var countDownStart = new Date(
        `${session.startDate} ${session.startTime} +8:00`
      ).getTime();
      var timeLeft = "";

      var now =
        new Date(serverTime).getTime() +
        new Date().getTime() -
        new Date(currentTime).getTime();
      if (now < countDownStart && now < countDownEnd) {
        self.countdownStatus = "not started";
      } else if (now > countDownStart && now < countDownEnd) {
        self.countdownStatus = "started";
      } else if (now > countDownStart && now > countDownEnd) {
        self.countdownStatus = "end";
        // self.navigate('/itempage/' + self.lotId);
      }
      // Find the distance between now and the count down date
      var distance = countDownEnd - now;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      timeLeft = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
      this.timeLeft = timeLeft;
    },
    getFrontMessageQuery() {
      var self = this;
      var apiUrl = self.serverUrl + "/api/toggleinfo/get/id/10";
      axios
        .get(apiUrl)
        .then(function(response) {
          self.frontMessage = response.data;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {});
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red" || color == "orange") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
  },
  watch: {},
};
</script>
