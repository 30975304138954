// import jsPDF from 'jspdf'
// import 'jspdf-autotable';
const jsPDF = require("jspdf");
require("jspdf-autotable");

function open(mybid, itemDetails, user, logo, signature) {
  var doc = new jsPDF();
  var marginLeft = 20;
  var marginTop = 25;

  doc.addImage(logo, "JPEG", marginLeft - 5, marginTop - 8, 60, 25);
  doc.setFontSize(10);
  doc.setFontType("bold");
  doc.text(80, marginTop - 3, "AUCTION MANAGEMENT SERVICES SDN BHD");
  doc.setFontSize(7);
  doc.setFontType("normal");
  doc.text(162, marginTop - 3, "(1322220-W)");
  doc.setFontSize(7);
  doc.setFontType("bold");
  doc.text(
    marginLeft + 60,
    marginTop,
    "(formerly known as Fleet Management Solution Sdn Bhd)"
  );
  doc.setFontSize(7);
  doc.setFontType("normal");
  doc.text(
    80,
    marginTop + 3,
    "Kompleks Telekom, Lot 1, Persiaran Jubli Perak, Seksyen 17, 40000 Shah Alam,"
  );
  doc.text(80, marginTop + 6, "Selangor Darul Ehsan.");
  doc.text(80, marginTop + 9, "Tel : 03-5545 1176");
  doc.text(105, marginTop + 9, "Fax : 03-5541 0617");
  doc.text(80, marginTop + 12, "Website : www.amsauction.com.my");

  doc.setFontSize(10);
  doc.setFontType("bold");
  doc.text(90, marginTop + 22, "OFFICIAL RECEIPT");

  doc.setFontSize(8);
  doc.setFontType("bold");
  doc.setTextColor(255, 0, 0);

  var checkPaid = calculateTotal(mybid) - mybid.amountPaid - mybid.deposit;
  if (checkPaid > 0) {
    doc.text(160, marginTop + 27, "DEPOSIT");
  } else {
    doc.text(153, marginTop + 27, "FULL SETTLEMENT");
  }

  doc.setFontSize(8);
  doc.setTextColor("black");
  doc.setFontType("normal");
  doc.text(marginLeft, marginTop + 34, "Date :");
  doc.rect(marginLeft + 25, marginTop + 30, 75, 6);
  // var date = new Date(mybid.date);
  var date = new Date();
  doc.text(
    marginLeft + 53,
    marginTop + 34,
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
  doc.text(130, marginTop + 34, "OR No. :");
  doc.rect(150, marginTop + 30, 35, 6);
  doc.setFontType("bold");
  doc.text(160, marginTop + 34, mybid.auctionName);

  doc.setFontType("normal");
  doc.text(marginLeft, marginTop + 43, "Received from :");
  doc.rect(marginLeft + 25, marginTop + 38, 75, 8);
  const displayName = doc.splitTextToSize(user.name, 70);
  doc.text(marginLeft + 28, marginTop + 41.5, displayName);
  doc.text(130, marginTop + 43, "Auction Date :");
  doc.rect(150, marginTop + 39, 35, 6);
  var auctionDate = new Date(mybid.startDate);
  doc.text(
    160,
    marginTop + 43,
    auctionDate.getDate() +
      "/" +
      (auctionDate.getMonth() + 1) +
      "/" +
      auctionDate.getFullYear()
  );

  doc.text(marginLeft, marginTop + 52, "Bidder No. :");
  doc.rect(marginLeft + 25, marginTop + 48, 75, 6);
  doc.text(marginLeft + 55, marginTop + 52, String(user.id));

  let columns = [
    { title: "No.", dataKey: "no" },
    { title: "Regn. No./ Trailer No.", dataKey: "regn" },
    { title: "Lot No.", dataKey: "lotno" },
    { title: "Make & Model / Description of Goods", dataKey: "make" },
    { title: "Ref.", dataKey: "ref" },
    { title: "Selling Price (RM)", dataKey: "price" },
    { title: "Buyer's Premium (RM)", dataKey: "premium" },
    { title: "Total (RM)", dataKey: "total" },
  ];
  let rows = [];
  var auctionDeposit = mybid.deposit + mybid.additionalDepositPaid;
  var adminFee = mybid.adminFee;
  var amountPaid = mybid.amountPaid;
  // for multiple items
  // for(var i=0; i<session.length; i++){
  // var item = {
  //     no: i+1,
  //     regn: session[i].particular,
  //     lotno: session[i].lotNo,
  //     make: session[i].make,
  //     ref: "",
  //     price: session[i].bidPrice.toFixed(2),
  //     premium: session[i].premium.toFixed(2),
  //     total: (session[i].bidPrice + 0).toFixed(2),
  // }
  // auctionDeposit = auctionDeposit + session[0].deposit;
  // adminFee = adminFee + 0;
  // rows.push(item)
  // }

  //for single item
  var item = {
    no: 1,
    regn: mybid.particular,
    lotno: mybid.lotNo,
    make: checkText(itemDetails.make) + " " + checkText(itemDetails.descOfGood),
    ref: checkText(itemDetails.vendor),
    price: mybid.bidPrice.toFixed(2),
    premium: calculatePremium(mybid.premium, mybid.bidPrice).toFixed(2),
    total: (
      mybid.bidPrice + calculatePremium(mybid.premium, mybid.bidPrice)
    ).toFixed(2),
  };
  rows.push(item);

  var total = calculateTotal(mybid);
  var totalBalance =
    calculateTotal(mybid) -
    mybid.amountPaid -
    mybid.deposit -
    mybid.additionalDepositPaid;

  doc.autoTable({
    margin: { top: 83 },
    theme: "striped",
    columns: columns,
    body: rows,
  });

  var marginAfterTable = 100 + 7.5 * rows.length;
  var tableSpace = 7.5;
  var makeLines = 0;
  if (item.make.length > 20 && item.make.length < 34) {
    makeLines = Math.floor(item.make.length / 20);
    marginAfterTable = marginAfterTable + 4 * makeLines;
  }
  if (item.make.length > 34) {
    makeLines = Math.floor(item.make.length / 34);
    marginAfterTable = marginAfterTable + 8 * makeLines;
  }
  doc.setLineWidth(0.5);
  doc.line(14, marginAfterTable - 5, 196, marginAfterTable - 5);
  doc.setFontSize(10);
  doc.setFontType("normal");
  doc.setTextColor(80, 80, 80);
  doc.text(120, marginAfterTable, "SAFETY DEPOSIT (REFUNDABLE)");
  doc.text(182, marginAfterTable, mybid.safetyDeposit.toFixed(2));
  doc.text(120, marginAfterTable + tableSpace, "ADMINISTRATIVE FEE");
  doc.text(182, marginAfterTable + tableSpace, adminFee.toFixed(2));
  doc.setFontType("bold");
  doc.setTextColor("black");
  doc.text(120, marginAfterTable + tableSpace * 2, "TOTAL");
  doc.text(182, marginAfterTable + tableSpace * 2, total.toFixed(2));
  doc.setFontType("normal");
  doc.setTextColor(80, 80, 80);
  doc.text(
    120,
    marginAfterTable + tableSpace * 3,
    "DEPOSIT PAID DURING AUCTION"
  );
  doc.text(182, marginAfterTable + tableSpace * 3, auctionDeposit.toFixed(2));
  doc.text(120, marginAfterTable + tableSpace * 4, "AMOUNT PAID");
  doc.text(182, marginAfterTable + tableSpace * 4, amountPaid.toFixed(2));
  doc.setFontType("bold");
  doc.setTextColor("black");
  doc.text(120, marginAfterTable + tableSpace * 5, "TOTAL BALANCE TO BE PAID");
  doc.text(182, marginAfterTable + tableSpace * 5, totalBalance.toFixed(2));

  doc.setFontSize(8);
  doc.setFontType("normal");
  doc.setTextColor("black");
  var topMargin1 = marginAfterTable + tableSpace * 5 + 5;
  doc.text(marginLeft - 5, topMargin1 + 10, "Note:");
  doc.setFontType("bold");
  var dateline = new Date(auctionDate.getTime() + 3600 * 1000 * 24 * 7);
  // var text1 = "Balance of payment must be paid on or before "+dateline.getDate()+"/"+(1+dateline.getMonth())+"/"+dateline.getFullYear()+" after which sale will be cancelled and all deposit will be forfeited without prior notice.";
  var text1 =
    "Balance of payment must be paid on or before " +
    dateline.getDate() +
    "/" +
    (1 + dateline.getMonth()) +
    "/" +
    dateline.getFullYear() +
    " after which sale will be cancelled and all deposit will be forfeited without prior notice. A storage fee of RM100.00 will be charged calculated on daily basis per unit if the bidder fails to collect the auction goods after 14 days from auction date.";
  var lines = doc.splitTextToSize(text1, 100);
  doc.text(marginLeft + 10, topMargin1 + 10, lines);
  doc.setFontType("normal");
  var text2 =
    "All payments can be made to Auction Management Services Sdn Bhd either by cash, banker's cheque (bank draft) or telegraphic transfer ";
  var lines2 = doc.splitTextToSize(text2, 100);
  doc.text(marginLeft + 10, topMargin1 + 30, lines2);
  doc.setFontType("bold");
  doc.setFontSize(10);
  // doc.text(marginLeft+10, topMargin1+38, "Maybank A/C No. : 564119109232")
  doc.text(
    marginLeft + 10,
    topMargin1 + 38,
    "CIMB Bank Bhd A/C No. : 8604040785"
  );

  doc.setFontType("normal");
  doc.setFontSize(7);
  doc.text(145, topMargin1 + 10, "Auction Management Services Sdn Bhd");
  doc.setDrawColor(0, 0, 0);
  doc.line(140, topMargin1 + 25, 196, topMargin1 + 25);
  doc.text(157, topMargin1 + 29, "Mohd Nizam Jailani");
  doc.text(158, topMargin1 + 33, "General Manager");
  doc.text(161, topMargin1 + 37, "012-6975866");
  doc.addImage(signature, "JPEG", 123, topMargin1 + 10, 60, 37.1);

  var topMargin2 = topMargin1 + 40;
  doc.text(marginLeft - 5, topMargin2 + 7, "NOTE 1 :");
  doc.setDrawColor(60, 60, 60);
  doc.rect(marginLeft + 19, topMargin2 + 4, 138, 40);

  // Notes here
  var note1_t1 =
    "IN ORDER TO AVOID ANY FURTHER COMPLICATIONS ON FUTURE EVENTS THE BUYER MUST AGREES TO CHANGE OWNERSHIP AND REGISTER THE GOODS INTO HIS/HER/COMPANY NAME BY:";
  var note1_t2 =
    '1. INSPECTION WITH PUSPAKOM FOR "SIJIL PEMERIKSAAN TUKARNAMA - B5"';
  var note1_t3 = "2. INFORM TMFA PERSONNEL FOR THUMBPRINT PROCESS";
  var note1_t4 =
    "3. CHANGE OF OWNERSHIP WITH THE DEPARTMENT OF MOTOR VEHICLES (JABATAN PENGANGKUTAN JALAN - JPJ) WITHIN TWO (2) WEEKS IN-RELATION WITH SEKSYEN 13 - AKTA PENGANGKUTAN JALAN 1987";

  var note1_l1 = doc.splitTextToSize(note1_t1, 135);
  var note1_l2 = doc.splitTextToSize(note1_t2, 135);
  var note1_l3 = doc.splitTextToSize(note1_t3, 135);
  var note1_l4 = doc.splitTextToSize(note1_t4, 135);

  var note2_t1 =
    "SPECIAL TERMS & CONDITIONS FOR SCRAP ITEMS: FULL PAYMENT MUST BE MADE TOGETHER WITH THE ADMINISTRATION FEE OF RM300.00 PER UNIT (NOT REFUNDABLE). NO TRANSFER DOCUMENTS WILL BE GIVEN FOR SPARE PARTS / SCRAP USE ONLY. BUYER MUST CUT THE SCRAP ITEMS/TRAILERS AT KONTENA NASIONAL BERHAD DESIGNATED AREA BEFORE TAKING DELIVERY OF THE SCRAP ITEMS/TRAILERS. FOR SAFETY PURPOSES, BUYER MUST ENSURE THE CUTTING PROCESS FOLLOW OSHA RULES & REGULATIONS INCLUDING ANY PARTICULAR LICENSE OR PERMIT REQUIRED BY LAW IN MALAYSIA. ALL COST FOR CUTTING THE TRAILERS WILL BE BORNED BY THE BUYER. BUYER MUST COMPLY WITH ALL SAFETY REQUIREMENTS INCLUDING FULL PPE OUTFIT, USAGE OF PROPER CUTTING GAS TANK (INDUSTRIAL) AND FIRE EXTINGUISHERS. USAGE OF COOKING GAS TANK IS STRICTLY PROHIBITED. USAGE OF CRANE MUST BE ACCOMPANIED BY PROPER PMA LISENCE. BUYER WILL HAVE TO FILL UP FORMS AND INDEMNITY LETTER IN ORDER TO AQUIRE WORK PERMIT FROM KONTENA NASIONAL BERHAD. A REPRESENTATIVE FROM KONTENA NASIONAL BERHAD WILL WITNESS AND VERIFY THE TRAILER BEFORE DELIVERY. BID AT YOUR OWN RISK.";
  var note2_l1 = doc.splitTextToSize(note2_t1, 135);

  var note3_t1 =
    "ALL INTENDED BIDDERS ARE REQUIRED TO PAY AUCTION DEPOSIT SUM OF RM1,500.00 PER LOT ON AUCTION DAY WHICH IS REFUNDABLE IF THE TRANSFER IS UNSUCCESSFUL DUE TO KNB'S DELAY IN PREPARING AND EXECUTING THE NECESSARY DOCUMENTATIONS FOR THE TRANSFER. IN CASE OF SUCCESSFUL BID, THE AMOUNT WILL BE TREATED AS SAFETY DEPOSIT AND WILL ONLY BE REFUNDED UPON CLEARANCE OF AUCTION ITEMS FROM KONTENA NASIONAL BERHAD PREMISE, SUBJECT TO THERE BEING NO DAMAGES ONTO KNB'S PREMISE AND/OR ANY EQUIPMENT/ITEMS/FIXTURES THEREON DURING THE INSPECTION OF THE EQUIPMENT AND/OR THE REMOVAL OF THE EQUIPMENT FROM KNB'S PREMISE.";
  var note3_l1 = doc.splitTextToSize(note3_t1, 135);

  var note4_t1 =
    "FULL PAYMENT MUST BE MADE TOGETHER WITH THE ADMINISTRATION FEE OF RM300.00 PER UNIT (NOT REFUNDABLE). ALL LORRIES, PRIME MOVERS, TRAILERS, PORT TRAILERS AND SIDE LOADERS MUST BE INSPECTED BY PUSPAKOM AND TRANSFER OF OWNERSHIP (OFF-ROAD) TO BE DONE BEFORE DELIVERY (MANDATORY). BUYER IS REQUIRED TO ARRANGE WITH PUSPAKOM FOR THE INSPECTION TO BE DONE IN KNB PREMISES AND MAKE THE TRANSFER OF OWNERSHIP AFTERWARDS. ALL COSTS WILL BE BORNED BY THE BUYER. BUYER MUST TAKE DELIVERY OF AUCTION GOODS WITHIN THE STIPULATED TIME OR WHEN REQUESTED BY THE AUCTIONEER (ITEM 7 AMS E-AUCTION TERMS & CONDITIONS).";
  var note4_l1 = doc.splitTextToSize(note4_t1, 135);

  var note5_t1 =
    "THE BUYER MUST TAKE DELIVERY OF AUCTION GOODS WITHIN THE STIPULATED TIME OR WHEN REQUESTED BY THE AUCTIONEER (ITEM 7 AMS E-AUCTION TERMS & CONDITIONS OF SALE).";
  var note5_l1 = doc.splitTextToSize(note5_t1, 135);

  var note6_t1 =
    "Bidders are required to pay a sum of RM500/unit as a Safety Deposit (refundable upon completion of ownership transfer). Bidders are given 3 months (from the date of auction) to complete the transfer of ownership, failing which the Safety Deposit will be forfeited and any appeal will not be entertained. Refund of the Safety Deposit is subjected to :";
  var note6_t2 =
    "1. Bidder to submit proof of transfer of ownership (copy of registration card or E-Auto slip). ";
  var note6_t3 =
    "2. Vehicle is free from any summons issued by JPJ/PDRM or any relevant authorities/local councils from the date of vehicle delivery until completion of transfer.";
  var note6_t4 =
    "3. Bidders agree to be responsible for any late issuance of summons due to system delay (if any). AMS will notify the bidder on any late summons.";
  var note6_t5 = "4. Approval by vendor.";
  var note6_t6 =
    "Bidders must take delivery of auction goods within the stipulated time or when requested by the auctioneer (item 7 AMS E-Auction Terms & Conditions of Sale).";

  var note6_l1 = doc.splitTextToSize(note6_t1, 135);
  var note6_l2 = doc.splitTextToSize(note6_t2, 134);
  var note6_l3 = doc.splitTextToSize(note6_t3, 134);
  var note6_l4 = doc.splitTextToSize(note6_t4, 134);
  var note6_l5 = doc.splitTextToSize(note6_t5, 134);
  var note6_l6 = doc.splitTextToSize(note6_t6, 134);
  // end notes

  var text3 = checkText(itemDetails.remark1);

  if (text3.toUpperCase() == "NOTE 1") {
    doc.text(marginLeft + 20, topMargin2 + 7, note1_l1);
    doc.text(marginLeft + 23, topMargin2 + 15, note1_l2);
    doc.text(marginLeft + 23, topMargin2 + 19, note1_l3);
    doc.text(marginLeft + 23, topMargin2 + 23, note1_l4);
  } else if (text3.toUpperCase() == "NOTE 2") {
    doc.text(marginLeft + 20, topMargin2 + 7, note2_l1);
  } else if (text3.toUpperCase() == "NOTE 3") {
    doc.text(marginLeft + 20, topMargin2 + 7, note3_l1);
  } else if (text3.toUpperCase() == "NOTE 4") {
    doc.text(marginLeft + 20, topMargin2 + 7, note4_l1);
  } else if (text3.toUpperCase() == "NOTE 5") {
    doc.text(marginLeft + 20, topMargin2 + 8, note5_l1);
  } else if (text3.toUpperCase() == "NOTE 6") {
    doc.text(marginLeft + 20, topMargin2 + 8, note6_l1);
    doc.text(marginLeft + 22, topMargin2 + 17, note6_l2);
    doc.text(marginLeft + 22, topMargin2 + 20, note6_l3);
    doc.text(marginLeft + 22, topMargin2 + 26, note6_l4);
    doc.text(marginLeft + 22, topMargin2 + 32, note6_l5);
    doc.text(marginLeft + 22, topMargin2 + 35, note6_l6);
  } else {
    var lines3 = doc.splitTextToSize(text3, 130);
    doc.text(marginLeft + 20, topMargin2 + 7, lines3);
  }

  doc.text(marginLeft - 5, topMargin2 + 47, "NOTE 2 :");
  doc.rect(marginLeft + 19, topMargin2 + 44, 138, 25);
  var text4 = checkText(itemDetails.remark2);

  if (text4.toUpperCase() == "NOTE 1") {
    topMargin2 = topMargin2 + 40;
    doc.text(marginLeft + 20, topMargin2 + 7, note1_l1);
    doc.text(marginLeft + 23, topMargin2 + 15, note1_l2);
    doc.text(marginLeft + 23, topMargin2 + 19, note1_l3);
    doc.text(marginLeft + 23, topMargin2 + 23, note1_l4);
    topMargin2 = topMargin2 - 40;
  } else if (text4.toUpperCase() == "NOTE 2") {
    doc.text(marginLeft + 20, topMargin2 + 47, note2_l1);
  } else if (text4.toUpperCase() == "NOTE 3") {
    doc.text(marginLeft + 20, topMargin2 + 47, note3_l1);
  } else if (text4.toUpperCase() == "NOTE 4") {
    doc.text(marginLeft + 20, topMargin2 + 47, note4_l1);
  } else if (text4.toUpperCase() == "NOTE 5") {
    doc.text(marginLeft + 20, topMargin2 + 47, note5_l1);
  } else if (text4.toUpperCase() == "NOTE 6") {
    topMargin2 = topMargin2 + 39;
    doc.text(marginLeft + 20, topMargin2 + 8, note6_l1);
    doc.text(marginLeft + 22, topMargin2 + 17, note6_l2);
    doc.text(marginLeft + 22, topMargin2 + 20, note6_l3);
    doc.text(marginLeft + 22, topMargin2 + 26, note6_l4);
    doc.text(marginLeft + 22, topMargin2 + 32, note6_l5);
    doc.text(marginLeft + 22, topMargin2 + 35, note6_l6);
    topMargin2 = topMargin2 - 39;
  } else {
    var lines4 = doc.splitTextToSize(text4, 130);
    doc.text(marginLeft + 20, topMargin2 + 47, lines4);
  }

  doc.text(marginLeft - 5, topMargin2 + 77, "Special Note :");
  doc.rect(marginLeft + 19, topMargin2 + 74, 138, 10);
  var text5 = checkText(itemDetails.location);
  var lines5 = doc.splitTextToSize(text5, 130);
  doc.text(marginLeft + 20, topMargin2 + 77, lines5);
  doc.text(
    marginLeft - 5,
    topMargin2 + 90,
    "Note : This receipt is valid subject to clearance of cheque(s)"
  );
  doc.text(marginLeft - 5, topMargin2 + 94, "E & O.E.");

  window.open(doc.output("bloburl"), "_blank");
  // doc.save(pdfName + '.pdf');
}

// for multiple items
// function calculateTotal(mybid){
//     var total = 0;
//     for(var i=0; i<mybid.length; i++){
//     total = total + mybid[i].bidPrice + mybid[i].premium - mybid[i].deposit
//     }
//     return total;
// }

// for single item
function calculateTotal(mybid) {
  var total = 0;
  var adminFee = mybid.adminFee;
  total =
    total +
    mybid.bidPrice +
    calculatePremium(mybid.premium, mybid.bidPrice) +
    mybid.safetyDeposit +
    adminFee;
  return total;
}
function calculatePremium(premium, salePrice) {
  if (premium > 1) {
    return premium;
  }
  if (premium < 1 && premium > 0) {
    var rtrnPremium = salePrice * premium;
    if (rtrnPremium < 600) rtrnPremium = 600;
    return rtrnPremium;
  } else return 0;
}
function checkText(text) {
  if (text == null) {
    return "";
  } else {
    return text;
  }
}

module.exports = { open };
