var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-none d-sm-flex"},[_c('v-app-bar',{attrs:{"height":"380","color":_vm.navColor(_vm.showNav)}},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-row',{staticClass:" text-right",attrs:{"align":"center","justify":"center"}},[_c('v-img',{attrs:{"max-height":"500","max-width":"500","src":require("../assets/amsauction_logo.png"),"lazy-src":require("../assets/amsauction_logo.png")}})],1),_c('div',{staticClass:"subheading text-right white--text"},[_vm._v(" Auction Management Services Sdn Bhd "),_c('span',{staticClass:"black--text"},[_vm._v("(1322220-W)")])]),_c('div',{staticClass:"body-2 text-right"},[_vm._v(" (formerly known as Fleet Management Solution Sdn Bhd) ")]),_c('v-row',[_c('v-col',{staticClass:"pa-0 ma-0 flex-grow-0 flex-shrink-1",attrs:{"cols":"10"}},[(!_vm.showNav)?_c('Navbar2',{attrs:{"storageUser":_vm.user,"noOfLoseBid":_vm.noOfLoseBid,"width":"90%"}}):_vm._e(),(_vm.showNav)?_c('AdminNavbar',{attrs:{"storageUser":_vm.user}}):_vm._e()],1),_c('v-col',{staticClass:"flex-grow-1 flex-shrink-0 pa-0 ma-0",attrs:{"cols":"2"}},[(
                (_vm.user.role == 'admin' || _vm.user.role == 'vendor') && _vm.showNav
              )?_c('v-tabs',{attrs:{"background-color":"rgb(15, 106, 210)","dark":"","color":"","align":"center"}},[_c('v-spacer'),(_vm.checkLoggedIn())?_c('v-btn',{staticClass:"ma-1",attrs:{"color":""},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Logout")]):_vm._e()],1):_vm._e(),(
                (_vm.user.role != 'admin' && _vm.user.role != 'vendor') || !_vm.showNav
              )?_c('v-tabs',{attrs:{"background-color":"#d26e0f","dark":"","color":"","align":"center"}},[_c('v-spacer'),(!_vm.checkLoggedIn())?_c('router-link',{staticClass:"ma-1",staticStyle:{"text-decoration":"none"},attrs:{"to":{ path: '/login' }}},[_c('v-btn',{attrs:{"color":""}},[_vm._v("Login")])],1):_vm._e(),(_vm.checkLoggedIn())?_c('v-btn',{staticClass:"ma-1",attrs:{"color":""},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Logout")]):_vm._e()],1):_vm._e()],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex d-sm-none mx-auto overflow-hidden"},[_c('v-app-bar',{attrs:{"color":"#ffa11f","dark":"","app":_vm.removeAppMargin()}},[(_vm.noOfLoseBid > 0)?_c('v-badge',{attrs:{"overlap":"","left":"","offset-x":"","offset-y":"25","color":"red","content":_vm.noOfLoseBid}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = true}}})],1):_vm._e(),(!_vm.noOfLoseBid > 0)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = true}}}):_vm._e(),_c('v-toolbar-title',[_vm._v("AMS Auction")])],1),_c('v-navigation-drawer',{attrs:{"app":"","temporary":"","height":"100%"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-card',[_c('v-img',{staticClass:"text-center",attrs:{"src":require("../assets/amsauction_logo.png"),"gradient":"to top right, rgba(197, 197, 197, 0.39), rgba(255, 255, 255, 0.17)"}})],1),_c('v-list',{attrs:{"nav":"","dense":""}},[(_vm.user != null)?_c('div',{staticClass:"ml-2 font-italic"},[_vm._v(" Welcome "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.user.name))])]):_vm._e(),_c('v-divider'),_c('v-list-item-group',{attrs:{"active-class":"deep-orange--text text--accent-4"}},[_vm._l((_vm.pages),function(page,i){return _c('v-list-item',{key:i,on:{"click":function($event){_vm.drawer = false;
              _vm.navigate(page.path);}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(page.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(page.name))])],1)}),_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1),_c('v-list-item-title',[_vm._v("Help")])]},proxy:true}])},[_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/help/termsAndCondition');}}},[_c('v-list-item-title',[_vm._v("T&C")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/help/bidderProcess');}}},[_c('v-list-item-title',[_vm._v("Bidder Process")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/help/onlinePayment');}}},[_c('v-list-item-title',[_vm._v("Online Payment")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/help/tutorial');}}},[_c('v-list-item-title',[_vm._v("Tutorial")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/help/contactUs');}}},[_c('v-list-item-title',[_vm._v("Contact Us")])],1)],1),(!_vm.checkLoggedIn())?_c('v-btn',{attrs:{"block":"","color":"orange","dark":""},on:{"click":function($event){return _vm.navigate('/login')}}},[_vm._v("Login")]):_vm._e(),(_vm.checkLoggedIn())?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[(_vm.noOfLoseBid > 0)?_c('v-badge',{attrs:{"offset-x":"","offset-y":"10","color":"red","content":_vm.noOfLoseBid}},[_c('v-icon',[_vm._v("mdi-account")])],1):_vm._e(),(!_vm.noOfLoseBid > 0)?_c('v-icon',[_vm._v("mdi-account")]):_vm._e()],1),_c('v-list-item-title',[_vm._v("Account")])]},proxy:true}],null,false,3201166801)},[_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/myaccount/mybid');}}},[_c('v-list-item-title',[_vm._v("My Bid")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/myaccount/bidonline');}}},[(_vm.noOfLoseBid > 0)?_c('v-badge',{attrs:{"offset-x":"","offset-y":"10","color":"red","content":_vm.noOfLoseBid}},[_c('v-list-item-title',[_vm._v("Bid In Progress")])],1):_vm._e(),(!_vm.noOfLoseBid > 0)?_c('v-list-item-title',[_vm._v("Bid In Progress")]):_vm._e()],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/myaccount/transaction');}}},[_c('v-list-item-title',[_vm._v("Transaction")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/myaccount/myprofile');}}},[_c('v-list-item-title',[_vm._v("My Profile")])],1)],1):_vm._e(),(_vm.user.role == 'admin' && _vm.user.id <= 2)?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-head-minus-outline")])],1),_c('v-list-item-title',[_vm._v("Admin")])]},proxy:true}],null,false,1358430223)},[_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/adminPage');}}},[_c('v-list-item-title',[_vm._v("Homepage Manager")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/adminPage/mailing');}}},[_c('v-list-item-title',[_vm._v("Mailing")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/adminPage/auctionManager');}}},[_c('v-list-item-title',[_vm._v("Auction Manager")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/adminPage/userManager');}}},[_c('v-list-item-title',[_vm._v("User Manager")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/adminPage/documentManager');}}},[_c('v-list-item-title',[_vm._v("Document Manager")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/adminPage/unitMonitor');}}},[_c('v-list-item-title',[_vm._v("Unit Monitor")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/adminPage/reports');}}},[_c('v-list-item-title',[_vm._v("Reports")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/adminPage/itemRecords');}}},[_c('v-list-item-title',[_vm._v("Item Records")])],1)],1):_vm._e(),(
              _vm.user.role == 'vendor' || (_vm.user.role == 'admin' && _vm.user.id > 2)
            )?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-head-minus-outline")])],1),_c('v-list-item-title',[_vm._v("Admin")])]},proxy:true}],null,false,1358430223)},[_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/adminPage/userManager');}}},[_c('v-list-item-title',[_vm._v("User Manager")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/adminPage/unitMonitor');}}},[_c('v-list-item-title',[_vm._v("Unit Monitor")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/adminPage/reports');}}},[_c('v-list-item-title',[_vm._v("Reports")])],1),_c('v-list-item',{staticClass:"ml-5 pl-5",on:{"click":function($event){_vm.drawer = false;
                _vm.navigate('/adminPage/itemRecords');}}},[_c('v-list-item-title',[_vm._v("Item Records")])],1)],1):_vm._e(),(_vm.checkLoggedIn())?_c('v-btn',{staticClass:"ma-0 pa-0",attrs:{"block":"","color":""},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Logout")]):_vm._e()],2)],1)],1)],1),_c('v-snackbar',{class:_vm.snackbar.class1,attrs:{"timeout":_vm.snackbar.timeout},model:{value:(_vm.snackbar.show1),callback:function ($$v) {_vm.$set(_vm.snackbar, "show1", $$v)},expression:"snackbar.show1"}},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"justify":"center"}},[_c('v-icon',{attrs:{"large":"","color":"green"}},[_vm._v("mdi-arrow-top-right-thick")]),_c('v-col',{staticClass:"pa-0 ma-0 headline white--text"},[_vm._v(" "+_vm._s(_vm.snackbar.text1)+" ")])],1)],1),_c('v-snackbar',{class:_vm.snackbar.class2,attrs:{"timeout":_vm.snackbar.timeout},model:{value:(_vm.snackbar.show2),callback:function ($$v) {_vm.$set(_vm.snackbar, "show2", $$v)},expression:"snackbar.show2"}},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"justify":"center"}},[_c('v-icon',{attrs:{"large":"","color":"green"}},[_vm._v("mdi-arrow-top-right-thick")]),_c('v-col',{staticClass:"pa-0 ma-0 headline white--text"},[_vm._v(" "+_vm._s(_vm.snackbar.text2)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }