function SetUser(email, name, role, id, jwt){
    var user = {
        email: email,
        name: name,
        role: role,
        id: id,
        jwt: jwt,
    }
    localStorage.setItem("user", JSON.stringify(user));
}

function clearUserStorage(){
    SetUser("", "", "", 0, "");
}

function GetUser(){
    if(localStorage.getItem("user")==null){
        SetUser("", "", "", 0, "")
    }
    return JSON.parse(localStorage.getItem("user"));
}

function switchNavBar(navbarName){
    localStorage.setItem("navbar", navbarName);
}

function showNavBar(){
    var tempBool = false;
    var navbar = localStorage.getItem("navbar");
    if(navbar==null){
        tempBool = false; 
    }
    else if(navbar == "website"){
        tempBool = false;
    }
    else{
        tempBool = true;
    }
    return tempBool;
}


module.exports = {GetUser, SetUser, switchNavBar, showNavBar, clearUserStorage};