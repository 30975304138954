<template>
  <div
    style="
      background-image: url('https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg');
      background-size: cover;
      height: 100%;
    "
  >
    <v-col>
      <v-card
        v-if="show == 'login'"
        color="rgba(239, 228, 225, 0.52)"
        class="mx-auto my-12 pa-4 text-center"
        max-width="374"
      >
        <div class="display-1 ma-2 mb-4">Member Login</div>
        <div class="red--text font-weight-bold mb-4">
          SEMUA AKAUN LELONGAN YANG SEBELUM INI DIDAFTARKAN SEBAGAI "STAF" PERLU
          MENDAFTAR AKAUN BARU SEBAGAI AKAUN "PUBLIC" DAN PERLU MENGGUNAKAN
          E-MAIL SELAIN DARI E-MAIL SYARIKAT.
        </div>
        <v-form>
          <v-text-field
            prepend-inner-icon="mdi-email"
            class="pa-0"
            rounded
            solo
            background-color="rgba(239, 228, 225, 0.8)"
            v-model="email"
            label="email"
          >
          </v-text-field>
          <v-text-field
            prepend-inner-icon="mdi-lock"
            class="pa-0"
            type="password"
            rounded
            solo
            background-color="rgba(239, 228, 225, 0.8)"
            v-model="pwd"
            label="password"
          >
          </v-text-field>
          <div class="red--text mb-2">{{ errorMessage }}</div>
          <v-btn
            @click="requestLoginOTP()"
            width="80%"
            color="blue"
            dark
            rounded=""
          >
            Request OTP
          </v-btn>
          <a
            ><div @click="show = 'resetPwd'" class="ma-2">
              Forgot password?
            </div></a
          >
          <!-- <div class="ma-2">Haven't registered? <router-link :to="{path: '/register'}">Click here to register</router-link></div> -->
          <div class="ma-2">
            Haven't registered?
            <a @click="checkIfthereIsAuction()">Click here to register</a>
          </div>
        </v-form>
      </v-card>

      <v-card
        v-if="show == 'otpConfirmation'"
        color="rgba(239, 228, 225, 0.52)"
        class="mx-auto my-12 pa-4 text-center"
        max-width="374"
      >
        <div class="display-1 ma-2 mb-12">Member Login</div>
        <v-form>
          Please insert OTP code recieved from your email.
          <v-text-field
            class="pa-0"
            filled
            label="OTP Code"
            type="text"
            hide-details="auto"
            v-model="otp"
            maxlength="6"
            :rules="[
              (v) => (v && v.length === 6) || 'OTP must be 6 characters',
            ]"
          >
          </v-text-field>
          <div class="red--text mb-2">{{ errorMessage }}</div>
          <v-btn @click="login()" width="80%" color="blue" dark rounded="">
            Login
          </v-btn>
        </v-form>
      </v-card>

      <v-card
        v-if="show == 'resetPwd'"
        color="rgba(239, 228, 225, 0.52)"
        class="mx-auto my-12 pa-4 text-center"
        max-width="374"
      >
        <div class="display-1 ma-2 mb-5">Reset Password</div>
        <v-form>
          <v-text-field
            prepend-inner-icon="mdi-email"
            class="pa-0"
            rounded
            solo
            background-color="rgba(239, 228, 225, 0.8)"
            v-model="resetPwdEmail"
            label="email"
          >
          </v-text-field>
          <v-btn
            @click="resetPwdQuery()"
            width="80%"
            color="brown"
            dark
            rounded=""
          >
            Reset
          </v-btn>
          <a><div @click="show = 'login'" class="ma-2">Back to Login</div></a>
        </v-form>
      </v-card>

      <v-card
        v-if="show == 'resetSuccess'"
        color="rgba(255, 234, 217, 1)"
        class="mx-auto my-12 pa-4 text-center"
        max-width="374"
      >
        <div class="display-1 ma-2 mb-5">Reset Password Successful</div>
        <div>
          An email has been sent to you with password reset instructions.
        </div>
        <div>Please check your email.</div>
        <v-btn
          @click="show = 'login'"
          width="80%"
          color="orange"
          dark
          rounded=""
        >
          Back to login
        </v-btn>
      </v-card>
    </v-col>

    <v-dialog v-model="show.registerDisable" persistent max-width="600px">
      <v-card class="pa-2 mb-1">
        <v-row justify="center" class="pa-0 ma-0">
          <v-col class="text-center pa-0 ma-0">
            <v-row class="pa-0 ma-0" justify="center"
              ><v-icon size="50" color="orange"
                >mdi-alert-outline</v-icon
              ></v-row
            >
            <div class="pa-1 title orange--text">Registration Closed</div>
            <v-divider></v-divider>
            <div class="pa-1 text-center">
              An auction is currently in progress.
            </div>
            <div class="text-center">
              Registration is not allowed during this period.
            </div>
            <v-btn
              class="mx-5 mt-1"
              @click="show = 'registerDisable'"
              color="grey"
              dark
              >Close</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import storage from "../jscontroller/storage";
import serverConfig from "../jscontroller/serverConfig";
import axios from "axios";
export default {
  data() {
    return {
      email: "",
      pwd: "",
      resetPwdEmail: "",
      errorMessage: "",
      show: "login",
      otp: "",
      snackbar: {
        showProgress: false,
        showStatus: false,
        text: "",
        timeout: 5000,
        icon: "mdi-check-circle-outline",
        color: "green",
      },
    };
  },
  mounted() {
    this.checkEmailVerification();
  },
  methods: {
    navigate: function (pagePath) {
      if (this.$route.path != pagePath) {
        this.$router.push(pagePath);
      }
    },
    requestLoginOTP() {
      var self = this;
      self.showSnackbar();

      if (self.email == "") {
        self.errorMessage = "Please enter your email address.";
        self.hideSnackbar("Please enter your email address.", "red");
        return;
      }
      if (self.pwd == "") {
        self.errorMessage = "Please enter your password.";
        self.hideSnackbar("Please enter your password.", "red");
        return;
      }

      var apiUrl = serverConfig.hostname + "/api/sc/login/request-otp";
      var bodyFormData = new FormData();
      bodyFormData.set("email", this.email);
      bodyFormData.set("pwd", this.pwd);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          self.hideSnackbar(response.data.message, "green");
          self.show = "otpConfirmation";
        })
        .catch(function (error) {
          self.hideSnackbar(error.response.data.message, "red");
        });
    },
    login() {
      var self = this;
      self.showSnackbar();
      if (this.email == "") {
        this.errorMessage = "Please enter your email address.";
        self.hideSnackbar("Please enter your email address.", "red");
      } else if (this.pwd == "") {
        this.errorMessage = "Please enter your password.";
        self.hideSnackbar("Please enter your password.", "red");
      } else {
        var apiUrl = serverConfig.hostname + "/api/sc/login";
        var bodyFormData = new FormData();
        bodyFormData.set("email", this.email);
        bodyFormData.set("pwd", this.pwd);
        bodyFormData.set("otp", this.otp);
        axios
          .post(apiUrl, bodyFormData)
          .then(function (response) {
            var tempData = response.data;
            storage.SetUser(
              String(tempData.email),
              String(tempData.name),
              String(tempData.role),
              tempData.userId,
              tempData.jwt
            );
            self.hideSnackbar(tempData.message, "green");
            self.errorMessage = "";
            self.$router.push("/myaccount/myprofile");
            self.$router.go(0);
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            self.hideSnackbar(error.response.data.message, "orange");
          });
      }
    },
    resetPwdQuery() {
      var self = this;
      self.showSnackbar();
      if (this.resetPwdEmail == "") {
        self.hideSnackbar("Please insert an email", "orange");
      } else {
        var apiUrl = serverConfig.hostname + "/api/sc/reset/password";
        var bodyFormData = new FormData();
        bodyFormData.set("email", self.resetPwdEmail);
        axios
          .post(apiUrl, bodyFormData)
          .then(function (response) {
            var tempData = response.data.message;
            self.hideSnackbar(tempData, "green");
            self.show = "resetSuccess";
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            // eslint-disable-next-line no-console
            self.hideSnackbar(error.response.data.message, "red");
          });
      }
    },
    checkEmailVerification() {
      var self = this;
      var currentPage = window.location.href;
      if (currentPage.includes("/login/verify")) {
        self.showSnackbar();
        var email = new URL(currentPage).searchParams.get("email");
        var apiUrl = serverConfig.hostname + "/api/sc/verify/email";
        var bodyFormData = new FormData();
        bodyFormData.set("email", email);
        axios
          .post(apiUrl, bodyFormData)
          .then(function (response) {
            if (response.data == "success")
              self.hideSnackbar(
                "Email successfuly verified. You can now login using your email.",
                "green"
              );
            else self.hideSnackbar("Verification failed.", "red");
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            self.hideSnackbar("Verification failed.", "red");
          });
      }
    },
    checkIfthereIsAuction() {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/auctionmanager/sessions";
      axios
        .get(apiUrl)
        .then(function (response) {
          if (response.data.length == 0) {
            self.navigate("/register");
          } else {
            self.show = "registerDisable";
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red" || color == "orange") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
  },
};
</script>
