<template>
  <div
    style="
      background-image: url('https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg');
      background-size: cover;
      height: 100%;
    "
  >
    <v-col>
      <v-card
        color="rgba(239, 228, 225, 0.82)"
        class="mx-auto my-12 pa-0 text-center"
        max-width="674"
      >
        <!-- <div class="display-1 ma-2 mb-5">Register</div> -->
        <v-form v-if="show.registration">
          <v-card>
            <v-tabs v-model="tab" dark>
              <v-tab :key="1">Step 1</v-tab>
              <v-tab :key="2">Step 2</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item :key="1">
                <v-col
                  class="pa-0 ma-0 mt-2 display-1 font-italic font-weight-bold"
                  >Registration</v-col
                >
                <v-divider class="mx-5 mt-1"></v-divider>
                <v-row class="pa-2 ma-0">
                  <v-col class="pa-0 ma-0"
                    >Every input with the symbol "<span class="red--text"
                      >*</span
                    >" is mandatory.</v-col
                  >
                  <v-col cols="12" sm="12" md="12">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-select
                        class="pa-0"
                        filled
                        dense=""
                        v-model="accountType"
                        :items="['Individual', 'Organization']"
                        hide-details="auto"
                        label="Choose your account type.."
                      >
                      </v-select>
                    </v-row>
                  </v-col>
                  <!-- 'TM Staff' -->

                  <v-col cols="12" sm="12" md="12">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-text-field
                        prepend-inner-icon="mdi-account"
                        class="pa-0"
                        filled
                        dense=""
                        v-model="fname"
                        hide-details="auto"
                        label="Full Name/Organization Name"
                      >
                      </v-text-field>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-text-field
                        prepend-inner-icon="mdi-card-account-details"
                        class="pa-0"
                        filled
                        dense=""
                        v-model="ic"
                        hide-details="auto"
                        label="IC No/SSM No (e.g. 9xxxxx1x1xxx/1xxxxxxW)"
                      >
                      </v-text-field>
                    </v-row>
                  </v-col>

                  <!-- <v-col
                    v-if="accountType == 'Individual'"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-row class="pa-0 ma-0 ml-2">
                      <v-checkbox
                        v-model="isStaff"
                        hide-details="auto"
                        color="primary"
                        class="ma-0 pa-0"
                      ></v-checkbox>
                      <span
                        >Tick this checkbox if you're registering as a
                        staff.</span
                      >
                    </v-row>
                  </v-col> -->

                  <v-col v-if="isStaff" cols="12" sm="12" md="12">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-select
                        class="pa-0"
                        filled
                        dense=""
                        v-model="staffOf"
                        :items="['TM Staff', 'KNB Staff']"
                        hide-details="auto"
                        label="Staff.."
                      >
                      </v-select>
                    </v-row>
                  </v-col>

                  <v-col v-if="isStaff" cols="12" sm="12" md="12">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-text-field
                        prepend-inner-icon="mdi-card-account-details"
                        class="pa-0"
                        filled
                        dense=""
                        v-model="staffId"
                        hide-details="auto"
                        label="Staff ID"
                      >
                      </v-text-field>
                    </v-row>
                  </v-col>

                  <v-col
                    v-if="accountType == 'Organization'"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-text-field
                        prepend-inner-icon="mdi-account"
                        class="pa-0"
                        filled
                        dense=""
                        v-model="representativeName"
                        hide-details="auto"
                        label="Representative Name"
                      >
                      </v-text-field>
                    </v-row>
                  </v-col>

                  <v-col
                    v-if="accountType == 'Organization'"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-text-field
                        prepend-inner-icon="mdi-card-account-details"
                        class="pa-0"
                        filled
                        dense=""
                        v-model="representativeIc"
                        hide-details="auto"
                        label="Representative IC No (e.g. 9xxxxx1x1xxx)"
                      >
                      </v-text-field>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-text-field
                        prepend-inner-icon="mdi-email"
                        class="pa-0"
                        filled
                        dense=""
                        v-model="email"
                        hide-details="auto"
                        label="Email"
                      >
                      </v-text-field>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-text-field
                        prepend-inner-icon="mdi-lock"
                        class="pa-0"
                        filled
                        dense=""
                        v-model="pwd"
                        hide-details="auto"
                        label="Password"
                        type="password"
                      >
                      </v-text-field>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-text-field
                        prepend-inner-icon="mdi-lock-outline"
                        class="pa-0"
                        filled
                        dense=""
                        v-model="confirmPwd"
                        hide-details="auto"
                        label="Confirm Password"
                        type="password"
                      >
                      </v-text-field>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-text-field
                        prepend-inner-icon="mdi-phone"
                        class="pa-0"
                        filled
                        dense=""
                        v-model="phoneNumber"
                        label="Phone Number (e.g. +601xxxxxxxx)"
                        type="tel"
                        hide-details="auto"
                      >
                      </v-text-field>
                    </v-row>
                  </v-col>
                  <v-btn
                    class="my-2 pa-0"
                    @click="register()"
                    width="100%"
                    color="blue"
                    dark
                  >
                    Next
                  </v-btn>
                </v-row>
              </v-tab-item>

              <v-tab-item :key="2">
                <v-row class="pa-2 ma-0">
                  <v-col cols="12" sm="12" md="12">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-text-field
                        class="pa-0"
                        filled
                        dense=""
                        v-model="address"
                        hide-details="auto"
                        label="Address"
                      >
                      </v-text-field>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-text-field
                        class="pa-0"
                        filled
                        dense=""
                        v-model="state"
                        hide-details="auto"
                        label="State"
                      >
                      </v-text-field>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-text-field
                        class="pa-0"
                        filled
                        dense=""
                        v-model="city"
                        hide-details="auto"
                        label="City"
                      >
                      </v-text-field>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-text-field
                        class="pa-0"
                        filled
                        dense=""
                        v-model="postcode"
                        hide-details="auto"
                        label="Postcode"
                      >
                      </v-text-field>
                    </v-row>
                  </v-col>
                  <div class="ml-5">Enter bank info for refund purpose.</div>
                  <v-col class="pt-0" cols="12" sm="12" md="12">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-select
                        class="pa-0"
                        filled
                        dense=""
                        v-model="bankName"
                        :items="banklist"
                        :hint="bankName"
                        item-text="name"
                        item-value="name"
                        hide-details="auto"
                        label="Bank Name"
                      >
                      </v-select>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-row class="pa-0 ma-0">
                      <span class="mr-1 red--text">*</span>
                      <v-text-field
                        class="pa-0"
                        filled
                        dense=""
                        v-model="bankAccNo"
                        hide-details="auto"
                        type="number"
                        label="Bank Account No."
                      >
                      </v-text-field>
                    </v-row>
                  </v-col>
                  <v-row class="pa-1 ma-0 font-weight-bold" justify="center">
                    <v-checkbox
                      v-model="agree"
                      hide-details="auto"
                      color="primary"
                      class="ma-0 pa-0"
                    ></v-checkbox>
                    I/We hereby agree to AMS E-AUCTION
                    <a
                      class="mx-1"
                      @click="openTermsAndCondition()"
                      style="text-decoration: underline"
                      >TERMS AND CONDITIONS</a
                    >
                    OF SALE.
                  </v-row>
                  <v-btn
                    :disabled="!agree"
                    class="my-2 pa-0"
                    @click="register()"
                    width="100%"
                    color="green"
                    :dark="agree"
                  >
                    Register
                  </v-btn>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-form>

        <v-card v-if="!show.registration">
          <v-card dark>
            <v-row class="pa-2 ma-2 title" justify="center"
              >Registration Successful</v-row
            >
          </v-card>
          <v-card>
            <v-row class="pa-1 ma-1" justify="center">
              An email has been sent for verification.
            </v-row>
            <v-row class="pa-1 ma-1" justify="center">
              Please check your email and verify your email address before you
              login.
            </v-row>
            <v-icon size="100" color="green" class="my-3"
              >mdi-check-circle-outline</v-icon
            >
          </v-card>
        </v-card>
      </v-card>
    </v-col>

    <v-dialog v-model="show.registerDisable" persistent max-width="600px">
      <v-card class="pa-2 mb-1">
        <v-row justify="center" class="pa-0 ma-0">
          <v-col class="text-center pa-0 ma-0">
            <v-row class="pa-0 ma-0" justify="center"
              ><v-icon size="50" color="orange"
                >mdi-alert-outline</v-icon
              ></v-row
            >
            <div class="pa-1 title orange--text">Registration Closed</div>
            <v-divider></v-divider>
            <div class="pa-1 text-center">
              An auction is currently in progress.
            </div>
            <div class="text-center">
              Registration is not allowed during this period.
            </div>
            <v-btn
              class="mx-5 mt-1"
              @click="navigate('/login')"
              color="grey"
              dark
              >OK</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import storage from "../jscontroller/storage";
import serverConfig from "../jscontroller/serverConfig";
import axios from "axios";

export default {
  data() {
    return {
      serverUrl: serverConfig.hostname,
      tab: null,
      fname: "",
      email: "",
      pwd: "",
      ic: "",
      confirmPwd: "",
      phoneNumber: "",
      bankName: "",
      bankAccNo: "",
      address: "",
      state: "",
      city: "",
      accountType: "",
      representativeName: "",
      representativeIc: "",
      staffId: "",
      isStaff: false,
      staffOf: "",
      agree: false,
      postcode: "",
      banklist: [],
      snackbar: {
        showProgress: false,
        showStatus: false,
        text: "",
        timeout: 5000,
        icon: "mdi-check-circle-outline",
        color: "green",
      },
      show: {
        registration: true,
        registerDisable: false,
      },
    };
  },
  mounted() {
    this.getAllBankList();
    this.checkIfthereIsAuction();
  },
  methods: {
    navigate: function (pagePath) {
      if (this.$route.path != pagePath) {
        this.$router.push(pagePath);
      }
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(String(email).toLowerCase());
    },
    register() {
      var self = this;
      if (self.accountType == "") {
        self.hideSnackbar("Please choose your account type.", "red");
        self.tab = 0;
      } else if (self.fname == "") {
        if (self.accountType == "Individual")
          self.hideSnackbar("Please enter your full name.", "red");
        else
          self.hideSnackbar(
            "Please enter the name of your organization.",
            "red"
          );
        self.tab = 0;
      } else if (self.ic == "") {
        if (self.accountType == "Individual")
          self.hideSnackbar("Please enter your IC number.", "red");
        else self.hideSnackbar("Please enter SSM number.", "red");
        self.tab = 0;
      } else if (
        self.representativeName == "" &&
        self.accountType == "Organization"
      ) {
        self.hideSnackbar(
          "Please enter the representative's full name.",
          "red"
        );
        self.tab = 0;
      } else if (
        self.representativeIc == "" &&
        self.accountType == "Organization"
      ) {
        self.hideSnackbar(
          "Please enter the representative's IC number.",
          "red"
        );
        self.tab = 0;
      } else if (self.isStaff == true && self.staffOf == "") {
        self.hideSnackbar("Staff input is empty.", "red");
        self.tab = 0;
      } else if (self.isStaff == true && self.staffId == "") {
        self.hideSnackbar("Staff enter your staff ID.", "red");
        self.tab = 0;
      } else if (self.email == "") {
        self.hideSnackbar("Please enter your email address.", "red");
        self.tab = 0;
      } else if (!self.validateEmail(self.email)) {
        self.hideSnackbar("Please enter a valid email address.", "red");
        self.tab = 0;
      } else if (self.pwd == "") {
        self.hideSnackbar("Please enter your password.", "red");
        self.tab = 0;
      } else if (self.pwd.length < 8) {
        self.hideSnackbar(
          "Your password must contain at least 8 characters",
          "red"
        );
        self.tab = 0;
      } else if (self.confirmPwd == "") {
        self.hideSnackbar("Please confirm your password.", "red");
        self.tab = 0;
      } else if (self.pwd != self.confirmPwd) {
        self.hideSnackbar("Your confirmation password is not the same.", "red");
        self.tab = 0;
      } else if (self.phoneNumber == "") {
        self.hideSnackbar("Please enter your phone number.", "red");
        self.tab = 0;
      } else if (self.address == "") {
        self.hideSnackbar("Please enter your address line.", "red");
        self.tab = 1;
      } else if (self.state == "") {
        self.hideSnackbar("Please enter your state address.", "red");
        self.tab = 1;
      } else if (self.city == "") {
        self.hideSnackbar("Please enter your city address.", "red");
        self.tab = 1;
      } else if (self.postcode == "") {
        self.hideSnackbar("Please enter your postcode.", "red");
        self.tab = 1;
      } else if (self.bankName == "") {
        self.hideSnackbar("Please enter your bank name.", "red");
        self.tab = 1;
      } else if (self.bankAccNo == "") {
        self.hideSnackbar("Please enter your bank account number.", "red");
        self.tab = 1;
      } else {
        self.showSnackbar();
        var apiUrl = serverConfig.hostname + "/api/user/register";
        var body = {
          firstName: self.fname,
          email: self.email,
          pwd: self.pwd,
          phoneNo: self.phoneNumber,
          ic: self.ic.replace(/-/g, ""),
          bankName: self.bankName,
          bankAccNo: self.bankAccNo,
          address: self.address,
          state: self.state,
          city: self.city,
          postcode: self.postcode,
          accountType: self.accountType,
          representativeName: self.representativeName,
          representativeIc: self.representativeIc.replace(/-/g, ""),
          staffId: self.staffId,
          staff: self.isStaff,
          staffOf: self.staffOf,
        };
        axios
          .post(apiUrl, body)
          .then(function (response) {
            self.hideSnackbar(response.data, "green");
            self.show.registration = false;
          })
          .catch(function (error) {
            // eslint-disable-next-line no-console
            self.hideSnackbar(error.response.data, "orange");
          });
      }
    },
    login() {
      var self = this;
      if (this.email == "") {
        this.errorMessage = "Please enter your email address.";
      } else if (this.pwd == "") {
        this.errorMessage = "Please enter your password.";
      } else {
        var apiUrl = serverConfig.hostname + "/api/sc/login";
        var bodyFormData = new FormData();
        bodyFormData.set("email", this.email);
        bodyFormData.set("pwd", this.pwd);
        axios
          .post(apiUrl, bodyFormData)
          .then(function (response) {
            var tempData = response.data;
            if (tempData.status.toString() == "login successful") {
              storage.SetUser(
                String(tempData.email),
                String(tempData.name),
                String(tempData.role),
                tempData.userId,
                tempData.jwt
              );
              self.errorMessage = "";
              self.$router.push("/");
              self.$router.go(0);
            }
          })
          .catch(function () {});
      }
    },
    checkIfthereIsAuction() {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/auctionmanager/sessions";
      axios
        .get(apiUrl)
        .then(function (response) {
          if (response.data.length > 0) {
            self.show.registerDisable = true;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    getAllBankList() {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/bank/getall";
      axios
        .get(apiUrl)
        .then(function (response) {
          self.banklist = response.data;
        })
        .catch(function () {});
    },
    verifyBankAccount() {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/billplz/verify";
      var bodyFormData = new FormData();
      var organization = false;
      if (self.accountType == "Organization") organization = true;
      bodyFormData.set("name", self.fname);
      bodyFormData.set("ic", self.ic.replace(/-/g, ""));
      bodyFormData.set("bankAccNo", self.bankAccNo);
      bodyFormData.set("bankName", self.bankName);
      bodyFormData.set("organization", organization);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          self.hideSnackbar(
            "Verification status: " + response.data.status,
            "green"
          );
          // setTimeout(function(){ self.login(); }, 1500);
          self.show.registration = false;
        })
        .catch(function () {});
    },
    openTermsAndCondition() {
      var self = this;
      window.open(self.serverUrl + "/api/file/document/tnc/pdf/tnc.pdf");
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red" || color == "orange") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
  },
};
</script>
