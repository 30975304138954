const hostnameConst = location.origin;
var hostname = hostnameConst;
var hostnameSocket = hostnameConst;
if (hostnameConst == "http://localhost:8080") {
  hostname = "http://localhost:8081";
  hostnameSocket = "http://localhost:3000";
  // eslint-disable-next-line no-console
  // console.log("current hostname: " + hostname)
}

module.exports = { hostname, hostnameSocket };
