<template>
  <div>
    <v-card color="" class="pa-2 mx-2 mt-2">
      <div color="" class="pa-1 title" style="text-decoration: underline">
        Unit Monitor
      </div>
      <div class="pa-1">
        - These are the unit lots that are currently being bid.
      </div>
      <div class="pa-1">
        - The table below includes the lot number of which lot is being bid, the
        current bid price, and the bidder ID.
      </div>
      <v-divider></v-divider>
      <v-divider></v-divider>
      <v-row class="pa-0 ma-0 text-center" justify="center">
        <v-col class="ma-0 pa-0 ml-2" align-self="center">
          <div class="font-weight-bold">
            Click here to see the latest ongoing bidding.
          </div>
          <v-btn @click="getAllAuctionLotSocket()" color="green" dark=""
            ><v-icon large="">mdi-refresh</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-col class="pa-2 ma-0">
        <span>Filter by: </span>
        <v-chip
          @click="
            filter = 'all';
            getAllAuctionLotSocket();
          "
          class="mx-1"
          >All</v-chip
        >
        <v-chip @click="filter = 'vendor'" class="mx-1">Vendors</v-chip>
      </v-col>
      <v-row v-if="filter == 'vendor'" class="pa-1 ma-1">
        <v-text-field
          prepend-inner-icon="mdi-account-search-outline"
          class="pa-0"
          solo
          background-color="rgba(232, 232, 232, 0.8)"
          v-model="vendor"
          hide-details="auto"
          label="Enter vendor.."
        >
        </v-text-field>
        <v-btn
          @click="getAllAuctionLotSocket()"
          dark
          color="blue"
          class="ma-1 mt-2"
          >Search</v-btn
        >
      </v-row>
      <div v-if="auctionLotSocket.length == 0" class="pa-1 font-weight-bold">
        There are no units that are currently being bid..
      </div>
      <v-row
        v-if="auctionLotSocket.length > 0"
        class="ma-0 pa-0 pl-1 ml-1"
        style="font-size: 20px"
        >Total sold units for {{ auctionLotSocket[0].session }}:
        <span class="ml-2 font-weight-bold">{{ auctionLotSocket.length }}</span>
      </v-row>
      <v-row
        v-if="auctionLotSocket.length > 0"
        class="ma-0 pa-0 pl-1 ml-1"
        style="font-size: 20px"
        >Total deposit amount for {{ auctionLotSocket[0].session }}:
        <span class="ml-2 font-weight-bold green--text">{{
          ringgitFormat(calculateTotalDeposit(auctionLotSocket))
        }}</span>
      </v-row>
      <v-row
        v-if="auctionLotSocket.length > 0"
        class="ma-0 pa-0 pl-1 ml-1"
        style="font-size: 20px"
        >Total Sale for {{ auctionLotSocket[0].session }}:
        <span class="ml-2 font-weight-bold green--text">{{
          ringgitFormat(calculateTotalSale(auctionLotSocket))
        }}</span>
      </v-row>
      <v-simple-table v-if="auctionLotSocket.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">No.</th>
              <th class="text-left">Lot No</th>
              <th class="text-left pl-5">Bidder No</th>
              <!-- <th class="text-left pl-5">Email</th> -->
              <th class="text-left pl-5">Name</th>
              <th class="text-left pl-5">Vendor</th>
              <th class="text-left pl-5">Bid Price</th>
              <th class="text-left pl-5">Deposit</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lot, i) in auctionLotSocket" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ lot.id }}</td>
              <td>{{ lot.bidderNo }}</td>
              <!-- <td>{{lot.latestEmail}}</td> -->
              <td>{{ lot.bidderName }}</td>
              <td>{{ lot.vendor }}</td>
              <td>{{ ringgitFormat(lot.latestBid) }}</td>
              <td>{{ ringgitFormat(lot.deposit) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
import storage from "../jscontroller/storage";
// eslint-disable-next-line no-unused-vars
import usersPDF from "../jscontroller/usersPDF";
import authorization from "../jscontroller/authorization";
export default {
  name: "SalesReport",
  data: () => ({
    user: {},
    serverUrl: serverConfig.hostname,
    auctionLotSocket: [],
    filter: "all",
    vendor: "",
    snackbar: {
      showProgress: false,
      showStatus: false,
      text: "",
      timeout: 5000,
      icon: "mdi-check-circle-outline",
      color: "green",
    },
  }),
  created() {},
  mounted() {
    this.user = storage.GetUser();
    if (!authorization.checkVendorAuthorization(this.user)) {
      storage.switchNavBar("website");
      this.$router.push("/");
      this.$router.go();
    }
    this.getAllAuctionLotSocket();
  },
  methods: {
    ringgitFormat(amount) {
      var currency = "RM";
      if (amount < 0) {
        currency = "-RM";
      }
      if (amount == null) {
        return currency + (0).toFixed(2);
      } else {
        var num = Math.abs(amount).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        });
        var deci = num.split(".")[1];
        if (deci != null) {
          if (deci.length == 1) {
            num = num + "0";
          }
        } else {
          num = num + ".00";
        }
        return currency + num;
      }
    },
    getAllAuctionLotSocket() {
      var self = this;
      self.showSnackbar();
      var apiUrl = self.serverUrl + "/api/socket/auctionlot/get/all";
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function (response) {
          var list = response.data;
          self.auctionLotSocket = [];
          if (self.filter == "all") self.auctionLotSocket = list;
          else {
            for (var i = 0; i < list.length; i++) {
              if (list[i].vendor == self.vendor)
                self.auctionLotSocket.push(list[i]);
            }
          }
          self.hideSnackbar("Finish fetching data..", "green");
          // eslint-disable-next-line no-console
          // console.log(self.auctionLotSocket)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {});
    },
    calculateTotalDeposit(auctionLotSocket) {
      var totalDeposit = 0;
      for (var i = 0; i < auctionLotSocket.length; i++) {
        totalDeposit = totalDeposit + auctionLotSocket[i].deposit;
      }
      return totalDeposit;
    },
    calculateTotalSale(auctionLotSocket) {
      var totalSale = 0;
      for (var i = 0; i < auctionLotSocket.length; i++) {
        totalSale = totalSale + auctionLotSocket[i].latestBid;
      }
      return totalSale;
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red" || color == "orange") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
  },
  watch: {
    // selectAuction: function(){
    //     var self = this;
    //     self.salesReport = []
    //     self.filter = ''
    // },
  },
};
</script>
