<template>
  <div>
    <v-card color="#9ed4ff" class="pa-2 mx-2 mt-2 title">Home Page Info</v-card>
    <v-row>
      <v-col class="pa-0 ma-0">
        <v-card-title class="pa-0 ma-0 mt-2 ml-5">Banner</v-card-title>
        <div>
          <v-row justify="center">
            <v-col cols="6">
              <v-carousel
                cycle
                height="120"
                hide-delimiter-background
                show-arrows-on-hover
              >
                <v-carousel-item
                  v-for="(slide, i) in slides"
                  :key="i"
                  :src="serverUrl + '/api/file' + slide.imgUrl"
                >
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </div>
        <v-col>
          <v-card color="white" class="pa-5 py-2 mt-0 ma-3">
            <v-row justify="center" class="pa-0 ma-0">
              <v-col
                v-for="(photo, i) in slides"
                :key="i"
                class="pa-1 ma-0"
                cols="3"
              >
                <v-img
                  max-height="200"
                  contain
                  :src="serverUrl + '/api/file' + photo.imgUrl"
                  :lazy-src="serverUrl + '/api/file' + photo.imgUrl"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <v-row justify="end" class="ma-1">
                    <v-btn
                      @click="
                        bannerStatus.progress = true;
                        deletePhoto(
                          slides[i].imgUrl,
                          'banner',
                          slides[i].photoId,
                          i
                        );
                      "
                      color="rgba(255, 255, 255, 0.6)"
                      dark
                      fab
                      x-small
                    >
                      <v-icon color="#560000b0">mdi-delete</v-icon>
                    </v-btn>
                  </v-row>
                </v-img>
              </v-col>
              <v-col cols="3" class="ma-0 pa-1">
                <v-card class="pa-0" height="100%">
                  <!-- <v-file-input  class="ml-5 pl-5 pt-5" reverse rounded accept="image/*" ></v-file-input> -->
                  <input
                    type="file"
                    ref="fileInputBanner"
                    style="display: none"
                    accept="image/*"
                    @change="showPhotoInput('banner')"
                  />
                  <v-row justify="center" align="center" class="fill-height">
                    <v-btn icon @click="$refs.fileInputBanner.click()"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="center" class="mt-2">
              <v-progress-circular
                v-if="bannerStatus.progress"
                :class="classStyle.bannerStatus"
                indeterminate
              ></v-progress-circular>
              <div
                v-if="!bannerStatus.progress"
                :class="classStyle.bannerStatus"
              >
                {{ bannerStatus.message }}
              </div>
            </v-row>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
    <!-- end banner -->
    <v-divider class="ma-3"></v-divider>

    <!-- part photo -->
    <v-row class="px-2">
      <v-col cols="6" class="pa-0 ma-0">
        <v-card-title class="pa-0 ma-0 mt-2 ml-5">Photos</v-card-title>
        <v-card
          v-for="(auctionPhotos, x) in homepagePhotos"
          :key="x"
          color="grey lighten-2"
          class="pa-5 py-2 mt-0 ma-3"
        >
          <v-card-title class="pa-0 ma-0">{{
            auctionPhotos[0].session
          }}</v-card-title>
          <v-row class="pa-0 ma-0">
            <v-col
              v-for="(photo, i) in auctionPhotos"
              :key="i"
              class="pa-1 ma-0"
              cols="3"
            >
              <v-img
                max-height="200"
                :src="serverUrl + '/api/file' + photo.imgUrl"
                :lazy-src="serverUrl + '/api/file' + photo.imgUrl"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <v-row justify="end" class="ma-1">
                  <v-btn
                    @click="
                      photoStatus.progress = true;
                      deleteHomePagePhotoUrl(photo.imgUrl, x, i);
                    "
                    color="rgba(255, 255, 255, 0.6)"
                    dark
                    fab
                    x-small
                  >
                    <v-icon color="#560000b0">mdi-delete</v-icon>
                  </v-btn>
                </v-row>
              </v-img>
            </v-col>
            <!-- <v-col cols="3" class="ma-0 pa-1">
              <v-card class=" pa-0" height="100%"> -->
            <!-- <v-file-input  class="ml-5 pl-5 pt-5" reverse rounded accept="image/*" ></v-file-input> -->
            <!-- <input
                  type="file"
                  ref="fileInput"
                  style="display:none;"
                  accept="image/*"
                  @change="showPhotoInput('homepagePhotos')"
                /> -->
            <!-- <v-row justify="center" align="center" class="fill-height">
              <v-btn icon @click="$refs.fileInput.click()"><v-icon>mdi-plus</v-icon></v-btn>
                                </v-row>                                 -->
            <!-- </v-card>
            </v-col> -->
          </v-row>
        </v-card>
        <!-- <v-row justify='center'>
                    <v-progress-circular v-if="photoStatus.progress" :class="classStyle.photoStatusMessage" indeterminate></v-progress-circular>
                    <div v-if="!photoStatus.progress" :class="classStyle.photoStatusMessage">{{photoStatus.message}}</div>                    
                </v-row>                 -->
      </v-col>
      <v-divider vertical=""></v-divider>
      <v-col class="pa-0 ma-0">
        <v-card-title class="pa-0 ma-0 mt-2 ml-5">Documents</v-card-title>
        <v-row justify="center">
          <input
            type="file"
            ref="fileInputCatalogue"
            style="display: none"
            accept="application/pdf"
            @change="showPhotoInput('catalogue')"
          />
          <input
            type="file"
            ref="fileInputTNC"
            style="display: none"
            accept="application/pdf"
            @change="showPhotoInput('tnc')"
          />
          <v-btn
            @click="$refs.fileInputCatalogue.click()"
            dark
            class="ma-2 headline"
            color="rgb(19, 115, 255)"
            height="100"
            >Upload<br />Catalogue</v-btn
          >
          <v-btn
            @click="$refs.fileInputTNC.click()"
            dark
            class="ma-2 headline"
            color="rgb(19, 115, 255)"
            height="100"
            >Upload<br />Terms & Condition</v-btn
          >
        </v-row>
        <v-row class="mt-2" justify="center">
          <v-progress-circular
            v-if="documentStatus.progress"
            :class="classStyle.documentStatus"
            indeterminate
          ></v-progress-circular>
          <div
            v-if="!documentStatus.progress"
            :class="classStyle.documentStatus"
          >
            {{ documentStatus.message }}
          </div>
        </v-row>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row>
      <!-- part infos -->
      <v-col cols="12" class="pa-1 ma-0">
        <v-card-title class="pa-0 ma-0 mt-2 ml-5 pl-1">Info</v-card-title>
        <v-card color="grey lighten-2" class="pa-5 py-2 mt-0 ma-3">
          <v-row class="mb-2">
            <v-toolbar dense="">
              <v-btn
                @click="
                  changeTextStyle(
                    'bold',
                    textBtnStyle.column,
                    textBtnStyle.rowIndex
                  )
                "
                :color="textBtnStyle.bold.color"
                :dark="textBtnStyle.bold.dark"
                ><v-icon>mdi-format-bold</v-icon>
              </v-btn>
              <v-btn
                @click="
                  changeTextStyle(
                    'underline',
                    textBtnStyle.column,
                    textBtnStyle.rowIndex
                  )
                "
                :color="textBtnStyle.underlined.color"
                :dark="textBtnStyle.underlined.dark"
              >
                <v-icon>mdi-format-underline</v-icon>
              </v-btn>
            </v-toolbar>
          </v-row>
          <v-row v-for="(info, i) in homepageInfos" :key="i">
            <input
              @click="buttonStyleFollow(1, i)"
              :style="style.inputKey"
              v-model="homepageInfos[i].column1"
              :class="checkIfBold(info.column1Bold)"
              type="text"
            />
            <input
              @click="buttonStyleFollow(2, i)"
              v-model="homepageInfos[i].value"
              :class="checkIfBold(info.valueBold)"
              :style="checkIfUnderlined(info.valueUnderlined)"
              type="text"
            />

            <v-row class="ml-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    v-if="i != 0"
                    @click="removeHomepageInfoColumn(i, homepageInfos[i].id)"
                    icon
                    dark
                    color="red"
                    x-small
                    ><v-icon>mdi-minus</v-icon></v-btn
                  >
                </template>
                <span>Delete Row</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="
                      addHomepageInfoColumn(i, homepageInfos[i].sessionOrder)
                    "
                    icon
                    dark
                    color="primary"
                    x-small
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </template>
                <span>Add Column Below</span>
              </v-tooltip>
            </v-row>
            <v-row>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ homepageInfos[i].sessionOrder }} </span>
                </template>
                <span>Auction session Order</span>
              </v-tooltip>
            </v-row>
          </v-row>
          <v-row class="pa-1 mt-2">
            <v-btn
              @click="
                addHomepageInfoColumn(
                  homepageInfos.length,
                  homepageInfos[homepageInfos.length - 1].sessionOrder + 1
                )
              "
              block
              dark
              color="grey"
              >Add info for next auction</v-btn
            >
          </v-row>
          <v-row class="pa-1">
            <v-btn @click="saveHomepageInfos()" block dark color="#45b916"
              >Save</v-btn
            >
          </v-row>
        </v-card>
        <v-row class="mt-2" justify="center">
          <v-progress-circular
            v-if="infoStatus.progress"
            :class="classStyle.infoStatus"
            indeterminate
          ></v-progress-circular>
          <div v-if="!infoStatus.progress" :class="classStyle.infoStatus">
            {{ infoStatus.message }}
          </div>
        </v-row>
      </v-col>
    </v-row>

    <v-divider class="ma-3"></v-divider>

    <v-card color="#9ed4ff" class="pa-2 mx-2 mt-2 title">Contact Info</v-card>
    <v-row>
      <v-col cols="6" class="pa-0 ma-0">
        <v-card-title class="pa-0 ma-0 mt-2 ml-5">Address</v-card-title>
        <v-card color="grey lighten-2" class="pa-5 py-2 mt-0 ma-3">
          <v-row>
            <input
              v-model="contactInfos.addressLine1"
              :style="style.inputAddress"
              type="text"
            />
          </v-row>
          <v-row>
            <input
              v-model="contactInfos.addressLine2"
              :style="style.inputAddress"
              type="text"
            />
          </v-row>
          <v-row>
            <input
              v-model="contactInfos.addressLine3"
              :style="style.inputAddress"
              type="text"
            />
          </v-row>
          <v-row>
            <input
              v-model="contactInfos.addressLine4"
              :style="style.inputAddress"
              type="text"
            />
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-0 ma-0">
        <v-card-title class="pa-0 ma-0 mt-2 ml-5">Contacts</v-card-title>
        <v-card color="grey lighten-2" class="pa-5 py-2 mt-0 ma-3">
          <v-row>
            <v-col cols="2" class="pa-0 ma-0 font-weight-bold">Tel:</v-col>
            <v-col class="pa-0 ma-0"
              ><input
                v-model="contactInfos.tel"
                :style="style.inputAddress"
                type="text"
            /></v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pa-0 ma-0 font-weight-bold">Fax:</v-col>
            <v-col class="pa-0 ma-0"
              ><input
                v-model="contactInfos.fax"
                :style="style.inputAddress"
                type="text"
            /></v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pa-0 ma-0 font-weight-bold">Email:</v-col>
            <v-col class="pa-0 ma-0"
              ><input
                v-model="contactInfos.email"
                :style="style.inputAddress"
                type="text"
            /></v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pa-0 ma-0 font-weight-bold">Map Url:</v-col>
            <v-col class="pa-0 ma-0"
              ><input
                v-model="contactInfos.mapURL"
                :style="style.inputAddress"
                type="text"
            /></v-col>
          </v-row>
          <!-- <v-row>
                        <v-col cols="2" class="pa-0 ma-0 font-weight-bold">Map URL:</v-col>
                        <v-col class="pa-0 ma-0"><input v-model="contactInfos.mapURL" :style="style.inputAddress" type="text"></v-col>
                    </v-row>           -->
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-2" justify="center">
      <v-progress-circular
        v-if="contactStatus.progress"
        :class="classStyle.contactStatus"
        indeterminate
      ></v-progress-circular>
      <div v-if="!contactStatus.progress" :class="classStyle.contactStatus">
        {{ contactStatus.message }}
      </div>
    </v-row>
    <v-row justify="center" class="py-3">
      <v-btn @click="saveContactInfos()" dark color="#45b916"
        >Save Contact Info</v-btn
      >
    </v-row>
    <v-divider class="ma-3"></v-divider>

    <v-snackbar class="mb-5" v-model="snackbar.showProgress">
      <v-row justify="center">
        <v-progress-circular color="green" indeterminate></v-progress-circular>
      </v-row>
    </v-snackbar>
    <v-snackbar
      class="mb-5"
      v-model="snackbar.showStatus"
      :timeout="snackbar.timeout"
    >
      <v-row justify="center">
        <v-col class="title">
          {{ snackbar.text }}
        </v-col>
        <v-icon :color="snackbar.color" large="" class="mx-5">{{
          snackbar.icon
        }}</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import serverConfig from "../jscontroller/serverConfig";
import storage from "../jscontroller/storage";
import authorization from "../jscontroller/authorization";
export default {
  name: "AdminPage",
  data: () => ({
    user: {},
    serverUrl: serverConfig.hostname,
    slides: [],
    homepageInfos: [],
    contactInfos: {},
    homepagePhotos: [],
    snackbar: {
      showProgress: false,
      showStatus: false,
      text: "",
      timeout: 2000,
      icon: "mdi-check-circle-outline",
      color: "green",
    },
    photoStatus: {
      message: "",
      progress: false,
    },
    bannerStatus: {
      message: "",
      progress: false,
    },
    documentStatus: {
      message: "",
      progress: false,
    },
    infoStatus: {
      message: "",
      progress: false,
    },
    contactStatus: {
      message: "",
      progress: false,
    },
    style: {
      inputKey: { backgroundColor: "white", width: "30%" },
      inputValue: { backgroundColor: "white", width: "60%" },
      inputValue2: {
        backgroundColor: "white",
        width: "60%",
        textDecoration: "underline",
      },
      inputAddress: { backgroundColor: "white", width: "100%" },
    },
    classStyle: {
      photoStatusMessage: "green--text font-weight-bold",
      bannerStatus: "green--text font-weight-bold",
    },
    textBtnStyle: {
      bold: {
        dark: false,
        color: "",
      },
      underlined: {
        dark: false,
        color: "",
      },
      rowIndex: 0,
      column: 0,
    },
  }),
  created() {},
  mounted() {
    this.user = storage.GetUser();
    if (!authorization.checkSuperAdminAuthorization(this.user)) {
      if (authorization.checkVendorAuthorization(this.user)) {
        this.$router.push("/adminPage/userManager");
      } else {
        storage.switchNavBar("website");
        this.$router.push("/");
        this.$router.go();
      }
    }
    this.scrollToTop();
    this.getBanner();
    this.getHomepageInfo();
    this.getContactInfo();
    this.getHomepagephotos();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getBanner() {
      var self = this;
      var apiUrl = serverConfig.hostname + "/api/admin/banners";
      axios.get(apiUrl).then((response) => {
        self.slides = response.data;
      });
    },
    changeTextStyle(functionName, column, index) {
      var self = this;
      if (functionName == "bold") {
        var boldState = false;
        if (column == 1) {
          boldState = self.homepageInfos[index].column1Bold;
          if (boldState == true || boldState == "true") {
            self.homepageInfos[index].column1Bold = false;
          } else {
            self.homepageInfos[index].column1Bold = true;
          }
        }
        if (column == 2) {
          boldState = self.homepageInfos[index].valueBold;
          if (boldState == true || boldState == "true") {
            self.homepageInfos[index].valueBold = false;
          } else {
            self.homepageInfos[index].valueBold = true;
          }
        }
      }
      if (functionName == "underline") {
        var underlinedState = false;
        if (column == 2) {
          underlinedState = self.homepageInfos[index].valueUnderlined;
          if (underlinedState == true || underlinedState == "true") {
            self.homepageInfos[index].valueUnderlined = false;
          } else {
            self.homepageInfos[index].valueUnderlined = true;
          }
        }
      }
      self.buttonStyleFollow(column, index);
    },
    buttonStyleFollow(column, index) {
      var self = this;
      self.textBtnStyle.rowIndex = index;
      self.textBtnStyle.column = column;
      if (column == 1) {
        if (
          self.homepageInfos[index].column1Bold == true ||
          self.homepageInfos[index].column1Bold == "true"
        ) {
          self.textBtnStyle.bold.color = "purple";
          self.textBtnStyle.bold.dark = true;
        } else {
          self.textBtnStyle.bold.color = "";
          self.textBtnStyle.bold.dark = false;
        }
      }
      if (column == 2) {
        if (
          self.homepageInfos[index].valueBold == true ||
          self.homepageInfos[index].valueBold == "true"
        ) {
          self.textBtnStyle.bold.color = "purple";
          self.textBtnStyle.bold.dark = true;
        }
        if (
          self.homepageInfos[index].valueBold != true &&
          self.homepageInfos[index].valueBold != "true"
        ) {
          self.textBtnStyle.bold.color = "";
          self.textBtnStyle.bold.dark = false;
        }

        if (
          self.homepageInfos[index].valueUnderlined == true ||
          self.homepageInfos[index].valueUnderlined == "true"
        ) {
          self.textBtnStyle.underlined.color = "purple";
          self.textBtnStyle.underlined.dark = true;
        }
        if (
          self.homepageInfos[index].valueUnderlined != true &&
          self.homepageInfos[index].valueUnderlined != "true"
        ) {
          self.textBtnStyle.underlined.color = "";
          self.textBtnStyle.underlined.dark = false;
        }
      }
    },
    saveHomepageInfos() {
      var apiUrl = serverConfig.hostname + "/api/admin/homepage/save";
      this.infoStatus.progress = true;
      var self = this;
      self.showSnackbar();
      for (var i = 0; i < this.homepageInfos.length; i++) {
        this.homepageInfos[i].id = i + 1;
        if (
          this.homepageInfos[i].valueBold != "true" &&
          this.homepageInfos[i].valueBold != true
        ) {
          this.homepageInfos[i].valueBold = "";
        }
        if (
          this.homepageInfos[i].valueUnderlined != "true" &&
          this.homepageInfos[i].valueUnderlined != true
        ) {
          this.homepageInfos[i].valueUnderlined = "";
        }
        if (
          this.homepageInfos[i].column1Bold != "true" &&
          this.homepageInfos[i].column1Bold != true
        ) {
          this.homepageInfos[i].column1Bold = "";
        }
      }

      axios
        .post(apiUrl, this.homepageInfos, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(function (response) {
          self.infoStatus.message = "Info successfully updated.";
          self.classStyle.infoStatus = "green--text font-weight-bold";
          self.infoStatus.progress = false;
          self.hideSnackbar("Info successfully updated.", "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.infoStatus.message = "Update info failed";
          self.classStyle.infoStatus = "red--text font-weight-bold";
          self.infoStatus.progress = false;
          self.hideSnackbar("Update info failed", "red");
        });
    },
    addHomepageInfoColumn(colId, sessionOrder) {
      this.homepageInfos.splice(colId + 1, 0, {
        column1: "",
        value: "",
        column1Bold: "",
        valueBold: "",
        valueUnderlined: "",
        sessionOrder: sessionOrder,
      });
    },
    removeHomepageInfoColumn(colNo, id) {
      var self = this;
      this.homepageInfos.splice(colNo, 1);
      var apiUrl = serverConfig.hostname + "/api/admin/deletecolumn/" + id;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(() => {});
    },
    checkIfBold(bool) {
      var style = "";
      if (bool === "true" || bool == true) {
        style = "font-weight-bold";
      }
      return style;
    },
    checkIfUnderlined(bool) {
      var style = "";
      if (bool === "true" || bool == true) {
        style = this.style.inputValue2;
      } else {
        style = this.style.inputValue;
      }
      return style;
    },
    getHomepageInfo() {
      var apiUrl = serverConfig.hostname + "/api/admin/homepageinfo";
      axios.get(apiUrl).then((response) => {
        this.homepageInfos = response.data;
      });
    },
    getContactInfo() {
      var apiUrl = serverConfig.hostname + "/api/admin/contactinfo";
      axios.get(apiUrl).then((response) => {
        this.contactInfos = response.data;
      });
    },
    saveContactInfos() {
      this.contactStatus.progress = true;
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/admin/contactinfo/save";
      axios
        .post(apiUrl, this.contactInfos, {
          headers: {
            Authorization: `Bearer ${self.user.jwt}`,
          },
        })
        .then(function () {
          self.contactStatus.message = "Contact Info successfully updated.";
          self.classStyle.contactStatus = "green--text font-weight-bold";
          self.contactStatus.progress = false;
          self.hideSnackbar("Contact Info successfully updated.", "green");
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.contactStatus.message = "Update Contact Info failed";
          self.classStyle.contactStatus = "red--text font-weight-bold";
          self.contactStatus.progress = false;
          self.hideSnackbar("Update Contact Info failed.", "red");
        });
    },
    getHomepagephotos() {
      var apiUrl = serverConfig.hostname + "/api/admin/homepagephotos";
      axios.get(apiUrl).then((response) => {
        let homepagePhotos = response.data;

        homepagePhotos.forEach((homepagePhoto) => {
          homepagePhoto.sessionOrder = parseInt(
            homepagePhoto.session.replace("AMS", "")
          );
        });

        homepagePhotos.sort(
          (a, b) => parseFloat(a.sessionOrder) - parseFloat(b.sessionOrder)
        );

        const auctionSession = [
          ...new Set(homepagePhotos.map((row) => row.session)),
        ];

        // set homepagePhotos to list of available auction first
        this.homepagePhotos = auctionSession;

        // each auction session push all auction session homepagephotos
        for (var i = 0; i < auctionSession.length; i++) {
          this.homepagePhotos[i] = homepagePhotos.filter(
            (homepagePhoto) => homepagePhoto.session == auctionSession[i]
          );
        }

        // eslint-disable-next-line no-console
        console.log(this.homepagePhotos);
      });
    },
    showPhotoInput(model) {
      var photoInput = null;
      // var reader = new FileReader();
      // reader.onload = () => {

      // };
      // reader.readAsDataURL(photoInput);
      if (model == "homepagePhotos") {
        this.photoStatus.progress = true;
        photoInput = this.$refs.fileInput.files[0];
        this.saveHomepagePhoto(photoInput, photoInput.size);
      }
      if (model == "banner") {
        this.bannerStatus.progress = true;
        photoInput = this.$refs.fileInputBanner.files[0];
        this.saveFile(photoInput, "image", model, "photo");
      }

      if (model == "catalogue") {
        this.documentStatus.progress = true;
        photoInput = this.$refs.fileInputCatalogue.files[0];
        this.saveFile(photoInput, "document", model, "pdf");
      }

      if (model == "tnc") {
        this.documentStatus.progress = true;
        photoInput = this.$refs.fileInputTNC.files[0];
        this.saveFile(photoInput, "document", model, "pdf");
      }
    },
    saveHomepagePhoto(file, size) {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/file/homepagephoto/upload";
      var bodyFormData = new FormData();
      bodyFormData.set("file", file);
      bodyFormData.set("size", size);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          self.homepagePhotos.push(response.data);
          self.$refs.fileInput.value = "";
          self.photoStatus.message =
            "Photo no." + self.homepagePhotos.length + " successfully saved.";
          self.classStyle.photoStatusMessage = "green--text font-weight-bold";
          self.photoStatus.progress = false;
          self.hideSnackbar(
            "Photo no." + self.homepagePhotos.length + " successfully saved.",
            "green"
          );
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.photoStatus.message = "Save photo unsuccessful";
          self.classStyle.photoStatusMessage = "red--text font-weight-bold";
          self.photoStatus.progress = false;
          self.hideSnackbar("Save photo unsuccessful", "red");
        });
    },
    saveFile(file, folderType, folderName, folderName2) {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/file/upload";
      var bodyFormData = new FormData();
      bodyFormData.set("file", file);
      bodyFormData.set("size", file.size);
      bodyFormData.set("folderType", folderType);
      bodyFormData.set("folderName", folderName);
      bodyFormData.set("folderName2", folderName2);
      axios
        .post(apiUrl, bodyFormData)
        .then(function (response) {
          if (folderName == "banner") {
            self.slides.push(response.data);
            self.$refs.fileInputBanner.value = "";
            self.bannerStatus.message =
              "Banner no." + self.slides.length + " successfully saved.";
            self.classStyle.bannerStatus = "green--text font-weight-bold";
            self.bannerStatus.progress = false;
            self.hideSnackbar(
              "Banner no." + self.slides.length + " successfully saved.",
              "green"
            );
          }
          if (folderName == "catalogue") {
            self.$refs.fileInputCatalogue.value = "";
            self.documentStatus.message = "Catalogue successfully uploaded.";
            self.classStyle.documentStatus = "green--text font-weight-bold";
            self.documentStatus.progress = false;
            self.hideSnackbar("Catalogue successfully uploaded.", "green");
          }
          if (folderName == "tnc") {
            self.$refs.fileInputTNC.value = "";
            self.documentStatus.message =
              "Terms & Condition successfully uploaded.";
            self.classStyle.documentStatus = "green--text font-weight-bold";
            self.documentStatus.progress = false;
            self.hideSnackbar(
              "Terms & Condition successfully uploaded.",
              "green"
            );
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          if (folderName == "banner") {
            self.bannerStatus.message = "Save banner unsuccessful";
            self.classStyle.bannerStatus = "red--text font-weight-bold";
            self.bannerStatus.progress = false;
            self.hideSnackbar("Save banner unsuccessful", "red");
          }
          if (folderName == "catalogue") {
            self.documentStatus.message = "Save catalogue unsuccessful";
            self.classStyle.documentStatus = "red--text font-weight-bold";
            self.documentStatus.progress = false;
            self.hideSnackbar("Save catalogue unsuccessful", "red");
          }
          if (folderName == "tnc") {
            self.documentStatus.message = "Save Terms & Condition unsuccessful";
            self.classStyle.documentStatus = "red--text font-weight-bold";
            self.documentStatus.progress = false;
            self.hideSnackbar("Save Terms & Condition unsuccessful", "red");
          }
        });
    },
    deletePhoto(url, model, photoId, index) {
      var self = this;
      self.showSnackbar();
      var apiUrl = serverConfig.hostname + "/api/file/delete";
      var bodyFormData = new FormData();
      bodyFormData.set("url", url);
      bodyFormData.set("photoId", photoId);
      bodyFormData.set("folderName", model);
      axios
        .post(apiUrl, bodyFormData)
        // eslint-disable-next-line no-unused-vars
        .then(function (response) {
          if (model == "homepagePhotos") {
            self.homepagePhotos.splice(index, 1);
            self.photoStatus.message =
              "Photo no." + (index + 1) + " successfully deleted.";
            self.classStyle.photoStatusMessage =
              "orange--text font-weight-bold";
            self.photoStatus.progress = false;
            self.hideSnackbar(
              "Photo no." + (index + 1) + " successfully deleted.",
              "orange"
            );
          }
          if (model == "banner") {
            self.slides.splice(index, 1);
            self.bannerStatus.message =
              "Banner no." + (index + 1) + " successfully deleted.";
            self.classStyle.bannerStatus = "orange--text font-weight-bold";
            self.bannerStatus.progress = false;
            self.hideSnackbar(
              "Banner no." + (index + 1) + " successfully deleted.",
              "orange"
            );
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Failed to delete photo.", "red");
        });
    },
    deleteHomePagePhotoUrl(imageUrl, auctionPhotos, index) {
      var self = this;
      self.showSnackbar();
      var apiUrl =
        serverConfig.hostname + "/api/admin/homepagephotos/photourl/delete";
      var bodyFormData = new FormData();
      bodyFormData.set("imageUrl", imageUrl);
      axios
        .post(apiUrl, bodyFormData)
        // eslint-disable-next-line no-unused-vars
        .then(function (response) {
          self.hideSnackbar(
            "Photo " + index + " remove from homepage.",
            "green"
          );
          self.homepagePhotos[auctionPhotos].splice(index, 1);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
          self.hideSnackbar("Failed to remove photo from homepage.", "red");
        });
    },
    showSnackbar() {
      this.snackbar.showProgress = true;
    },
    hideSnackbar(text, color) {
      this.snackbar.showProgress = false;
      this.snackbar.showStatus = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
      if (color == "red") {
        this.snackbar.icon = "mdi-alert-circle-outline";
      } else {
        this.snackbar.icon = "mdi-check-circle-outline";
      }
    },
  },
};
</script>

<style scoped>
.input {
  width: 40%;
  background-color: white;
}
</style>
