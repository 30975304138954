const jsPDF = require("jspdf");
require("jspdf-autotable");

function open(users){
    var doc = new jsPDF('landscape');    
    // var marginLeft = 20;  
    var marginTop = 25;  
    var lMargin=15; //left margin in mm
    var rMargin=15; //right margin in mm
    var pdfInMM=297;  // width of A4 in mm
    var pageCenter=pdfInMM/2;  

    var text1 = "REGISTERED USER LIST";
    var lines1 =doc.splitTextToSize(text1, (pdfInMM-lMargin-rMargin));
    doc.text(lines1,pageCenter,marginTop,'center');

    marginTop = marginTop + 5;

    // accountType: "Individual"
// address: "LOT 24488 JALAN SENTOSA 2"
// bankAccNo: "1"
// bankName: "CIMB Bank Berhad"
// city: "BANDAR BARU BANGI"
// email: "tsukito.hateru@gmail.com"
// firstName: "MOHD MUNAZIL BIN MOHD AZLAN"
// ic: "930713875079"
// id: 1
// phoneNo: "0189500482"
// postcode: "43650"
// pwd: "jQaI+ItfjdVFSf0Nv8jBgw=="
// representativeIc: ""
// representativeName: ""
// role: "admin"
// staff: true
// staffId: ""
// staffOf: ""
// state: "SELANGOR"
// verified: true
    let columns = [
        {title: "BIDDER/USER ID", dataKey: "id"},
        {title: "NAME/ORGANIZATION", dataKey: "firstName"},
        {title: "IC/SSM NO", dataKey: "ic"},
        {title: "PHONE NO.", dataKey: "phoneNo"},
        {title: "EMAIL", dataKey: "email"},
        {title: "STAFF", dataKey: "staffOf"},
    ];
    let rows = [];
    // doc.autoTable({margin: { top: marginTop+10 },theme:'striped',columns:columns, body:rows});
    doc.autoTable({margin: { top: marginTop-5 },theme:'striped',columns:[], body:[],styles: {fontSize: 9}});
    rows = [];
    // for(var i=0; i<itemDetails.length; i++){
    //     var itemDetail = itemDetails[i]
    //     // itemDetail.price = ringgitFormat(itemDetails[i].price)
    //     itemDetail.id = { content: itemDetails[i].id,styles: {minCellWidth: 9} },
    //     itemDetail.regnNo = { content: itemDetails[i].regnNo,styles: {minCellWidth: 18} },
    //     itemDetail.make = { content: itemDetails[i].make,styles: {minCellWidth: 18} },
    //     itemDetail.descOfGood = { content: itemDetails[i].descOfGood,styles: {minCellWidth: 23} },
    //     itemDetail.price = { content: ringgitFormat(itemDetails[i].price),styles: {minCellWidth: 30} },
    //     itemDetail.ma = { content: itemDetails[i].ma,styles: {minCellWidth: 25} },
    //     itemDetail.year = { content: itemDetails[i].year,styles: {minCellWidth: 11} },
    //     itemDetail.vendor = { content: itemDetails[i].vendor,styles: {minCellWidth: 18} },
    //     itemDetail.location = { content: itemDetails[i].location,styles: {minCellWidth: 18} },
    //     itemDetail.remarks = { content: itemDetails[i].remarks,styles: {minCellWidth: 20} },
    //     rows.push(itemDetail)
    // }
    marginTop = 25;
    rows = users
    doc.autoTable({margin: { top: marginTop },theme:'striped',columns:columns, body:rows,styles: {fontSize: 8}});
            
    
    window.open(doc.output('bloburl'), '_blank');
}

// eslint-disable-next-line no-unused-vars
function checkMarginTop(marginTop, limit){
    if(marginTop > limit){
        return 25;
    }
    else
        return marginTop;
}

// eslint-disable-next-line no-unused-vars
function ringgitFormat(amount){
    if(amount == null){
        return "RM " + (0).toFixed(2);
    }
    else{
        var num = amount.toLocaleString(undefined, {maximumFractionDigits:2})
        var deci = num.split(".")[1]
        if(deci != null){
        if(deci.length == 1){
            num = num + "0"
        }
        
        }
        else{
        num = num + ".00"
        }
        return "RM " + num;
    }
}

module.exports = {open};