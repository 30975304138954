<template>
    <v-navigation-drawer
        :permanent="true"        
        height="100%"
        class="pa-5"
        >
        <v-list
            dense
        >
            <!-- <v-list-item >
                <v-list-item-content class="subtitle-1 font-weight-bold pa-0">My Account</v-list-item-content>
            </v-list-item>
            <v-list-item>
                fewf
            </v-list-item> -->
            <div class="subtitle-1 font-weight-bold pa-0 orange--text">My Account</div>
            <div @click="navigate('/myaccount/bidonline')" class="ml-3">
                <!-- <v-badge v-if="noOfLoseBid > 0" color="red" :content="noOfLoseBid">
                    <span :class="classStyle.bidInProgress">Bid In Progress</span>
                </v-badge>
                <span v-if="!noOfLoseBid > 0" :class="classStyle.bidInProgress">Bid In Progress</span> -->
                <span :class="classStyle.bidInProgress">Bid In Progress</span>
            </div>
            <div @click="navigate('/myaccount/mybid')" class="ml-3"><span :class="classStyle.myBid">My Bid</span></div>
            <div @click="navigate('/myaccount/transaction')" class="ml-3"><span :class="classStyle.transaction">Transaction</span></div>
            <div @click="navigate('/myaccount/myprofile')" class="ml-3"><span :class="classStyle.myprofile">My Profile</span></div>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import axios from "axios"
// import io from "socket.io-client";
import serverConfig from "../jscontroller/serverConfig"
import storage from "../jscontroller/storage"
export default {    
    data () {
      return {
        classStyle:{
            myBid: "",
            bidInProgress: "",
            transaction: "",
            myprofile: "",
        },
        serverUrl: serverConfig.hostname,    
        // socket: {},
        user: {},
        userInLot: [],
        LotSocket: [],
        noOfLoseBid: 0,
      }
    },
    created(){
        this.user = storage.GetUser();
        // this.socket = io.connect(serverConfig.hostnameSocket, {path: '/socket.io'}); 
    },
    mounted(){
        this.highlightPage()  
        this.getAllLotUserIsIn(this.user.id);
        // this.socket.on("updateBidInProgress", ()=>{
        //     this.getAllLotUserIsIn(this.user.email);
        // });             
    },
    methods: {
        navigate: function(pagePath){
            if(this.$route.path != pagePath){
                this.$router.push(pagePath);
            }  
        },
        highlightPage(){
            var self = this
            if(self.$route.path == "/myaccount/mybid")
                self.classStyle.myBid = "font-weight-bold"  
            if(self.$route.path == "/myaccount/bidonline")
                self.classStyle.bidInProgress = "font-weight-bold"   
            if(self.$route.path == "/myaccount/transaction")
                self.classStyle.transaction = "font-weight-bold"  
            if(self.$route.path == "/myaccount/myprofile")
                self.classStyle.myprofile = "font-weight-bold"  
        },
        getAllLotUserIsIn(id){
            var self = this;
            var apiUrl = serverConfig.hostname + "/api/socket/userinlot/findall";
            var bodyFormData = new FormData();
            bodyFormData.set('userId', id);      
            axios.post(apiUrl, bodyFormData)
            .then(function (response) {
                self.userInLot = response.data; 
                self.LotSocket = [];
                for(var i=0; i<self.userInLot.length; i++){
                self.getAuctionSocketById(self.userInLot[i].lotNo)
                }        
            })
            .catch(function () {            
            });
            },  
        getAuctionSocketById(id){
            var self = this;
            var apiUrl = serverConfig.hostname + "/api/socket/auctionlot/"+id;
            axios.get(apiUrl)
            .then(function (response) {
                if(response.data == ""){
                self.LotSocket.push({id:id, latestBid:0, latestEmail: "", bidderNo: null})        
                }
                else
                self.LotSocket.push(response.data)  
                
                self.countLoseBid(self.LotSocket)
            })
            .catch(function () {})
        },
        countLoseBid(lotSocket){
            var self = this;
            var tempNo = 0;
            for(var i=0; i<lotSocket.length; i++){
                if(lotSocket[i].bidderNo != self.user.id){
                    tempNo = tempNo + 1
                }
            }
            self.noOfLoseBid = tempNo
        }
    },
    
}
</script>