<template>
    <div v-resize="onResize" class="ma-1 pa-1">
        <v-col class="pa-1 ma-0 px-2">
            <v-card dark="" class="title">
                <v-row class="pa-3 ma-0 ml-2">Tutorial</v-row>
            </v-card>
            <!-- <v-row class="pa-1 ma-1 mb-0 pb-0 title">FPX</v-row> -->
        
        </v-col>
        <v-row class="pa-0 ma-0">
            <v-col class="pa-1 ma-0 px-2">
                <v-stepper v-model="e6" vertical>
                    <v-stepper-step editable="" step="1">
                    Registration
                    <!-- <small>Summarize if needed</small> -->
                    </v-stepper-step>

                    <v-stepper-content editable step="1">
                    <v-card color="grey lighten-1" class="mb-2"  :height="resizeVideo(windowSize.x)">
                        <iframe ref="iframe1" height="100%" width="100%"
                        :src="videoUrl[0]">
                        </iframe>
                    </v-card>
                    <div><span class="font-weight-bold">1.</span> Go to login page and click on the registration link.</div>
                    <div><span class="font-weight-bold">2.</span> Enter all important information and click register.</div>
                    <div><span class="font-weight-bold">3.</span> After the registration is complete, an email will be sent for account verification.</div>
                    <div><span class="font-weight-bold">4.</span> Click on the "Verify" button and it will redirect you to the verification page.</div>
                    <div><span class="font-weight-bold">5.</span> After the verification popup message was shown, you can now log into the website using your email and password.</div>
                    <v-btn color="primary" @click="e6 = 2">Continue</v-btn>
                    </v-stepper-content>

                    <v-stepper-step editable step="2">View Auction Lot & Sold Lot</v-stepper-step>

                    <v-stepper-content  step="2">
                    <v-card color="grey lighten-1" class="mb-2"  :height="resizeVideo(windowSize.x)">
                        <iframe ref="iframe1" height="100%" width="100%"
                        :src="videoUrl[1]">
                        </iframe>
                    </v-card>
                    <div><span class="font-weight-bold">1.</span> Click on the "ONLINE AUCTION" tab to view all the lot available for auction.</div>
                    <div><span class="font-weight-bold">2.</span> Click on the "Sold" link to view all sold lot for the day of the auction.</div>
                    <div><span class="font-weight-bold">3.</span> Click on the "View Item"/"View Details" button to view the details of the item lot.</div>
                    <v-btn color="primary" @click="e6 = 3">Continue</v-btn>
                    <v-btn @click="e6 = e6-1">Previous</v-btn>
                    </v-stepper-content>

                    <v-stepper-step editable step="3">Change Profile Info</v-stepper-step>

                    <v-stepper-content step="3">
                    <v-card color="grey lighten-1" class="mb-2"  :height="resizeVideo(windowSize.x)">
                        <iframe ref="iframe1" height="100%" width="100%"
                        :src="videoUrl[2]">
                        </iframe>
                    </v-card>             
                    <div><span class="font-weight-bold">1.</span> Click on "MY ACCOUNT" tab and select "My Profile" to view your profile.</div>
                    <div><span class="font-weight-bold">2.</span> You can modify any of your personal information on this page.</div>
                    <div><span class="font-weight-bold">3.</span> Click on "Save Changes" to save all your modified infos.</div>           
                    <v-btn color="primary" @click="e6 = 4">Continue</v-btn>
                    <v-btn @click="e6 = e6-1">Previous</v-btn>
                    </v-stepper-content>

                    <v-stepper-step editable step="4">Topping up Deposit Balance</v-stepper-step>
                    <v-stepper-content step="4">
                    <v-card color="grey lighten-1" class="mb-2"  :height="resizeVideo(windowSize.x)">
                        <iframe ref="iframe1" height="100%" width="100%"
                        :src="videoUrl[3]">
                        </iframe>
                    </v-card>
                    <div><span class="font-weight-bold">1.</span> Click on "MY ACCOUNT" tab and select "Transaction" to view your deposit balance.</div>
                    <div><span class="font-weight-bold">2.</span> Click the "TOP UP" button to increase your deposit balance.</div>
                    <div><span class="font-weight-bold">3.</span> Enter the amount you wish to add and click "PAY WITH FPX".</div>           
                    <div><span class="font-weight-bold">4.</span> You will be redirected to an online payment page.</div>           
                    <div><span class="font-weight-bold">5.</span> Choose your desired Online Banking options and proceed to payment.</div>
                    <div><span class="font-weight-bold">6.</span> After a successfull payment, you will be redirected to the transaction page.</div>
                    <div><span class="font-weight-bold">7.</span> Refresh the page to view your current deposit balance.</div>
                    <v-row justify="center" class="font-weight-bold">OR</v-row>
                    <div><span class="font-weight-bold">1.</span> If you have an insufficient deposit balance, a top up into your deposit balance is required to participate in an auction.</div>
                    <div><span class="font-weight-bold">2.</span> Click on the link(Click here to topup deposit balance) provided to go the "Transaction" page.</div>
                    <div><span class="font-weight-bold">3.</span> Repeat step 2-7 as the above, to proceed in the topup process.</div>           
                    
                    <v-btn color="primary" @click="e6 = 5">Continue</v-btn>
                    <v-btn @click="e6 = e6-1">Previous</v-btn>
                    </v-stepper-content>

                    <v-stepper-step editable step="5">Bidding on a Lot</v-stepper-step>
                    <v-stepper-content step="5">
                    <v-card color="grey lighten-1" class="mb-2"  :height="resizeVideo(windowSize.x)">
                        <iframe ref="iframe1" height="100%" width="100%"
                        :src="videoUrl[4]">
                        </iframe>
                    </v-card>
                    <div><span class="font-weight-bold">1.</span> Click on the "ONLINE AUCTION" tab to view all the lot available for auction.</div>
                    <div><span class="font-weight-bold">2.</span> Click on the "View Item" button to view the details of the item lot.</div>
                    <div><span class="font-weight-bold">3.</span> Click on the "Participate Auction" button.</div>
                    <div><span class="font-weight-bold">4.</span> If you are the first bidder for the lot, you can click on the "Start Bid" buttton to initiate the bidding.</div>
                    <div><span class="font-weight-bold">5.</span> The ongoing bidding price will be displayed on any page that shows the lot in auction.</div>
                    <div><span class="font-weight-bold">6.</span> If you have been outbid, you can raise the bid to reclaim your position as the highest bidder.</div>
                    <div><span class="font-weight-bold">7.</span> To keep in track of every lot you have participated, click on the "MY ACCOUNT" tab and select "Bid In Progress".</div>
                    <div><span class="font-weight-bold">8.</span> Click "Go to Auction Page" button to immediately go the specified item lot page, to resume your bidding.</div>
                    <v-btn color="primary" @click="e6 = 6">Continue</v-btn>
                    <v-btn @click="e6 = e6-1">Previous</v-btn>
                    </v-stepper-content>

                    <v-stepper-step editable step="6">Add and Subtract Bidding Value</v-stepper-step>
                    <v-stepper-content step="6">
                    <v-card color="grey lighten-1" class="mb-2"  :height="resizeVideo(windowSize.x)">
                        <iframe ref="iframe1" height="100%" width="100%"
                        :src="videoUrl[5]">
                        </iframe>
                    </v-card>
                    <div><span class="font-weight-bold">1.</span> Go to any Auction Lot page that the bidder is currently bidding. Auction Lot page can be access from "Bid In Progress" or from the Online Auction page.</div>
                    <div><span class="font-weight-bold">2.</span> Once on an Auction lot, to add bidding value, simply press the green "+" button.</div>
                    <div><span class="font-weight-bold">3.</span> To substract bidding value, simply press the red "-" button.</div>
                    <div><span class="font-weight-bold">4.</span> Once you're happy with the value, click on "Raise Bid"</div>
                    <div><span class="font-weight-bold">5.</span> Click on "yes" to confirm your bidding raise.</div>
                    <v-btn color="primary" @click="e6 = 7">Continue</v-btn>
                    <v-btn @click="e6 = e6-1">Previous</v-btn>
                    </v-stepper-content>                    

                    <v-stepper-step editable step="7">Won a lot</v-stepper-step>
                    <v-stepper-content step="7">
                    <v-card color="grey lighten-1" class="mb-2"  :height="resizeVideo(windowSize.x)">
                        <iframe ref="iframe1" height="100%" width="100%"
                        :src="videoUrl[6]">
                        </iframe>
                    </v-card>
                    <div><span class="font-weight-bold">1.</span> After an auction session is over, you will be redirected to "My Bid" page.</div>
                    <v-row justify="center" class="font-weight-bold">OR</v-row>
                    <div><span class="font-weight-bold">1.</span> Click on the "MY ACCOUNT" tab and select "My Bid".</div>
                    <div><span class="font-weight-bold">2.</span> You can view all of the items that you have won from an auction, here.</div>
                    <div><span class="font-weight-bold">3.</span> You can view and download the receipt of the items you've won by clicking on the "View Receipt" button.</div>
                    <div><span class="font-weight-bold">4.</span> You can also view and download the memorandum by clicking on the "Memorandum of Sales" button.</div>
                    <v-btn color="primary" @click="e6 = 1">First</v-btn>
                    <v-btn @click="e6 = e6-1">Previous</v-btn>
                    </v-stepper-content>
                </v-stepper>
            </v-col>

            <v-col cols="3" class="d-none d-md-flex pr-0 ">
                <HelpNav></HelpNav>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import HelpNav from '@/components/HelpNav'
import serverConfig from "../jscontroller/serverConfig"
export default {
    // eslint-disable-next-line vue/no-unused-components
    components: {HelpNav},
    data: ()=>({
       serverUrl: serverConfig.hostname,
       e6: 1,
       windowSize: {
        x: 0,
        y: 0,
        },
        videoUrl: [
            "https://www.youtube.com/embed/zdjefESM0XQ",
            "https://www.youtube.com/embed/IKWQcu8984E",
            "https://www.youtube.com/embed/vdsCBMOuCTM",
            "https://www.youtube.com/embed/cWh67LztkmU",
            "https://www.youtube.com/embed/55bM9qNLn1A",
            "https://www.youtube.com/embed/iXwlO4tfDn4",
            "https://www.youtube.com/embed/Zp9uGjz-BEc"
        ]
    }),
    mounted () {
      this.onResize()
    },
    methods:{
        onResize () {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },
        resizeVideo(x){
            if(x > 700)
                return (450)
            else
                return x/2
        }
    }
    
    
}
</script>