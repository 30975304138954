function downloadUserWithDepositBalance(users) {
  const headers = {
    bidderNo: "BIDDER NO",
    name: "NAME",
    email: "EMAIL",
    depositBalance: "DEPOSIT BALANCE",
  };

  const today = new Date();

  const year = today.toLocaleString("default", { year: "numeric" });
  const month = today.toLocaleString("default", { month: "2-digit" });
  const day = today.toLocaleString("default", { day: "2-digit" });

  const dateString = `${year}-${month}-${day}`;

  const fileTitle = `User with deposit balance - ${dateString}`;

  let items = [];
  users.forEach((user) => {
    items.push({
      bidderNo: user.id,
      name: user.firstName,
      email: user.email,
      depositBalance: user.depositBalance,
    });
  });

  exportCSVFile(headers, items, fileTitle);
}

function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle + ".csv" || "export.csv";

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

function convertToCSV(objArray) {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ",";

      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
}

module.exports = { downloadUserWithDepositBalance };
